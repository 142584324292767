/**
 * @file auth.guard.ts
 */
 import { Injectable } from '@angular/core';
 import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
 import { Observable } from 'rxjs';
 import { AuthService } from '../../services/auth/auth.service';
 import { environment } from '../../../../environments/environment';
 
 @Injectable({
   providedIn: 'root'
 })
 export class AzureB2CAuthGuard implements CanActivate {
 
   constructor(
     private readonly authService: AuthService
   ) { }
 
   canActivate(
     route: ActivatedRouteSnapshot,
     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
 
     const fragment = route.fragment;
     if (fragment) {
      const token = new URLSearchParams(fragment).get('id_token')
       const errorDescription = new URLSearchParams(fragment).get('error_description');
       if (token) {
         // handle token
         this.authService.login(token);
         return true;
       } else {
         if (errorDescription?.split(':').includes('AADB2C90118')) {
           // user forgotpassword flow
           this.resetPass();
         } else {
           this.login();
         }
         return false;
       }
     } else {
       if (this.authService.isAuthorizedByAzureB2c) {
         return true;
       } else {
         this.login();
         return false;
       }
     }
 
   }
 
   public resetPass(): void {
     window.location.replace(environment.b2cForgotPassFlow);
   }
 
   public login(): void {
     window.location.replace(environment.b2cSignInSignUpFlow);
   }
 
 }
 
