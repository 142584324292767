/**
 * @file header.component.ts
 */
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from '../../../app.constants';
import { LogoutModalComponent } from '../logout-modal/logout-modal.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilService } from '../../../services/util/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isSideBarClosed: boolean;
  userName: string;
  @Input() public showSideBarMenu: boolean = true;
  @Input() public showClientName: boolean = true;
  @Output() sideBarToggle: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  clientName: string;
  public subscription: Subscription = new Subscription();

  constructor(public dialog: MatDialog, public router: Router, private readonly sharedService: SharedService, private utilService: UtilService) { }

  ngOnInit(): void {
    this.utilService.sidebarClose.subscribe(v => this.isSideBarClosed = v);
    this.userName = sessionStorage.getItem(AppConstants.LABELS.userName);
    this.clientName = sessionStorage.getItem(AppConstants.LABELS.clientName);

    if (!this.userName) {
      this.subscription.add(this.sharedService.getClients().subscribe((response) => {
        sessionStorage.setItem(AppConstants.LABELS.userName, response.userName);
        this.userName = response.userName;
      }));
    }
  }

  /**
  * @description This method is used to open the logout modal.
  * @returns void
  */
  public openDialog(): void {
    this.dialog.open(LogoutModalComponent, {
      autoFocus: false,
      minWidth: window.innerWidth < 766 ? 'auto' : 400,
      disableClose: true
    });
  }

  /**
   * @description This method is used to toggle the sidebar reports/menus.
   * @returns void
   */
  public toggleSidebar(): void {
    this.isSideBarClosed = !this.isSideBarClosed;
    this.sideBarToggle.emit(this.isSideBarClosed);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
