import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { LoaderService } from '../../services/loader/loader.service';
import { Observable, Subscription, of } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { MemberDetails } from 'src/app/interfaces/member.interface';
import { SaveAuditLogsRequestInterface } from 'src/app/interfaces/audit-logs.interface';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import {
  formatDate,
} from 'src/app/util';
import { MemberGroupInterface } from 'src/app/interfaces/member-group.interface';
import { AppConstants } from 'src/app/app.constants';
import { RequestIDCardRequestInterface } from 'src/app/interfaces/request-card.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/core/components/error/error.component';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { SharedService } from '../../../services/shared/shared.service';
import { HttpErrorResponse } from '@angular/common/http';

interface ReasonInterface {
  reasonCode: string;
  reason: string;
}
@Component({
  selector: 'app-request-id-card',
  templateUrl: './request-id-card.component.html',
  styleUrls: ['./request-id-card.component.scss'],
})
export class RequestIdCardComponent implements OnInit, OnDestroy {
  public requestDuplicateIDCardForm: FormGroup;
  mailToList: string[] = ['abc@example.com', 'def@example.com'];
  addresses: string[] = ['22, blue street', '34 red street'];
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  // reasons: string[] = ['lost', 'other'];
  reasons: ReasonInterface[] = [
    { reasonCode: '1', reason: 'Lost ID card' },
    { reasonCode: '2', reason: 'Need extra card for dependent' },
    { reasonCode: '3', reason: 'Card never received' },
    { reasonCode: '4', reason: 'Name misspelled' },
    { reasonCode: '5', reason: 'Other' },
  ];
  public maxDate = new Date('12/31/9999');

  chosenReason: ReasonInterface = {
    reason: '',
    reasonCode: '',
  };
  public minDate = new Date();
  private initialUpdateMemberFormValues: any;
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  clientId: string = '';
  cardId: string = '';
  personCode: string = '';
  public memberDetails: MemberDetails;
  groupInfo: MemberGroupInterface = {
    groupId: '',
    groupName: '',
  };
  groupInfo$: Observable<MemberGroupInterface> = new Observable();
  subscription: Subscription;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  constructor(
    private formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly appInsightsService: AppInsightsService,
    private eligibilityService: EligibilityService,
    private loader: LoaderService,
    private activatedRoute: ActivatedRoute,
    private auditLogsService: AuditLogsService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.REQUEST_ID_CARD);
    const requiredValidator = Validators.required;
    this.requestDuplicateIDCardForm = this.formBuilder.group({
      // mailTo: ['', [requiredValidator]],
      // address: ['', [requiredValidator]],
      cardIssueDate: ['', [requiredValidator]],
      reason: ['', requiredValidator],
      otherReason: [''],
    });

    this.initialUpdateMemberFormValues = {
      ...this.requestDuplicateIDCardForm.value,
    };
    this.getSelectedMemberDetails();
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public checkIfAnythingUpdated(): boolean {
    return (
      JSON.stringify(this.requestDuplicateIDCardForm.value) !==
      JSON.stringify(this.initialUpdateMemberFormValues)
    );
  }

  getSelectedMemberDetails() {
    this.loader.showLoader();
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        return this.eligibilityService.getMemberDetailsById(res).pipe(
          map((res) => res[0]),
          tap((resp) => {
            // console.log('Res:', resp);
            this.memberDetails = resp;
            this.clientId = resp.clientId;
            this.cardId = resp.cardId;
            this.personCode = resp.personCode;
            this.groupInfo$ = this.eligibilityService
              .getGroupInfo(
                this.memberDetails.clientId,
                this.memberDetails.coverage.groupNumber
              )
              .pipe(
                tap((res) => {
                  this.groupInfo = res;
                  // console.log('group Info', res);
                })
              );
            this.loader.hideLoader();
          }),
          catchError((err) => {
            this.loader.hideLoader();
            return of([]);
          })
        );
      })
    );
  }

  chooseReason(event) {
    // console.log('Reason:', reason);
    const reason = this.reasons.filter(r => r.reasonCode === event.value)[0];
    this.chosenReason.reasonCode = reason.reasonCode;
    this.chosenReason.reason = reason.reason;
  }

  createAuditLog(success: boolean, err?: HttpErrorResponse) {
    this.loader.showLoader();
    const reqBody: SaveAuditLogsRequestInterface = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
      personCd: this.memberDetails.personCode,
      firstName: this.memberDetails.firstName,
      lastName: this.memberDetails.lastName,
      emailId: this.memberDetails.email && this.memberDetails.email.toLowerCase() !== 'null'
        ? this.memberDetails.email
        : this.authService.userEmail,
      birthDt: this.memberDetails.DOB,
      groupNo: this.groupInfo.groupId,
      groupName: this.groupInfo.groupName,
      changes: 'Requested Duplicate ID card',
      comments: JSON.stringify({
        dateRequested: new Date(
          this.requestDuplicateIDCardForm.value.cardIssueDate
        ).toISOString(),
        comment: `Reason: ${this.chosenReason.reason}`,
        reasonCode: `${this.chosenReason.reasonCode}`,
      }),
      addedBy: this.authService.userEmail,
      isSuccess: success
    };
    if (!success) {
      const message = err?.error?.Message ? err.error.Message : JSON.stringify(err);
      reqBody.httpStatusCode = err.status,
        reqBody.errorMsg = message
    }
    // console.log('Request Body create audit log:', reqBody);
    this.auditLogsService.saveAuditLog(reqBody).subscribe({
      next: (res) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_SUCCESS, { Response: res });
      },
      error: (err) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_ERROR, { Response: err });
        this.loader.hideLoader();
      },
      complete: () => {
        this.requestDuplicateIDCardForm.reset();
        this.loader.hideLoader();
      },
    });
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  requestIDCard() {
    // const reqBody: RequestIDCardRequestInterface = {
    //   clientId: this.memberDetails.clientId,
    //   cardId: this.memberDetails.cardId,
    //   patientId: this.memberDetails.patientId,
    //   personCode: this.memberDetails.personCode,
    //   cardIssueDate: formatDate(
    //     this.requestDuplicateIDCardForm.value.cardIssueDate
    //   ),
    //   reason: this.chosenReason.reason,
    // };
    this.loader.showLoader();
    const reqBody: RequestIDCardRequestInterface = {
      patientIds: [+this.memberDetails.patientId],
      cardRequestDate: formatDate(
        this.requestDuplicateIDCardForm.value.cardIssueDate
      ),
      cardsRequested: 1,
    };
    // if (this.requestDuplicateIDCardForm.get('reason').value === '5')
    //   reqBody.otherReason = this.requestDuplicateIDCardForm.value.otherReason;
    this.subscription = this.eligibilityService.requestCard(reqBody).subscribe({
      next: (res) => {
        // console.log('Response on request card:', res);
      },
      error: (err) => {
        // console.log('Error on request card:', err);
        this.createAuditLog(false, err);
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
        this.createAuditLog(true);
        const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
          ErrorComponent,
          {
            width: '300px',
            data: {
              title: 'Success',
              message: 'ID Card requested successfully',
            },
          }
        );
        dialogRef.afterClosed().subscribe(() => {
          this.navigateBackToMemberSearch();
        });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
