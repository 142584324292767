import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocal',
})
export class UtcToLocalPipe implements PipeTransform {
  transform(timestamp: string): string {
    try {
      if (timestamp) {
        const date = new Date(`${timestamp}Z`);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to the month to account for zero-based index
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      }
      return '';
    } catch (err) {
      console.error(
        `Could not parse UtcToLocalPipe timestamp: ${timestamp}`,
        err
      );
      return '';
    }
  }
}
