import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MaterialModule } from '../core/modules/material.module';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { FooterComponent } from './components/footer/footer.component';
import { WhoWeAreComponent } from './components/who-we-are/who-we-are.component';
import { CustomMatHintComponent } from './components/custom-mat-hint/custom-mat-hint.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeStampToDatePipe } from './pipe/time-stamp-to-date.pipe';
import { SsnFormatDirective } from './directive/ssn-format.directive';
import { PhoneNumberFormatDirective } from './directive/phone-number-format.directive';
import { UtcToLocalPipe } from './pipe/utc-to-local.pipe';
import { CustomMatSortDirective } from './directive/custom-mat-sort.directive';
import { DowntimeDialogComponent } from './components/downtime-dialog/downtime-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    SanitizeHtmlPipe,
    LogoutModalComponent,
    TermsAndConditionsComponent,
    PrivacyStatementComponent,
    FooterComponent,
    WhoWeAreComponent,
    CustomMatHintComponent,
    TimeStampToDatePipe,
    UtcToLocalPipe,
    SsnFormatDirective,
    PhoneNumberFormatDirective,
    CustomMatSortDirective,
    DowntimeDialogComponent
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [
    HeaderComponent,
    SidebarComponent,
    SanitizeHtmlPipe,
    FooterComponent,
    CustomMatHintComponent,
    TimeStampToDatePipe,
    UtcToLocalPipe,
    SsnFormatDirective,
    CustomMatSortDirective,
    PhoneNumberFormatDirective,
    DowntimeDialogComponent
  ],
  providers: [SanitizeHtmlPipe],
})
export class SharedModule { }
