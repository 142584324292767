<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <div class="page-header">
    <h2>Update Coverage</h2>
  </div>
  <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <div class="detail-card">
      <div class="client-id-name">
        <h4>Member</h4>
        <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
      </div>
      <div class="mat-row">
        <div class="mat-2">
          <label class="card-label">Name</label>
          <p>
            {{ selectedMemberDetails.firstName }}
            {{ selectedMemberDetails.lastName }}
          </p>
        </div>
        <div class="mat-2">
          <label class="card-label">Person Code</label>
          <p>{{ selectedMemberDetails.personCode }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">DOB</label>
          <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Relationship</label>
          <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Card/Alt ID</label>
          <p>{{ selectedMemberDetails.cardId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Coverage Type</label>
          <p>
            {{
            displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="custom-tab">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Update Coverage">
          <div fxLayoutAlign="end end" class="mt-3">
            <button mat-stroked-button rounded color="accent" (click)="addNewCoverage('addCoverage')"
              class="no-border-btn" [disabled]="isActiveCoverage || coverage?.length === 0">
              Add New Coverage
            </button>
          </div>
          <div class="table-filter mb-2">
            <div class="filter">
              <div role="radiogroup" id="radio-group-orders" class="custom-radio">
                <p aria-labelledby="radio-btn-recent-label">
                  <input type="radio" role="radio" id="radio-btn-recent" name="radio-btn-recent-past" [value]="false"
                    [checked]="true" (click)="onTabClick('active')" />
                  <label id="radio-btn-recent-label" for="radio-btn-recent">Active</label>
                </p>
                <p aria-labelledby="radio-btn-past-label">
                  <input type="radio" role="radio" id="radio-btn-past" name="radio-btn-recent-past" [value]="true"
                    (click)="onTabClick('all')" />
                  <label id="radio-btn-past-label" for="radio-btn-past">All</label>
                </p>
              </div>
            </div>
            <div class="active-member" *ngIf="isActiveCoverage && coverage?.length > 0">
              <p>
                Only one active member benefit can exist at a time.
              </p>
            </div>
          </div>

          <div class="update-table" *ngIf="this.coverageDetails$ | async as coverage">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
              <ng-container matColumnDef="group">
                <th mat-header-cell *matHeaderCellDef>Group</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.groupName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef>Client</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.clientName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="effectiveDate">
                <th mat-header-cell *matHeaderCellDef>Effective Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.startDate | timeStampToDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.endDate | timeStampToDate }}
                </td>
              </ng-container>
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon class="tr-btn-icon" matListIcon
                    *ngIf="element.active === 'True' "
                    (click)="updateCoverage('updateCoverage', element)">edit</mat-icon>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"></tr>
              <tr class="mat-row no-data-row" *matNoDataRow>
                <td class="mat-cell" colspan="9" role="status">
                  No Data Found
                </td>
              </tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab *ngIf="
        availableFlexTag?.length
          " label="Add/Update Coverage Type">
          <div class="mat-12">
            <div fxLayoutAlign="end end" class="pad-1">
              <button mat-stroked-button rounded color="accent" (click)="addNewCoverage('addRx')" class="no-border-btn">
                Add Coverage Type
              </button>
            </div>
          </div>
          <!-- <p fxLayoutAlign="end end" *ngIf="!allDatesInThePast(allEndDatesArray)">
            Only one active member benefit can exist at a time.
          </p> -->

          <div *ngIf="this.memberRxDetails$ | async as memberRxDetails" class="update-table">
            <table mat-table [dataSource]="dataSourceRx" multiTemplateDataRows class="mat-elevation-z8">
              <ng-container matColumnDef="memberRxType">
                <th mat-header-cell *matHeaderCellDef>Coverage Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ coverageTypes[element.flexTagName]}}
                </td>
              </ng-container>

              <ng-container matColumnDef="effectiveDate">
                <th mat-header-cell *matHeaderCellDef>Effective Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.flexTagStartDate | timeStampToDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.flexTagEndDate | timeStampToDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon class="tr-btn-icon" matListIcon *ngIf="element.active === 'True'"
                    (click)="updateCoverage('updateRx', element)">edit</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsRx"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumnsRx" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"></tr>
              <tr class="mat-row no-data-row" *matNoDataRow>
                <td class="mat-cell" colspan="9" role="status">
                  No Data Found
                </td>
              </tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</div>