<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <!-- Page Header -->
  <div class="page-header">
    <h2>Request ID Card</h2>
  </div>

  <ng-container *ngIf="groupInfo$ | async as groupInfo"></ng-container>
  <!-- Member Detail card -->
  <div class="detail-card" *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <div class="client-id-name">
      <h4>Member</h4>
      <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
    </div>
    <div class="mat-row">
      <div class="mat-2">
        <label class="card-label">Name</label>
        <p>
          {{ selectedMemberDetails.firstName }}
          {{ selectedMemberDetails.lastName }}
        </p>
      </div>
      <div class="mat-2">
        <label class="card-label">Person Code</label>
        <p>{{ selectedMemberDetails.personCode }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">DOB</label>
        <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Relationship</label>
        <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Card/Alt ID</label>
        <p>{{ selectedMemberDetails.cardId }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Coverage Type</label>
        <p>
          {{
          displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
          }}
        </p>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <h3 class="form-heading mar-b-half">Request a Duplicate ID Card</h3>
  <form [formGroup]="requestDuplicateIDCardForm">
    <div class="form-wrapper">
      <div class="mat-row pad-b-1 search-filter">
        <div class="mat-6 pad-r-2">
          <mat-label>Card Issue Date<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" formControlName="cardIssueDate"
              placeholder="Enter Issue Date (MM/DD/YYYY)" maxlength="10" />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="requestDuplicateIDCardForm"
            controlName="cardIssueDate"></app-custom-mat-hint>
        </div>
        <div class="mat-6">
          <mat-label>Reason<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Reason" (selectionChange)="chooseReason($event)" formControlName="reason">
              <mat-option *ngFor="let reason of reasons" [value]="reason.reasonCode">{{ reason.reason }}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="requestDuplicateIDCardForm" controlName="reason"></app-custom-mat-hint>
        </div>
      </div>

      <div *ngIf="requestDuplicateIDCardForm.get('reason').value === '5'" class="mat-row pad-b-1 search-filter">
        <div class="mat-6 pad-r-2"></div>
        <div class="mat-6">
          <mat-label>Other</mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <textarea matInput placeholder="Specify Reason" formControlName="otherReason"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="mat-row button-row search-filter">
        <div class="mat-6" fxLaoutAlign="start start">
          <p class="note mar-b-0">
            *Please note: Additional costs may apply with requests for Duplicate
            ID cards.
          </p>
        </div>
        <div class="mat-6 action-items" fxLayoutAlign="end end">
          <button mat-button class="primary-btn" [disabled]="requestDuplicateIDCardForm.invalid"
            (click)="requestIDCard()">
            Save
          </button>
          <button mat-button class="secondary-btn" (click)="navigateBackToMemberSearch()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
  <!-- <form [formGroup]="requestDuplicateIDCardForm">
    <div class="form-wrapper">
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-2">
          <mat-form-field floatLabel="never" class="full-width">
            <mat-label>Mail To*</mat-label>
            <mat-select
              placeholder="Select"
              formControlName="mailTo"
              matNativeControl
            >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mat-6">
          <mat-form-field floatLabel="never" class="full-width">
            <mat-label>Address*</mat-label>
            <mat-select placeholder="Select Address" formControlName="address">
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-2">
          <mat-label>Card Issue Date*</mat-label>
          <mat-form-field
            floatLabel="never"
            class="full-width mat-datepicker-alignment"
          >
            <input
              matInput
              [matDatepicker]="issueDate"
              placeholder="Enter Issue Date"
              formControlName="issueDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="issueDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #issueDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="mat-6">
          <mat-form-field floatLabel="never" class="full-width">
            <mat-label>Reason*</mat-label>
            <mat-select placeholder="Select Reason" formControlName="reason">
              <mat-option value="other">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field
        floatLabel="never"
        class="full-width"
        *ngIf="requestDuplicateIDCardForm.get('reason').value === 'other'"
      >
        <textarea
          matInput
          placeholder="Specify Reason"
          formControlName="otherReason"
        ></textarea>
      </mat-form-field>
      <div class="mat-row button-row">
        <div class="mat-12" fxLayoutAlign="end end">
          <button mat-button class="primary-btn" (click)="save()">
            Save Changes
          </button>
          <button
            mat-button
            class="secondary-btn"
            (click)="navigateBackToMemberSearch()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>

    <p class="note">
      *Please note: Additional costs may apply with requests for Duplicate ID
      cards.
    </p>
  </form> -->
</div>