/**
 * @file main.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../app.constants';
import { AppInsightsService } from '../services/app-insights/app-insights.service';
import { log } from 'util';
import { AppCenterAnalytics } from '../services/app-insights/app-insights';
import { SharedService } from '../services/shared/shared.service';
import { AuthTokenService } from '../core/services/authToken/auth-token.service';

@Component({
   selector: 'app-main',
   templateUrl: './main.component.html',
   styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
   isSideBarClosed: boolean;
   embedId: string;
   menu: string;
   showIframe: boolean;
   showOtherMenu: boolean;
   showInvoices: boolean;
   labels = AppConstants.LABELS;
   constructor(private readonly appInsightService: AppInsightsService, private readonly sharedService: SharedService) { }

   ngOnInit(): void {
      // this.sharedService.setEmail();
      this.isSideBarClosed = false;
      this.showIframe = false;
      this.showOtherMenu = false;
      this.showInvoices = false;
      this.appInsightService.trackPageView(AppCenterAnalytics.DASHBOARD_VIEWED);
   }
   /**
   * This method is used to get the embedId which is passed it as a input parameter and show the Iframe.
   * @param embedId contains string for the dashboard. 
   * @returns void
   */
   public getValue(embedId: string): void {
      this.showIframe = true;
      this.showOtherMenu = false;
      this.showInvoices = false;
      this.embedId = embedId;
   }

   /**
   * This method is used to get the menu name which is passed it as a input parameter and show the other menus.
   * @param menu contains string to load the documents page. 
   * @returns void
   */
   public getMenu(menu: string): void {
      this.showIframe = false;
      this.showOtherMenu = menu === this.labels.financialDocuments ? false : true;
      this.showInvoices = menu === this.labels.financialDocuments ? true : false;
      this.menu = menu;
   }
}
