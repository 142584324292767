/**
 * @file privacy-statement.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppConstants } from '../../../app.constants';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent implements OnInit {
  constants = AppConstants.LABELS;
  panelOpenState: boolean;
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(this.constants.pageTitle  + this.constants.hyphen +  this.constants.privacyStatement);
   }

  ngOnInit(): void {
    this.panelOpenState = false;
  }

}
