import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeductibleService } from 'src/app/core/services/deductible/deductible.service';
import { AnnualCapDetails } from 'src/app/interfaces/annual-cap.interface';
import { Group } from 'src/app/interfaces/benefit-tool.interface';
import {
  DeductibleInterface,
  DeductibleRequestInterface,
  DeductibleResponseInterface,
} from 'src/app/interfaces/deductible.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';

@Component({
  selector: 'app-deductible',
  templateUrl: './deductible.component.html',
  styleUrls: ['./deductible.component.scss'],
})
export class DeductibleComponent implements OnInit {
  displayedColumns: string[] = [
    'frequency',
    'familyTotal',
    'familyMember',
    'single',
  ];
  public benefitToolData: Group;
  dataSource: AnnualCapDetails[] = [];
  public isNoData: boolean = false;
  groupChosen: boolean = false;
  deductibles$: Observable<DeductibleResponseInterface>;
  constructor(
    private readonly appInsightService: AppInsightsService,
    private deductibleService: DeductibleService
  ) {}

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.DEDUCTABLE);
  }
  public filterList(event) {
    this.benefitToolData = event;
  }
  public NoDataHandling(event) {
    this.isNoData = event;
    this.groupChosen = false;
    if (this.isNoData) {
      this.deductibles$ = null;
    }
  }

  clearData(clear: boolean) {
    this.groupChosen = false;
    if (clear) this.deductibles$ = null;
  }
  public onFieldsSelect(event: DeductibleRequestInterface) {
    this.groupChosen = true;
    this.deductibles$ = this.deductibleService
      .searchDeductibles(event, this.benefitToolData)
      .pipe(
        tap((res) => {
          // console.log('Response on deductibles:', res);
          if (res.annualCapList) {
            this.dataSource = res?.annualCapList?.filter(
              (data) => data.type === 'Standard Deductible'
            );
          } else {
            // console.error('annualCapList is null or undefined in the response');
          }
        })
      );
  }
}
