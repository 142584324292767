import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, defer, of } from 'rxjs';
import { switchMap, map, shareReplay } from 'rxjs/operators';
import {
  AuditLogsInterface,
  AuditLogsRequestInterface,
  AuditLogsResponseInterface,
  SaveAuditLogsRequestInterface,
  SearchLogsInterface,
} from 'src/app/interfaces/audit-logs.interface';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import { DemoClientService } from '../demo-client/demo-client.service';
import { trimIfUNE } from 'src/app/util';

@Injectable({
  providedIn: 'root',
})
export class AuditLogsService {
  public auditLogsSearchResultCache$!: Observable<AuditLogsInterface[]>;
  public inputSearchCriteria: BehaviorSubject<AuditLogsRequestInterface> =
    new BehaviorSubject<AuditLogsRequestInterface>(null);
  public searchCriteriaCache: any;
  constructor(
    private httpService: HttpService,
    private demoClientService: DemoClientService
  ) {}

  public searchAuditLogs(): Observable<any> {
    if (!this.auditLogsSearchResultCache$) {
      this.auditLogsSearchResultCache$ = this.inputSearchCriteria
        .asObservable()
        .pipe(
          switchMap((res) => {
            return defer(() => {
              if (!res) {
                return of(null);
              } else {
                // console.log('Search Criteria', res);
                return this.getAuditLogs(res).pipe(
                  map((res) => {
                    return res;
                  })
                  // shareReplay(1)
                );
              }
            });
          })
        );
    }
    return this.auditLogsSearchResultCache$;
  }

  public getAuditLogs(reqBody: AuditLogsRequestInterface): Observable<any[]> {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    const url = `/AuditLogs/GetAuditLogs`;
    // console.log('Inside getAuditLogs', reqBody);
    if (reqBody.clientId === '19121' && reqBody.cardId)
      reqBody.cardId = trimIfUNE(reqBody.cardId);
    return this.httpService.post<AuditLogsInterface[]>(url, reqBody, {
      headers,
    });
  }

  public getIdRequestedDates(
    reqBody: AuditLogsRequestInterface
  ): Observable<any[]> {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    const url = `/AuditLogs/GetDuplicateIDCardAuditLogs`;
    return this.httpService.post<AuditLogsInterface[]>(url, reqBody, {
      headers,
    });
  }

  saveAuditLog(reqBody: SaveAuditLogsRequestInterface) {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    const url = `/AuditLogs/SaveAuditLog`;
    return this.httpService.post(url, reqBody, { headers });
  }
}
