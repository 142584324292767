import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Coverage,
  ICoverageAddRequest,
  ICoverageUpdateRequest,
  IUpdateCoverageFormGroupValue,
  MemberDetails,
} from '../../../../interfaces/member.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, defer, Observable, of } from 'rxjs';
import {
  ClientInfoResponse,
  GroupDetails,
} from '../../../../interfaces/UserGroup.interface';
import { LoaderService } from '../../../services/loader/loader.service';
import { EligibilityService } from '../../../../core/services/eligibility/eligibility.service';
import { catchError, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { formatDate, getErrorMessage } from '../../../../util';
import { ErrorComponent } from '../../../../core/components/error/error.component';
import { SaveAuditLogsRequestInterface } from 'src/app/interfaces/audit-logs.interface';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { AppConstants } from 'src/app/app.constants';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ErrorManagerService, ErrorType } from '../../../../core/services/error';
import { HttpErrorResponse } from '@angular/common/http';
import { AppCenterAnalytics } from '../../../../services/app-insights/app-insights';
import { AppInsightsService } from '../../../../services/app-insights/app-insights.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-update-coverage-details-dialog',
  templateUrl: './update-coverage-details-dialog.component.html',
  styleUrls: ['./update-coverage-details-dialog.component.scss'],
})
export class UpdateCoverageDetailsDialogComponent implements OnInit {
  public updateCoverageFormGroup: FormGroup;
  public cardHolderRC: string = AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER;
  public groups: string[] = ['Demo'];
  public listOfGroups$: Observable<Array<GroupDetails>>;
  public filteredGroups!: Observable<Array<GroupDetails>>;

  public displayedColumns: string[] = [
    'personCode',
    'name',
    'cardId',
    'relationshipCode',
    'startDate',
    'endDate',
  ];
  public displayGender = AppConstants.DISPLAY_GENDER;
  public initialFormGroupValue: IUpdateCoverageFormGroupValue;
  public refreshDependents: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  updateRx: boolean = false;
  addRx: boolean = false;
  updateCoverage: boolean = false;
  addCoverage: boolean = false;
  updatedMember: Partial<MemberDetails> = {};
  selectedGroupName: string = '';
  dataSource!: MemberDetails[];
  dependents$!: Observable<MemberDetails[]>;
  public totalDependents: number;
  public activeDependents: MemberDetails[] = [];
  groupInfo: { groupId: string; groupName: string } = {
    groupId: '',
    groupName: '',
  };
  clientInfo$: Observable<ClientInfoResponse[]>;
  public selectedDependents: MemberDetails[] = [];
  public groupFilterControl = new FormControl();
  public maxDate = new Date('12/31/9999');

  constructor(
    public dialogRef: MatDialogRef<UpdateCoverageDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private readonly formBuilder: FormBuilder,
    private loader: LoaderService,
    private eligibilityService: EligibilityService,
    private matDialog: MatDialog,
    private auditLogsService: AuditLogsService,
    private authService: AuthService,
    private errorManager: ErrorManagerService,
    private readonly appInsightsService: AppInsightsService,
  ) { }

  ngOnInit(): void {
    if (this.dialogData.action === 'addCoverage' && this.dialogData.memberDetails.relationshipCode === this.cardHolderRC) {
      this.displayedColumns.unshift('select');
    }
    this.loader.showLoader();
    this.dependents$ = defer(() => {
      return this.refreshDependents.pipe(
        tap(() => {
          this.loader.showLoader();
        }),
        switchMap(() => this.getDependents().pipe(
          tap((res) => {
            res.forEach(dep => {
              dep.relationshipValue = AppConstants.RELATIONSHIP_VALUES[dep.relationshipCode];
              if ((new Date(dep.coverage.endDate).valueOf() > new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf())) {
                this.activeDependents.push(dep);
              }
            });
            if (this.activeDependents.length !== 0 && this.dialogData.action === 'addCoverage') {
              const errorDialog: MatDialogRef<ErrorComponent> = this.matDialog.open(
                ErrorComponent,
                {
                  width: '500px',
                  data: {
                    title: '',
                    message: 'There are some active dependent(s). Please terminate the dependent(s) to proceed with adding new coverage',
                  },
                }
              );
              errorDialog.afterClosed().subscribe(() => {
                this.dialogRef.close();
              });
            }
            const termedUsers = res.filter((m) => new Date(m.coverage.endDate).valueOf() < new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf()).sort((a,b)=> parseInt(a.personCode) - parseInt(b.personCode));;
            const activeUsers = res.filter((m) => new Date(m.coverage.endDate).valueOf() >= new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf()).sort((a,b)=> parseInt(a.personCode) - parseInt(b.personCode));;
            this.dataSource = [...activeUsers, ...termedUsers];
            this.totalDependents = this.dataSource.length;
          }),
          finalize(() => {
            this.loader.hideLoader();
          })
        )));
    });

    const requiredValidator = Validators.required;
    this.updateCoverageFormGroup = this.formBuilder.group({
      groupName: ['', requiredValidator],
      startDate: ['', requiredValidator],
      endDate: ['']
    });
    this.updateCoverageFormGroup
      .get('startDate')
      .valueChanges.subscribe((startDate) => {
        // Update the end date filter when the start date changes
        this.updateCoverageFormGroup.get('endDate').updateValueAndValidity();
      });

    if (
      this.dialogData.action === 'addRx' ||
      this.dialogData.action === 'updateRx' ||
      this.dialogData.action === 'updateCoverage'
    ) {
      this.updateCoverageFormGroup.controls.endDate.setValidators([
        Validators.required,
      ]);
    }

    if (this.dialogData.coverage.edit) {
      // console.log('Coverage data:', this.dialogData.coverage);
      this.populateFormGroup(this.dialogData.coverage);
    }
    this.getGroupsByClient(
      this.dialogData.memberDetails.clientId
    );

    this.clientInfo$ = this.eligibilityService
      .getClientDetailsForBenefit(this.dialogData.memberDetails.clientId)
      .pipe(
        tap((res) => {
          if ((this.dialogData.memberDetails.relationshipCode !== this.cardHolderRC && (this.dialogData.action === 'updateCoverage' || this.dialogData.action === 'addCoverage')) || (this.dialogData.action !== 'updateCoverage' && this.dialogData.action !== 'addCoverage')) {
            this.loader.hideLoader();
          }
          // console.log('Res get client details:', res);
          let result = {};

          for (let detail of res) {
            for (let plan of detail.plans) {
              for (let group of plan.groups) {
                if (
                  group.groupId?.toUpperCase() ===
                  this.dialogData.memberDetails.coverage.groupNumber?.toUpperCase()
                ) {
                  result = {
                    groupName: group.groupName,
                    groupId: group.groupId,
                  };
                  break;
                }
              }

              if (Object.keys(result).length > 0) break;
            }
          }

          this.groupInfo.groupId = result['groupId'] ? result['groupId'] : '';
          this.groupInfo.groupName = result['groupName']
            ? result['groupName']
            : '';

          // console.log('Group Info:', this.groupInfo);
        })
      );
  }

  private filterGroup(searchTerm: string): Observable<Array<GroupDetails>> {
    const lowerCaseTerm = searchTerm.trim().toLowerCase();
  
    return this.listOfGroups$.pipe(
      map(groups =>
        groups.filter(group =>
          group.groupName.toLowerCase().includes(lowerCaseTerm) ||
          group.groupId.trim().toLowerCase().includes(lowerCaseTerm) // Case-insensitive and trimmed
        )
      )
    );
  }

  public getDependents(): Observable<MemberDetails[]> {
    const details = {
      clientId: this.dialogData.memberDetails.clientId,
      cardId: this.dialogData.memberDetails.cardId,
    };
    const query = this.eligibilityService.getSearchQuery(details);
    return this.eligibilityService
      .getMemberDetails(query)
      .pipe(
        map((res) =>
          res.filter(
            (i) =>
              i.relationshipCode !==
              AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER
          )
        )
      );
  }

  public get minDate(): Date {
    let startDate = this.updateCoverageFormGroup?.controls?.startDate?.value;
    if (!startDate) {
      return null;
    }
    let oneDayPrior = new Date(startDate);
    oneDayPrior.setDate(oneDayPrior.getDate() - 1);
    return this.dialogData.action === 'updateCoverage' || this.dialogData.action === 'updateRx' ? oneDayPrior : startDate;
  }

  private getGroupsByClient(clientId: string) {
    this.loader.showLoader();
    this.eligibilityService.getClientDetails(clientId).pipe(
      map((res) => {
        let groupsArray = [];
        if (res[0].plans) {
          for (const plan of res[0].plans) {
            if (plan.groups) {
              groupsArray.push(...plan.groups);
            }
          }
        }
        let filteredGroupArray = groupsArray.filter((group) => !!group.groupId);
        if (this.dialogData.memberDetails.relationshipCode !== this.cardHolderRC && this.dialogData.cardHolderGroupNumber) {
          filteredGroupArray = filteredGroupArray.filter((g) => g.groupId?.toUpperCase() === this.dialogData.cardHolderGroupNumber?.toUpperCase());
        }
        return filteredGroupArray;
      }),
      catchError(() => {
        this.loader.hideLoader();
        return of([]);
      })
    ).subscribe((res) => {
      this.listOfGroups$ = of(res);
      // Filter clients based on search input
      this.filteredGroups = this.groupFilterControl.valueChanges.pipe(
        startWith(''), // Initially show all clients
        switchMap(searchTerm => this.filterGroup(searchTerm)) // Filter clients when search term changes
      );
    });
  }

  private populateFormGroup(dialogData): void {
    this.initialFormGroupValue = {
      groupName:
        this.dialogData.action === 'addRx' ||
          this.dialogData.action === 'updateRx'
          ? this.dialogData.coverage.flexTagName
          : dialogData.groupId,
      startDate:
        this.dialogData.action === 'addRx' ||
          this.dialogData.action === 'updateRx'
          ? new Date(`${dialogData.flexTagStartDate}T00:00:00`)
          : new Date(`${dialogData.startDate}T00:00:00`),
      endDate:
        this.dialogData.action === 'addRx' ||
          this.dialogData.action === 'updateRx'
          ? new Date(`${dialogData.flexTagEndDate}T00:00:00`)
          : new Date(`${dialogData.endDate}T00:00:00`),
    };
    this.updateCoverageFormGroup.setValue(this.initialFormGroupValue);
    this.selectedGroupName = this.dialogData.coverage.groupName;
  }

  public checkIfAnythingUpdated() {
    return (
      JSON.stringify(this.updateCoverageFormGroup.value) !==
      JSON.stringify(this.initialFormGroupValue)
    );
  }

  startDateChange() {
    // console.log(
    //   'Effective date',
    //   formatDate(this.updateCoverageFormGroup.value.startDate)
    // );
  }

  public save(): void {
    const reqObj: MemberDetails = {
      patientId: this.dialogData.memberDetails.patientId,
      firstName: this.dialogData.memberDetails.firstName,
      lastName: this.dialogData.memberDetails.lastName,
      middleName: this.dialogData.memberDetails.middleName,
      DOB: this.dialogData.memberDetails.DOB,
      clientId: this.dialogData.memberDetails.clientId,
      gender: this.dialogData.memberDetails.gender,
      email: this.dialogData.memberDetails.email,
      addressLine1: this.dialogData.memberDetails.addressLine1,
      addressLine2: this.dialogData.memberDetails.addressLine2,
      zipCode: this.dialogData.memberDetails.zipCode,
      city: this.dialogData.memberDetails.city,
      state: this.dialogData.memberDetails.state,
      effectiveDate: this.dialogData.memberDetails.coverage.startDate,
      terminationDate: this.dialogData.memberDetails.coverage.endDate,
      homePhone: this.getPhoneNumber('homePhone'),
      cellPhone: this.getPhoneNumber('cellPhone'),
      workPhone: this.getPhoneNumber('workPhone'),
      isUpdated: true,
      personCode: this.dialogData.memberDetails.personCode,
      relationshipCode: this.dialogData.memberDetails.relationshipCode,
      cardId: this.dialogData.memberDetails.cardId,
      groupNumber: this.updateCoverageFormGroup.value.groupName,
      coverageType: this.dialogData.memberDetails.coverage?.coverageType,
      SSN: this.dialogData.memberDetails.SSN,
      flexTagName: this.dialogData.memberDetails.flexTag.flexTagName,
      flexTagStartDate: this.dialogData.memberDetails.flexTag.flexTagStartDate,
      flexTagEndDate: this.dialogData.memberDetails.flexTag.flexTagEndDate,
      flexTagName2: this.dialogData.memberDetails.flexTag2.flexTagName,
      flexTagStartDate2: this.dialogData.memberDetails.flexTag2.flexTagStartDate,
      flexTagEndDate2: this.dialogData.memberDetails.flexTag2.flexTagEndDate,
      flexTagName3: this.dialogData.memberDetails.flexTag3.flexTagName,
      flexTagStartDate3: this.dialogData.memberDetails.flexTag3.flexTagStartDate,
      flexTagEndDate3: this.dialogData.memberDetails.flexTag3.flexTagEndDate,
      flexTagName4: this.dialogData.memberDetails.flexTag4.flexTagName,
      flexTagStartDate4: this.dialogData.memberDetails.flexTag4.flexTagStartDate,
      flexTagEndDate4: this.dialogData.memberDetails.flexTag4.flexTagEndDate,
      flexTagName5: this.dialogData.memberDetails.flexTag5.flexTagName,
      flexTagStartDate5: this.dialogData.memberDetails.flexTag5.flexTagStartDate,
      flexTagEndDate5: this.dialogData.memberDetails.flexTag5.flexTagEndDate,
      clientInternalMemberId: this.dialogData.memberDetails.clientInternalMemberId
    };
    if (
      this.dialogData.action === 'addRx' ||
      this.dialogData.action === 'updateRx'
    ) {
      reqObj.effectiveDate = this.dialogData.memberDetails.coverage.startDate;
      reqObj.terminationDate = this.dialogData.memberDetails.coverage.endDate;
      reqObj.groupNumber = this.dialogData.memberDetails.coverage.groupNumber;
      reqObj[AppConstants.FLEX_TAG_KEY_MAPPING[this.updateCoverageFormGroup.value.groupName][1]] = formatDate(
        this.updateCoverageFormGroup.value.startDate
      );
      reqObj[AppConstants.FLEX_TAG_KEY_MAPPING[this.updateCoverageFormGroup.value.groupName][2]] = this.updateCoverageFormGroup.value.endDate
        ? formatDate(this.updateCoverageFormGroup.value.endDate)
        : '';
      reqObj[AppConstants.FLEX_TAG_KEY_MAPPING[this.updateCoverageFormGroup.value.groupName][0]] = this.updateCoverageFormGroup.value.groupName;
    }
    if (
      this.dialogData.action === 'addCoverage' ||
      this.dialogData.action === 'updateCoverage'
    ) {
      (reqObj.effectiveDate = formatDate(
        this.updateCoverageFormGroup.value.startDate
      )),
        (reqObj.terminationDate = this.updateCoverageFormGroup.value.endDate
          ? formatDate(this.updateCoverageFormGroup.value.endDate)
          : '');
    }
    this.loader.showLoader();
    this.loader.showWaitingMessage$.next(AppConstants.ERROR.WAIT_MESSAGE_FOR_UPDATE_MEMBER);
    if (this.dialogData.memberDetails.relationshipCode === this.cardHolderRC && this.dialogData.action === 'updateCoverage') {
      const reqObject: ICoverageUpdateRequest = {
        cardId: reqObj.cardId,
        clientId: reqObj.clientId,
        modifiedSource: 'BAP',
        endDate: reqObj.terminationDate,
        startDate: reqObj.effectiveDate,
        groupId: reqObj.groupNumber,
        groupName: this.dialogData.coverage.groupName
      }
      this.eligibilityService.updateMemberCoverage(reqObject).subscribe({
        next: (res) => {
          if (res) {
            this.loader.hideLoader();
            this.loader.showWaitingMessage$.next(null);
            const successDialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
              ErrorComponent,
              {
                width: '300px',
                data: {
                  title: 'Success',
                  message: 'Coverage updated successfully',
                },
              }
            );
            successDialogRef.afterClosed().subscribe(() => {
              this.eligibilityService.searchCoverage.next(true);
              this.initialFormGroupValue = this.updateCoverageFormGroup.value;
              this.refreshDependents.next(true);
            });
          }
        },
        error: (err) => {
          this.createAuditLog(false, err);
          const message = err.error ? JSON.parse(err.error).Message : AppConstants.ERROR.GENERIC_ERROR_MESSAGE
          this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: message,
            component: 'Update coverage details',
            title: '',
          });
          this.loader.hideLoader();
          this.loader.showWaitingMessage$.next(null);
        },
        complete: () => {
          this.createAuditLog(true);
        },
      })
    } else if (this.dialogData.memberDetails.relationshipCode === this.cardHolderRC && this.dialogData.action === 'addCoverage') {
      const reqObject: ICoverageAddRequest = {
        cardId: reqObj.cardId,
        clientId: reqObj.clientId,
        modifiedSource: 'BAP',
        endDate: reqObj.terminationDate,
        startDate: reqObj.effectiveDate,
        groupId: reqObj.groupNumber,
        groupName: this.selectedGroupName,
        patientIds: this.selectedDependents.map((d) => d.patientId)
      }
      reqObject.patientIds.push(this.dialogData.memberDetails.patientId);
      this.eligibilityService.addMemberCoverage(reqObject).subscribe({
        next: (res) => {
          if (res) {
            this.loader.hideLoader();
            this.loader.showWaitingMessage$.next(null);
            const successDialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
              ErrorComponent,
              {
                width: '300px',
                data: {
                  title: 'Success',
                  message: 'Coverage updated successfully',
                },
              }
            );
            successDialogRef.afterClosed().subscribe(() => {
              this.dialogRef.close();
              this.eligibilityService.searchCoverage.next(true);
            });
          }
        },
        error: (err) => {
          this.createAuditLog(false, err);
          const message = err.error ? JSON.parse(err.error).Message : AppConstants.ERROR.GENERIC_ERROR_MESSAGE
          this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: message,
            component: 'Update coverage details',
            title: '',
          });
          this.loader.hideLoader();
          this.loader.showWaitingMessage$.next(null);
        },
        complete: () => {
          this.createAuditLog(true);
        },
      })
    } else {
      this.eligibilityService.addMember(reqObj).subscribe({
        next: (res) => {
          if (res) {
            this.updatedMember = res;
            this.loader.hideLoader();
            this.loader.showWaitingMessage$.next(null);
            const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
              ErrorComponent,
              {
                width: '300px',
                data: {
                  title: 'Success',
                  message: 'Coverage updated successfully',
                },
              }
            );
            dialogRef.afterClosed().subscribe(() => {
              this.dialogRef.close();
              this.eligibilityService.searchCoverage.next(true);
            });
          }
        },
        error: (err) => {
          this.createAuditLog(false, err);
          const message = getErrorMessage(err);
          this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: message,
            component: 'Update coverage details',
            title: '',
          });
          this.loader.hideLoader();
          this.loader.showWaitingMessage$.next(null);
        },
        complete: () => {
          this.createAuditLog(true);
        },
      });
    }
  }

  public selectRow(dependent: MemberDetails): void {
    const index = this.selectedDependents.indexOf(dependent);
    if (index >= 0) {
      dependent.selected = false;
      this.selectedDependents.splice(index, 1);
    } else {
      dependent.selected = true;
      this.selectedDependents.push(dependent);
    }
  }

  createAuditLog(success: boolean, err?: HttpErrorResponse) {
    let addCoverageSelectedDependents: MemberDetails[] | string[] = [];
    let dependents: MemberDetails[] | string[] = [];
    if (this.totalDependents) {
      dependents = this.dataSource.filter((d) => {
        return new Date(d.coverage.endDate).valueOf() > new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf() || (new Date(d.coverage.endDate).valueOf() >= new Date(this.updateCoverageFormGroup.value.endDate).valueOf() && new Date(d.coverage.endDate).valueOf() < new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf());
      });
      dependents = dependents.map((d) => d.personCode);
    }
    if (this.selectedDependents) {
      addCoverageSelectedDependents = this.selectedDependents.map((d) => d.personCode);
    }
    addCoverageSelectedDependents.unshift(this.dialogData.memberDetails.personCode);
    dependents.unshift(this.dialogData.memberDetails.personCode);
    let reqBody: SaveAuditLogsRequestInterface = {
      clientId: this.dialogData.memberDetails.clientId,
      cardId: this.dialogData.memberDetails.cardId,
      personCd: this.dialogData.memberDetails.personCode,
      firstName: this.dialogData.memberDetails.firstName,
      lastName: this.dialogData.memberDetails.lastName,
      emailId: this.dialogData.memberDetails.email && this.dialogData.memberDetails.email.toLowerCase() !== 'null'
        ? this.dialogData.memberDetails.email
        : this.authService.userEmail,
      birthDt: this.dialogData.memberDetails.DOB,
      groupNo: this.updateCoverageFormGroup.value.groupName,
      groupName: this.selectedGroupName,
      changes:
        this.dialogData.action === 'addCoverage'
          ? AppConstants.AUDIT_LOGS.updateCoverage.action
            .memberCoverageAdded.change
          : AppConstants.AUDIT_LOGS.updateCoverage.action.changedTermedDate
            .change,
      comments:
        this.dialogData.action === 'addCoverage'
          ? this.dialogData.memberDetails.relationshipCode === this.cardHolderRC ? `${AppConstants.AUDIT_LOGS.updateCoverage.action.cardHolderCoverageAdded.comments} ${addCoverageSelectedDependents}`.replace('%GROUP_NAME%', this.selectedGroupName)
            .replace(
              '%DATE%',
              formatDate(this.updateCoverageFormGroup.value.startDate)
            ) : AppConstants.AUDIT_LOGS.updateCoverage.action.memberCoverageAdded.comments
              .replace('%GROUP_NAME%', this.selectedGroupName)
              .replace(
                '%DATE%',
                formatDate(this.updateCoverageFormGroup.value.endDate) ? formatDate(this.updateCoverageFormGroup.value.endDate) : '9999-12-31'
              )
          : this.dialogData.memberDetails.relationshipCode === this.cardHolderRC ? `${AppConstants.AUDIT_LOGS.updateCoverage.action.changedTermedDateCardHolder.comments} ${dependents}` : AppConstants.AUDIT_LOGS.updateCoverage.action.changedTermedDate
            .comments,
      addedBy: this.authService.userEmail,
      isSuccess: success,
    };
    if (this.dialogData.action === 'addRx') {
      reqBody.comments =
        AppConstants.AUDIT_LOGS.updateCoverage.action.memberNoRxCoverageAdded.comments.replace(
          '%DATE%',
          formatDate(this.updateCoverageFormGroup.value.startDate)
        ).replace('%COVERAGE_TYPE%', AppConstants.COVERAGE_TYPES[this.updateCoverageFormGroup.value.groupName]);
      reqBody.changes =
        AppConstants.AUDIT_LOGS.updateCoverage.action.memberNoRxCoverageAdded.change;
    } else if (this.dialogData.action === 'updateRx') {
      reqBody.comments =
        AppConstants.AUDIT_LOGS.updateCoverage.action.memberNoRxCoverageTerminated.comments.replace(
          '%DATE%',
          formatDate(this.updateCoverageFormGroup.value.endDate)
        ).replace('%COVERAGE_TYPE%', AppConstants.COVERAGE_TYPES[this.updateCoverageFormGroup.value.groupName]);;
      reqBody.changes =
        AppConstants.AUDIT_LOGS.updateCoverage.action.memberNoRxCoverageAdded.change
    }
    if (!success) {
      const message = err?.error?.Message ? err.error.Message : JSON.stringify(err);
      reqBody.httpStatusCode = err.status,
        reqBody.errorMsg = message
    }
    this.auditLogsService.saveAuditLog(reqBody).subscribe({
      next: (res) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_SUCCESS, { Response: res });
      },
      error: (err) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_ERROR, { Response: err });
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });

  }

  onGroupSelection(group) {
    this.selectedGroupName = group.groupName;
  }

  public getPhoneNumber(phoneType: string): string {
    return this.dialogData.memberDetails.phones.filter(
      (p) => p.type === phoneType
    )[0].phoneNumber;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public checkIfCoverageExpired(element) {
    return new Date(element.coverage.endDate).valueOf() < new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf();
  }

  public checkIfCoverageIsStartingSoon(element) {
    return (
      new Date(element.coverage.startDate).valueOf() > new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf()
    );
  }

  public onChangeFlag(): void {
    this.updateCoverageFormGroup.controls.startDate.reset();
    this.updateCoverageFormGroup.controls.endDate.reset();
    const selected = AppConstants.FLEX_TAG_KEY_MAPPING[this.updateCoverageFormGroup.value.groupName];
    const startDate = this.dialogData.memberDetails[selected[3]].flexTagStartDate;
    const endDate = this.dialogData.memberDetails[selected[3]].flexTagEndDate;
    const name = this.dialogData.memberDetails[selected[3]].flexTagName;
    this.initialFormGroupValue = {
      groupName: name ? name : this.updateCoverageFormGroup.controls.groupName.value,
      startDate: startDate
        ? new Date(`${startDate}T00:00:00`)
        : new Date(`${this.updateCoverageFormGroup.controls.startDate.value}T00:00:00`),
      endDate: endDate ? new Date(`${endDate}T00:00:00`)
        : new Date(`${this.updateCoverageFormGroup.controls.endDate.value}T00:00:00`)
    };
    this.updateCoverageFormGroup.setValue(this.initialFormGroupValue);
  }

}
