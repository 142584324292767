/**
 * @file loader.service.ts
 * @description handles loader show/hide functionality
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppConstants } from '../../../app.constants';
import { Loader } from '../../../interfaces/loader.interface';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private static readonly className = 'LoaderService';

  private readonly loaderSubject = new BehaviorSubject<Loader>({ show: false, message: AppConstants.LOADER.DEFAULT_MESSAGE });
  public loader = this.loaderSubject.asObservable();

  constructor() { }

  /**
   * This method shows global loader on screen
   * @param string message
   * @returns void
   */
  public show(message: string = AppConstants.LOADER.DEFAULT_MESSAGE): void {
    this.loaderSubject.next({ show: true, message });
  }

  /**
   * @description This method hides loader
   */
  public hide(): void {
    this.loaderSubject.next({ show: false, message: AppConstants.LOADER.DEFAULT_MESSAGE });
  }
}
