/**
 * @file main.module.ts
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { MaterialModule } from '../core/modules/material.module';
import { SharedModule } from '../shared/shared.module';
import { ReportSelectionComponent } from './report-selection/report-selection.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentsComponent } from './documents/documents.component';
import { FinanicalInvoicesComponent } from './finanical-invoices/finanical-invoices.component';


@NgModule({
   declarations: [MainComponent, ReportSelectionComponent, DashboardComponent, DocumentsComponent, FinanicalInvoicesComponent],
   imports: [
      CommonModule,
      MainRoutingModule,
      FormsModule,
      SharedModule,
      MaterialModule
      ],
   exports:[]
})
export class MainModule { }
