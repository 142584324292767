<mat-nav-list>
  <div (click)="goToHome()" mat-subheader class="text-label back-arrow">
    <mat-icon matListIcon>arrow_back</mat-icon> Back to Home
  </div>
  <mat-list
    *ngFor="let sb of accessRoles"
    [class.submenu]="sb.menus?.length > 0"
    [class.open]="showSubMenus && sb.menus?.length > 0"
  >
    <mat-list-item
      [class]="sb.class"
      [class.selected]="selectedMainMenu === sb.name"
      matTooltipPosition="right"
      matTooltipClass="bg-accent"
      (click)="clickMainMenu(sb)"
    >
      <mat-icon class="mat-list-icon"></mat-icon>
      <h3 matLine>{{ sb.name }}</h3>
      <mat-icon *ngIf="sb.menus?.length > 0" class="down-arrow"
        >expand_more</mat-icon
      >
    </mat-list-item>
    <mat-nav-list *ngIf="showSubMenus && sb.menus">
      <mat-list *ngFor="let menu of sb.menus" (click)="clickMenu(menu)">
        <mat-list-item
          [class.selected]="router.url.includes(menu.route)"
          matTooltipPosition="right"
          matTooltipClass="bg-accent"
        >
          <h3 matLine>{{ menu.name }}</h3>
        </mat-list-item>
      </mat-list>
    </mat-nav-list>
  </mat-list>
  <div *ngIf="isInternalUser$ | async" class="goto-link">
    <a [href]="redirectToTredium" target="_blank">Go to Tredium</a>
  </div>
</mat-nav-list>
