import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constants';
import { ClientsResponse } from 'src/app/interfaces/clients-response.interface';
import { Clients } from 'src/app/interfaces/clients.interface';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';
import { UserAccessLevel } from '../../../interfaces/user-access.interface';
import { LoaderService } from '../../../management/services/loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class BenefitManagementService {
  public clientsResponseCache$: Observable<Clients[]>;

  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly loader: LoaderService,
  ) { }

  /**
   * Get list of clients for Benefit and Management module, which the user has access
   * @returns
   */
  public getClientsListByAccess(): Observable<Clients[]> {
    if (!this.clientsResponseCache$) {
      this.loader.showLoader();
      this.clientsResponseCache$ = this.httpService
        .get<ClientsResponse>(
          `${AppConstants.URLS.BAP_CLIENTS}${this.authService.userEmail}`
        )
        .pipe(
          // tap((res) => res.listOfClients.forEach((e) => {
          //   res.listOfClients[0].olA_Access_Type = UserAccessLevel.LEVEL_A
          //   res.listOfClients[1].olA_Access_Type = UserAccessLevel.LEVEL_B
        // })),
          tap(() => {
            this.loader.hideLoader();  
          }),
          map((res) => res.listOfClients.filter((x) => x.olA_Access === 'Y')),
          shareReplay(1)
        );
    }
    return this.clientsResponseCache$;
  }

  isInternalUser(): Observable<boolean> {
    return this.getClientsListByAccess().pipe(
      map((res) => res[0].userType === 'Internal')
    );
  }
}
