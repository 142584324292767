import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { Address } from 'src/app/interfaces/address.interface';
import { Coverage, IAddressUpdateRequest, MemberDetails } from 'src/app/interfaces/member.interface';
import { numericOnly as externalNumericOnly, formatDate, getErrorMessage } from 'src/app/util';
import { LoaderService } from '../../../services/loader/loader.service';
import { EligibilityService } from '../../../../core/services/eligibility/eligibility.service';
import { ErrorComponent } from '../../../../core/components/error/error.component';
import { Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { SaveAuditLogsRequestInterface } from 'src/app/interfaces/audit-logs.interface';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ClientInfoResponse } from 'src/app/interfaces/UserGroup.interface';
import { element } from 'protractor';
import { ErrorManagerService, ErrorType } from '../../../../core/services/error';
import { HttpErrorResponse } from '@angular/common/http';
import { AppCenterAnalytics } from '../../../../services/app-insights/app-insights';
import { AppInsightsService } from '../../../../services/app-insights/app-insights.service';
import { zipCodeValidator } from '../../../../shared/validators/zip-code-validator';

@Component({
  selector: 'app-update-address-info-dialog',
  templateUrl: './update-address-info-dialog.component.html',
  styleUrls: ['./update-address-info-dialog.component.scss'],
})
export class UpdateAddressInfoDialogComponent implements OnInit {
  public states = AppConstants.STATES;
  public filteredStates!: Observable<Array<{ name: string; value: string }>>;
  public cardHolderRC: string = AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER;
  public cities: string[] = ['NY'];
  public dependents$: Observable<MemberDetails[]>;
  public updateAddressFormGroup: FormGroup;
  private initialUpdateAddressFormValues: any;
  public AddressDetails: Address = {
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    state: '',
    dependents: '',
  };
  depList: MemberDetails[];
  numericOnly = externalNumericOnly;
  dataSaved: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public stateFilterControl = new FormControl();

  public memberDetails: any;
  updatedMember: Partial<MemberDetails> = {};

  groupInfo: { groupId: string; groupName: string } = {
    groupId: '',
    groupName: '',
  };
  groupInfo$: Observable<{ groupId: string; groupName: string }> =
    new Observable();
  clientInfo$: Observable<ClientInfoResponse[]>;

  constructor(
    public dialogRef: MatDialogRef<UpdateAddressInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private readonly formBuilder: FormBuilder,
    private loader: LoaderService,
    private eligibilityService: EligibilityService,
    private matDialog: MatDialog,
    private auditLogsService: AuditLogsService,
    private authService: AuthService,
    private errorManager: ErrorManagerService,
    private readonly appInsightsService: AppInsightsService,
  ) { }

  ngOnInit(): void {
    this.dependents$ = this.getDependents();
    const requiredValidator = Validators.required;

    this.updateAddressFormGroup = this.formBuilder.group({
      addressLine1: ['', [requiredValidator, Validators.maxLength(35)]],
      addressLine2: ['', [Validators.maxLength(55)]],
      zipCode: [
        '',
        [requiredValidator, zipCodeValidator(),],
      ],
      city: ['', requiredValidator],
      state: ['', requiredValidator],
      dependents: [''],
    });
    this.AddressDetails.addressLine1 =
      this.dialogData.memberDetails.addressLine1;
    this.AddressDetails.addressLine2 =
      this.dialogData.memberDetails.addressLine2;
    this.AddressDetails.zipCode = this.dialogData.memberDetails.zipCode;
    this.AddressDetails.state = this.dialogData.memberDetails.state;
    this.AddressDetails.city = this.dialogData.memberDetails.city;
    this.populateMemberDetails(this.AddressDetails);
    this.initialUpdateAddressFormValues = {
      ...this.updateAddressFormGroup.value,
    };

    this.groupInfo$ = this.eligibilityService
      .getGroupInfo(
        this.dialogData.memberDetails.clientId,
        this.dialogData.memberDetails.coverage.groupNumber
      )
      .pipe(
        tap((res) => {
          // console.log('group Info', res);
          this.groupInfo = res;
          this.loader.hideLoader();
        })
      );
    this.fetchStatesDropdownValue();
  }

  fetchStatesDropdownValue(): void {
    this.filteredStates = this.stateFilterControl.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => this.filterState(searchTerm)) // Filter group)
    )
  }

  private filterState(searchTerm: string): Observable<Array<{ name: string; value: string }>> {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return of(this.states.filter(s =>
      s.name.toLowerCase().includes(lowerCaseTerm)
    ))
  }

  private getDependents(): Observable<MemberDetails[]> {
    const details = {
      clientId: this.dialogData.memberDetails.clientId,
      cardId: this.dialogData.memberDetails.cardId,
    };
    this.loader.showLoader();
    const query = this.eligibilityService.getSearchQuery(details);

    return this.eligibilityService.getMemberDetails(query).pipe(
      map((res) => {
        this.depList = res;
        // console.log('Before hide loader');
        this.loader.hideLoader();
        // console.log(this.updateAddressFormGroup);
        return res.filter(
          (i) =>
            i.relationshipCode !==
            AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER
        );
      }),
    );
  }

  private populateMemberDetails(memberDetails: any) {
    this.updateAddressFormGroup.setValue(memberDetails);
  }

  public checkIfAnythingUpdated(): boolean {
    return (
      JSON.stringify(this.updateAddressFormGroup.value) !==
      JSON.stringify(this.initialUpdateAddressFormValues)
    );
  }
  public save(): void {
    const updateFormGroupValue = this.updateAddressFormGroup.value;
    const memberDetails = this.dialogData.memberDetails;
    if (memberDetails.relationshipCode === AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER) {
      const updateAddressReq: IAddressUpdateRequest = {
        AddressLine1: this.updateAddressFormGroup.value.addressLine1,
        AddressLine2: this.updateAddressFormGroup.value.addressLine2,
        ZipCode: this.updateAddressFormGroup.value.zipCode,
        City: this.updateAddressFormGroup.value.city,
        State: this.updateAddressFormGroup.value.state,
        PatientIds: [memberDetails.patientId]
      }
      if (updateFormGroupValue.dependents) {
        updateFormGroupValue.dependents.forEach((patientId) => {
          updateAddressReq.PatientIds.push(patientId)
        });
      }
      this.loader.showLoader();
      this.eligibilityService.updateMemberAddress(updateAddressReq).subscribe({
        next: (res) => {
          console.log(res);
          this.updatedMember = memberDetails;

          if (res) {
            this.loader.hideLoader();
            this.dataSaved.emit(true);
            this.dialogRef.close();
          }
        },
        error: (err) => {
          this.createAuditLog(false, err);
          // console.log('Error on Update Address:', err);
          const message = err.error ? JSON.parse(err.error).Message.toLowerCase().trim() != AppConstants.ERROR.CONCURRENT_APPEND_EXCEPTION_MESSAGE.toLowerCase().trim() ? JSON.parse(err.error).Message : AppConstants.ERROR.END_USER_CONCURRENT_APPEND_EXCEPTION_MESSAGE : AppConstants.ERROR.GENERIC_ERROR_MESSAGE
          const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
            ErrorComponent,
            {
              width: '300px',
              data: {
                title: 'ERROR',
                message: message
              },
            }
          );
          dialogRef.afterClosed().subscribe(() => this.dialogRef.close());
          this.loader.hideLoader();
        },
        complete: () => {
          this.createAuditLog(true);
        },
      })
    } else {
      const reqObj: MemberDetails = {
        patientId: memberDetails.patientId,
        firstName: memberDetails.firstName,
        lastName: memberDetails.lastName,
        middleName: memberDetails.middleName,
        DOB: memberDetails.DOB,
        clientId: memberDetails.clientId,
        gender: memberDetails.gender,
        email: memberDetails.email,
        addressLine1: this.updateAddressFormGroup.value.addressLine1,
        addressLine2: this.updateAddressFormGroup.value.addressLine2,
        zipCode: this.updateAddressFormGroup.value.zipCode,
        city: this.updateAddressFormGroup.value.city,
        state: this.updateAddressFormGroup.value.state,
        effectiveDate: memberDetails.coverage?.startDate,
        terminationDate: memberDetails.coverage?.endDate,
        groupNumber: memberDetails.coverage?.groupNumber,
        isUpdated: true,
        homePhone: this.getPhoneNumber('homePhone', memberDetails),
        cellPhone: this.getPhoneNumber('cellPhone', memberDetails),
        workPhone: this.getPhoneNumber('workPhone', memberDetails),
        personCode: memberDetails.personCode,
        relationshipCode: memberDetails.relationshipCode,
        cardId: memberDetails.cardId,
        coverageType: memberDetails.coverage?.coverageType,
        SSN: memberDetails.SSN,
        flexTagName: memberDetails.flexTag.flexTagName,
        flexTagStartDate: memberDetails.flexTag.flexTagStartDate,
        flexTagEndDate: memberDetails.flexTag.flexTagEndDate,
        flexTagName2: memberDetails.flexTag2.flexTagName,
        flexTagStartDate2: memberDetails.flexTag2.flexTagStartDate,
        flexTagEndDate2: memberDetails.flexTag2.flexTagEndDate,
        flexTagName3: memberDetails.flexTag3.flexTagName,
        flexTagStartDate3: memberDetails.flexTag3.flexTagStartDate,
        flexTagEndDate3: memberDetails.flexTag3.flexTagEndDate,
        flexTagName4: memberDetails.flexTag4.flexTagName,
        flexTagStartDate4: memberDetails.flexTag4.flexTagStartDate,
        flexTagEndDate4: memberDetails.flexTag4.flexTagEndDate,
        flexTagName5: memberDetails.flexTag5.flexTagName,
        flexTagStartDate5: memberDetails.flexTag5.flexTagStartDate,
        flexTagEndDate5: memberDetails.flexTag5.flexTagEndDate,
        flexTagName6: memberDetails.flexTag6.flexTagName,
        flexTagStartDate6: memberDetails.flexTag6.flexTagStartDate,
        flexTagEndDate6: memberDetails.flexTag6.flexTagEndDate,
        clientInternalMemberId: memberDetails.clientInternalMemberId
      };
      this.loader.showLoader();
      this.loader.showWaitingMessage$.next(AppConstants.ERROR.WAIT_MESSAGE_FOR_UPDATE_MEMBER);
      this.eligibilityService.addMember(reqObj).subscribe({
        next: (res) => {
          // console.log(res);
          this.updatedMember = res;

          if (res) {
            this.loader.hideLoader();
            this.loader.showWaitingMessage$.next(null);
            this.dataSaved.emit(true);
            this.dialogRef.close();
          }
        },
        error: (err) => {
          this.createAuditLog(false, err)
          // console.log('Error on Update Address:', err);
          const message = getErrorMessage(err);
          this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: message,
            component: 'Update Address Info',
            title: '',
          });
          this.loader.hideLoader();
          this.loader.showWaitingMessage$.next(null);
        },
        complete: () => {
          this.createAuditLog(true);
        },
      });
    }
  }


  createAuditLog(success: boolean, err?: HttpErrorResponse) {
    const reqBody: SaveAuditLogsRequestInterface = {
      clientId: this.dialogData.memberDetails.clientId,
      cardId: this.dialogData.memberDetails.cardId,
      personCd: this.dialogData.memberDetails.personCode,
      firstName: this.dialogData.memberDetails.firstName,
      lastName: this.dialogData.memberDetails.lastName,
      emailId: this.updatedMember.email && this.updatedMember.email.toLowerCase() !== 'null'
        ? this.updatedMember.email
        : this.authService.userEmail,
      birthDt: this.dialogData.memberDetails.DOB,
      groupNo: this.groupInfo.groupId,
      groupName: this.groupInfo.groupName,
      changes: AppConstants.AUDIT_LOGS.addContactInfo.action.updateAddress.change,
      comments: AppConstants.AUDIT_LOGS.addContactInfo.action.updateAddress
        .comments,
      addedBy: this.authService.userEmail,
      isSuccess: success
    };
    if (!success) {
      const error = typeof (err.error) === 'string' ? JSON.parse(err.error) : err.error;
      const message = error?.Message ? error.Message : JSON.stringify(error);
      reqBody.httpStatusCode = err.status,
        reqBody.errorMsg = message
    }
    this.auditLogsService.saveAuditLog(reqBody).subscribe({
      next: (res) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_SUCCESS, { Response: res });
      },
      error: (err) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_ERROR, { Response: err });
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  public getPhoneNumber(
    phoneType: string,
    memberDetails: MemberDetails
  ): string {
    return memberDetails.phones.filter((p) => p.type === phoneType)[0]
      .phoneNumber;
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
