<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <!-- Page Header -->
  <div class="page-header">
    <h2>Mail Orders</h2>
  </div>
  <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <!-- Member Detail card -->
    <div class="detail-card">
      <div class="client-id-name">
        <h4>Member</h4>
        <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
      </div>
      <div class="mat-row">
        <div class="mat-2">
          <label class="card-label">Name</label>
          <p>
            {{ selectedMemberDetails.firstName }}
            {{ selectedMemberDetails.lastName }}
          </p>
        </div>
        <div class="mat-2">
          <label class="card-label">Person Code</label>
          <p>{{ selectedMemberDetails.personCode }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">DOB</label>
          <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Relationship</label>
          <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Card/Alt ID</label>
          <p>{{ selectedMemberDetails.cardId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Coverage Type</label>
          <p>
            {{
            displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
            }}
          </p>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="form-wrapper">
      <form [formGroup]="mailOrdersSearchFormGroup">
        <div class="mat-row pad-b-1">
          <div class="mat-4 pad-r-1">
            <mat-label>From<span class="asterisk-mark">*</span></mat-label>
            <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
              <input matInput
                [max]="mailOrdersSearchFormGroup.value.toDateControl? mailOrdersSearchFormGroup.value.toDateControl: maxDate"
                [matDatepicker]="formDatePicker" formControlName="formDateControl" matNativeControl
                placeholder="Enter Date (MM/DD/YYYY)"  maxlength="10"/>
              <mat-datepicker-toggle matIconSuffix [for]="formDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #formDatePicker></mat-datepicker>
            </mat-form-field>
            <app-custom-mat-hint [formGroup]="mailOrdersSearchFormGroup" [isCheckTouch]="checkTouchForForm"
              controlName="formDateControl"></app-custom-mat-hint>
          </div>
          <div class="mat-4 pad-r-1" [ngClass]="{'disabled-date':!mailOrdersSearchFormGroup.value.formDateControl}">
            <mat-label>To<span class="asterisk-mark">*</span></mat-label>
            <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
              <input matInput [disabled]="!mailOrdersSearchFormGroup.value.formDateControl"
                [min]="mailOrdersSearchFormGroup.value.formDateControl" [max]="maxDate" matNativeControl
                [matDatepicker]="toDatePicker" formControlName="toDateControl" placeholder="Enter Date (MM/DD/YYYY)"  maxlength="10"/>
              <mat-datepicker-toggle matIconSuffix [for]="toDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
            <app-custom-mat-hint [formGroup]="mailOrdersSearchFormGroup" [isCheckTouch]="checkTouchForForm"
              controlName="toDateControl"></app-custom-mat-hint>
          </div>

          <div class="mat-6">
            <button class="primary-btn top-label-space"
              [disabled]="dateDifferenceValidator() || mailOrdersSearchFormGroup.invalid" (click)="searchMailOrder()"
              mat-button>
              Search
            </button>
          </div>
        </div>
        <div *ngIf="dateDifferenceValidator()">
          <p style="color: #FF8605">
            The date range cannot be more than 24 months.
          </p>
        </div>
      </form>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
  <!--Search and status filter-->
  <div class="filter-block mb-1" *ngIf="showTable">
    <div class="mat-10 mt-4">
      <h4>{{totalLength}} Results Found</h4>
    </div>
    <div class="select-search">
      <div class="search-input-block">
        <img src="../../../../assets/images/search-icon.svg" alt="" />
        <input type="text" [(ngModel)]="searchList" class="form-control" placeholder="Search" (keyup)="search($event)"
          #input />
      </div>
      <div class="select-status" role="status">
        <mat-form-field floatLabel="never" class="full-width">
          <mat-select placeholder="Select Client" id="select-status" aria-labelledby="select-status-label"
            [(ngModel)]="status" class="form-control" (selectionChange)="statusChange()" matNativeControl>
            <ng-container>
              <mat-option value="all" selected>All</mat-option>
              <mat-option *ngFor="let statusVal of statusList" [value]="statusVal">{{ statusVal }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="showTable" class="orders-note justify-content-start ml-1">
    <img src="assets/images/exclaimation-mark.png" />
    <div>
      Orders or changes made outside this application may not reflect here. For the most current order status, please ask members to contact the number on the back of their card.
    </div>
  </div>

  <!--Table Component-->
  <div class="custom-box no-padding hidden custom-height" *ngIf="showTable">
    <table mat-table [dataSource]="dataSource" matSort class="mail-orders-table">
      <!-- dispensedDrugName Column -->
      <ng-container matColumnDef="dispensedDrugName">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Drug Name
        </th>
        <td mat-cell *matCellDef="let row">
          {{
          row.prescriptionFills.dispensedDrugName ||
          row.prescriptionFills.prescribedDrugName
          }}
        </td>
      </ng-container>

      <!-- orderNumber Column -->
      <ng-container matColumnDef="orderNumber">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Order Number
        </th>
        <td mat-cell *matCellDef="let row">{{ row.orderNumber }}</td>
      </ng-container>

      <!-- rxNumber Column -->
      <ng-container matColumnDef="rxNumber">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Rx Number
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.prescriptionFills.rxNumber }}
        </td>
      </ng-container>

      <!-- quantity Column -->
      <ng-container matColumnDef="quantity">
        <th class="header-center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Quantity
        </th>
        <td class="center" mat-cell *matCellDef="let row">
          {{ row.prescriptionFills.quantity || "-" }}
        </td>
      </ng-container>

      <!-- daysSupply Column -->
      <ng-container matColumnDef="daysSupply">
        <th class="header-center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Days Supply
        </th>
        <td class="center" mat-cell *matCellDef="let row">
          {{ row.prescriptionFills.daysSupply || "-" }}
        </td>
      </ng-container>

      <!-- receivedDate Column -->
      <ng-container matColumnDef="receivedDate">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Received Date
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.receivedDate | timeStampToDate }}
        </td>
      </ng-container>

      <!-- orderStatus Column -->
      <ng-container matColumnDef="orderStatus">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="text-w-400 status inprogress">
            {{ row.orderStatus }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr tabindex="0" (keydown.enter)="$event.stopPropagation(); openDialog(row)" (click)="openDialog(row)" mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <ng-container *matNoDataRow>
        <tr tabindex="0" class="mat-row no-data-row" *ngIf="totalLength == 0 || dataSource.data.length == 0">
          <td class="mat-cell" colspan="9" role="status">No Data Found</td>
        </tr>
      </ng-container>
    </table>
    <div class="mt-3 mb-3">
      <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button class="secondary-btn top-label-space" mat-button (click)="navigateBackToMemberSearch()">
        Cancel
      </button>
    </div>
  </div>
</div>