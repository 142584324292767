import { Component, OnInit } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { MemberDetails } from '../../../interfaces/member.interface';
import { LoaderService } from '../../services/loader/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { AppConstants } from '../../../app.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorManagerService, ErrorType } from '../../../core/services/error';
import { AppInsightsService } from '../../../services/app-insights/app-insights.service';
import { AppCenterAnalytics } from '../../../services/app-insights/app-insights';

@Component({
  selector: 'app-explanation-of-benefits',
  templateUrl: './explanation-of-benefits.component.html',
  styleUrls: ['./explanation-of-benefits.component.scss']
})
export class ExplanationOfBenefitsComponent implements OnInit {
  public explanationOfBenefitForm: FormGroup;
  public years = [];
  public selectedMemberDetails$: Observable<MemberDetails[]>;
  public memberDetails: MemberDetails;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  constructor(private readonly loader: LoaderService, private activatedRoute: ActivatedRoute, private readonly appInsightService: AppInsightsService,
    private eligibilityService: EligibilityService, private readonly formBuilder: FormBuilder,
    private readonly router: Router, private errorManager: ErrorManagerService) {
    this.explanationOfBenefitForm = this.formBuilder.group({
      year: [new Date().getFullYear(), Validators.required],
    });
  }

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.EOB);
    const sixYearBefore = new Date().getFullYear() - 6;
    const currentYear = new Date().getFullYear();
    this.years = this.yearsBetween(sixYearBefore, currentYear);
    this.selectedMemberDetails$ = defer(
      () => {
        this.loader.showLoader();
        return this.activatedRoute.queryParams.pipe(
          switchMap((params) => {
            const details = {
              clientId: params.clientId,
              cardId: params.cardId,
            };
            const query = this.eligibilityService.getSearchQuery(details);
            return this.eligibilityService.getMemberDetails(query).pipe(
              tap((res) => {
                this.memberDetails = res.filter((m) => m.patientId == params.patientId)[0];
                this.loader.hideLoader();
              })
            );
          })
        )
      });
  }

  private yearsBetween(startYear, endYear): string[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years.sort((a, b) => b - a);
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }


  public openPdf() {
    this.appInsightService.trackPageView(AppCenterAnalytics.EOB_PDF_VIEWED);
    this.loader.showLoader();
    this.eligibilityService.getEob({
      cardId: this.memberDetails.cardId,
      clientId: this.memberDetails.clientId,
      personCode: this.memberDetails.personCode,
      patientID: this.memberDetails.patientId,
      year: this.explanationOfBenefitForm.controls.year.value
    }).subscribe(
      (res: any) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.EOB_API_SUCCESS);
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);

        // Open a new window only after the blob is generated (PDF ready)
        setTimeout(() => {
          const newWindow = window.open(fileURL, '_blank');

          // Handle pop-up blockers gracefully
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            alert('Please allow pop-ups for this site to view the PDF.');
          }
        }, 0);

        this.loader.hideLoader();
      },
      (error) => {
        // this.errorManager.throwError({
        //   type: ErrorType.ERROR_GENERIC,
        //   message: error,
        // });
        this.appInsightService.trackPageView(AppCenterAnalytics.EOB_API_ERROR, { Response: error });
        this.loader.hideLoader();
      }
    );
  }

}
