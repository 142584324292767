<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <div class="page-header">
    <h2>Drug Pricing</h2>
    <p>
      This tool has been designed to assist you in understanding the cost of
      prescription medications and any generic alternatives that may be
      available to you under your program.
    </p>
  </div>

  <!-- Member Detail card -->
  <div class="detail-card" *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <div class="client-id-name">
      <h4>Member</h4>
      <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
    </div>
    <div class="mat-row">
      <div class="mat-2">
        <label class="card-label">Name</label>
        <p>
          {{ selectedMemberDetails.firstName }}
          {{ selectedMemberDetails.lastName }}
        </p>
      </div>
      <div class="mat-2">
        <label class="card-label">Person Code</label>
        <p>{{ selectedMemberDetails.personCode }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">DOB</label>
        <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Relationship</label>
        <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Card/Alt ID</label>
        <p>{{ selectedMemberDetails.cardId }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Coverage Type</label>
        <p>
          {{
          displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
          }}
        </p>
      </div>
    </div>
  </div>

  <!-- Search by -->
  <div class="search-by-container pad-b-0">
    <h4 class="sec-heading">Search Drugs and Pharmacy</h4>
    <div class="custom-tab">
      <form [formGroup]="drugPricingSearchFormGroup">
        <mat-tab-group [(selectedIndex)]="this.selectedIndex" (selectedIndexChange)="onTabChange($event)"
          animationDuration="0ms">
          <mat-tab label="City and State">
            <div class="tab-action-group mat-row pad-b-0">
              <div class="mat-4 pad-r-1">
                <mat-label>Drug Name<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field appearance="fill" class="full-width zero-padding">
                  <input type="text" matInput formControlName="drugName" [matAutocomplete]="auto"
                    placeholder="Search Drug" />
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                    <mat-option [disabled]="true" *ngIf="loadingDrugNames">Loading...</mat-option>
                    <mat-option *ngFor="let drug of filteredDrugNames | async" [value]="drug.name">
                      {{ drug.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="drugName"></app-custom-mat-hint>
              </div>

              <div class="mat-4 pad-r-1">
                <mat-label>Pharmacy Name (Optional)</mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input type="text" matInput formControlName="pharmacyName" placeholder="Enter Pharmacy Name" />
                </mat-form-field>
              </div>
            </div>
            <div class="tab-action-group mat-row pad-b-1 pad-t-0">
              <div class="mat-4-25 pad-r-1">
                <mat-label>Quantity<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input type="text" matInput formControlName="quantity" placeholder="Enter Quantity"
                    (keydown)="numericOnly($event)" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="quantity"></app-custom-mat-hint>
              </div>
              <div class="mat-4-25 pad-r-1">
                <mat-label>Days Supply<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input type="text" matInput formControlName="daysSupply" placeholder="Enter Days Supply"
                    (keydown)="numericOnly($event)" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="daysSupply"></app-custom-mat-hint>
              </div>
              <div class="mat-4-25 pad-r-1">
                <mat-label>City<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input matInput placeholder="Enter City" formControlName="city" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="city"></app-custom-mat-hint>
              </div>
              <div class="mat-4-25 pad-r-1">
                <mat-label>State<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <mat-select matNativeControl placeholder="Select State" formControlName="state">
                    <mat-option *ngFor="let state of states" [value]="state.value">{{ state.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="state"></app-custom-mat-hint>
              </div>

              <div class="mat-4-25 d-flex">
                <button (click)="
                    getDrugPricingResults(); checkTouchForValidation = true
                  " class="primary-btn top-label-space" [disabled]="drugPricingSearchFormGroup.invalid" mat-button>
                  Search
                </button>
                <button (click)="resetForm()" class="secondary-btn top-label-space"
                  [ngClass]="{ 'disabled-btn': allFieldsEmpty }" mat-button>
                  Reset
                </button>
              </div>
            </div>
            <mat-divider class="mar-b-0"></mat-divider>
          </mat-tab>
          <mat-tab label="Zip Code">
            <div class="tab-action-group mat-row pad-b-0">
              <div class="mat-4 pad-r-1">
                <mat-label>Drug Name<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field appearance="fill" class="full-width zero-padding">
                  <input type="text" matInput formControlName="drugName" [matAutocomplete]="auto"
                    placeholder="Search Drug" />
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                    <mat-option *ngFor="let drugName of filteredDrugNames | async" [value]="drugName">
                      {{ drugName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="drugName"></app-custom-mat-hint>
              </div>
              <div class="mat-4 pad-r-1">
                <mat-label>Pharmacy Name (Optional)</mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input type="text" matInput formControlName="pharmacyName" placeholder="Enter Pharmacy Name" />
                </mat-form-field>
              </div>
            </div>
            <div class="tab-action-group mat-row pad-b-1 pad-t-0">
              <div class="mat-4-25 pad-r-1">
                <mat-label>Quantity<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input type="text" matInput formControlName="quantity" placeholder="Enter Quantity"
                    (keydown)="numericOnly($event)" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="quantity"></app-custom-mat-hint>
              </div>
              <div class="mat-4-25 pad-r-1">
                <mat-label>Days Supply<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input type="text" matInput formControlName="daysSupply" placeholder="Enter Days Supply"
                    (keydown)="numericOnly($event)" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="daysSupply"></app-custom-mat-hint>
              </div>
              <div class="mat-4-25 pad-r-1">
                <mat-label>Zip Code<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input matInput (keydown)="numericOnly($event)" placeholder="Enter Zip Code"
                    formControlName="zipCode" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="zipCode"></app-custom-mat-hint>
              </div>
              <div class="mat-4-25 pad-r-1">
                <mat-label>Within<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <mat-select formControlName="radius" placeholder="Select Within">
                    <mat-option value="5"> 5 </mat-option>
                    <mat-option value="10"> 10 </mat-option>
                    <mat-option value="15"> 15 </mat-option>
                    <mat-option value="20"> 20 </mat-option>
                    <mat-option value="25"> 25 </mat-option>
                  </mat-select>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="drugPricingSearchFormGroup" [isCheckTouch]="checkTouchForValidation"
                  controlName="radius"></app-custom-mat-hint>
              </div>
              <div class="mat-4-25 d-flex">
                <button (click)="
                    getDrugPricingResults(); checkTouchForValidation = true
                  " class="primary-btn top-label-space" [disabled]="drugPricingSearchFormGroup.invalid" mat-button>
                  Search
                </button>
                <button *ngIf="drugPricingSearchFormGroup.touched" (click)="resetForm()"
                  [ngClass]="{ 'disabled-btn': allFieldsEmpty }" class="secondary-btn top-label-space" mat-button>
                  Reset
                </button>
              </div>
            </div>
            <mat-divider class="mar-b-0"></mat-divider>
          </mat-tab>
        </mat-tab-group>
      </form>
    </div>
  </div>
  <ng-container *ngIf="dataLoaded">
    <div class="mat-row search-results" fxLayout="row">
      <div class="mat-6">
        <h4 class="sec-heading">
          {{ searchedDrugName ? searchedDrugName : "Search Results" }}
        </h4>
      </div>
      <div class="mat-6" fxLayoutAlign="end end">
        <mat-button-toggle-group (change)="resetSearch()" [(ngModel)]="pharmacyType" class="toggle-buttons">
          <mat-button-toggle value="retail">Retail</mat-button-toggle>
          <mat-button-toggle value="mail-order">Mail Order</mat-button-toggle>
        </mat-button-toggle-group>

        <button mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
          <mat-icon matListIcon>filter_alt</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="filter-box">
          <div (click)="$event.stopPropagation()">
            <div class="filter-heading">
              Filter
              <mat-icon class="close-icon" matListIcon (click)="closeFilter()">close</mat-icon>
            </div>
            <div class="slide-toggle-field">
              In Network
              <mat-slide-toggle [(ngModel)]="isNetwork"></mat-slide-toggle>
            </div>
            <div class="slide-toggle-field">
              Operating 24 hrs
              <mat-slide-toggle [(ngModel)]="is24Hrs"></mat-slide-toggle>
            </div>
            <button class="primary-btn" mat-button (click)="resetSearch()" (keyup.enter)="resetSearch()">
              Apply
            </button>
            <button (click)="isNetwork = !true; is24Hrs = !true; resetSearch()"
              (keyup.enter)="isNetwork = !true; is24Hrs = !true; resetSearch()" class="secondary-btn" mat-button>
              Clear
            </button>
          </div>
        </mat-menu>
      </div>
    </div>

    <!--Table Component-->
    <div class="mar-t-1">
      <table class="clickable-row table-td-spacing" mat-table [dataSource]="dataSource" matSort
        (matSortChange)="sortData($event)">
        <!-- Pharmacy Name Column -->
        <ng-container matColumnDef="name">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
            Pharmacy Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.pharmacyName }}</td>
        </ng-container>

        <!-- Distance (in Miles) Column -->
        <ng-container matColumnDef="distance">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
            Distance (in Miles)
          </th>
          <td mat-cell *matCellDef="let row">
            {{
            row.distanceMiles ? (row.distanceMiles | number : "1.0-2") : "-"
            }}
          </td>
        </ng-container>

        <!-- Hours of Operation Column -->
        <ng-container matColumnDef="hoursOp">
          <th scope="col" mat-header-cell *matHeaderCellDef>
            Hours of Operation
          </th>
          <td mat-cell *matCellDef="let row">
            {{
            row.pharmacy.hours.operating24Hours
            ? "24 Hrs"
            : getTodaysTiming(row.pharmacy.hours)
            ? getTodaysTiming(row.pharmacy.hours)
            : "-"
            }}
          </td>
        </ng-container>

        <!-- Brand/Generic Column -->
        <ng-container matColumnDef="brandOrGeneric">
          <th class="w-10" scope="col" mat-header-cell *matHeaderCellDef>
            Brand/Generic
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="td-space">
              <p *ngIf="row.pricings[0].pricing" class="title-12">
                {{ "Brand" }}
              </p>
              <p *ngIf="row.pricings[0].genericAlternativePricing" class="title-13 light-gray-color">
                {{ "Generic" }}
              </p>
            </div>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="brandOrGeneric">
          <th scope="col" mat-header-cell *matHeaderCellDef>Brand/Generic</th>
          <td mat-cell *matCellDef="let row">
            {{ row.pricings[0].pricing.name }}/{{
              row.pricings[0].genericAlternativePricing.name
            }}
          </td>
        </ng-container> -->

        <!-- Member Cost Column -->
        <ng-container matColumnDef="memberCost">
          <th scope="col" mat-header-cell *matHeaderCellDef>Member Cost</th>
          <td class="center" mat-cell *matCellDef="let row">
            <div class="td-space">
              <p *ngIf="row.pricings[0].pricing" class="title-12 mb-0" [ngClass]="{
                  'text-maroon': row.pricings[0].pricing?.rejectMessage
                }">
                {{
                row.pricings[0].pricing.memberCost
                ? "$" + row.pricings[0].pricing.memberCost
                : row.pricings[0].pricing?.rejectMessage
                ? ""
                : "$-"
                }}
                <mat-icon *ngIf="row.pricings[0].pricing?.rejectMessage"
                  class="pointer text-maroon reject-msg-text">priority_high</mat-icon>
              </p>
              <p *ngIf="row.pricings[0].genericAlternativePricing" class="title-13 light-gray-color" [ngClass]="{
                  'text-maroon':
                    row.pricings[0].genericAlternativePricing?.rejectMessage
                }">
                {{
                row.pricings[0].genericAlternativePricing.memberCost
                ? "$" + row.pricings[0].genericAlternativePricing.memberCost
                : row.pricings[0].genericAlternativePricing?.rejectMessage
                ? ""
                : "$-"
                }}
                <mat-icon *ngIf="
                    row.pricings[0].genericAlternativePricing?.rejectMessage ===
                      null && row.pricings[0].pricing?.rejectMessage
                  " class="pointer text-maroon reject-msg-text">priority_high</mat-icon>
              </p>
            </div>
          </td>
          <!-- <td mat-cell *matCellDef="let row">
            {{ row.pricings[0].pricing.memberCost | currency : "USD" }}/{{
              row.pricings[0].genericAlternativePricing.memberCost
                | currency : "USD"
            }}
          </td> -->
        </ng-container>

        <!-- Plan Cost Column -->
        <ng-container matColumnDef="planCost">
          <th scope="col" mat-header-cell *matHeaderCellDef>Plan Cost</th>
          <td class="center" mat-cell *matCellDef="let row">
            <div class="td-space">
              <p *ngIf="row.pricings[0].pricing" class="title-12 mb-0" [ngClass]="{
                  'text-maroon': row.pricings[0].pricing?.rejectMessage
                }">
                {{
                row?.pricings[0]?.pricing?.planCost
                ? "$" + row?.pricings[0]?.pricing?.planCost
                : row.pricings[0].pricing?.rejectMessage
                ? ""
                : "$-"
                }}
                <mat-icon *ngIf="row.pricings[0].pricing?.rejectMessage"
                  class="icon-information pointer text-maroon reject-msg-text">priority_high</mat-icon>
              </p>
              <p *ngIf="row.pricings[0].genericAlternativePricing" class="title-13 light-gray-color" [ngClass]="{
                  'text-maroon':
                    row.pricings[0].genericAlternativePricing?.rejectMessage
                }">
                {{
                row?.pricings[0]?.genericAlternativePricing?.planCost
                ? "$" +
                row?.pricings[0]?.genericAlternativePricing?.planCost
                : row.pricings[0].genericAlternativePricing?.rejectMessage
                ? ""
                : "$-"
                }}
                <mat-icon *ngIf="
                    row.pricings[0].genericAlternativePricing?.rejectMessage ===
                      null && row.pricings[0].pricing?.rejectMessage
                  " class="icon-information pointer text-maroon reject-msg-text">priority_high</mat-icon>
              </p>
            </div>
          </td>
          <!-- <td mat-cell *matCellDef="let row">
            {{ row.pricings[0].pricing.planCost | currency : "USD" }}/{{
              row.pricings[0].genericAlt  ernativePricing.planCost
                | currency : "USD"
            }}
          </td> -->
        </ng-container>

        <!-- Total Cost Column -->
        <ng-container matColumnDef="totalCost">
          <th scope="col" mat-header-cell *matHeaderCellDef>Total Cost</th>
          <td class="center" mat-cell *matCellDef="let row">
            <div class="td-space">
              <p *ngIf="row.pricings[0].pricing" class="title-12 mb-0" [ngClass]="{
                  'text-maroon': row.pricings[0].pricing?.rejectMessage
                }">
                {{
                row?.pricings[0]?.pricing?.totalCost
                ? "$" + row?.pricings[0]?.pricing?.totalCost
                : row.pricings[0].pricing?.rejectMessage
                ? ""
                : "$-"
                }}
                <mat-icon *ngIf="row.pricings[0].pricing?.rejectMessage"
                  class="icon-information pointer text-maroon reject-msg-text">priority_high</mat-icon>
              </p>
              <p *ngIf="row.pricings[0].genericAlternativePricing" class="title-13 light-gray-color" [ngClass]="{
                  'text-maroon':
                    row.pricings[0].genericAlternativePricing?.rejectMessage
                }">
                {{
                row?.pricings[0]?.genericAlternativePricing?.totalCost
                ? "$" +
                row?.pricings[0]?.genericAlternativePricing?.totalCost
                : row.pricings[0].genericAlternativePricing?.rejectMessage
                ? ""
                : "$-"
                }}
                <mat-icon *ngIf="
                    row.pricings[0].genericAlternativePricing?.rejectMessage ===
                      null && row.pricings[0].pricing?.rejectMessage
                  " class="icon-information pointer text-maroon reject-msg-text">priority_high</mat-icon>
              </p>
            </div>
          </td>
          <!-- <td mat-cell *matCellDef="let row">
            {{ row.pricings[0].pricing.totalCost | currency : "USD" }}/{{
              row.pricings[0].genericAlternativePricing.totalCost
                | currency : "USD"
            }}
          </td> -->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr tabindex="0" (keydown.enter)="$event.stopPropagation(); openDialog(row)" (click)="openDialog(row)" mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr tabindex="0" class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9" role="status">
            No Data Found
          </td>
        </tr>
      </table>
      <div class="accordion-table-footer paginator" fxLayout="row" fxLayoutAlign="end center">
        <div class="drug-note">
          <mat-icon class="pointer text-maroon reject-msg-text">priority_high</mat-icon>
          This drug is not covered in the Current plan
        </div>
        <div class="drug-paginator">
          <mat-paginator #paginator (page)="onPageChange($event)" [pageIndex]="this.currentPageIndex"
          [pageSizeOptions]="[5, 10]"></mat-paginator>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="mat-row button-row mar-t-1">
    <div class="mat-12" fxLayoutAlign="end end">
      <button mat-button color="warn" class="secondary-btn" (click)="navigateBackToMemberSearch()">
        Cancel
      </button>
    </div>
  </div>
</div>