/**
 * @file services.constants.ts
 */
export const APIConstants = {
    CAP_DEDUCTIBLES_ACCUMULATION :'/benefits-service/benefits-service/rest/member/caps-deductibles-accumulations',
    MEMBER_SEARCH: '/member/search',
    BENEFIT_TOOLS:'/benefits-service/benefits-service/rest/getBenefitTools',
    SEARCH_PATIENT_ENROLLMENT:'/mail-order/search-patient-enrollment',
    MEMBER_COVERAGE: '/member/coverage',
    CLIENT_INFORMATION: '/client/info',
    GET_PHARMACIES:'/member-service/member-service/rest/pharmacies',
    GET_DRUG_NAME:'/drug-service/drug-service/rest/drugs',
    GET_DRUG_PRICING: '/member-service/member-service/rest/drug-pricing',
    GET_FAMILY_COVERAGE: '/member-service/member-service/rest/family-coverage',
    GET_CLIENT_CLAIM:'/claims-service/claims-service/rest/getclientclaim',
    GET_THIRD_PARTY_ASSISTANCE:'/third/party/assistance',
    UPDATE_MEMBER_ADDRESS:'/members/addressupdate',
    TERM_DEPENDENT: '/members/term-dependents',
    MEMBER_MODIFICATION:'/member/modification',
    GET_CLIENT_FLAG:'/client/flags',
    GET_TEMP_CLIENT_ID:'/member-service/member-service/rest/temp-id-card',
    GET_RX_COVERAGE: '/rx/coverage',
    GET_CLIENT_FLEX_TAG:'/client/flex_tags',
    GET_CLIENT_EFFECTIVE_DATE:'/client/effective_date',
    HISTORIC_DATA_REQUEST:'/historic_data_request/register',
    REQUEST_CARD: '/cards/requestCards',
    GET_MEMBER_EOB: '/member/eob',
    SEARCH_MAIL_ORDER:'/bap/mail-orders/search',
    PRESCRIPTION_SEARCH: '/bap/prescriptions/search',
    CLINICAL_REVIEW_SEARCH: '/bap/clinicalreview/search',
    CLINICAL_REVIEW_DOCUMENT: '/bap/clinicalreview/document',
    PREFERRED_MEDICATION: '/preferred/medications'
}