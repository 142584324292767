/**
 * All APIs will by default point to default version (defaultVersion).
 * This configuration will be use to identify the API version while calling in token interceptor.
 * The version needs to be passed in the API request header.
 * 
 * To override the default version for any API, create a separate entry  -
 * Example :
 * "api-url" : {
 *    "httpMethod": [
 *      "GET",
 *    ],
 *    "apiVersion": "version"
 * }
 * 
 */
export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE';

export type VersionOverrideType = Record<string, { apiVersion: string; methods?: Array<HttpMethod | string> }>;

export type APIVersionConfigType = {
  defaultVersion: string;
  versionOverrides: VersionOverrideType;
}

export const APIVersionConfig: APIVersionConfigType = {
  defaultVersion: "1.0",

  // overrides
  versionOverrides: {
    "/AuditLogs/GetAuditLogs": {
      "methods": [
        "POST"
      ],
      "apiVersion": "2.0"
    },
    "/cards/requestCards" : {
      "methods": [
        "POST"
      ],
      "apiVersion":"2.0"
    },
    "/bap/prescriptions/search" : {
      "methods": [
        "POST"
      ],
      "apiVersion":"2.0"
    }
  }
}