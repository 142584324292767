import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-downtime-dialog',
  templateUrl: './downtime-dialog.component.html',
  styleUrls: ['./downtime-dialog.component.scss']
})
export class DowntimeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DowntimeDialogComponent>,
    public readonly authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data?: any,
  ) { }

  ngOnInit(): void { }

  close() {
    if(this.data.outageFlag == true) {
      this.authService.logout();
    } else {
     this.dialogRef.close();
    }
  }

}
