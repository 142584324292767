<mat-nav-list>
    <mat-list>
        <mat-list-item (click)="navigateToHome()" matTooltip="{{constants.LABELS.backToClientSelection}}"
            matTooltipPosition="right" matTooltipClass="bg-accent">
            <mat-icon matListIcon>home</mat-icon>
            <h3 matLine>{{constants.LABELS.backToClientSelection}}</h3>
        </mat-list-item>
    </mat-list>
    <mat-divider *ngIf="mainMenu.length > 0"></mat-divider>
    <div *ngIf="mainMenu.length > 0" mat-subheader class="text-label">Reports</div>
    <mat-list [class.submenu]="menu.subReports.length > 0" [class.open]="showSubMenus && menu.reportId === reportId"
        *ngFor="let menu of mainMenu" (click)="clickMainMenu(menu)">
        <mat-list-item [class.selected]="menu.reportId === reportId && !(showDocuments || showInvoices)"
            matTooltip="{{menu.reportName}}" matTooltipPosition="right" matTooltipClass="bg-accent">
            <mat-icon matListIcon class="empirx-icon">{{menuIcons(menu.reportId)}}</mat-icon>
            <h3 matLine>{{menu.reportName}}</h3>
            <mat-icon *ngIf="menu.subReports.length > 0" class="down-arrow">expand_more</mat-icon>
        </mat-list-item>
        <mat-nav-list *ngIf="showSubMenus && reportId ===menu.reportId">
            <mat-list *ngFor="let sb of subMenu" (click)="clickSubMenu(sb)">
                <mat-list-item [class.selected]="sb.reportId === subReportId && !(showDocuments || showInvoices)"
                    matTooltip="{{sb.reportName}}" matTooltipPosition="right" matTooltipClass="bg-accent">
                    <h3 matLine>{{sb.reportName}} </h3>
                </mat-list-item>
            </mat-list>
        </mat-nav-list>
    </mat-list>
    <mat-divider *ngIf="mainMenu.length > 0"></mat-divider>
    <mat-list (click)="showOtherMenus()" *ngIf="mainMenu.length > 0">
        <mat-list-item matTooltip="{{constants.LABELS.documents}}" [class.selected]="showDocuments"
            matTooltipPosition="right" matTooltipClass="bg-accent">
            <mat-icon matListIcon>description</mat-icon>
            <h3 matLine>{{constants.LABELS.documents}}</h3>
        </mat-list-item>
    </mat-list>
    <mat-divider *ngIf="isInvoice"></mat-divider>
    <mat-list (click)="clickedInvoices()" *ngIf="isInvoice">
        <mat-list-item matTooltip="{{constants.LABELS.financialDocuments}}" [class.selected]="showInvoices"
            matTooltipPosition="right" matTooltipClass="bg-accent">
            <mat-icon matListIcon class="empirx-icon">financialdocument</mat-icon>
            <h3 matLine>{{constants.LABELS.financialDocuments}}</h3>
        </mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-list (click)="showWhoWeArePage()">
        <mat-list-item matTooltip="{{constants.LABELS.whoWeAre}}" matTooltipPosition="right"
            matTooltipClass="bg-accent">
            <mat-icon matListIcon>groups</mat-icon>
            <h3 matLine>{{constants.LABELS.whoWeAre}}</h3>
        </mat-list-item>
    </mat-list>
</mat-nav-list>