<section class="static-content">

    <div class="static-header mat-app-background">
        <div class="container">
            <div class="header-inner" fxLayout="row" fxLayoutAlign="start center">
                <div class="static-brand">
                    <a href="https://www.empirxhealth.com" target="_blank" rel="noopener noreferrer">
                        <img class="brand" src="../../../../assets/images/brand-logo/logo.svg" width="95"
                            alt="EmpiRx"></a>
                </div>
                <h3 class="static-title">EmpiRx Health Privacy Statement</h3>
            </div>

            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="container">
        <div class="static-text">

            <p>EmpiRx Health is committed to respecting the privacy and security of your information. By using the
                EmpiRx Health website (the “Website”) and EmpiRx Health portal (the “Portal”), you are agreeing to our
                Privacy Statement. We may update and change this Statement from time to time without notice to you, and
                by using or accessing the Website and Portal, you are agreeing to accept those changes. </p>

            <p>Our use and disclosure of client health plan (“Client” or “Health Plan”) member personal health
                information (PHI) is governed by the Health Insurance Portability and Accountability Act (commonly known
                as “HIPAA”), and a Business Associate agreement between us and the Health Plan. If you are a consultant,
                advisor or broker to the Health Plan, your access to, use and disclosure of PHI is governed by your
                agreements, including Business Associate Agreements, with the Health Plan. If you have any questions
                about which Statement applies to information you submit to us, please do not hesitate to contact us via
                email at: <a href="mailto:privacy@empirxhealth.com">privacy@empirxhealth.com </a>or mail at: EmpiRx
                Health, LLC, ATTN: Privacy Officer, 155 Chestnut Ridge Road, Montvale, NJ 07645. </p>

            <p>We maintain security measures as required under HIPAA and the Health Information Technology for Economic
                Clinical Health Act (“HITECH”) to help protect against the loss, misuse, and alteration of the
                information under our control. Our web server uses encryption technology to ensure private and
                authenticated communication by encrypting all user-identifying data between two parties (user and
                Website). </p>
        </div>

        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Security Measures
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>We take certain measures to enhance the security of our systems and services, specifically: (i)
                        the Service is protected by multiple firewalls; (ii) we have installed functions capable of
                        detecting and thwarting cyber-attacks; (iii) the Service is protected by SSL certificates that
                        encrypts all ingress and egress traffic; (iv) we have taken steps to allow only the intended
                        traffic to traverse between the servers hosting the application; (v) no PHI is stored within our
                        mobile application; and (vi) periodic vulnerability assessment and penetration testing is
                        carried out on the Service. Notwithstanding the foregoing, we make no representations as to the
                        security or privacy of your information outside of the specific industry-standard mechanisms we
                        have undertaken in good faith.
                    </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Information Collected
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>We may collect and store certain personal information from you when you register through the
                        Portal that can be used to identify you, such as your name, email address, Client ID(s), type of
                        profile (e.g., individual or combined Client, or Advisor for multiple Clients) and/or other
                        information that we use to establish your profile. The information we collect from you to the
                        extent that it is private, is disclosed only in accordance with the Terms of Use.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        De-Identified Information
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Whenever you use our services, we also may collect non-identifying information from you, such as
                        your IP address, referring URL, operating system, cookie information, and Internet Service
                        Provider. We do not identify you by individual name or email address. We use this data in the
                        aggregate to evaluate use of the Website and Portal to troubleshoot and improve the Website and
                        Portal, among other business purposes. We may also provide this statistical information to
                        third-parties.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Personal Information
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>We may use your email address to contact you regarding administrative notices, and communications
                        relevant to your use of the Website and Portal. We may also use or disclose information to
                        resolve disputes, investigate problems, or enforce the Terms of Use. We may disclose or access
                        information whenever we believe in good faith that the law so requires or if we otherwise
                        consider it necessary to do so to maintain and improve the services.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Cookies
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Cookies are small data files that are placed on your computer or other devices by websites that
                        you visit. We may use cookies for various purposes including, but not limited to, remembering
                        your preferences, maintaining User sessions, improving security of our services, and collecting
                        usage information. You can set your browser to notify you when you receive a cookie, giving you
                        the chance to decide whether to accept it. You may also delete a cookie manually from your hard
                        drive through your internet browser or other programs. Because the Website makes use of cookies,
                        please note that some parts of the Website may not function properly or be available to you if
                        you refuse to accept a cookie or choose to disable the acceptance of cookies.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Third Party Access to Your Information
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>We use third party organizations to assist us in providing the services and establishing and
                        maintaining the Website and Portal, including contractors, web hosting companies, and others.
                        Throughout the course of our provision of our services to you, we may share your information
                        with such third parties who may have authority to collect, access, use, and disseminate your
                        information in accordance with the Terms of Use and this Privacy Statement. Without limiting the
                        generality of the foregoing, you authorize us to collect, share, store, exchange, and otherwise
                        use your information in conjunction with the services.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Release of Your Information for Legal Purposes
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>At times, it may become necessary to release your information in response to a request from a
                        government agency or in connection with a lawsuit or other legal action. You agree that we may
                        disclose your information to a third party where we believe, in good faith, that it is
                        appropriate to do so for the purposes of a civil action, criminal investigation, or other legal
                        matter. We may also proactively report you, and release your information to, third parties where
                        we believe that it is prudent to do so for legal reasons, such as our belief that you have
                        engaged in fraudulent activities. You release us from any damages that may arise from or relate
                        to the release of your information to a request from law enforcement agencies or connection with
                        a lawsuit or other legal matter.</p>
                </div>
            </mat-expansion-panel>

        </mat-accordion>




    </div>
</section>
<div class="footer text-center top-spacing">
    <app-footer></app-footer>
</div>