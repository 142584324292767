import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from '../../../../app.constants';
import {
  ICreateMemberResponse,
  MemberDetails,
} from '../../../../interfaces/member.interface';
import { formatPhone } from 'src/app/util';

@Component({
  selector: 'app-add-member-response-dialog',
  templateUrl: './add-member-response-dialog.component.html',
  styleUrls: ['./add-member-response-dialog.component.scss'],
})
export class AddMemberResponseDialogComponent implements OnInit {
  public displayGender = AppConstants.DISPLAY_GENDER;
  public phoneLabels = AppConstants.PHONE_LABELS;
  cardHolderRC: string = AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER;
  constructor(
    public dialogRef: MatDialogRef<AddMemberResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: MemberDetails,
    private router: Router
  ) {}

  ngOnInit(): void {}

  public cancel(): void {
    this.dialogRef.close();
  }
  public navigateBackToMemberSearch(): void {
    this.cancel();
    this.router.navigateByUrl('/management/online-eligibility');
  }

  formatPhoneNumber(phone: string): string {
    return formatPhone(phone);
  }
}
