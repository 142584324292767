<div class="page-wrapper" *ngIf="selectedMemberDetails$ | async">
    <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
            matListIcon>arrow_back</mat-icon> Member Search</a>
    <div class="page-header">
        <h2>Explanation of Benefits (EOB)</h2>
    </div>
    <div class="detail-card">
        <div class="client-id-name">
            <h4>Member</h4>
            <span>(Client: {{memberDetails.clientId}}-{{memberDetails.client_full_name}})</span>
        </div>
        <div class="mat-row">
            <div class="mat-2">
                <label class="card-label">Name</label>
                <p>
                    {{ memberDetails.firstName }}
                    {{ memberDetails.lastName }}
                </p>
            </div>
            <div class="mat-2">
                <label class="card-label">Person Code</label>
                <p>{{ memberDetails.personCode }}</p>
            </div>
            <div class="mat-2">
                <label class="card-label">DOB</label>
                <p>{{ memberDetails.DOB | timeStampToDate }}</p>
            </div>
            <div class="mat-2">
                <label class="card-label">Relationship</label>
                <p>{{memberDetails.relationshipCode}} - {{relationShip[memberDetails.relationshipCode] }}</p>
            </div>
            <div class="mat-2">
                <label class="card-label">Card/Alt ID</label>
                <p>{{ memberDetails.cardId }}</p>
            </div>
            <div class="mat-2">
                <label class="card-label">Coverage Type</label>
                <p>
                    {{
                    displayCoverageType[memberDetails?.coverage?.coverageType]
                    }}
                </p>
            </div>
        </div>
    </div>

    <mat-divider></mat-divider>
    <form [formGroup]="explanationOfBenefitForm">
        <div class="form-wrapper">
            <div class="eob-view-pdf-form">
                    <div class="select-year">
                        <mat-label>Select Year <span class="asterisk-mark">*</span></mat-label>
                        <mat-form-field floatLabel="never" class="full-width">
                            <mat-select placeholder="Select" formControlName="year">
                                <mat-option *ngFor="let value of years" [value]="value">
                                    {{ value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-custom-mat-hint [formGroup]="explanationOfBenefitForm" controlName="year"></app-custom-mat-hint>
                    </div>
                    <div class="view-pdf">
                        <a class="view-file-eob" style="cursor: pointer;" (click)="openPdf()" target="_blank">VIEW PDF</a>
                    </div>
            </div>
        </div>
    </form>
    
    <div class="note-wrapper">
        <h4>Note:</h4>
        <p>
            ** Choose a year from the dropdown, then click the "VIEW PDF" button to view or download the Explanation of
            Benefits for the selected year.
        </p>
    </div>
</div>