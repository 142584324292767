import { Injectable } from '@angular/core';
import {
  CoverageResponse,
  IAddressUpdateRequest,
  ICclientFlags,
  ICoverageAddRequest,
  ICoverageUpdateRequest,
  IFamilyCoverageResponse,
  IMemberSearchResponse,
  MemberDetails,
  MemberSearchRequest,
} from 'src/app/interfaces/member.interface';
import {
  Observable,
  of,
  BehaviorSubject,
  defer,
  throwError,
  Subject,
} from 'rxjs';
import {
  map,
  switchMap,
  shareReplay,
  finalize,
  catchError,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientInfoResponse } from 'src/app/interfaces/UserGroup.interface';
import { PharmacySearchRequest } from 'src/app/interfaces/pharmacy.interface';
import { PharmacySearchResponse } from 'src/app/interfaces/pharmacy-search-response.interface';
import { LoaderService } from '../../../management/services/loader/loader.service';
import { PricingResponse } from 'src/app/interfaces/pricing-response.interface';
import { DrugPricingSearchRequest } from 'src/app/interfaces/pricing.interface';
import {
  ClaimSearchListRequest,
  ClaimSearchListResponse,
} from '../../../interfaces/claim-search-list-response.interface';
import {
  ClaimRequestInterface,
  ClaimSearchCompareDetailsResponse,
  ClaimSearchDetailsResponse,
  ThirdPartyAssistanceResponseInterface,
} from 'src/app/interfaces/claim-search-detail-response.interface';
import {
  convertJSONToQueryParams,
  formatDateToYYYYMMDD,
  trimIfUNE,
} from '../../../util';
import { SearchDrugResponseInterface } from 'src/app/interfaces/drug-search.interface';
import { BenefitTool } from 'src/app/interfaces/benefit-tool.interface';
import { CapDeductiblesResponse } from 'src/app/interfaces/cap-deductibles-response.interface';
import { CapDeductibleRequestInterface } from 'src/app/interfaces/cap-deductible-accumulation.interface';
import { MemberGroupInterface } from 'src/app/interfaces/member-group.interface';
import { PrintDigitalIDCardRequestInterface } from 'src/app/interfaces/print-digital-id-card.interface';
import { IFlexTagTypes, MemberRxHistoryResponseInterface } from 'src/app/interfaces/coverage.interface';
import { RequestIDCardRequestInterface } from 'src/app/interfaces/request-card.interface';
import { AuditLogsService } from '../audit-logs/audit-logs.service';
import { RequestOptions } from '../../../interfaces/request-options.interface';
import { Clients } from '../../../interfaces/clients.interface';
import { DemoClientService } from '../demo-client/demo-client.service';
import { ServiceConstants } from 'src/app/shared/constants/service.constants';
import { AuthService } from '../auth/auth.service';
import { RequestHistoricalDataRequestInterface } from '../../../interfaces/historical-data-request.interface';
import { OrdersResponse } from 'src/app/interfaces/mail-order.interface';
import { PrescriptionsResponse } from 'src/app/interfaces/prescription.interface';
import { ClinicalReviewStatusResponse } from '../../../interfaces/clinical-reviews.interface';
import { IClinicalReviewDocRequest } from '../../../interfaces/clinical-review-doc-request.interface';
import { CommonRequest } from 'src/app/interfaces/common-request.interface';
import { RequestEOB } from '../../../interfaces/eob.interface';
import { APIConstants } from 'src/app/services/service.constant';

@Injectable({
  providedIn: 'root',
})
export class EligibilityService {
  public selectedIndex: number = 0; // service
  public membersSearchResultCache$!: Observable<IMemberSearchResponse>;
  public queryString: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private clearSearchClaimCache$ = new Subject<void>();
  private claimSearchCache$: Observable<ClaimSearchListResponse> | null = null;
  public claimSearchCriteria: BehaviorSubject<ClaimSearchListRequest> =
    new BehaviorSubject<ClaimSearchListRequest>(null);
  public searchCoverage: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public benefitSelectedValue: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  public benefitResponseData: BehaviorSubject<BenefitTool> =
    new BehaviorSubject<BenefitTool>({ groups: [] });
  public planArrayRes: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public groupArray: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public searchCriteriaCache: any;
  public selectedClientSubject: BehaviorSubject<Clients> = new BehaviorSubject(
    null
  );
  private selectedClient2 = new BehaviorSubject<any>(null);
  selectedClient2$ = this.selectedClient2.asObservable();
  private selectedClient3 = new BehaviorSubject<any>(null);
  selectedClient3$ = this.selectedClient3.asObservable();
  public claimSearchInputs: ClaimSearchListRequest = {};

  private isMigratedClientSubject = new BehaviorSubject<boolean>(false);
  public isMigratedClient$ = this.isMigratedClientSubject.asObservable();

  private isGroupLevelAccessSubject = new BehaviorSubject<boolean>(false);
  public isGroupLevelAccess$ = this.isGroupLevelAccessSubject.asObservable();

  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private auditLogsService: AuditLogsService,
    private demoClientService: DemoClientService,
    private authService: AuthService
  ) { }

  setSelectedClient2(client: any) {
    this.selectedClient2.next(client);
  }
  setSelectedClient3(client: any) {
    this.selectedClient3.next(client);
  }

  public getMemberDetailsById(
    searchQuery: MemberSearchRequest
  ): Observable<Partial<MemberDetails>> {
    const query = this.getSearchQuery(searchQuery);
    return defer(() => {
      if (!query) {
        return of(null);
      } else {
        // console.log('Search Query', searchQuery);
        this.loader.showLoader();
        return this.getMemberDetails(query).pipe(
          map((res) => {
            const filteredPatient = res.filter(
              (p) => p.patientId == searchQuery.patientId
            );
            return filteredPatient;
          }),
          tap((res) => {
            // console.log('Res on get member details:', res);
          }),
          shareReplay(1),
          catchError((err) => of([])),
          finalize(() => {
            if (!searchQuery.loading) {
              this.loader.hideLoader()
            }
          })
        );
      }
    });
  }

  public updateIsMigratedValue(value: boolean) {
    this.isMigratedClientSubject.next(value);
  }

  public updateIsGroupLevelAccessValue(value: boolean) {
    this.isGroupLevelAccessSubject.next(value);
  }

  public searchMembers(): Observable<IMemberSearchResponse> {
    if (!this.membersSearchResultCache$) {
      this.membersSearchResultCache$ = this.queryString.asObservable().pipe(
        switchMap((res) => {
          return defer(() => {
            if (!res) {
              return of(null);
            } else {
              // console.log('Search Query', res);
              this.loader.showLoader();
              return this.getMemberDetailsForMemberSearch(res).pipe(
                shareReplay(1),
                catchError((err) => {
                  this.loader.hideLoader();
                  return of([]);
                }),
                finalize(() => this.loader.hideLoader())
              );
            }
          });
        })
      );
    }
    return this.membersSearchResultCache$;
  }

  getCapDeductibleMoopDetails(
    reqBody: CapDeductibleRequestInterface
  ): Observable<CapDeductiblesResponse> {
    let query = this.getSearchQuery(reqBody);
    let url = `${environment.apiGatewayBaseURL}${APIConstants.CAP_DEDUCTIBLES_ACCUMULATION}?subscription-key=${environment.scApiSubscriptionKey}&${query}`;
    return this.http.get<CapDeductiblesResponse>(url);
    // return of({
    //   groupName: '',
    //   capDeductibleAccumulation: [
    //     {
    //       alias: 'MOP-MAIN',
    //       familyAccumulation: 0,
    //       familyLimit: 12000,
    //       familyMemberAccumulation: 0,
    //       familyMemberLimit: 4000,
    //       frequency: 'BENEFIT YEAR',
    //       type: 'Max Out Of Pocket',
    //     },
    //     {
    //       alias: 'MOP-MAIN',
    //       familyAccumulation: 0,
    //       familyLimit: 12000,
    //       familyMemberAccumulation: 0,
    //       familyMemberLimit: 4000,
    //       frequency: 'BENEFIT YEAR',
    //       type: 'Standard Deductible',
    //     },
    //     {
    //       alias: 'MOP-MAIN',
    //       familyAccumulation: 0,
    //       familyLimit: 12000,
    //       familyMemberAccumulation: 0,
    //       familyMemberLimit: 4000,
    //       frequency: 'BENEFIT YEAR',
    //       type: 'Fill',
    //     },
    //     {
    //       alias: 'MOP-MAIN',
    //       familyAccumulation: 0,
    //       familyLimit: 12000,
    //       familyMemberAccumulation: 0,
    //       familyMemberLimit: 4000,
    //       frequency: 'BENEFIT YEAR',
    //       type: 'Initial Plan Pay',
    //     },
    //     {
    //       alias: 'MOP-MAIN',
    //       familyAccumulation: 0,
    //       familyLimit: 12000,
    //       familyMemberAccumulation: 0,
    //       familyMemberLimit: 4000,
    //       frequency: 'BENEFIT YEAR',
    //       type: 'Max Paid By Plan',
    //     },
    //     {
    //       alias: 'MOP-MAIN',
    //       familyAccumulation: 0,
    //       familyLimit: 12000,
    //       familyMemberAccumulation: 0,
    //       familyMemberLimit: 4000,
    //       frequency: 'BENEFIT YEAR',
    //       type: 'Out Of Pocket Phase',
    //     },
    //   ],
    // });
  }

  public getSearchQuery(searchQuery: any): string {
    const query: any = {
      clientId: searchQuery.clientId,
      ...(searchQuery.cardId && { cardId: searchQuery.cardId }),
      ...(searchQuery.firstName && { firstName: searchQuery.firstName }),
      ...(searchQuery.lastName && { lastName: searchQuery.lastName }),
      ...(searchQuery.patientId && { patientId: searchQuery.patientId }),
      ...(searchQuery.personCode && { personCode: searchQuery.personCode }),
      ...(searchQuery.SSN && { SSN: searchQuery.SSN }),
      ...(searchQuery.empirxMemberId && { empirxMemberId: searchQuery.empirxMemberId }),
      ...(searchQuery.clientInternalMemberId && { clientInternalMemberId: searchQuery.clientInternalMemberId }),
      ...(searchQuery.count && { count: searchQuery.count }),
      ...(searchQuery.limit && { limit: searchQuery.limit }),
      ...(searchQuery.offset && { offset: searchQuery.offset }),
    };
    return Object.keys(query)
      .map((key) => {
        return `${key}=${encodeURIComponent(query[key])}`;
      })
      .join('&');
  }

  public getMemberDetailsForMemberSearch(queryString: string): Observable<IMemberSearchResponse> {
    return this.http
      .get<IMemberSearchResponse>(
        `${environment.apiGatewayBaseURL}${APIConstants.MEMBER_SEARCH}?subscription-key=${environment.ocpApimSubscriptionKey}&${queryString}`
      )
      .pipe(
        map((res) => {
          if (res.members?.length === 0) {
            return {
              members: [],
              total_count: 0
            };
          } else {
            res.members.map((member) => {
              // console.log('Member:', member);
              member.coverageHistory$ = this.getCoverageDetails(
                member.clientId,
                member.patientId
              ).pipe(
                map((res) => res.coverage),
                catchError((err) => {
                  // this.loader.hideLoader();
                  return of([]);
                })
              );
              member.mailOrderProfileStatus = this.getMailOrderProfileStatus(
                {
                  clientId: member.clientId,
                  cardId: member.cardId,
                  personCode: member.personCode
                }
              ).pipe(tap((res) => {
                member.mailOrderRegStatus = res;
              }))
              map((res: string) => {
                return res;
              }),
                tap((res) => {
                  // console.log('Response:', res);
                }),
                catchError((err) => {
                  // this.loader.hideLoader();
                  return of([]);
                })
              const currentDate = new Date();
              const twoYearsAgo = new Date(currentDate);
              twoYearsAgo.setUTCFullYear(currentDate.getUTCFullYear() - 2);

              member.idRequests$ = this.auditLogsService
                .getIdRequestedDates({
                  clientId: member.clientId,
                  firstName: member.firstName,
                  lastName: member.lastName,
                  cardId: member.cardId
                })
                .pipe(
                  map((res) => {
                    // console.log('Res ES:', res);
                    const filteredLogs = res.filter((logs) =>
                      logs.changes.includes('Requested Duplicate ID card')
                    );

                    const dateAddedArray = filteredLogs.map(
                      (log) => log.dateAdded
                    );

                    return dateAddedArray;
                  }),
                  tap((res) => {
                    // console.log('Response on get Id requests:', res);
                  }),
                  catchError((err) => {
                    // this.loader.hideLoader();
                    return of({ members: [], total_count: 0 });
                  })
                );
            });
            return res;
          }
        })
      );
  }


  public getMemberDetails(queryString: string): Observable<MemberDetails[]> {
    return this.http
      .get<IMemberSearchResponse>(
        `${environment.apiGatewayBaseURL}${APIConstants.MEMBER_SEARCH}?subscription-key=${environment.ocpApimSubscriptionKey}&${queryString}`
      )
      .pipe(
        map((res) => {
          if (res.members.length === 0) {
            return [];
          } else {
            res.members.map((member) => {
              // console.log('Member:', member);
              member.coverageHistory$ = this.getCoverageDetails(
                member.clientId,
                member.patientId
              ).pipe(
                map((res) => res.coverage),
                catchError((err) => {
                  // this.loader.hideLoader();
                  return of([]);
                })
              );
              member.mailOrderProfileStatus = this.getMailOrderProfileStatus(
                {
                  clientId: member.clientId,
                  cardId: member.cardId,
                  personCode: member.personCode
                }
              ).pipe(tap((res) => {
                member.mailOrderRegStatus = res;
              }))
              map((res: string) => {
                return res;
              }),
                tap((res) => {
                  // console.log('Response:', res);
                }),
                catchError((err) => {
                  // this.loader.hideLoader();
                  return of([]);
                })
              const currentDate = new Date();
              const twoYearsAgo = new Date(currentDate);
              twoYearsAgo.setUTCFullYear(currentDate.getUTCFullYear() - 2);

              member.idRequests$ = this.auditLogsService
                .getIdRequestedDates({
                  clientId: member.clientId,
                  firstName: member.firstName,
                  lastName: member.lastName,
                  cardId: member.cardId
                })
                .pipe(
                  map((res) => {
                    // console.log('Res ES:', res);
                    const filteredLogs = res.filter((logs) =>
                      logs.changes.includes('Requested Duplicate ID card')
                    );

                    const dateAddedArray = filteredLogs.map(
                      (log) => log.dateAdded
                    );

                    return dateAddedArray;
                  }),
                  tap((res) => {
                    // console.log('Response on get Id requests:', res);
                  }),
                  catchError((err) => {
                    // this.loader.hideLoader();
                    return of([]);
                  })
                );
            });
            return res.members;
          }
        })
      );
  }

  public getBenefitTools(queryString: string): Observable<BenefitTool> {
    //Replace dynamic client Id once data is available for all client ids
    // console.log('queryString:', queryString);
    let body: any = {
      ClientId: queryString,
    };
    //--//
    return this.http
      .post<any>(
        `${environment.apiGatewayBaseURL}${APIConstants.BENEFIT_TOOLS}?subscription-key=${environment.scApiSubscriptionKey}&clientId=${body.ClientId}`,
        body
      )
      .pipe(
        map((res) => {
          if (Object.keys(res).length === 0) {
            return [];
          } else {
            map((res: BenefitTool) => {
              return res && res.groups.length > 0 ? res[0] : null;
            });
            return res;
          }
        }),
        tap((res) => {
          // console.log('Response in get benefit tools:', res);
        })
      );
  }

  public getMailOrderProfileStatus(
    reqBody: {
      clientId: string,
      cardId: string,
      personCode: string
    }
  ): Observable<string> {
    const body = reqBody;
    return this.http
      .post<any>(
        `${environment.apiGatewayBaseURL}${APIConstants.SEARCH_PATIENT_ENROLLMENT}?subscription-key=${environment.scApiSubscriptionKey}`,
        body
      )
      .pipe(
        map((res) => {
          const statusFlag = res && res.enrolledInPharmacySystem ? 'Enrolled' : 'Not Enrolled';
          return statusFlag;
        }),
        catchError((err) => {
          // this.loader.hideLoader();
          return of('Not Enrolled');
        })
      );
  }
  public getCoverageDetails(
    clientId: string,
    patientId: string
  ): Observable<CoverageResponse> {
    const query = convertJSONToQueryParams({ clientId, patientId });
    const url = `${environment.apiGatewayBaseURL}${APIConstants.MEMBER_COVERAGE}?subscription-key=${environment.ocpApimSubscriptionKey}&${query}`;
    return this.http.get<CoverageResponse>(url);
  }
  public getClientDetailsForBenefit(
    client: string
  ): Observable<ClientInfoResponse[]> {
    // console.log('Client Id:', client);
    const body = {
      clientId: `('${client}')`,
    };
    //--//
    return this.http
      .post<ClientInfoResponse[]>(
        `${environment.apiGatewayBaseURL}${APIConstants.CLIENT_INFORMATION}?subscription-key=${environment.ocpApimSubscriptionKey}`,
        body
      )
      .pipe(
        map((res) => {
          return res && res.length > 0 ? res : null;
        })
      );
  }

  getGroupInfo(
    clientId: string,
    groupId: string
  ): Observable<MemberGroupInterface> {
    return this.getClientDetailsForBenefit(clientId).pipe(
      map((res) => {
        // console.log('Res get group info ES:', res);
        return this.extractGroupInfo(res, groupId);
      })
    );
  }

  private extractGroupInfo(data: any, groupNum: string): MemberGroupInterface {
    let result = {};

    for (let detail of data) {
      for (let plan of detail.plans) {
        for (let group of plan.groups) {
          if (group.groupId === groupNum) {
            result = {
              groupName: group.groupName,
              groupId: group.groupId,
            };
            break;
          }
        }

        if (Object.keys(result).length > 0) break;
      }
    }

    return {
      groupId: result['groupId'] ? result['groupId'] : '',
      groupName: result['groupName'] ? result['groupName'] : '',
    };
  }

  public getMemberCoverageDetails(
    clientId: string,
    patientId: string,
    groupRequired?: string
  ): Observable<CoverageResponse> {
    const query = convertJSONToQueryParams({ clientId, patientId, groupRequired });
    const url = `${environment.apiGatewayBaseURL}${APIConstants.MEMBER_COVERAGE}?subscription-key=${environment.ocpApimSubscriptionKey}&${query}`;
    return this.http.get<CoverageResponse>(url);
  }
  public getClientDetails(client: string): Observable<ClientInfoResponse[]> {
    // console.log('Client Id:', client);
    const body = {
      clientId: `('${client}')`,
    };
    //--//
    return this.http
      .post<ClientInfoResponse[]>(
        `${environment.apiGatewayBaseURL}${APIConstants.CLIENT_INFORMATION}?subscription-key=${environment.ocpApimSubscriptionKey}`,
        body
      )
      .pipe(
        map((res) => {
          return res && res.length > 0 ? res : null;
        })
      );
  }

  public pharmacySearch(
    filter: PharmacySearchRequest
  ): Observable<PharmacySearchResponse> {
    if (!(filter.city && filter.state)) {
      filter.city = '';
      filter.state = '';
    }
    if (!(filter.zipCode && filter.radius)) {
      filter.zipCode = '';
      filter.radius = '';
    }

    let url = `${environment.apiGatewayBaseURL}${APIConstants.GET_PHARMACIES}?subscription-key=${environment.scApiSubscriptionKey}`;

    return this.http.get<PharmacySearchResponse>(
      `${url}&cardId=${filter.cardId}&clientId=${filter.clientId
      }${this.getSearchParams(filter)}`
    );
  }

  public searchDrugName(name: string): Observable<SearchDrugResponseInterface> {
    return this.http.get<SearchDrugResponseInterface>(
      `${environment.apiGatewayBaseURL}${APIConstants.GET_DRUG_NAME}?name=${name}&subscription-key=${environment.scApiSubscriptionKey}`
    );
  }

  public drugPricingSearch(
    filter: DrugPricingSearchRequest
  ): Observable<PricingResponse> {
    if (!(filter.city.trim() && filter.state.trim())) {
      filter.city = '';
      filter.state = '';
    }
    if (!(filter.zipCode.trim() && filter.radius.trim())) {
      filter.zipCode = '';
      filter.radius = '';
    }

    let query = Object.entries(filter)
      .filter(
        ([key, value]) => value !== undefined && value !== null && value !== ''
      )
      .map(([key, value]) => {
        if (typeof value === 'object') {
          return Object.entries(value)
            .map(([objKey, objValue]) => {
              return `${key}.${objKey}=${encodeURIComponent(
                objValue as string
              )}`;
            })
            .join('&');
        } else {
          return `${key}=${encodeURIComponent(value as string)}`;
        }
      })
      .join('&');

    let url = `${environment.apiGatewayBaseURL}${APIConstants.GET_DRUG_PRICING}?subscription-key=${environment.scApiSubscriptionKey}&${query}`;
    return this.http.get<PricingResponse>(url);
  }

  public getDummyMembers() {
    const dummyData: MemberDetails[] = [
      {
        patientId: '1',
        clientId: 'Client-1',
        clientName: 'Demo',
        personCode: '01',
        firstName: 'Jonathan',
        lastName: 'Robinson',
        middleName: '',
        cardId: '999999999',
        DOB: '02/20/1969',
        gender: 'M',
        clientInternalMemberId: '999999999',
        empirxMemberId: '999999999',
        orderIdCard: false,
        coverage: null,
        SSN: '999999999',
        email: 'test@gmail.com',
        phones: [
          {
            type: 'Home Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Cell Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Work Phone',
            phoneNumber: '9994234234',
          },
        ],

        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city',
        state: 'state',
        zipCode: '12432',
      },
      {
        patientId: '2',
        clientId: 'Client-1',
        clientName: 'Demo',
        personCode: '01',
        firstName: 'Jonathan',
        lastName: 'Robinson',
        middleName: '',
        cardId: '999999999',
        DOB: '02/20/1969',
        gender: 'F',
        clientInternalMemberId: '999999999',
        empirxMemberId: '999999999',
        orderIdCard: false,
        coverage: null,
        SSN: '999999999',
        email: 'test@gmail.com',
        phones: [
          {
            type: 'Home Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Cell Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Work Phone',
            phoneNumber: '9994234234',
          },
        ],

        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city',
        state: 'state',
        zipCode: '12432',
      },
      {
        patientId: '3',
        clientId: 'Client-1',
        clientName: 'Demo',
        personCode: '01',
        firstName: 'Jonathan',
        lastName: 'Robinson',
        middleName: '',
        cardId: '999999999',
        DOB: '02/20/1969',
        gender: 'F',
        clientInternalMemberId: '999999999',
        empirxMemberId: '999999999',
        orderIdCard: false,
        coverage: null,
        SSN: '999999999',
        email: 'test@gmail.com',
        phones: [
          {
            type: 'Home Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Cell Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Work Phone',
            phoneNumber: '9994234234',
          },
        ],

        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city',
        state: 'state',
        zipCode: '12432',
      },
      {
        patientId: '4',
        clientId: 'Client-1',
        clientName: 'Demo',
        personCode: '01',
        firstName: 'Jonathan',
        lastName: 'Robinson',
        middleName: '',
        cardId: '999999999',
        DOB: '02/20/1969',
        gender: 'M',
        clientInternalMemberId: '999999999',
        empirxMemberId: '999999999',
        orderIdCard: false,
        coverage: null,
        SSN: '999999999',
        email: 'test@gmail.com',
        phones: [
          {
            type: 'Home Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Cell Phone',
            phoneNumber: '9994234234',
          },
          {
            type: 'Work Phone',
            phoneNumber: '9994234234',
          },
        ],

        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city',
        state: 'state',
        zipCode: '12432',
      },
    ];
    return dummyData;
  }

  public searchClaims(): Observable<ClaimSearchListResponse> {
    return this.claimSearchCriteria.pipe(
      switchMap((res) => {
        return defer(() => {
          if (!res) {
            return of(null);
          }
          this.loader.showLoader();
          return this.http
            .post<ClaimSearchListResponse>(
              `${environment.apiGatewayBaseURL}${ServiceConstants.API_URL.MEMBER_CLAIM_SEARCH}?subscription-key=${environment.scApiSubscriptionKey}`, res
            )
            .pipe(
              catchError((err) => of([])),
              finalize(() => this.loader.hideLoader())
            );
        });
      }));
  }

  public clearSearchClaimCache() {
    // Manually clear the cache by setting it to null
    this.claimSearchCache$ = null;
    this.clearSearchClaimCache$.next();
  }

  public getFamilyCoverage(body: {
    clientId: string;
    cardId: string;
  }): Observable<IFamilyCoverageResponse> {
    return defer(() => {
      this.loader.showLoader();
      return this.http
        .get<IFamilyCoverageResponse>(
          `${environment.apiGatewayBaseURL}${APIConstants.GET_FAMILY_COVERAGE}?subscription-key=${environment.scApiSubscriptionKey}&clientId=TEST&cardId=TESTFAMILY1`
        )
        .pipe(
          catchError((err) => of(null)),
          finalize(() => this.loader.hideLoader())
        );
    });
  }

  public getClaimSearchDetails(
    reqBody: ClaimRequestInterface
  ): Observable<ClaimSearchDetailsResponse> {
    let errMessage = '';

    // console.log('Request body get claim search details:', reqBody);
    const query = convertJSONToQueryParams(reqBody);
    return defer(() => {
      this.loader.showLoader();
      return this.http
        .get<ClaimSearchDetailsResponse>(
          `${environment.apiGatewayBaseURL}${APIConstants.GET_CLIENT_CLAIM}?subscription-key=${environment.scApiSubscriptionKey}&${query}`
        )
        .pipe
        // catchError((err) => {
        //   console.log('Err in search claims:', err);
        //   errMessage = err.error.errorMessage;
        //   return of({
        //     clientID: '-',
        //     clientName: '-',
        //     groupID: null,
        //     groupName: '-',
        //     cardID: '-',
        //     name: '-',
        //     gender: '',
        //     dob: '',
        //     personCode: '-',
        //     relationshipCode: '-',
        //     claimID: '-',
        //     processDate: '',
        //     rxNumber: '-',
        //     rxFillDate: '',
        //     fillNumber: '',
        //     writtenDate: '',
        //     quantityPrescribed: null,
        //     quantityDispensed: null,
        //     compound: null,
        //     daw: null,
        //     daySupply: null,
        //     pharmacy: '-',
        //     pharmacyNpi: '-',
        //     pharmacyAddress: '-',
        //     prescription: '-',
        //     prescriberNpi: '-',
        //     prescriber: '-',
        //     medication: '-',
        //     brandGeneric: '',
        //     routeofAdmin: '-',
        //     multisourceCode: '-',
        //     ndc: '-',
        //     specialty: '-',
        //     formularyTier: '',
        //     maintenance: '-',
        //     priorAuthList: '-',
        //     steptherapyProtocol: null,
        //     quantityLimitList: null,
        //     equivalentMedication: null,
        //     therapeuticAlternativeMedications: null,
        //     indication: null,
        //     primaryDrugCategory: '-',
        //     additionalDrugCategories: null,
        //     status: '-',
        //     processedAsPrimary: false,
        //     additionalMessage: null,
        //     rejectCode: '',
        //     rejectCodeDescription: null,
        //     planAmount: null,
        //     totalMemberResponsibility: null,
        //     thirdPartAssistance: null,
        //     totalMemberPay: null,
        //     memberCopay: null,
        //     memberDeductible: null,
        //     memberOutOfPocket: null,
        //     ancillaryCharge: null,
        //     ingredientCost: null,
        //     dispensingFee: null,
        //     shippingCharge: null,
        //     salesTax: null,
        //     incentiveAmount: null,
        //     totalCost: null,
        //     pricingSourceUsed: '-',
        //     awp: null,
        //     pharmacyReimbursement: null,
        //   } as ClaimSearchDetailsResponse);
        // }),
        // finalize(() => {
        // if (errMessage) {
        //   const dialogRef: MatDialogRef<ErrorComponent> =
        //     this.matDialog.open(ErrorComponent, {
        //       width: '300px',
        //       data: {
        //         title: 'ERROR',
        //         message: errMessage,
        //       },
        //     });
        //   dialogRef.afterClosed().subscribe(() => {
        //     // this.router.navigateByUrl('/management/online-eligibility');
        //     this.location.back();
        //   });
        // }
        //   this.loader.hideLoader();
        // })
        ();
    });
    const data: ClaimSearchDetailsResponse = {
      clientId: 'TEST',
      clientName: 'TEST CARRIER',
      groupId: 1,
      groupName: 'TEST GROUP',
      cardId: 'TESTFAMILY1',
      name: 'RYLAND MANN',
      gender: 'M',
      dob: '2010-06-13T00:00:00Z',
      personCode: '05',
      relationshipCode: '5',
      claimId: '565730',
      processDate: '2023-11-12T21:28:23.028Z',
      rxNumber: '988235977767',
      rxFillDate: '2023-11-12T00:00:00Z',
      fillNumber: '1',
      writtenDate: '2023-11-12T00:00:00Z',
      quantityPrescribed: 0,
      quantityDispensed: null,
      compound: null,
      daw: null,
      daysSupply: null,
      pharmacy: 'HEB PHARMACY',
      pharmacyNPI: '1730298175',
      pharmacyAddress: '7112 ED BLUESTEINAUSTINTX78723',
      prescription: 'Escitalopram Oxalate',
      prescriberNPI: '1245974195',
      prescriber: 'KATHERINESTANFIELD',
      medication: 'Escitalopram Oxalate',
      brandGeneric: 'G',
      routeOfAdmin: 'PO',
      multiSourceCode: 'Y',
      ndc: '69097084705',
      specialty: 'No',
      formularyTier: '',
      maintenance: '2',
      priorAuthList: ',,,,',
      steptherapyProtocol: null,
      quantityLimitList: null,
      equivalentMedication: null,
      therapeuticAlternativeMedications: null,
      indication: null,
      primaryDrugCategory: '*Selective Serotonin Reuptake Inhibitors (SSRIs)**',
      additionalDrugCategories: null,
      status: 'Paid',
      processedAsPrimary: '',
      additionalMessage: null,
      rejectCode: '',
      rejectCodeDescription: null,
      planAmount: 0.0,
      totalMemberResponsibility: 3.84,
      thirdPartAssistance: null,
      totalMemberPay: null,
      memberCopay: 3.84,
      memberDeductible: 3.84,
      memberOutOfPocket: 3.84,
      ancillaryCharge: null,
      ingredientCost: 2.84,
      dispensingFee: 1.0,
      shippingCharge: null,
      salesTax: 0.0,
      incentiveAmount: 0.0,
      totalCost: 0.0,
      pricingSourceUsed: 'AWP',
      awp: 4.37111,
      pharmacyReimbursement: null,
    };

    // return of(data);
    // return defer(() => {
    //   this.loader.showLoader();
    //   return this.http
    //     .get<ClaimSearchDetailsResponse>(
    //       `${environment.apiGatewayBaseURL}/claims-service/claims-service/rest/claim/10019?subscription-key=${environment.scApiSubscriptionKey}&clientId=TEST&cardId=2334&personCode=03`
    //     )
    //     .pipe(
    //       catchError((err) => of(data)),
    //       finalize(() => this.loader.hideLoader())
    //     );
    // });
  }

  public getThirdPartyAssistance(
    reqBody: ClaimRequestInterface
  ): Observable<ThirdPartyAssistanceResponseInterface> {
    const query = convertJSONToQueryParams(reqBody);
    const url = `${environment.apiGatewayBaseURL}${APIConstants.GET_THIRD_PARTY_ASSISTANCE}?subscription-key=${environment.ocpApimSubscriptionKey}&${query}`;
    return this.http.get<ThirdPartyAssistanceResponseInterface>(url);
  }

  public getClaimSearchCompareDetails(): Observable<ClaimSearchCompareDetailsResponse> {
    const data: ClaimSearchCompareDetailsResponse = {
      claim1: {
        clientId: '-',
        clientName: '-',
        groupId: null,
        groupName: '-',
        cardId: '-',
        name: '-',
        gender: '',
        dob: '',
        personCode: '-',
        relationshipCode: '-',
        claimId: '-',
        processDate: '-',
        rxNumber: '-',
        rxFillDate: '-',
        fillNumber: '',
        writtenDate: '-',
        quantityPrescribed: null,
        quantityDispensed: null,
        compound: null,
        daw: null,
        daysSupply: null,
        pharmacy: '-',
        pharmacyNPI: '-',
        pharmacyAddress: '-',
        prescription: '-',
        prescriberNPI: '-',
        prescriber: '-',
        medication: '-',
        brandGeneric: '',
        routeOfAdmin: '-',
        multiSourceCode: '-',
        ndc: '-',
        specialty: '-',
        formularyTier: '',
        maintenance: '-',
        priorAuthList: '-',
        steptherapyProtocol: null,
        quantityLimitList: null,
        equivalentMedication: null,
        therapeuticAlternativeMedications: null,
        indication: null,
        primaryDrugCategory: '-',
        additionalDrugCategories: null,
        status: '-',
        processedAsPrimary: '',
        additionalMessage: null,
        rejectCode: '',
        rejectCodeDescription: null,
        planAmount: null,
        totalMemberResponsibility: null,
        thirdPartAssistance: null,
        totalMemberPay: null,
        memberCopay: null,
        memberDeductible: null,
        memberOutOfPocket: null,
        ancillaryCharge: null,
        ingredientCost: null,
        dispensingFee: null,
        shippingCharge: null,
        salesTax: null,
        incentiveAmount: null,
        totalCost: null,
        pricingSourceUsed: '-',
        awp: null,
        pharmacyReimbursement: null,
      },
      claim2: {
        clientId: '-',
        clientName: '-',
        groupId: null,
        groupName: '-',
        cardId: '-',
        name: '-',
        gender: '',
        dob: '',
        personCode: '-',
        relationshipCode: '-',
        claimId: '-',
        processDate: '-',
        rxNumber: '-',
        rxFillDate: '-',
        fillNumber: '',
        writtenDate: '-',
        quantityPrescribed: null,
        quantityDispensed: null,
        compound: null,
        daw: null,
        daysSupply: null,
        pharmacy: '-',
        pharmacyNPI: '-',
        pharmacyAddress: '-',
        prescription: '-',
        prescriberNPI: '-',
        prescriber: '-',
        medication: '-',
        brandGeneric: '',
        routeOfAdmin: '-',
        multiSourceCode: '-',
        ndc: '-',
        specialty: '-',
        formularyTier: '',
        maintenance: '-',
        priorAuthList: '-',
        steptherapyProtocol: null,
        quantityLimitList: null,
        equivalentMedication: null,
        therapeuticAlternativeMedications: null,
        indication: null,
        primaryDrugCategory: '-',
        additionalDrugCategories: null,
        status: '-',
        processedAsPrimary: '',
        additionalMessage: null,
        rejectCode: '',
        rejectCodeDescription: null,
        planAmount: null,
        totalMemberResponsibility: null,
        thirdPartAssistance: null,
        totalMemberPay: null,
        memberCopay: null,
        memberDeductible: null,
        memberOutOfPocket: null,
        ancillaryCharge: null,
        ingredientCost: null,
        dispensingFee: null,
        shippingCharge: null,
        salesTax: null,
        incentiveAmount: null,
        totalCost: null,
        pricingSourceUsed: '-',
        awp: null,
        pharmacyReimbursement: null,
      },
    };
    return of(data);
  }

  public updateMemberAddress(requestBody: IAddressUpdateRequest): Observable<any> {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    requestBody.userName = this.authService.userEmail;
    return this.http
      .post(
        `${environment.apiGatewayBaseURL}${APIConstants.UPDATE_MEMBER_ADDRESS}?subscription-key=${environment.ocpApimSubscriptionKey}`,
        requestBody,
        { responseType: 'text', headers }
      )
  }

  public updateMemberCoverage(requestBody: ICoverageUpdateRequest) {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    requestBody.userName = this.authService.userEmail;
    return this.http
      .post(
        `${environment.apiGatewayBaseURL}${APIConstants.TERM_DEPENDENT}?subscription-key=${environment.ocpApimSubscriptionKey}`,
        requestBody,
        { responseType: 'text', headers }
      )
  }

  public addMemberCoverage(requestBody: ICoverageAddRequest) {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    requestBody.userName = this.authService.userEmail;
    return this.http
      .post(
        `${environment.apiGatewayBaseURL}${APIConstants.MEMBER_COVERAGE}?subscription-key=${environment.ocpApimSubscriptionKey}`,
        requestBody,
        { responseType: 'text', headers }
      )
  }

  public addMember(requestBody: MemberDetails): Observable<MemberDetails> {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    if (
      !requestBody.clientInternalMemberId ||
      requestBody.clientInternalMemberId.toLowerCase() === 'null'
    )
      delete requestBody.clientInternalMemberId;

    if (requestBody.clientId === '19121' && requestBody.isUpdated === false) {
      requestBody.cardId = trimIfUNE(requestBody.cardId);
    }

    this.checkIfRxCoverageAvailable(requestBody);
    requestBody.userName = this.authService.userEmail;
    return this.http
      .post(
        `${environment.apiGatewayBaseURL}${APIConstants.MEMBER_MODIFICATION}?subscription-key=${environment.ocpApimSubscriptionKey}`,
        requestBody
      )
      .pipe(
        map((res) => {
          try {
            if (typeof res === 'string') {
              const validJsonString = res.replace(/'/g, '"');
              return JSON.parse(validJsonString);
            }
            return res;
          } catch (error) {
            console.error('Error parsing response:', error);
            return null;
          }
        })
      );
  }

  private checkIfRxCoverageAvailable(requestBody: MemberDetails) {
    if (!requestBody.flexTagStartDate ||
      requestBody.flexTagStartDate.toLowerCase() === 'null') {
      delete requestBody.flexTagName;
      delete requestBody.flexTagStartDate;
      delete requestBody.flexTagEndDate;
    }
  }

  public checkIdGeneration(clientID: string): Observable<ICclientFlags> {
    return this.http.get<ICclientFlags>(
      `${environment.apiGatewayBaseURL}${APIConstants.GET_CLIENT_FLAG}?subscription-key=${environment.ocpApimSubscriptionKey}&clientId=${clientID}`
    );
  }

  printDigitalIDCard(reqBody: PrintDigitalIDCardRequestInterface) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const query = convertJSONToQueryParams(reqBody);
    const url = `${environment.apiGatewayBaseURL}${APIConstants.GET_TEMP_CLIENT_ID}?subscription-key=${environment.scApiSubscriptionKey}&${query}`;
    return this.http.get(url, { headers, responseType: 'arraybuffer' });
  }

  getRxCoverageDetails(reqBody: {
    patientId: string;
    clientId: string;
  }): Observable<MemberRxHistoryResponseInterface> {
    const query = convertJSONToQueryParams(reqBody);
    const url = `${environment.apiGatewayBaseURL}${APIConstants.GET_RX_COVERAGE}?subscription-key=${environment.ocpApimSubscriptionKey}&${query}`;
    return this.http.get<MemberRxHistoryResponseInterface>(url);
  }

  getFlexTagTypes(clientId: string): Observable<IFlexTagTypes[]> {
    const url = `${environment.apiGatewayBaseURL}${APIConstants.GET_CLIENT_FLEX_TAG}?subscription-key=${environment.ocpApimSubscriptionKey}&clientId=${clientId}`;
    return this.http.get<IFlexTagTypes[]>(url);
  }

  public getClientEffectiveDate(clientId: string) {
    const url = `${environment.apiGatewayBaseURL}${APIConstants.GET_CLIENT_EFFECTIVE_DATE}?subscription-key=${environment.ocpApimSubscriptionKey}&clientId=${clientId}`;
    return this.http.get<{
      clientId: string,
      clientEffectiveDate: string
    }>(url);
  }

  public requestHistoricalClaimData(reqBody: RequestHistoricalDataRequestInterface): Observable<any> {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    const url = `${environment.apiGatewayBaseURL}${APIConstants.HISTORIC_DATA_REQUEST}?subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.post(url, reqBody, { headers });
  }

  requestCard(reqBody: RequestIDCardRequestInterface): Observable<any> {
    const headers = new HttpHeaders({
      IsDemoClient: JSON.stringify(this.demoClientService.getDemoClientFlag)
        ? JSON.stringify(this.demoClientService.getDemoClientFlag)
        : 'false',
    });
    const url = `${environment.apiGatewayBaseURL}${APIConstants.REQUEST_CARD}?subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.post(url, reqBody, { headers });
  }

  public preferredMedicationListById(docUrl: string): Observable<Blob> {
    const url = `${environment.apiGatewayBaseURL}${docUrl}&subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.get<Blob>(url, { responseType: 'blob' } as RequestOptions);
  }

  public getEob(req: RequestEOB) {
    const url = `${environment.apiGatewayBaseURL}${APIConstants.GET_MEMBER_EOB}?patientId=${req.patientID}&cardId=${req.cardId}&personCode=${req.personCode}&clientId=${req.clientId}&year=${req.year}&subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.get<Blob>(url, { responseType: 'blob' } as RequestOptions);
  }

  private getDrugPricingParams(filter: DrugPricingSearchRequest): string {
    let params = '';
    if (!filter.radius) {
      filter.zipCode = undefined;
    }
    if (!filter.zipCode || filter.zipCode.length !== 5) {
      filter.zipCode = undefined;
      filter.radius = undefined;
    }
    if (!filter.city) {
      filter.state = undefined;
    }
    if (!filter.state) {
      filter.city = undefined;
    }
    if (filter.radius) {
      params += `&radius=${filter.radius}`;
    }
    if (filter.zipCode) {
      params += `&zipCode=${filter.zipCode}`;
    }
    if (filter.pharmacyName) {
      params += `&pharmacyName=${filter.pharmacyName}`;
    }
    if (filter.city) {
      params += `&city=${filter.city}`;
    }
    if (filter.state) {
      params += `&state=${filter.state}`;
    }
    if (filter.ndcTuple) {
      params += `&ndcTuple=${filter.ndcTuple}`;
    }
    if (filter.perPage) {
      params += `&perPage=${filter.perPage}`;
    }
    if (filter.page) {
      params += `&page=${filter.page}`;
    }
    if (filter.inNetwork) {
      params += `&inNetwork=${filter.inNetwork}`;
    }
    if (filter.operating24Hours) {
      params += `&operating24Hours=${filter.operating24Hours}`;
    }
    if (filter.sort) {
      params += `&sort.sortByField=${filter.sort.sortByField}`;
      params += `&sort.sortType=${filter.sort.sortType.toLocaleUpperCase()}`;
    }
    return params;
  }

  private getSearchParams(filter: PharmacySearchRequest): string {
    let params = '';
    if (filter.radius) {
      params += `&radius=${filter.radius}`;
    }
    if (filter.zipCode) {
      params += `&zipCode=${filter.zipCode}`;
    }
    if (filter.pharmacyName) {
      params += `&pharmacyName=${filter.pharmacyName}`;
    }
    if (filter.city) {
      params += `&city=${filter.city}`;
    }
    if (filter.state) {
      params += `&state=${filter.state}`;
    }
    if (filter.perPage) {
      params += `&perPage=${filter.perPage}`;
    }
    if (filter.page) {
      params += `&page=${filter.page}`;
    }
    if (filter.inNetwork) {
      params += `&inNetwork=${filter.inNetwork}`;
    }
    if (filter.operating24Hours) {
      params += `&operating24Hours=${filter.operating24Hours}`;
    }
    if (filter.sort) {
      params += `&sort.sortByField=${filter.sort.sortByField}`;
      params += `&sort.sortType=${filter.sort.sortType.toLocaleUpperCase()}`;
    }
    return params;
  }

  public getMailOrders(reqBody: CommonRequest): Observable<OrdersResponse> {
    const url = `${environment.apiGatewayBaseURL}${APIConstants.SEARCH_MAIL_ORDER}?subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.post<OrdersResponse>(url, reqBody);
  }

  public getPrescription(reqBody: CommonRequest): Observable<PrescriptionsResponse> {
    const url = `${environment.apiGatewayBaseURL}${APIConstants.PRESCRIPTION_SEARCH}?subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.post<PrescriptionsResponse>(url, reqBody);
  }

  public getClinicalReviews(reqBody: CommonRequest): Observable<ClinicalReviewStatusResponse> {
    const url = `${environment.apiGatewayBaseURL}${APIConstants.CLINICAL_REVIEW_SEARCH}?subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.post<ClinicalReviewStatusResponse>(url, reqBody);
  }

  public getClinicalReviewDocument(request: IClinicalReviewDocRequest): Observable<Blob> {
    const url = `${environment.apiGatewayBaseURL}${APIConstants.CLINICAL_REVIEW_DOCUMENT}?subscription-key=${environment.scApiSubscriptionKey}`;
    return this.http.post<Blob>(url, request, { responseType: 'blob' } as RequestOptions);
  }

}
