import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AzureB2CAuthGuard } from './core/guards/azure-b2c/azure-b2c.guard';
import { PrivacyStatementComponent } from './shared/components/privacy-statement/privacy-statement.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { WhoWeAreComponent } from './shared/components/who-we-are/who-we-are.component';
import { AppSelectorComponent } from './core/components/app-selector/app-selector.component';
import { UserAccessGuard } from './core/guards/user-access/user-access.guard';

const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'app', component: AppSelectorComponent,
    canActivate: [AzureB2CAuthGuard, UserAccessGuard]
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AzureB2CAuthGuard]
  },
  {
    path: 'management',
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    canActivate: [AzureB2CAuthGuard]
  },
  { path: 'termsAndConditions', component: TermsAndConditionsComponent },
  { path: 'privacyStatement', component: PrivacyStatementComponent },
  { path: 'whoWeAre', component: WhoWeAreComponent },
  { path: '**', redirectTo: 'main', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
