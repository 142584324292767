import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EligibilityService } from '../../../../core/services/eligibility/eligibility.service';
import { RequestHistoricalDataRequestInterface } from '../../../../interfaces/historical-data-request.interface';
import { MemberDetails } from '../../../../interfaces/member.interface';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { AppConstants } from '../../../../app.constants';
import { AppInsightsService } from '../../../../services/app-insights/app-insights.service';
import { AppCenterAnalytics } from '../../../../services/app-insights/app-insights';

@Component({
  selector: 'app-request-claims-data-dialog',
  templateUrl: './request-claims-data-dialog.component.html',
  styleUrls: ['./request-claims-data-dialog.component.scss']
})
export class RequestClaimsDataDialogComponent implements OnInit {
  public requestClaimDataForm: FormGroup;
  public years = [];
  constructor(
    public dialogRef: MatDialogRef<RequestClaimsDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { clientEffectiveDate: string, memberDetails: MemberDetails },
    private readonly formBuilder: FormBuilder,
    private readonly eligibilityService: EligibilityService,
    private readonly authService: AuthService,
    private readonly loader: LoaderService,
    private readonly appInsightsService: AppInsightsService,
  ) {
    const requiredValidator = Validators.required;
    this.requestClaimDataForm = this.formBuilder.group({
      year: ['', requiredValidator],
      reason: ['', requiredValidator]
    });
  }

  ngOnInit(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.REQUEST_HISTORIC_DATA_FORM_VIEWED);
    const clientEffectiveYear = new Date(this.dialogData.clientEffectiveDate).getFullYear();
    const currentYear = new Date().getFullYear() - 3;
    this.years = this.yearsBetween(clientEffectiveYear, currentYear);
    this.years = this.years.length > 3 ? this.years.slice(-3) : this.years;
  }

  private yearsBetween(startYear, endYear): string[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  public cancel(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.REQUEST_HISTORIC_DATA_FORM_CANCEL_BUTTON_CLICKED);
    this.dialogRef.close();
  }

  public save(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.REQUEST_HISTORIC_DATA_FORM_SUBMIT_BUTTON_CLICKED);
    const memberDetails = this.dialogData.memberDetails;
    const requestForHistoricData: RequestHistoricalDataRequestInterface = {
      username: `${memberDetails.firstName} ${memberDetails.lastName}`,
      clientId: memberDetails.clientId,
      cardId: memberDetails.cardId,
      personCode: memberDetails.personCode,
      email: this.authService.userEmail,
      entity: AppConstants.HISTORICAL_DATA_REQUEST.CLAIMS,
      year: `${this.requestClaimDataForm.value.year}`,
      reason: this.requestClaimDataForm.value.reason,
      created_by: AppConstants.HISTORICAL_DATA_REQUEST.CREATED_BY
    }
    this.loader.showLoader();
    this.eligibilityService.requestHistoricalClaimData(requestForHistoricData).subscribe(
      (res) => {
        this.loader.hideLoader();
        this.dialogRef.close({ success: true, result: res })
      },
      (err) => {
        this.loader.hideLoader();
      }
    )
  }

}
