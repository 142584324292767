import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MemberDetails, MemberSearchRequest } from '../../../../interfaces/member.interface';
import { AppConstants } from '../../../../app.constants';
import { Router } from '@angular/router';
import { EligibilityService } from '../../../services/eligibility/eligibility.service';

@Component({
  selector: 'app-member-modification-error-handler',
  templateUrl: './member-modification-error-handler.component.html',
  styleUrls: ['./member-modification-error-handler.component.scss']
})
export class MemberModificationErrorHandlerComponent implements OnInit {
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  public title? = '';
  public message = '';
  public existingMemberDetails: MemberDetails;
  public dataSource!: MemberDetails[];
  public displayedColumns: string[] = [
    'personCode',
    'name',
    'cardId',
    'relationshipCode',
    'DOB'
  ];

  /**
   * @param  MatDialogRef dialogRef
   * @param  ErrorObject dialogData
   */
  constructor(
    public dialogRef: MatDialogRef<MemberModificationErrorHandlerComponent>,
    private readonly eligibilityService: EligibilityService,
    @Inject(MAT_DIALOG_DATA) public dialogData: {
      title: string,
      message: string,
      familyDetails: MemberDetails[],
      memberDetails: MemberDetails,
    },
    private router: Router
  ) { }

  public ngOnInit(): void {
    console.log(this.dialogData);
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
    this.existingMemberDetails = this.dialogData.memberDetails;
    const termedUsers = this.dialogData.familyDetails.filter((m) => new Date(m.coverage.endDate).valueOf() < new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf()).sort((a, b) => parseInt(a.personCode) - parseInt(b.personCode));
    const activeUsers = this.dialogData.familyDetails.filter((m) => new Date(m.coverage.endDate).valueOf() >= new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf()).sort((a, b) => parseInt(a.personCode) - parseInt(b.personCode));
    this.dataSource = [...activeUsers, ...termedUsers];
  }

  public checkIfCoverageExpired(element) {
    return new Date(element.coverage.endDate).valueOf() < new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf();
  }

  public checkIfCoverageIsStartingSoon(element) {
    return (
      new Date(element.coverage.startDate).valueOf() > new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf()
    );
  }

  public cancel(): void {
    this.dialogRef.close();
  }
  public navigateBackToMemberSearch(): void {
    this.eligibilityService.searchCriteriaCache = {
      client: this.dialogData.memberDetails.clientId,
      idType: "Card/Alt ID",
      idValue: this.dialogData.memberDetails.cardId,
    }
    this.eligibilityService.selectedIndex = 0;
    let searchQuery: MemberSearchRequest = {
      clientId: this.dialogData.memberDetails.clientId,
    };
    searchQuery.cardId = this.dialogData.memberDetails.cardId,
      searchQuery.limit = 10,
      searchQuery.offset = '0',
      searchQuery.count = 1;
    this.eligibilityService.queryString.next(
      this.eligibilityService.getSearchQuery(searchQuery)
    );
    this.cancel();
    this.router.navigateByUrl('/management/online-eligibility');
  }
}

