import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ErrorManagerService } from 'src/app/core/services/error/error-manager/error-manager.service';
import { ErrorObject } from 'src/app/core/services/error/error.interface';
import { AppConstants } from '../../app.constants';
import { DocumentsResponse } from '../../interfaces/documents-response.interface';
import { SharedService } from '../../services/shared/shared.service';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';


@Component({
  selector: 'app-finanical-invoices',
  templateUrl: './finanical-invoices.component.html',
  styleUrls: ['./finanical-invoices.component.scss']
})
export class FinanicalInvoicesComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  dataLength: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showErrorMsg: boolean;
  constants = AppConstants;
  isParentId: boolean;
  constructor(private readonly sharedService: SharedService, private readonly errorService: ErrorManagerService,
    private readonly appInsightService: AppInsightsService) { }

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.FINANCIAL_DOCUMENTS_VIEWED);
    this.isParentId = JSON.parse(sessionStorage.getItem(this.constants.LABELS.isParentId));
    this.displayedColumns = this.isParentId ? this.constants.ARRAYS.parenIdColumns : this.constants.ARRAYS.clientIdColumns;
    this.getDocuments();
  }

  private getDocuments(): void {
    this.sharedService.getInvoices().subscribe((response: DocumentsResponse[]) => {
      this.dataLength = response.length;
      if (response && response.length) {
        this.showErrorMsg = false;
        response.forEach((item: DocumentsResponse) => {
          item.fileType = this.getFileExt(item.filename);
        });
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else if (response.length === 0) {
        this.showErrorMsg = true;
      }
    }, (error) => {
      this.showErrorResponse();
    });
  }
  /**
 * @description This method is used to get the fileName and return the fileType
 * @returns string for fileType
 */
  public getFileExt(fileName: string): string {
    const fileNameArray = fileName.split('.');
    const fileExtension = fileNameArray[fileNameArray.length - 1];
    return fileExtension.toUpperCase();
  }
  /**
* @description This method is used to get the utc date and convert it into local time.
* @returns string for local date
*/
  public getLocalTime(utcDate: Date): Date {
    return moment.utc(utcDate).local().toDate();
  }

  public getURL(fileName: string, documentId: string) {
    const request = {
      documentId: documentId,
      fileName: fileName,
      fileType: this.getFileExt(fileName).toLowerCase(),
      clientId: sessionStorage.getItem(this.constants.LABELS.selectedId)
    }
    this.sharedService.getInvoicesById(request).subscribe(response => {
      let url = window.URL.createObjectURL(response)
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.appInsightService.trackPageView(AppCenterAnalytics.INVOICE_DOWNLOAD, { DocumentDownload: fileName });
    }, (error) => {
      this.showErrorResponse();
    })
  }

  public search(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
  * @description This method is used to throw an error and show default message if the API call is not successful.
  * @returns void
  */
  public showErrorResponse(): void {
    const data: ErrorObject = { message: this.constants.ERROR.MESSAGES.GENERIC };
    this.errorService.throwError(data);
  }
}
