import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeStampToDate',
})
export class TimeStampToDatePipe implements PipeTransform {
  transform(timestamp: string): string {
    try {
      if (timestamp) {
        if (timestamp.includes('T')) {
          timestamp = timestamp.split('T')[0];
        }
        const date = new Date(timestamp);
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Add 1 to the month to account for zero-based index
        const day = date.getUTCDate().toString().padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${month}/${day}/${year}`;
      }
      return '';
    } catch (err) {
      console.error(
        `Could not parse TimeStampToDatePipe timestamp: ${timestamp}`,
        err
      );
      return '';
    }
  }
}
