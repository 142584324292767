<div aria-hidden="true" class="mt-3 center">
  <img src="../../../../assets/images/under-maintenance.svg" alt="Maintenance" width="90" />
</div>

<ng-container *ngIf="!data?.title">
  <h3 mat-dialog-title class="text-secondary title-9 uppercase pl-0 mt-1 mb-1 text-w-700 justify-content-center">
    SERVICE DISRUPTION
  </h3>
</ng-container>
<ng-container *ngIf="data?.title">
  <h3 mat-dialog-title class="text-secondary title-9 uppercase pl-0 mt-1 mb-1 text-w-700 justify-content-center"
    [innerHtml]="maintenanceTitle"></h3>
</ng-container>


<p role="alert" class="text-secondary center title-10 text-w-500 mt-1">
  <ng-container *ngIf="data.message">
    <div [innerHtml]="maintenanceMessage"></div>
  </ng-container>

  <ng-container *ngIf="!data.message">
    There is a planned maintenance. The app services will not be available
    during this time. Sorry for the inconvenience.
  </ng-container>
</p>

<mat-dialog-actions align="center">
  <button mat-raised-button attr.aria-label="Close the dialog" class="primary-btn uppercase" (click)="close()">
    OK
  </button>
</mat-dialog-actions>