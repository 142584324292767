import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader/loader.service';
import { Subscription } from 'rxjs';
import { UtilService } from '../../services/util/util.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public isSideBarClosed: boolean;
  public isShowLoader: boolean;
  public subscription: Subscription = new Subscription();
  public message: string;
  public innerWidth = window.innerWidth;

  constructor(private readonly router: Router, public loader: LoaderService, private cdr: ChangeDetectorRef, private utilService: UtilService) { }

  ngOnInit(): void {
    this.isSideBarClosed = window.innerWidth < 790 ? true : false;
    this.subscription.add(this.loader.showLoader$.subscribe((res) => {
      this.isShowLoader = res;
      this.cdr.detectChanges();
    }));
    this.subscription.add(this.loader.showWaitingMessage$.subscribe((res) => {
      this.message = res;
      this.cdr.detectChanges();
    }));
  }

  public onMenuToggle(event): void {
    this.isSideBarClosed = event;
    this.utilService.sidebarClose.next(event);
  }

  public navigateToCoPaySchedule(): void {
    this.router.navigateByUrl('/management/copaySchedule');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
