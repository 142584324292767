import { Component, Input, OnInit } from '@angular/core';
import { ErrorMessagingServiceService } from '../../../services/error-messaging/error-messaging-service.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-mat-hint',
  templateUrl: './custom-mat-hint.component.html',
  styleUrls: ['./custom-mat-hint.component.scss']
})
export class CustomMatHintComponent implements OnInit {
  @Input() public isCheckTouch: boolean;
  @Input() public formGroup: FormGroup;
  @Input() public controlName: string;
  constructor(public errorMessagingService: ErrorMessagingServiceService) { }

  ngOnInit(): void {
  }

}
