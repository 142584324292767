import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
@Input() isDownload: boolean;
blobLink = environment.bloblink;
currentYear: number;
  constructor() { 
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
  }

}
