/**
 * @file who-we-are.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppConstants } from '../../../app.constants';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})
export class WhoWeAreComponent implements OnInit {
  constants = AppConstants.LABELS;
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(this.constants.pageTitle + this.constants.hyphen + this.constants.whoWeAre);
  }

  ngOnInit(): void {
  }

}
