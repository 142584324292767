import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MaintenanceMode } from 'src/app/interfaces/maintenance-mode.interface';
import { HttpService } from '../http/http.service';
import { IPharmacySearchConfig } from '../../../interfaces/pharmacy.interface';
import { AppConstants } from '../../../app.constants';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../../management/services/loader/loader.service';
import { ConfigSettings } from '../../../interfaces/cms-interface';

@Injectable({
  providedIn: 'root',
})
export class CMSService {
  private readonly wordpressApiBaseUrl: string = environment.apiWordpressBaseUrl;
  public pharmacySearchConfigCache$: Observable<IPharmacySearchConfig[]>;
  public wordpressClientConfigCache$: Observable<ConfigSettings>;
  constructor(private readonly httpService: HttpService, private readonly http: HttpClient, private readonly loader: LoaderService) { }

  public getMaintenanceMode(): Observable<MaintenanceMode[]> {
    return this.httpService.get<MaintenanceMode[]>(`/MockAPIResponse/GetMaintenanceMode`);
  }

  /**
 * Get pharmacy search configuration from wordpress
 * @returns
 */
  public getPharmacySearchConfig(): Observable<IPharmacySearchConfig[]> {
    if (!this.pharmacySearchConfigCache$) {
      this.pharmacySearchConfigCache$ =
        this.http.get<IPharmacySearchConfig[]>(`${this.wordpressApiBaseUrl}/index.php/wp-json/wp/v2/pages/pharmacy-search-configuration?subscription-key=${environment.wordpressSubscriptionKey}`).pipe(map((response) => {
          this.loader.hideLoader();
          return response;
        }),
          catchError(() => {
            this.loader.hideLoader();
            return of([]);
          }),
          shareReplay(1)
        );

    } else {
      this.loader.hideLoader();
    }
    return this.pharmacySearchConfigCache$
  }
  /**
  * Get pharmacy search configuration from wordpress
  * @returns
  */
  public getWordpressClientConfig(): Observable<ConfigSettings> {
    if (!this.wordpressClientConfigCache$) {
      this.wordpressClientConfigCache$ =
        this.http.get<ConfigSettings>(`${this.wordpressApiBaseUrl}/index.php/wp-json/wp/v2/pages/configurationapi/en?subscription-key=${environment.wordpressSubscriptionKey}`).pipe(shareReplay(1));
    }
    return this.wordpressClientConfigCache$;
  }

}
