/**
 * @file app-insights.service.ts
 */
import { Injectable, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { EligibilityService } from '../../core/services/eligibility/eligibility.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService implements OnInit {

  appInsights: ApplicationInsights;
  userName: string;
  clientName: string;
  platForm: string;
  browserVersion: string;
  constants = AppConstants;
  migratedUser: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly eligibilityService: EligibilityService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        instrumentationKey: environment.instrumentationKey,
        extensions: [angularPlugin as any], // TODO Removed type any.
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    this.userName = sessionStorage.getItem(AppConstants.LABELS.userName);
    this.clientName = sessionStorage.getItem(AppConstants.LABELS.clientName);
    this.appInsights.loadAppInsights();
    this.appInsights.context.user.id = this.authService.userEmail;
    this.appInsights.config.isBrowserLinkTrackingEnabled = true;
    this.appInsights.config.autoTrackPageVisitTime = true;
    this.platForm = isPlatformBrowser(platformId) ? 'web' : 'mobile';
    this.browserVersion = navigator.userAgent.split('(')[0];

  }

  ngOnInit(): void {
    this.eligibilityService.isMigratedClient$.subscribe((c) => this.migratedUser = c);
  }

  getDate(): string {
    return new Date().toLocaleString();
  }

  detectBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }

    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  getPlatform(): string {
    let device: string;
    (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ?
      device = 'mobile' : device = 'web';
    return device;
  }

  getClientData() {
    let data = null;
    if (sessionStorage.getItem(this.constants.LABELS.selectedId) && sessionStorage.getItem(this.constants.LABELS.selectedId) != '') {
      data = {
        ClientId: sessionStorage.getItem(this.constants.LABELS.selectedId),
        ClientName: sessionStorage.getItem(this.constants.LABELS.clientName),
        ReportType: sessionStorage.getItem(this.constants.LABELS.reportType),
      };
    }
    return data;
  }

  trackPageView(
    name: string,
    item?: any
  ): void { // option to call manually
    const properties = {
      Date: this.getDate(),
      Name: sessionStorage.getItem(AppConstants.LABELS.userName),
      Email: this.authService.userEmail,
      Platform: this.getPlatform(),
      BrowserVersion: this.detectBrowserVersion(),
      MigratedUser: this.migratedUser,
      ...this.getClientData(),
      ...item
    };
    this.appInsights.trackPageView({ name, properties });
  }

  trackEvent(
    name: string,
    item: { [key: string]: any },
  ): void {
    const properties = {
      Date: this.getDate(),
      Name: this.userName,
      Email: this.authService.userEmail,
      ...item,
    }
    this.appInsights.trackEvent({ name }, properties);
  }

  trackMetric(
    name: string,
    average: number,
    properties: { [key: string]: any } = { clientID: this.appInsights.context.user.accountId, userID: this.appInsights.context.user.id }
  ): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  trackException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }

  trackTrace(
    message: string,
    properties: { [key: string]: any } = { clientID: this.appInsights.context.user.accountId, userID: this.appInsights.context.user.id }
  ): void {
    this.appInsights.trackTrace({ message }, properties);
  }

}
