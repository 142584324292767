<div fxLayout="column" class="financial-table" >
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h3>{{constants.LABELS.financialDocuments}}</h3>
        <div class="search-block">
            <mat-icon>search</mat-icon>
            <input type="text" class="form-control" placeholder="Search" (keyup)="search($event)" #input />
        </div>
    </div>
    <div fxLayout="column">
        <div *ngIf="showErrorMsg" fxLayoutAlign="center center" class="full-height">
            {{constants.ERROR.MESSAGES.NOINVOICES}}</div>
        <div *ngIf="!showErrorMsg">
            <table mat-table [dataSource]="dataSource" matSort aria-describedby="documents">

                <!-- fileName Column -->
                <ng-container matColumnDef="filename">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="filename">{{constants.LABELS.fileName}}
                    </th>
                    <td mat-cell *matCellDef="let element"> <a style="text-decoration: underline;cursor: pointer;"
                            (click)="getURL(element.filename,element.documentId)">{{element.filename}} </a></td>
                </ng-container>

                <!-- filetype Column -->
                <ng-container matColumnDef="fileType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="fileType">{{constants.LABELS.fileType}}
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.fileType}}</td>
                </ng-container>

                <!-- ClientName Column -->
                <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="clientName">
                        {{constants.LABELS.clientTableName}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
                </ng-container>

                <!-- DateModified Column -->
                <ng-container matColumnDef="dateModified">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="dateModified">
                        {{constants.LABELS.dateModified}}</th>
                    <td mat-cell *matCellDef="let element"> {{getLocalTime(element.dateModified) | date: 'medium'}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <ng-container *matNoDataRow>
                    <tr class="mat-row" *ngIf="!showErrorMsg && input.value && dataSource.data.length">
                        <td class="mat-cell" colspan="4">{{constants.ERROR.MESSAGES.NO_DATA_MATCH}}"{{input.value}}"
                        </td>
                    </tr>
                </ng-container>
            </table>
            <mat-paginator *ngIf="!showErrorMsg" [length]="dataLength" [pageSize]="10"
                showFirstLastButtons></mat-paginator>
        </div>

    </div>
</div>
