import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatSelectChange } from '@angular/material/select';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  defer,
  Subscription,
} from 'rxjs';
import {
  finalize,
  map,
  switchMap,
  tap,
  catchError,
  filter,
  distinctUntilChanged,
  take,
  takeUntil,
  startWith,
} from 'rxjs/operators';
import {
  PlanDetails,
  GroupDetails,
  ClientInfoResponse,
} from 'src/app/interfaces/UserGroup.interface';
import { Clients } from 'src/app/interfaces/clients.interface';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import { LoaderService } from '../services/loader/loader.service';
import { BenefitTool, Group } from 'src/app/interfaces/benefit-tool.interface';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-benefit-form',
  templateUrl: './benefit-form.component.html',
  styleUrls: ['./benefit-form.component.scss'],
})
export class BenefitFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onClientSelect: EventEmitter<{
    client: string;
    group: string;
    plan: string;
  }> = new EventEmitter<{ client: string; group: string; plan: string }>();

  @Output() benefitList: EventEmitter<any> = new EventEmitter<BenefitTool>();
  @Output() noDataForClient: EventEmitter<any> = new EventEmitter<boolean>(
    false
  );
  @Output() clearDataEvent: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  public planTypes = [
    'STANDARD',
    'COBRA',
    'RETIREE',
    'OAD',
    'WELLNESS',
    'ACTIVE',
  ];
  public listOfClients$: Observable<Array<Clients>> = this.getClients();
  // Observable to hold the filtered list of clients
  public filteredClients!: Observable<Array<Clients>>;
  public listOfGroups$: Observable<Array<GroupDetails>>;
  public listOfPlans$: Observable<Array<PlanDetails>>;

  public selectedGroupSubject: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public planType: string;
  public benefitsFormGroup: FormGroup;
  public showLoader: boolean = false;
  public benefitListData: Group[] = [];
  public filteredData: Group[] = [];
  public response: ClientInfoResponse[];
  public selectListArray: any = {};
  private benefitToolsSub: Subscription = new Subscription();
  private client2Sub: Subscription = new Subscription();
  private clientList: Clients[] = [];
  private groups: GroupDetails[] = [];

  clientIDFromSubject: string = '';
  clientFilterControl = new FormControl(); // Control for the search input

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private readonly formBuilder: FormBuilder,
    private benefitService: BenefitManagementService,
    private readonly eligibilityService: EligibilityService,
    private readonly appInsightsService: AppInsightsService,
    private readonly loader: LoaderService,
    private readonly sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.benefitsFormGroup = this.formBuilder.group({
      clientId: ['', [Validators.required]],
      groupId: ['', [Validators.required]],
      planId: ['', [Validators.required]],
      planType: [''],
    });

    this.benefitsFormGroup.controls.groupId.disable();
    this.benefitsFormGroup.controls.planId.disable();
    this.benefitsFormGroup.controls.clientId.disable();
    this.benefitsFormGroup.controls.planType.disable();

    this.listOfClients$ = this.getClients();

    // Filter clients based on search input
    this.filteredClients = this.clientFilterControl.valueChanges.pipe(
      startWith(''), // Initially show all clients
      switchMap(searchTerm => this.filterClients(searchTerm)) // Filter clients when search term changes
    );

    // this.eligibilityService.selectedClientSubject.next(JSON.parse(sessionStorage.getItem('currentClient')))
    this.client2Sub = this.eligibilityService.selectedClient2$
      .pipe(
        filter((client) => !!client),
        distinctUntilChanged()
      )
      .subscribe((client) => {
        // console.log('Client2:', client);
        this.benefitsFormGroup.reset();
        this.benefitsFormGroup.controls.clientId.patchValue(client.clientId);
        this.benefitsFormGroup.controls.groupId.disable();
        this.eligibilityService.selectedClientSubject.next(client);
        this.clientIDFromSubject = client.clientId;
        this.onClientSelection(client);
      });

    this.listOfPlans$ = this.eligibilityService.selectedClientSubject.pipe(
      filter((client) => !!client),
      distinctUntilChanged(),
      switchMap((client) => {
        // console.log('Client in subject:', client);
        return this.getPlanByClient(client.clientId);
      })
    );

    this.listOfGroups$ = this.selectedGroupSubject.pipe(
      filter((clientId) => !!clientId),
      distinctUntilChanged(),
      switchMap((clientId) => {
        // console.log('ClientId in list of groups: ', clientId);
        return this.getGroupByClient(clientId);
      })
    ).pipe(tap((res) => this.groups = res));

    this.eligibilityService.groupArray.subscribe(
      (group: GroupDetails[]) => {
        this.listOfGroups$ = of(group);
        this.groups = group;
      });

    // check dropdown value is already selected or not
    this.eligibilityService.benefitResponseData
      .pipe(distinctUntilChanged())
      .subscribe((data: BenefitTool) => {
        // console.log('Data received in benefit response data: ', data);
        if (data.groups.length > 0) {
          this.eligibilityService.benefitSelectedValue.subscribe((val) => {
            if (val.clientId && val.planId && val.groupId) {
              this.benefitsFormGroup.controls.clientId.setValue(val.clientId);

              this.eligibilityService.planArrayRes.subscribe(
                (plan: PlanDetails[]) => {
                  if (plan) {
                    this.listOfPlans$ = of(plan);
                    this.benefitsFormGroup.controls.planId.setValue(val.planId);
                    this.benefitsFormGroup.controls.planId.enable();
                  } else {
                    this.noDataForClient.emit(true);
                  }
                }
              );
              this.eligibilityService.groupArray.subscribe(
                (group: GroupDetails[]) => {
                  this.listOfGroups$ = of(group);
                  this.groups = group;

                  this.benefitsFormGroup.controls.groupId.setValue(
                    val.groupId.groupId
                  );
                  this.benefitsFormGroup.controls.planType.setValue(
                    val.groupId.planType
                  );
                  this.benefitsFormGroup.controls.groupId.enable();
                }
              );
              // this.eligibilityService.benefitResponseData.subscribe((data: BenefitTool) => {
              if (data) {
                this.benefitListData = data.groups;
                let filteredData = Object(this.benefitListData).filter(
                  (p) => p.groupId == val.groupId.groupId
                );
                this.noDataForClient.emit(false);
                this.benefitList.emit(filteredData);
              }
              this.onClientSelect.emit(this.benefitsFormGroup.value);
              // });
            } else if (
              val.clientId &&
              val.noData &&
              !val.planId &&
              !val.groupId
            ) {
              // console.log(
              //   ` val.clientId &&
              // val.noData &&
              // !val.planId &&
              // !val.groupId`,
              //   val
              // );
              /**logic is pending for this scenario */
              this.benefitsFormGroup.controls.clientId.setValue(val.clientId);

              this.eligibilityService.planArrayRes.subscribe(
                (plan: PlanDetails[]) => {
                  if (plan) {
                    this.listOfPlans$ = of(plan);
                    this.benefitsFormGroup.controls.planId.setValue(val.planId);
                    this.benefitsFormGroup.controls.planId.enable();
                    this.benefitsFormGroup.controls.groupId.reset();
                    this.benefitsFormGroup.controls.groupId.disable();
                    this.noDataForClient.emit(false);
                  } else {
                    this.noDataForClient.emit(true);
                  }
                }
              );
            }
          });
        } else {
          this.eligibilityService.benefitSelectedValue.subscribe((val) => {
            // console.log('Val:', val);
            if (!val.noData && val.clientId) {
              this.benefitsFormGroup.controls.clientId.setValue(val.clientId);

              this.benefitsFormGroup.controls.planId.reset();
              this.benefitsFormGroup.controls.groupId.reset();
              this.benefitsFormGroup.controls.planId.disable();
              this.benefitsFormGroup.controls.groupId.disable();
              this.noDataForClient.emit(true);
              this.loader.hideLoader();
            }
          });
        }
      });
  }

  // Function to filter clients based on the search term
  private filterClients(searchTerm: string): Observable<Array<Clients>> {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return this.listOfClients$.pipe(
      map(clients =>
        clients.filter(client =>
          client.clientName.toLowerCase().includes(lowerCaseTerm) || client.clientId.includes(lowerCaseTerm)
        )
      )
    );
  }

  ngAfterViewInit(): void {
    // this.listOfClients$ = this.getClients();
  }

  private getGroups() {
    return defer(() => {
      return this.eligibilityService.selectedClientSubject.pipe(
        switchMap((client) => {
          this.loader.showLoader();
          return this.getGroupsByClient(client.clientId).pipe(
            finalize(() => this.loader.hideLoader())
          );
        })
      );
    });
  }

  private getClients() {
    return defer(() => {
      return this.benefitService.getClientsListByAccess().pipe(
        tap((res) => {
          this.clientList = res;
          this.benefitsFormGroup.controls.clientId.enable();
        }),
        catchError(() => {
          this.loader.hideLoader();
          return of([]);
        })
      );
    });
  }

  onManuallyClientSelect(client: Clients) {
    this.sharedService.updateAccessLevel(client.olA_Access_Type);
    this.eligibilityService.updateIsMigratedValue(client.migrationFlag && client.migrationFlag === 'Y');
    this.eligibilityService.updateIsGroupLevelAccessValue(client?.groupLevelFlag);
    this.clientIDFromSubject = '';
    this.eligibilityService.queryString.next('');
    this.benefitsFormGroup.controls.planId.reset();
    this.benefitsFormGroup.controls.groupId.reset();
    this.benefitsFormGroup.controls.planId.disable();
    this.benefitsFormGroup.controls.groupId.disable();
  }


  public onClientSelection(client) {
    this.eligibilityService.setSelectedClient2(null);
    this.eligibilityService.setSelectedClient3(client);
    this.loader.showLoader();
    this.getPlanByClient(client.clientId).subscribe((res) => {
      this.benefitsFormGroup.controls.groupId.reset();
      this.benefitsFormGroup.controls.planType.reset();
      this.loader.hideLoader();
    });
    this.appInsightsService.trackPageView(AppCenterAnalytics.CLIENT_SELECTED);
    this.noDataForClient.emit(true);
    this.benefitList.emit(null);
    this.benefitsFormGroup.controls.planId.reset();
    this.benefitsFormGroup.controls.groupId.reset();
    this.benefitToolsSub = this.eligibilityService
      .getBenefitTools(this.benefitsFormGroup.controls.clientId.value)
      .pipe(distinctUntilChanged())
      .subscribe((data: BenefitTool) => {
        if (data) {
          this.benefitListData = data.groups;
          let noDataFlag = this.benefitListData.length > 0 ? true : false;
          this.selectListArray = {
            clientId: this.clientIDFromSubject
              ? this.clientIDFromSubject
              : this.benefitsFormGroup.controls.clientId.value,
            noData: noDataFlag,
          };
          // console.log('Select list array:', this.selectListArray);
          this.eligibilityService.benefitResponseData.next(data);
          this.eligibilityService.benefitSelectedValue.next(
            this.selectListArray
          );
        }
      });
  }

  public onSelectionChange(event) {
      const selectedClient = this.clientList.filter((c) => c.clientId === event.value)[0];
      this.appInsightsService.trackPageView(AppCenterAnalytics.GET_SELECTED_CLIENT_ID, { data: event.value });
      this.onClientSelection(selectedClient);
      this.onManuallyClientSelect(selectedClient);
  }

  public onPlanSelection(event: MatSelectChange) {
    this.appInsightsService.trackPageView(AppCenterAnalytics.PLAN_SELECTION);

    this.selectedGroupSubject.next(
      this.benefitsFormGroup.controls.clientId.value
    );
    this.loader.showLoader();
    this.getGroupByClient(
      this.benefitsFormGroup.controls.clientId.value
    ).subscribe((r) => {
      this.benefitsFormGroup.controls.groupId.reset();
      this.benefitsFormGroup.controls.groupId.enable();
      this.clearDataEvent.emit(true);
      this.loader.hideLoader();
    });
  }

  public onGroupSelection(event) {
    const selectedGroup = this.groups.filter(e => e.groupId === event.value)[0];
    this.appInsightsService.trackPageView(AppCenterAnalytics.GET_SELECTED_GROUP_ID, { data: event.value });
    this.appInsightsService.trackPageView(AppCenterAnalytics.GROUP_SELECTION);
    this.filteredData = Object(this.benefitListData).filter(
      (p) => p.groupId == selectedGroup.groupId
    );
    this.benefitsFormGroup.controls.planType.setValue(selectedGroup.planType);
    this.appInsightsService.trackPageView(AppCenterAnalytics.GET_SELECTED_PLAN_ID, { data: this.benefitsFormGroup.controls.planType.value });
    this.benefitList.emit(this.filteredData);
    this.onClientSelect.emit(this.benefitsFormGroup.value);
    this.selectListArray = {
      clientId: this.benefitsFormGroup.controls.clientId.value,
      planId: this.benefitsFormGroup.controls.planId.value,
      groupId: selectedGroup,
    };
    this.eligibilityService.benefitSelectedValue.next(this.selectListArray);
  }

  private getGroupsByClient(clientId: string): Observable<GroupDetails[]> {
    this.loader.showLoader();
    return this.eligibilityService.getClientDetails(clientId).pipe(
      map((res) => {
        let groupsArray = [];
        if (res) {
          groupsArray = res[0].plans[0].groups;
        }
        this.benefitsFormGroup.controls.groupId.enable();
        this.loader.hideLoader();
        return groupsArray.filter((group) => !!group.groupId);
      }),
      catchError((err) => {
        // console.log('Error on get client details:', err);
        this.loader.hideLoader();
        return of([]);
      })
    );
  }

  private getPlanByClient(clientId: string): Observable<PlanDetails[]> {
    // console.log('inside get plan by client:', clientId);
    this.loader.showLoader();
    return this.eligibilityService.getClientDetailsForBenefit(clientId).pipe(
      map((res) => {
        let plansArray: PlanDetails[] = [];
        if (res) {
          this.response = res;
          res[0].plans.forEach((key, value) => {
            if (
              res[0].carrierId == this.clientIDFromSubject
                ? this.clientIDFromSubject
                : this.benefitsFormGroup.controls.clientId.value
            ) {
              plansArray.push({
                planId: key.planId.toString(),
                planName: key.planName,
              });
            }
          });
          this.eligibilityService.planArrayRes.next(plansArray);
          if (plansArray.length != 0) {
            this.benefitsFormGroup.controls.planId.enable();
            this.benefitsFormGroup.controls.groupId.reset();
            this.benefitsFormGroup.controls.groupId.disable();
            this.noDataForClient.emit(false);
          } else {
            this.noDataForClient.emit(true);
            this.benefitsFormGroup.controls.planId.disable();
            this.benefitsFormGroup.controls.groupId.disable();
          }
        } else {
          this.noDataForClient.emit(true);
          this.benefitsFormGroup.controls.planId.disable();
          this.benefitsFormGroup.controls.groupId.disable();
          this.eligibilityService.planArrayRes.next(null);
        }
        this.loader.hideLoader();

        return plansArray;
      }),
      catchError(() => {
        this.loader.hideLoader();
        return of([]);
      })
    );
  }
  private getGroupByClient(clientId: string): Observable<GroupDetails[]> {
    // console.log('CLient ID:', clientId);
    this.loader.showLoader();

    return this.eligibilityService.getClientDetailsForBenefit(clientId).pipe(
      map((res) => {
        let groupsArray: GroupDetails[] = [];

        res[0].plans.forEach((plan) => {
          // Assuming plans is an array of objects
          if (
            res[0].carrierId ==
            this.benefitsFormGroup.controls.clientId.value &&
            plan.planId == this.benefitsFormGroup.controls.planId.value
          ) {
            groupsArray = plan.groups;
            this.loader.hideLoader();
          }
        });
        this.loader.hideLoader();
        this.eligibilityService.groupArray.next(groupsArray);
        return groupsArray;
      }),
      catchError(() => {
        this.loader.hideLoader();
        return of([]);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.client2Sub) this.client2Sub.unsubscribe();
    if (this.benefitToolsSub) this.benefitToolsSub.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
