<div class="page-wrapper">
  <!-- Page Header -->
  <div class="page-header">
    <h2>Exclusions</h2>
    <p>
      To view an Exclusion, select a client and group from the dropdown boxes
    </p>
  </div>

  <!-- Form Section -->
  <app-benefit-form
    (onClientSelect)="onFieldsSelect($event)"
    (benefitList)="filterList($event)"
    (noDataForClient)="NoDataHandling($event)"
    (clearDataEvent)="clearData($event)"
  ></app-benefit-form>
  <div *ngIf="!isNoData">
    <ng-container *ngIf="exclusions$ | async as exclusions">
      <!-- Details Information Section -->
      <div class="detail-information info-margin">
        <h2>Information</h2>
        <div class="mat-row">
          <div class="mat-12">
            <p class="bold-text pad-b-half">
              The following are therapeutic categories or medications that are
              not covered under your program. These medications may be available
              at a discount when you use your EmpiRx Health ID Card.
            </p>
          </div>
        </div>
      </div>

      <!-- Tabs Information Section -->
      <div class="tabs-information info-margin pad-t-0">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Mail">
            <div class="pad-1">
              <div
                *ngIf="
                  exclusionsRes &&
                  exclusionsRes.mail &&
                  exclusionsRes.mail.length > 0
                "
              >
                <div class="clear-both">
                  <ul class="item-list">
                    <li
                      class="three-column"
                      *ngFor="let mail of exclusionsRes.mail; let i = index"
                    >
                      {{ mail | titlecase }}
                    </li>
                  </ul>
                </div>
              </div>
              <div
                *ngIf="
                  exclusionsRes.mail == undefined && exclusionsRes.mail == null
                "
              >
                No Data
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Retail">
            <div class="pad-1">
              <div
                *ngIf="exclusionsRes.retail && exclusionsRes.retail.length > 0"
              >
                <div class="clear-both">
                  <ul class="item-list">
                    <li
                      class="three-column"
                      *ngFor="let retail of exclusionsRes.retail; let i = index"
                    >
                      {{ retail | titlecase }}
                    </li>
                  </ul>
                </div>
              </div>
              <div
                *ngIf="
                  exclusionsRes.retail == undefined &&
                  exclusionsRes.retail == null
                "
              >
                No Data
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Specialty">
            <div class="pad-1">
              <div
                *ngIf="
                  exclusionsRes &&
                  exclusionsRes.specialty &&
                  exclusionsRes.specialty.length > 0
                "
              >
                <div class="clear-both">
                  <ul class="item-list">
                    <li
                      class="three-column"
                      *ngFor="
                        let specialty of exclusionsRes.specialty;
                        let i = index
                      "
                    >
                      {{ specialty | titlecase }}
                    </li>
                  </ul>
                </div>
              </div>
              <div
                *ngIf="
                  exclusionsRes.specialty == undefined &&
                  exclusionsRes.specialty == null
                "
              >
                No Data
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-container>
  </div>
  <div *ngIf="isNoData">No data is available for selected client.</div>

  <div *ngIf="!(exclusions$ | async) && groupChosen">No Data Found.</div>
</div>
