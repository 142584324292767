import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorObject } from '../../services/error';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

	public title?= '';
	public message = '';

	/**
	 * @param  MatDialogRef dialogRef
	 * @param  ErrorObject dialogData
	 */
	constructor(
		public dialogRef: MatDialogRef<ErrorComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: ErrorObject
	) { }

	public ngOnInit(): void {
		this.title = this.dialogData.title;
		this.message = this.dialogData.message;
	}

}
