/**
 * @file environment.uat.ts
 */
export const environment = {
  envName: 'uat',
  production: false,
  b2cForgotPassFlow:
    'https://myportaluat.b2clogin.com/myportaluat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=186dbe5f-8977-4f55-920a-cdc4a245a7af&nonce=defaultNonce&redirect_uri=' +
    window.location.origin +
    '&scope=openid&response_type=id_token&prompt=login',
  baseUrl: 'https://myportalapi-uat.empirxhealth.com/api',
  b2cSignInSignUpFlow:
    'https://myportaluat.b2clogin.com/myportaluat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=186dbe5f-8977-4f55-920a-cdc4a245a7af&nonce=defaultNonce&redirect_uri=' +
    window.location.origin +
    '&scope=openid&response_type=id_token&prompt=login',
  bloblink:
    'https://ehcacustomuiuat.blob.core.windows.net/termsofuser/Empirx%20Health%20Reporting%20Portal%20-%20Terms%20Of%20Use.pdf',
  instrumentationKey: 'af59f029-1f02-4bfa-bbba-ab3e7dd7fad7',
  googleApiKey: 'AIzaSyBfo9a3aDoFj_C9TyCowJa2g7Dq7pVpkSI',
  apiGatewayBaseURL: 'https://eh-or-apim-uat.azure-api.net/api/bap',
  ocpApimSubscriptionKey: '21adba1d19e34869b85892e3707483ce',
  //   ocpApimSubscriptionKey: '4be0f7ce94df456aafe7258b64d3a0ce',
  scApiSubscriptionKey: '21adba1d19e34869b85892e3707483ce',
  trediumUrl: 'https://epx-pharm-test.tredium.com/#/',
};
