<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <div class="page-header">
    <h2>Update Member</h2>
  </div>
  <ng-container *ngIf="clientInfo$ | async as clientInfo"></ng-container>
  <!-- Member Detail card -->
  <div class="detail-card" *ngIf="selectedMemberDetails$ | async">
    <div class="client-id-name">
      <h4>Member</h4>
      <span>(Client: {{memberDetails.clientId}}-{{memberDetails.client_full_name}})</span>
    </div>
    <div class="mat-row">
      <div class="mat-2">
        <label class="card-label">Name</label>
        <p>
          {{ memberDetails.firstName }}
          {{ memberDetails.lastName }}
        </p>
      </div>
      <div class="mat-2">
        <label class="card-label">Person Code</label>
        <p>{{ memberDetails.personCode }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">DOB</label>
        <p>{{ memberDetails.DOB | timeStampToDate }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Relationship</label>
        <p>{{memberDetails.relationshipCode}} - {{relationShip[memberDetails.relationshipCode] }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Card/Alt ID</label>
        <p>{{ memberDetails.cardId }}</p>
      </div>
      <div class="mat-2">
        <label class="card-label">Coverage Type</label>
        <p>
          {{
          displayCoverageType[memberDetails?.coverage?.coverageType]
          }}
        </p>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <h3 class="form-heading">Personal</h3>

  <form [formGroup]="updateMemberFormGroup">
    <div class="form-wrapper update-member-wrapper">
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-2">
          <mat-label>First Name<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <input matInput placeholder="Enter First Name" formControlName="firstName" />
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup" controlName="firstName"></app-custom-mat-hint>
        </div>
        <div class="mat-6">
          <mat-label>Gender<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Gender" formControlName="gender">
              <mat-option *ngFor="let gender of genderList" [value]="gender.key">{{ gender.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup" controlName="gender"></app-custom-mat-hint>
        </div>
      </div>
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-2">
          <mat-label>Middle Name</mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <input matInput placeholder="Enter Middle Name" formControlName="middleName" />
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup" controlName="middleName"></app-custom-mat-hint>
        </div>
        <div class="mat-6">
          <mat-label>DOB<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
            <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="dob" placeholder="Enter DOB" />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup" controlName="dob"></app-custom-mat-hint>
        </div>
      </div>
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-2">
          <mat-label>Last Name<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <input matInput placeholder="Enter Last Name" formControlName="lastName" />
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup" controlName="lastName"></app-custom-mat-hint>
        </div>
        <div class="mat-6">
          <mat-label>SSN</mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <input matInput (keydown)="limitInputLength($event)" appSsnFormat type="text"
              placeholder="Enter SSN (999-99-9999)" formControlName="ssn" />
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup" controlName="ssn"></app-custom-mat-hint>
        </div>
      </div>
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-2"
          *ngIf="memberDetails?.relationshipCode && memberDetails?.relationshipCode !== cardHolderRC">
          <mat-label>Relationship<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Relationship" formControlName="relationshipCode">
              <mat-option *ngFor="let relation of relationshipData" [value]="relation.value">{{ relation.name
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup" controlName="relationshipCode"></app-custom-mat-hint>
        </div>
        <div class="mat-6 pad-r-2" *ngIf="memberDetails?.clientInternalMemberId">
          <mat-label>Client Internal Member ID <span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select matNativeControl placeholder="Select Client Internal Member ID"
              formControlName="clientInternalMemberId">
              <ng-container>
                <mat-option *ngFor="let locCode of locationCode" [value]="locCode.value">
                  {{ locCode.displayName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-hint class="text-danger title-11" *ngIf="locationCode?.length === 0">
            No Client Internal Member IDs found.
          </mat-hint>
          <app-custom-mat-hint [formGroup]="updateMemberFormGroup"
            controlName="clientInternalMemberId"></app-custom-mat-hint>
        </div>
      </div>
    </div>
    <div class="mat-row button-row">
      <div class="mat-12" fxLayoutAlign="end end">
        <button mat-button class="primary-btn" [disabled]="!checkIfAnythingUpdated()" (click)="saveMember()">
          Save Changes
        </button>
        <button mat-button class="secondary-btn" (click)="navigateBackToMemberSearch()">
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>