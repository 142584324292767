<!-- Form Section -->
<ng-container *ngIf="listOfClients$ | async as clients">
  <div class="form-wrapper">
    <form [formGroup]="benefitsFormGroup" class="benefits-form">
      <div class="mat-row pad-b-1">
        <div class="mat-12">
          <mat-label>Client<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select formControlName="clientId" placeholder="Select Client" panelClass="custom-select-panel" (selectionChange)="onSelectionChange($event)" [disableOptionCentering]="true">
              <mat-option>
                <ngx-mat-select-search [formControl]="clientFilterControl" [clearSearchInput]="true" [showToggleAllCheckbox]="false"
                  placeholderLabel="Search Client(s) by ID or Name..." noItemsLabel="No clients found">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="(filteredClients | async)?.length === 0" disabled>
                No clients found
              </mat-option>
                <mat-option *ngFor="let client of filteredClients | async" [value]="client.clientId">
                  {{ client.clientId }} - {{ client.clientName }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-1" [ngClass]="
          this.benefitsFormGroup.controls.planId.disabled
            ? 'disabled-control'
            : null
        ">
          <mat-label>Plan Name<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Plan Name" (selectionChange)="onPlanSelection($event)"
              formControlName="planId">
              <ng-container *ngIf="listOfPlans$ | async as plans">
                <mat-option *ngFor="let plan of plans" [value]="plan.planId">
                  {{ plan.planName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mat-6" [ngClass]="
            this.benefitsFormGroup.controls.groupId.disabled
              ? 'disabled-control'
              : null
          ">
          <mat-label>Group<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select Group" (selectionChange)="onGroupSelection($event)"
              formControlName="groupId" panelClass="custom-select-panel" [disableOptionCentering]="true">
              <mat-option>
                <ngx-mat-select-search [formControl]="groupFilterControl" [clearSearchInput]="true" [showToggleAllCheckbox]="false"
                  placeholderLabel="Search Group(s) by ID or Name..." noItemsLabel="No group found">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="(filteredGroups | async)?.length === 0" disabled>
                No groups found
              </mat-option>
              <ng-container *ngIf="filteredGroups | async as groups">
                <mat-option class="grp-dropdown" *ngFor="let group of filteredGroups| async as groups" [value]="group.groupId">
                  {{ group.groupId }}: {{group.groupName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-1 plan-type-select" *ngIf="benefitsFormGroup.controls.groupId.value">
          <mat-label>Plan Type</mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Not Available" formControlName="planType">
              <ng-container>
                <mat-option *ngFor="let plan of planTypes" [value]="plan">
                  {{ plan }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
  </div>
</ng-container>