import { Directive } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';

@Directive({
  selector: '[appCustomMatSort]'
})
export class CustomMatSortDirective {

  constructor(private matSort: MatSort) {
    this.overrideSortBehavior();
  }

  private overrideSortBehavior(): void {
    this.matSort.sort = this.customSort.bind(this);
  }

  private customSort(header: any): void {
    const disableReset = true;  // Set this to true to disable reset
    if (!header || !this.matSort.sortables.has(header.id)) {
      return;
    }

    const sortable = this.matSort.sortables.get(header.id)!;

    if (this.matSort.active !== header.id) {
      this.matSort.direction = 'asc';
    } else {
      if (this.matSort.direction === 'asc') {
        this.matSort.direction = 'desc';
      } else if (this.matSort.direction === 'desc') {
        if (disableReset) {
          this.matSort.direction = 'asc';  // Prevent resetting; cycle back to ascending
        } else {
          this.matSort.direction = '';
        }
      }
    }

    this.matSort.active = this.matSort.direction ? header.id : '';
    this.matSort.sortChange.emit({
      active: this.matSort.active,
      direction: this.matSort.direction as SortDirection
    });
  }
}
