import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Group } from 'src/app/interfaces/benefit-tool.interface';
import {
  ExclusionsRequestInterface,
  ExclusionsResponseInterface,
} from 'src/app/interfaces/exclusions.interface';

@Injectable({
  providedIn: 'root',
})
export class ExclusionsService {
  constructor() {}

  searchExclusions(
    query: ExclusionsRequestInterface,benefitTool:Group
  ): Observable<ExclusionsResponseInterface> {
    const response: ExclusionsResponseInterface = benefitTool[0];
    return of(response);
  }
}
