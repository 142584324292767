<h4 class="modal-header">
  <span *ngIf="!dialogData.edit">Add Contact</span
  ><span *ngIf="dialogData.edit">Edit Contact</span>
  <span class="close-icon-dialog"
    ><mat-icon (click)="cancel()">close</mat-icon></span
  >
</h4>
<ng-container *ngIf="groupInfo$ | async as groupInfo"></ng-container>
<form [formGroup]="updateContactEmailInfoFormGroup" class="update-contact">
  <div class="form-wrapper">
    <div *ngIf="dialogData.type == 'contact'">
      Phone Number
      <div class="mat-row pad-b-1">
        <div class="mat-6 pad-r-2">
          <mat-label>Type<span class="asterisk-mark">*</span></mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select
              placeholder="Select"
              formControlName="phoneType"
              [disabled]="this.dialogData.edit"
              matNativeControl
              (selectionChange)="onClientSelection($event)"
            >
              <mat-option *ngFor="let type of types" [value]="type.value">
                {{ type.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-custom-mat-hint
            [formGroup]="updateContactEmailInfoFormGroup"
            controlName="phoneType"
          ></app-custom-mat-hint>
        </div>
        <div class="mat-6">
          <mat-label
            >Phone Number<span class="asterisk-mark">*</span></mat-label
          >
          <mat-form-field floatLabel="never" class="full-width">
            <input
              appPhoneNumberFormat
              type="text"
              matInput
              placeholder="Enter Phone Number (555-555-5555)"
              formControlName="phoneNumber"
            />
          </mat-form-field>
          <app-custom-mat-hint
            [formGroup]="updateContactEmailInfoFormGroup"
            controlName="phoneNumber"
          ></app-custom-mat-hint>
        </div>
      </div>
    </div>
    <div *ngIf="dialogData.type == 'email'">
      Email<span class="asterisk-mark">*</span>
      <div class="mat-row pad-b-1">
        <div class="mat-12">
          <mat-form-field floatLabel="never" class="full-width">
            <input matInput placeholder="Enter Email" formControlName="email" />
          </mat-form-field>
          <app-custom-mat-hint
            [formGroup]="updateContactEmailInfoFormGroup"
            controlName="email"
          ></app-custom-mat-hint>
        </div>
      </div>
    </div>

    <div class="mat-row dialog-btn-row">
      <div class="mat-12" fxLayoutAlign="end end">
        <button
          *ngIf="dialogData.edit"
          mat-button
          class="primary-btn"
          [disabled]="
            !checkIfAnythingUpdated() || updateContactEmailInfoFormGroup.invalid
          "
          (click)="save()"
        >
          Save Changes
        </button>
        <button
          *ngIf="!dialogData.edit"
          mat-button
          class="primary-btn"
          [disabled]="
            !checkIfAnythingUpdated() || updateContactEmailInfoFormGroup.invalid
          "
          (click)="save()"
        >
          Save Changes
        </button>
        <button mat-button class="secondary-btn" (click)="cancel()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
