import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExclusionsService } from 'src/app/core/services/exclusions/exclusions.service';
import { Group } from 'src/app/interfaces/benefit-tool.interface';
import {
  ExclusionsInterface,
  ExclusionsRequestInterface,
  ExclusionsResponseInterface,
} from 'src/app/interfaces/exclusions.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';

@Component({
  selector: 'app-exclusions',
  templateUrl: './exclusions.component.html',
  styleUrls: ['./exclusions.component.scss'],
})
export class ExclusionsComponent implements OnInit {
  exclusionsRes: ExclusionsInterface = {
    mail: [],
    retail: [],
    specialty: [],
  };
  exclusions$: Observable<ExclusionsResponseInterface>;
  public benefitToolData: Group;
  public isNoData: boolean = false;
  groupChosen: boolean = false;
  constructor(
    private readonly appInsightService: AppInsightsService,
    private exclusionsService: ExclusionsService
  ) {}

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.EXCLUSIONS);
  }

  public onFieldsSelect(event: ExclusionsRequestInterface) {
    // console.log('form selection', event);
    this.groupChosen = true;
    this.exclusions$ = this.exclusionsService
      .searchExclusions(event, this.benefitToolData)
      .pipe(
        tap((res) => {
          // console.log('res on exclusions:', res);
          if (res && res.exclusions) {
            this.exclusionsRes = res.exclusions;
          } else {
            this.exclusionsRes.mail = null;
            this.exclusionsRes.retail = null;
            this.exclusionsRes.specialty = null;
          }
        })
      );
  }
  public NoDataHandling(event) {
    this.isNoData = event;
    this.groupChosen = false;
    if (this.isNoData) {
      this.exclusions$ = null;
    }
  }

  clearData(clear: boolean) {
    this.groupChosen = false;
    if (clear) this.exclusions$ = null;
  }
  public filterList(event) {
    this.benefitToolData = event;
  }
  getIndexes(length: number): number[] {
    return Array.from({ length }, (_, index) => index);
  }
}
