/**
 * @file http.service.ts
 * @description this file implements http methods from HttpClient
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RequestOptions } from '../../../interfaces/request-options.interface';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly apiBaseUrl: string = environment.baseUrl;
  /**
   * @param  HttpClient http
   */
  constructor(private readonly http: HttpClient) { }

  /**
   * @param  string path
   * @param  RequestOptions params?
   * @returns Observable
   */
  public get<T>(path: string, params?: RequestOptions): Observable<T>{
    return this.http.get<T>(`${this.apiBaseUrl}${path}`, params).pipe(map(response => response));
  }
  
  /**
   * @param  string path
   * @param  object body
   * @param  RequestOptions options?
   * @returns Observable
   */
   public put<T>(path: string, body: object = {}, options?: RequestOptions): Observable<T> {
    return this.http.put<T>(`${this.apiBaseUrl}${path}`, body, options).pipe(map(response => response));
  }

  /**
   * @param  string path
   * @param  object body
   * @param  RequestOptions options?
   * @returns Observable
   */
  public post<T>(path: string, body: object = {}, options?: RequestOptions): Observable<T> {
    return this.http.post<T>(`${this.apiBaseUrl}${path}`, body, options).pipe(map(response => response));
  }

   /**
   * @param  string path
   * @param  object body
   * @returns Observable
   */
  public getFile(path: string, body: object = {}) {
    return this.http.post(`${this.apiBaseUrl}${path}`, body, {responseType: 'blob'}).pipe(map(response => response));
  }

  /**
   * @param  string path
   * @param  RequestOptions options?
   * @returns Observable
   */
  public delete<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.http.delete<T>(`${this.apiBaseUrl}${path}`, options).pipe(map(response => response));
  }
}
