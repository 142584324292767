<section class="static-content">

    <div class="static-header mat-app-background">
        <div class="container">
            <div class="header-inner" fxLayout="row" fxLayoutAlign="start center">

                <div class="static-brand">
                    <a href="https://www.empirxhealth.com" target="_blank" rel="noopener noreferrer">
                        <img class="brand" src="../../../../assets/images/brand-logo/logo.svg" width="95"
                            alt="EmpiRx"></a>
                </div>
                <h3 class="static-title">The Value-Based PBM Alternative</h3>
            </div>

            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="container">
        <div class="who-we-are-content">
            <div fxLayout="row">
                <div fxFlex="50" class="left-content">
                    <div class="text-content">
                        <h3 class="secondary-sub-header">“em-PEER-ix” FROM EMPIRICAL, FOR OUR EVIDENCE BASED PRACTICES.
                        </h3>
                        <p>EmpiRx Health is the industry’s only value-based PBM.
                            We take a <strong>clinical-first</strong> approach to improving health
                            outcomes while delivering deep and sustainable
                            savings for our clients. And we do it in a <strong>high-touch</strong>,
                            <strong>people-focused</strong> way like no one else can.
                        </p>
                    </div>
                    <div class="text-content">
                        <h3 class="secondary-sub-header">PRECISION, PERFORMANCE, DELIVERED.</h3>
                        <p>At EmpiRx Health, we measure performance based on
                            value, not volume. And to us, value is a combination of
                            <strong>financial</strong>, <strong>clinical</strong> and <strong>service
                                performance</strong>.
                        </p>

                        <ul>
                            <li>We’re the only PBM with a pay-for-performance
                                model. If we don’t meet our savings guarantee, we pay
                                the difference, dollar-for-dollar.</li>

                            <li>The industry’s only Rx-driven population health
                                solution with a client-tailored clinical strategy that
                                lowers costs and improves health.</li>

                            <li>Our pharmacists are an extension of the patient’s care
                                team, partnering with physicians to drive clinically
                                appropriate solutions.</li>

                            <li>We deliver a white-glove service experience to clients
                                and members, a concierge-level amenity that sets
                                us apart.</li>
                        </ul>
                    </div>

                    <div class="text-content">
                        <mat-divider></mat-divider>
                        <div class="who-we-are-quote">
                            <h3>Positive health outcomes matter as
                                much as financial ones. Why
                                compromise between cost and
                                access when you can have <em>both?</em></h3>
                        </div>
                        <mat-divider></mat-divider>
                    </div>

                    <div class="text-content text-center">
                        <h3 class="secondary-sub-header">INDUSTRY RECOGNITION</h3>

                        <div class="industry-recognition-list">

                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div><a href="#"><img src="../../../../assets/images/recognition-3.png" alt=""></a>
                                </div>
                                <div><a href="#"><img src="../../../../assets/images/recognition-2.png" alt=""></a>
                                </div>
                                <div><a href="#"><img src="../../../../assets/images/recognition-1.png" alt=""></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="50" class="right-content">
                    <div class="result-chart">
                        <div class="text-content text-center">
                            <h3 class="secondary-sub-header">VALUE-BASED PILLARS</h3>

                            <div class="value-based-image">
                                <img src="../../../../assets/images/value-based-pillar.png" alt="">
                            </div>

                            <mat-divider></mat-divider>
                        </div>

                        <div class="text-content text-center">
                            <h3 class="secondary-sub-header">UNRIVALED RESULTS</h3>

                            <div class="result-list">

                                <div fxLayout="row layout-wrap">
                                    <div class="result-thumbnail" fxFlex="50"><img
                                            src="../../../../assets/images/result-1.png" alt=""></div>
                                    <div class="result-thumbnail" fxFlex="50"><img
                                            src="../../../../assets/images/result-2.png" alt=""></div>
                                    <div class="result-thumbnail" fxFlex="50"><img
                                            src="../../../../assets/images/result-3.png" alt=""></div>
                                    <div class="result-thumbnail" fxFlex="50"><img
                                            src="../../../../assets/images/result-4.png" alt=""></div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<div class="footer text-center top-spacing">
    <app-footer></app-footer>
</div>