import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Group } from 'src/app/interfaces/benefit-tool.interface';
import {
  DeductibleRequestInterface,
  DeductibleResponseInterface,
} from 'src/app/interfaces/deductible.interface';

@Injectable({
  providedIn: 'root',
})
export class DeductibleService {
  constructor() {}

  searchDeductibles(
    query: DeductibleRequestInterface,benefitList:Group
  ): Observable<DeductibleResponseInterface> {
    const response: DeductibleResponseInterface = benefitList[0];
    return of(response);
  }
}
