import { Component, OnInit } from '@angular/core';
import {
  AnnualCapDetails,
  AnnualCapResponse,
  ChildAnnualCapDetails,
  ChildAnnualCapResponse,
} from 'src/app/interfaces/annual-cap.interface';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { Group } from 'src/app/interfaces/benefit-tool.interface';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-annual-cap',
  templateUrl: './annual-cap.component.html',
  styleUrls: ['./annual-cap.component.scss'],
})
export class AnnualCapComponent implements OnInit {
  public annualCapData$: Observable<AnnualCapResponse>;
  public annualCapDataChild$: Observable<ChildAnnualCapResponse>;
  public benefitToolData: Group;
  public displayedColumns: string[] = [
    'phase',
    'capType',
    'frequency',
    'familyTotal',
    'familyMember',
    'single',
  ];

  public displayedColumnsChildTable: string[] = [
    'drug',
    'type',
    'capType',
    'frequency',
    'familyTotal',
    'familyMember',
    'single',
  ];

  groupChosen: boolean = false;

  dataSource!: MatTableDataSource<AnnualCapDetails>;
  dataSourceChildTable!: MatTableDataSource<ChildAnnualCapDetails>;
  public isNoData: boolean = false;
  constructor(private readonly appInsightService: AppInsightsService) {}

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.ANNUAL_CAP);
  }

  public NoDataHandling(event) {
    this.isNoData = event;
    this.groupChosen = false;
    if (this.isNoData) {
      this.annualCapData$ = null;
    }
  }

  clearData(clear: boolean) {
    this.groupChosen = false;
    if (clear) this.annualCapData$ = null;
  }
  public onFieldsSelect(data: { client: string; group: string; plan: string }) {
    // console.log('form selection', data);
    this.groupChosen = true;
    this.annualCapData$ = this.getAnnualCapData(data).pipe(
      tap((res) => {
        // console.log('Annual cap data res:', res);
        if (res) {
          this.dataSource = new MatTableDataSource(res.annualCapList);
        }
      })
    );

    this.annualCapDataChild$ = this.getAnnualCapChildData(data).pipe(
      tap((res) => {
        if (res) {
          this.dataSourceChildTable = new MatTableDataSource(res.annualCap);
        }
      })
    );
  }
  public filterList(event) {
    this.benefitToolData = event;
  }

  private getAnnualCapData(data: {
    client: string;
    group: string;
    plan: string;
  }): Observable<AnnualCapResponse> {
    const annualCap: AnnualCapResponse = this.benefitToolData[0];
    return of(annualCap);
  }

  private getAnnualCapChildData(data: {
    client: string;
    group: string;
    plan: string;
  }): Observable<ChildAnnualCapResponse> {
    const annualCap: ChildAnnualCapResponse = {
      count: 4,
      annualCap: [
        {
          drug: 'Drug 1',
          type: 'SPEBRA',
          single: 0,
          capType: 'Max Paid By Plan',
          frequency: 'Annual',
          familyTotal: 180,
          familyMember: 90,
        },
        {
          drug: 'Drug 1',
          type: 'SPEBRA',
          single: 90,
          capType: 'Out of pocket phase',
          frequency: 'Annual',
          familyTotal: 180,
          familyMember: 90,
        },
        {
          drug: 'Drug 1',
          type: 'SPEBRA',
          single: 0,
          capType: 'Max Paid By Plan',
          frequency: 'Annual',
          familyTotal: 180,
          familyMember: 90,
        },
      ],
    };

    return of(annualCap);
  }
}
