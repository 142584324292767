import { Directive, HostListener, ElementRef, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneNumberFormat]',
})
export class PhoneNumberFormatDirective {
  constructor(private el: ElementRef, private injector: Injector) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent): void {
    const input = this.el.nativeElement;
    const ngControl = this.injector.get(NgControl, null);

    if (!ngControl) {
      console.error(
        'NgControl not found. Make sure the directive is used within a form control.'
      );
      return;
    }

    let value = input.value.replace(/\D/g, ''); // Remove non-numeric characters

    // Ensure the input does not exceed 10 digits
    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    const formattedValue = this.formatPhoneNumber(value);

    // Update the form control's value
    ngControl.control?.setValue(value, { emitEvent: false });

    // Update the input value with dynamically added dashes
    input.value = formattedValue;

    // Set the cursor position after formatting
    const cursorPosition = this.calculateCursorPosition(
      input.selectionStart,
      formattedValue
    );
    input.setSelectionRange(cursorPosition, cursorPosition);
  }

  private formatPhoneNumber(value: string): string {
    const regex = /^(\d{3})(\d{0,3})(\d{0,4})$/;
    const match = value.match(regex);

    if (match) {
      return `${match[1]}${match[2] ? '-' + match[2] : ''}${
        match[3] ? '-' + match[3] : ''
      }`;
    } else {
      return value;
    }
  }

  private calculateCursorPosition(
    cursorPos: number,
    formattedValue: string
  ): number {
    const digitCount = formattedValue.replace(/\D/g, '').length;

    if (cursorPos <= 3) {
      // Cursor is before or at the first group of digits
      return cursorPos;
    } else if (cursorPos <= 7 && digitCount > 3) {
      // Cursor is within or at the second group of digits
      return cursorPos + 1;
    } else {
      // Cursor is within or at the last group of digits
      return cursorPos + 2;
    }
  }
}
