import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MaintenanceMode } from 'src/app/interfaces/maintenance-mode.interface';
import { CMSService } from '../cms/cms.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(private readonly cms: CMSService) { }

  public isOutageDialogDisplayed: boolean = false;

  private getMaintenanceMode(): Observable<MaintenanceMode[]> {
    return this.cms.getMaintenanceMode();
  }

  public showOutagePopup(): Observable<{ show: boolean, message: string, is_complete_shutdown: boolean }> {
    return this.getMaintenanceMode().pipe(
      map((res) => {
        try {
          if (res && res.length > 0) {
            const configuration = res[0];
            const isMaintenance = new Date().valueOf() >= new Date(configuration.start_date).valueOf() && new Date(configuration.end_date).valueOf() >= new Date().valueOf();
            // checked if maintenance and shutdown is enable or not  
            if (configuration.display_flag && configuration.is_complete_shutdown && configuration.complete_shutdown_settings) {
              const isCompleteShutDown = new Date().valueOf() >= new Date(configuration.complete_shutdown_settings.start_date).valueOf() && new Date(configuration.complete_shutdown_settings.end_date).valueOf() >= new Date().valueOf();
              // check current time lies between shutdown time show dialog
              if (isCompleteShutDown) {
                return {
                  show: isCompleteShutDown,
                  message: configuration.complete_shutdown_settings.message_en,
                  is_complete_shutdown: true
                }
              } else {
                // check current time lies between maintenance time show dialog
                if (isMaintenance) {
                  return {
                    show: isMaintenance,
                    message: configuration.message_en,
                    is_complete_shutdown: false
                  }
                } else {
                  return { show: false, message: '', is_complete_shutdown: false }
                }
              }
              // Checked if maintenance is true and shutdown is false
            } else if (configuration.display_flag && !configuration.is_complete_shutdown) {
              if (isMaintenance) {
                return {
                  show: isMaintenance,
                  message: configuration.message_en,
                  is_complete_shutdown: false
                }
              } else {
                return { show: false, message: '', is_complete_shutdown: false }
              }
            } else {
              return { show: false, message: '', is_complete_shutdown: false }
            }
          }
          return { show: false, message: '', is_complete_shutdown: false };
        } catch (error) {
          return { show: false, message: '', is_complete_shutdown: false };
        }
      }),
      catchError((err) => {
        return of({ show: false, message: '', is_complete_shutdown: false });
      }));
  }
}
