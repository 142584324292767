import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constants';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import { PharmacyHours } from 'src/app/interfaces/pharmacy-hours.interface';
import { PharmacyPricing } from 'src/app/interfaces/pharmacy-pricing.interface';
import { PricingResponse } from 'src/app/interfaces/pricing-response.interface';
import { DrugPricingSearchRequest } from 'src/app/interfaces/pricing.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { numericOnly as externalNumericOnly, searchClientId } from 'src/app/util';
import { DrugDetailsComponent } from '../drug-details/drug-details.component';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  SearchDrugInterface,
  SearchDrugResponseInterface,
} from 'src/app/interfaces/drug-search.interface';
import { LoaderService } from '../../services/loader/loader.service';
import { MemberDetails } from 'src/app/interfaces/member.interface';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { SharedService } from '../../../services/shared/shared.service';
import { CMSService } from '../../../core/services/cms/cms.service';
import { IPharmacySearchConfig } from '../../../interfaces/pharmacy.interface';

@Component({
  selector: 'app-drug-pricing',
  templateUrl: './drug-pricing.component.html',
  styleUrls: ['./drug-pricing.component.scss'],
})
export class DrugPricingComponent implements OnInit, AfterViewInit {
  lat = 0;
  lng = 0;
  isLoading = false;
  isError = false;
  errMsg!: string;
  isNetwork = true;
  is24Hrs = false;
  viewAdditionalPharmacyPricing = false;
  mailOrderNPI = AppConstants.PHARMACY_MAIL_ORDER_NPI_ARRAY;
  isFilterApplied = false;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  constructor(
    private readonly appInsightsService: AppInsightsService,
    private eligibilityService: EligibilityService,
    private formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly loader: LoaderService,
    private sharedService: SharedService,
    private cmsService: CMSService
  ) { }
  drugPricingSearchFormGroup: FormGroup;
  checkTouchForValidation: boolean = false;
  selectedIndex: number = 0;
  selectedNDC: string = '';
  public states: { name: string; value: string }[] = AppConstants.STATES;
  public filteredStates!: Observable<Array<{ name: string; value: string }>>;
  numericOnly = externalNumericOnly;
  displayedColumns: string[] = [
    'name',
    'distance',
    'hoursOp',
    'brandOrGeneric',
    'memberCost',
    'planCost',
    'totalCost',
  ];
  dataSource: MatTableDataSource<PharmacyPricing> = new MatTableDataSource(
    [] as PharmacyPricing[]
  );
  drugPricingSearchResponse: Partial<PricingResponse> = {};
  dataLoaded: boolean = false;
  pharmacyType: 'retail' | 'mail-order' = 'retail';
  drugNames: string[] = [];
  filteredDrugNames: Observable<SearchDrugInterface[]>;
  filteredDrugNamesSnapshot: SearchDrugInterface[] = [];
  selectedDrugName: string = '';
  searchedDrugName: string = '';
  loadingDrugNames: boolean = false;
  allFieldsEmpty: boolean = true;
  clientId: string = '';
  cardId: string = '';
  personCode: string = '';

  perPage: number = 5;
  currentPageIndex: number = 0;
  totalLength: number = 0;
  sortConfig: { sortByField: string; sortType: string };
  public clientIdsWithoutMailOrders: string;
  private subscription: Subscription[] = [];
  public stateFilterControl = new FormControl();
  public pharmacySearchConfig: IPharmacySearchConfig;
  public showOtherPharmacies = false;

  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;

  ngOnInit(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.DRUG_PRICING);
    this.subscription.push(this.cmsService.getWordpressClientConfig().subscribe((res) => this.clientIdsWithoutMailOrders = res.SUPPRESS_MAIL_ORDER_FOR_CLIENTS));
    this.drugPricingSearchFormGroup = this.formBuilder.group({
      drugName: ['', [Validators.required]],
      selectedNdc: [''],
      pharmacyName: [''],
      quantity: ['', [Validators.required]],
      daysSupply: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipCode: [''],
      radius: [''],
    });
    this.filteredDrugNames =
      this.drugPricingSearchFormGroup.controls.drugName.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.loadingDrugNames = true;
        }),
        switchMap((value) => {
          if (typeof value !== 'string' || value.length < 3) {
            this.loadingDrugNames = false;
            return of([]);
          }
          this.loadingDrugNames = true;
          return this.eligibilityService.searchDrugName(value).pipe(
            map((res: SearchDrugResponseInterface) => {
              if (Array.isArray(res.drugs)) {
                // this.loadingDrugNames = false;
                return res.drugs;
              }
              return [];
            }),
            tap((drugs) => {
              this.loadingDrugNames = false;
              this.filteredDrugNamesSnapshot = drugs;
            }) // Store the snapshot of drugs locally using tap
          );
        }),
        catchError((err) => {
          this.loadingDrugNames = false;
          console.error(err);
          return of([]);
        })
      );

    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((queryParams) => {
        const query = { loading: true, ...queryParams }
        return this.eligibilityService.getMemberDetailsById(query).pipe(
          switchMap((memberDetails) => {
            return this.cmsService.getPharmacySearchConfig().pipe(
              map((config) => {
                this.pharmacySearchConfig = this.getPharmacySearchConfig(config, memberDetails[0].clientId);
                this.memberDetails = memberDetails[0];
                return memberDetails[0]; // Return the desired value from the observable chain
              })
            );
          }),
          tap((member) => {
            this.clientId = member.clientId;
            this.cardId = member.cardId;
            this.personCode = member.personCode;
          })
        );
      })
    );


    this.drugPricingSearchFormGroup.valueChanges.subscribe((formValue) => {
      const formControls = Object.keys(formValue);
      const areAllFieldsEmpty = formControls.every((controlName) => {
        const control = this.drugPricingSearchFormGroup.get(controlName);
        return (
          control.value === null ||
          control.value === undefined ||
          control.value === ''
        );
      });

      if (areAllFieldsEmpty) {
        this.allFieldsEmpty = true;
        // console.log('All fields are empty');
      } else {
        this.allFieldsEmpty = false;
        // console.log('All fields are not empty');
      }
    });
    // if (this.sort) {
    // this.sort.sortChange.subscribe(() => {
    //   this.sortData();
    // });
    // }
    this.fetchStatesDropdownValue();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  fetchStatesDropdownValue(): void {
    this.filteredStates = this.stateFilterControl.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => this.filterState(searchTerm)) // Filter group)
    )
  }

  private filterState(searchTerm: string): Observable<Array<{ name: string; value: string }>> {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return of(this.states.filter(s =>
      s.name.toLowerCase().includes(lowerCaseTerm)
    ))
  }

  onTabChange(event: any) {
    this.dataLoaded = false;
    // this.drugPricingSearchFormGroup.reset();
    this.setCityStateValidator();
    this.setZipRadiusValidator();
    // console.log(event);
    switch (event) {
      case 0:
        this.removeZipRadiusValidator();
        break;
      case 1:
        this.removeCityStateValidator();
        break;
    }
  }

  setCityStateValidator() {
    this.drugPricingSearchFormGroup
      .get('city')
      .setValidators([Validators.required]);
    this.drugPricingSearchFormGroup
      .get('state')
      .setValidators([Validators.required]);
    this.drugPricingSearchFormGroup.get('city').updateValueAndValidity();
    this.drugPricingSearchFormGroup.get('state').updateValueAndValidity();
  }
  setZipRadiusValidator() {
    this.drugPricingSearchFormGroup
      .get('zipCode')
      .setValidators([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern('^[0-9]+$'),
      ]);
    this.drugPricingSearchFormGroup
      .get('radius')
      .setValidators([Validators.required]);
    this.drugPricingSearchFormGroup.get('zipCode').updateValueAndValidity();
    this.drugPricingSearchFormGroup.get('radius').updateValueAndValidity();
  }

  removeCityStateValidator() {
    this.drugPricingSearchFormGroup.get('city').clearValidators();
    this.drugPricingSearchFormGroup.get('state').clearValidators();
    this.drugPricingSearchFormGroup.get('city').updateValueAndValidity();
    this.drugPricingSearchFormGroup.get('state').updateValueAndValidity();
    if (this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig.defaultSearchRadius) {
      this.drugPricingSearchFormGroup.get('radius').clearValidators();
      this.drugPricingSearchFormGroup.get('radius').updateValueAndValidity();
    }
  }

  removeZipRadiusValidator() {
    this.drugPricingSearchFormGroup.get('zipCode').clearValidators();
    this.drugPricingSearchFormGroup.get('radius').clearValidators();
    this.drugPricingSearchFormGroup.get('zipCode').updateValueAndValidity();
    this.drugPricingSearchFormGroup.get('radius').updateValueAndValidity();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedDrug = this.filteredDrugNamesSnapshot.find(
      (drug) => drug.name === event.option.value
    );
    if (selectedDrug) {
      this.drugPricingSearchFormGroup.controls.selectedNdc.setValue(
        selectedDrug.ndc
      );
    }
    // console.log('Drug Name Selected:', event.option.value);
    this.selectedDrugName = event.option.value;
  }

  public applyFilter(): void {
    let displayData = this.drugPricingSearchResponse.pharmacyPricings;

    displayData =
      this.pharmacyType === 'retail'
        ? displayData?.filter((o) => !this.mailOrderNPI.includes(o.pharmacy.npi))
        : displayData?.filter((o) => this.mailOrderNPI.includes(o.pharmacy.npi));
    if (this.paginator) {
      const totalLengthMailOrder = this.getMailOrderCount(
        this.drugPricingSearchResponse
      );
      if (this.pharmacyType !== 'retail') {
        this.paginator.length = totalLengthMailOrder;
      } else {
        if (this.drugPricingSearchResponse.pagination?.currentPage === 1) {
          this.paginator.length = this.drugPricingSearchResponse.pagination
            ? this.drugPricingSearchResponse.pagination.totalRecords -
            totalLengthMailOrder
            : this.paginator.pageSize;
        } else {
          this.paginator.length = this.drugPricingSearchResponse.pagination
            ? this.drugPricingSearchResponse.pagination.totalRecords
            : this.paginator.pageSize;
        }
      }
    }
    displayData?.forEach(
      (d) => (
        (d.pharmacyName = d.pharmacy.name),
        (d.distanceMiles = d.pharmacy.distance)
      )
    );
    this.dataSource = new MatTableDataSource(displayData);

    this.closeFilter();
  }

  private getMailOrderCount(drugPricing: any) {
    // for mail order
    let mailOrderPharmacies = drugPricing.pharmacyPricings?.filter((o) =>
      this.mailOrderNPI.includes(o.pharmacy.npi)
    );
    return mailOrderPharmacies?.length;
  }

  private getPharmacySearchConfig(
    pharmacySearchConfig: IPharmacySearchConfig[],
    memberClientId: string
  ): IPharmacySearchConfig | null {
    let clientConfiguration: IPharmacySearchConfig | null = null;

    const clientIndex = (pharmacySearchConfig || []).findIndex((x) =>
      searchClientId(x.clientIds, memberClientId)
    );

    if (clientIndex != -1) {
      // get the configuration against client ID
      clientConfiguration = pharmacySearchConfig[clientIndex];
    }
    return clientConfiguration;
  }

  public closeFilter(): void {
    this.menuTrigger?.closeMenu();
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public checkIfDefaultRadiusConfigured() {
    return this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig?.defaultSearchRadius;
  }

  public checkIfMailOrdersAvailableForClient() {
    return searchClientId(this.clientIdsWithoutMailOrders, this.memberDetails.clientId);
  }

  getDrugPricing(filter: DrugPricingSearchRequest, observer?: any) {
    //console.log('Drug pricing filter:', filter);
    this.appInsightsService.trackEvent(AppCenterAnalytics.GET_DRUG_PRICING_REQUEST, { data: filter })
    this.loader.showLoader();
    let drugPricingConfigAPI = this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig.drugPricingAPI && !this.showOtherPharmacies && !this.viewAdditionalPharmacyPricing ? this.pharmacySearchConfig.drugPricingAPI : '';
    if (this.pharmacySearchConfig?.clientIds.includes(this.memberDetails.clientId) && this.pharmacySearchConfig.defaultSearchRadius) {
      this.appInsightsService.trackPageView(
        AppCenterAnalytics.DRUG_PRICING_SET_DEFAULT_RADIUS,
        { data: this.pharmacySearchConfig.defaultSearchRadius }
      );
      filter.radius = this.pharmacySearchConfig.defaultSearchRadius;
    }
    this.eligibilityService.drugPricingSearch(filter, drugPricingConfigAPI).subscribe({
      next: (res: PricingResponse) => {
        // console.log('Response on drugPricingSearch:', res);
        if (!observer) {
          this.drugPricingSearchResponse = res;

          res.pharmacyPricings?.forEach((d) => {
            (d.pharmacyName = d.pharmacy.name),
              (d.distanceMiles = d.pharmacy.distance);
          });
          setTimeout(() => {
            this.paginator.length = res.pagination.totalRecords;
            this.dataSource.sort = this.sort;

            this.applyFilter();
          }, 100);
        }

        this.drugNames = res.pharmacyPricings?.map(
          (pricing) => pricing.drugName
        );

        this.isLoading = false;
        if (observer) {
          observer.next(res);
          observer.complete();
        }
      },
      error: (err) => {
        this.dataSource = new MatTableDataSource([] as PharmacyPricing[]);
        this.isError = true;
        this.errMsg = err;
        this.isLoading = false;
        if (observer) observer.error(err);
        this.loader.hideLoader();
        this.searchedDrugName = this.selectedDrugName;
        this.dataLoaded = true;
      },
      complete: () => {
        if (!observer) this.dataLoaded = true;
        this.searchedDrugName = this.selectedDrugName;
        this.loader.hideLoader();
      },
    });
  }

  public loadOtherPharmacies() {
    this.showOtherPharmacies = true;
    this.appInsightsService.trackPageView(
      AppCenterAnalytics.DRUG_PRICING_VIEW_OTHER_PHARMACY_CLICKED,
      { data: { parentElement: 'LINK', value: true } }
    );
    this.getDrugPricingResults()
  }

  sortData(sortState: Sort): void {
    if (sortState.direction) {
      this.sortConfig = {
        sortByField: sortState.active,
        sortType: sortState.direction.toUpperCase(),
      };
      this.resetSearch(false, this.sortConfig);
    }
  }

  private sortByDistance(data: any): void {
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      const distanceA = Number(a.pharmacy.distance);
      const distanceB = Number(b.pharmacy.distance);

      if (isNaN(distanceA) || distanceA === null) {
        return isAsc ? -1 : 1;
      }
      if (isNaN(distanceB) || distanceB === null) {
        return isAsc ? 1 : -1;
      }

      return isAsc ? distanceA - distanceB : distanceB - distanceA;
    });
  }

  compareNumbers(a: number, b: number, isAsc: boolean): number {
    return (a - b) * (isAsc ? 1 : -1);
  }

  public onPageChange(pageEvent: PageEvent) {
    this.paginator.pageIndex = this.currentPageIndex = pageEvent.pageIndex;
    this.getDrugPricingResults(false, this.sortConfig);
  }

  public resetSearch(viewAdditionalPharmacyPricing?: boolean, sort?: { sortByField: string; sortType: string }) {
    if (viewAdditionalPharmacyPricing) {
      this.showOtherPharmacies = false;
      this.appInsightsService.trackPageView(
        AppCenterAnalytics.DRUG_PRICING_VIEW_OTHER_PHARMACY_CLICKED,
        { data: { parentElement: 'TOGGLE', value: this.viewAdditionalPharmacyPricing } }
      );
    }
    this.currentPageIndex = this.paginator.pageIndex = 0;
    this.getDrugPricingResults(false, sort || this.sortConfig);
  }

  getSearchRequest(
    filter: DrugPricingSearchRequest,
    sort?: { sortByField: string; sortType: string }
  ): DrugPricingSearchRequest {

    filter.perPage = this.paginator?.pageSize || 5; // Use optional chaining to handle potential undefined
    filter.page = this.currentPageIndex + 1;
    // console.log('SOrt:', sort);
    if (sort) {
      filter.sort = sort as any;
    }
    if (this.is24Hrs) {
      filter.operating24Hours = this.is24Hrs
    }
    if (this.isNetwork) {
      filter.inNetwork = this.isNetwork;
    }
    return filter;
  }
  getDrugPricingResults(manualClick?: boolean, sort?: {
    sortByField: string;
    sortType: string;
  }): Observable<PricingResponse> | void {
    if (manualClick) {
      this.showOtherPharmacies = false;
      this.isNetwork = true;
      this.is24Hrs = false;
      this.viewAdditionalPharmacyPricing = false;
    }
    // console.log('Inside getDrugPricingResults', sort);
    let filter: DrugPricingSearchRequest = {
      clientId: this.clientId,
      cardId: this.cardId,
      personCode: this.personCode,
      // ndcTuple: '66887000105;1;1',
      ndcTuple: `${this.drugPricingSearchFormGroup.controls.selectedNdc.value};${this.drugPricingSearchFormGroup.controls.quantity.value};${this.drugPricingSearchFormGroup.controls.daysSupply.value}`,
    };
    // console.log('Selected Index:', this.selectedIndex);
    // console.log(this.drugPricingSearchFormGroup.value);
    switch (this.selectedIndex) {
      case 0:
        filter.city = this.drugPricingSearchFormGroup.controls.city.value?.trim();
        filter.state = this.drugPricingSearchFormGroup.controls.state.value;
        filter.radius = '';
        filter.zipCode = '';
        break;
      case 1:
        filter.radius = this.drugPricingSearchFormGroup.controls.radius.value;
        filter.zipCode = this.drugPricingSearchFormGroup.controls.zipCode.value;
        filter.city = '';
        filter.state = '';
        break;
    }
    if (this.drugPricingSearchFormGroup.value.pharmacyName) {
      filter.pharmacyName = this.drugPricingSearchFormGroup.value.pharmacyName?.trim()
    }
    this.isLoading = true;
    this.isError = false;
    this.getDrugPricing(this.getSearchRequest(filter, this.sortConfig));
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  getTodaysTiming(hrs: PharmacyHours): string {
    if (!hrs.monday) return '-';
    const val: string[] = hrs[
      AppConstants.DAYS[new Date().getDay()] as keyof PharmacyHours
    ] as string[];
    if (!val[0] || !val[1]) {
      return '-';
    }
    const str1 = (+val[0].split(':')[0] % 12).toString() || '12';
    const str2 = (+val[1].split(':')[0] % 12).toString() || '12';
    return str1 + ':00AM' + ' - ' + str2 + ':00PM';
  }

  public openDialog(drugPricing: PharmacyPricing): void {
    const dialogRef = this.dialog.open(DrugDetailsComponent, {
      height: '80vh',
      width: '80vw',
      data: {
        drugPricing,
        drugName: this.drugPricingSearchFormGroup.controls.drugName.value,
      },
    });
  }

  resetForm() {
    this.drugPricingSearchFormGroup.reset();
  }
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.forEach((s) => s.unsubscribe());
  }
}
