<h4 class="modal-header" *ngIf="dialogData.relationshipCode === cardHolderRC">
  New Member Added
  <span class="close-icon"><mat-icon (click)="cancel()">close</mat-icon></span>
</h4>

<h4 class="modal-header" *ngIf="dialogData.relationshipCode !== cardHolderRC">
  New Dependent Added
  <span class="close-icon"><mat-icon (click)="cancel()">close</mat-icon></span>
</h4>

<div class="info-section">
  <div class="left-section">
    <div class="info-item">
      <label>Name</label>
      <p>
        {{ dialogData.firstName }}
        {{ dialogData.lastName }}
      </p>
    </div>
    <div class="info-item">
      <label>DOB</label>
      <p>{{ dialogData.dateOfBirth | timeStampToDate }}</p>
    </div>
    <div class="info-item">
      <label>Gender</label>
      <p>{{ displayGender[dialogData.gender] }}</p>
    </div>
  </div>

  <div class="middle-section">
    <div class="info-item">
      <label>Card ID</label>
      <p>{{ dialogData.patientNum }}</p>
    </div>
    <div class="info-item">
      <label>Coverage Type</label>
      <p>{{ dialogData.coverageType }}</p>
    </div>
    <div class="info-item">
      <label>Effective Date</label>
      <p>{{ dialogData.startDate | timeStampToDate }}</p>
    </div>
  </div>

  <div class="right-section">
    <div class="info-item">
      <label>Address</label>
      <p>
        {{
          dialogData.addressLine1 +
            ", " +
            (dialogData.addressLine2 ? dialogData.addressLine2 + ", " : "") +
            dialogData.city +
            ", " +
            dialogData.state +
            ", " +
            dialogData.zipCode
        }}
      </p>
    </div>
    <div class="info-item">
      <label>Contact</label>
      <p>
        {{ phoneLabels[dialogData?.PhoneType] }} -
        {{
          dialogData[dialogData?.PhoneType]
            ? formatPhoneNumber(dialogData[dialogData?.PhoneType])
            : ""
        }}
      </p>
    </div>
    <div class="info-item">
      <label>Email</label>
      <p>{{ dialogData.email ? dialogData.email : "-" }}</p>
    </div>
  </div>
</div>

<div class="mat-row dialog-btn-row">
  <div class="mat-12" fxLayoutAlign="end end">
    <button
      mat-button
      class="primary-btn"
      (click)="navigateBackToMemberSearch()"
    >
      Back to Member Search
    </button>
  </div>
</div>
