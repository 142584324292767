<div class="page-wrapper">
  <!-- Page Header -->
  <div class="page-header">
    <h2>Annual Cap</h2>
    <p>To view Annual Cap, select a client and group from the dropdown boxes</p>
  </div>

  <!-- Form Section -->
  <app-benefit-form
    (onClientSelect)="onFieldsSelect($event)"
    (benefitList)="filterList($event)"
    (noDataForClient)="NoDataHandling($event)"
    (clearDataEvent)="clearData($event)"
  ></app-benefit-form>

  <!-- Details Information Section -->

  <div *ngIf="!isNoData">
    <div
      class="detail-information info-margin"
      *ngIf="this.annualCapData$ | async as annualCapList"
    >
      <h2>Details</h2>

      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="phase">
          <th scope="col" mat-header-cell *matHeaderCellDef>Phase</th>
          <td mat-cell *matCellDef="let row">{{ row.phase }}</td>
        </ng-container>

        <ng-container matColumnDef="capType">
          <th scope="col" mat-header-cell *matHeaderCellDef>Cap Type</th>
          <td mat-cell *matCellDef="let row">{{ row.type }}</td>
        </ng-container>

        <ng-container matColumnDef="frequency">
          <th scope="col" mat-header-cell *matHeaderCellDef>Frequency</th>
          <td mat-cell *matCellDef="let row">{{ row.frequency }}</td>
        </ng-container>

        <ng-container matColumnDef="familyTotal">
          <th scope="col" mat-header-cell *matHeaderCellDef>Family Total</th>
          <td mat-cell *matCellDef="let row">{{ row.familyTotal | currency : "USD" }}</td>
        </ng-container>

        <ng-container matColumnDef="familyMember">
          <th scope="col" mat-header-cell *matHeaderCellDef>Family Member</th>
          <td mat-cell *matCellDef="let row">{{ row.familyMember | currency : "USD" }}</td>
        </ng-container>

        <ng-container matColumnDef="single">
          <th scope="col" mat-header-cell *matHeaderCellDef>Single</th>
          <td mat-cell *matCellDef="let row">{{ row.single | currency : "USD" }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr tabindex="0" class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9" role="status">No Data Found</td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="isNoData">No data is available for selected client.</div>
  <div *ngIf="!(annualCapData$ | async) && groupChosen">No Data Found.</div>
  <!-- <div class="detail-information" *ngIf="this.annualCapDataChild$ | async as annualCapChild">
    <h2>Child Details</h2>
    <table mat-table [dataSource]="dataSourceChildTable">
      <ng-container matColumnDef="drug">
        <th scope="col" mat-header-cell *matHeaderCellDef >
          Drug
        </th>
        <td mat-cell *matCellDef="let row">{{ row.drug }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th scope="col" mat-header-cell *matHeaderCellDef >
          Phase
        </th>
        <td mat-cell *matCellDef="let row">{{ row.type }}</td>
      </ng-container>

      <ng-container matColumnDef="capType">
        <th scope="col" mat-header-cell *matHeaderCellDef >
          Cap Type
        </th>
        <td mat-cell *matCellDef="let row">{{ row.capType }}</td>
      </ng-container>

      <ng-container matColumnDef="frequency">
        <th scope="col" mat-header-cell *matHeaderCellDef >
          Frequency
        </th>
        <td mat-cell *matCellDef="let row">{{ row.frequency }}</td>
      </ng-container>

      <ng-container matColumnDef="familyTotal">
        <th scope="col" mat-header-cell *matHeaderCellDef >
          Family Total
        </th>
        <td mat-cell *matCellDef="let row">{{ row.familyTotal }}</td>
      </ng-container>

      <ng-container matColumnDef="familyMember">
        <th scope="col" mat-header-cell *matHeaderCellDef >
          Family Member
        </th>
        <td mat-cell *matCellDef="let row">{{ row.frequency }}</td>
      </ng-container>

      <ng-container matColumnDef="single">
        <th scope="col" mat-header-cell *matHeaderCellDef >
          Single
        </th>
        <td mat-cell *matCellDef="let row">{{ row.frequency }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsChildTable"></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnsChildTable"
      ></tr>
    </table>
  </div> -->
</div>
