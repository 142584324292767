<h4 class="modal-header" *ngIf="dialogData.action === 'updateCoverage'">
  Edit Coverage
</h4>
<h4 class="modal-header" *ngIf="dialogData.action === 'addCoverage'">
  Add Coverage
</h4>
<h4 class="modal-header" *ngIf="dialogData.action === 'addRx'">
  Add Coverage Type
</h4>
<h4 class="modal-header" *ngIf="dialogData.action === 'updateRx'">
  Edit Coverage Type
</h4>
<ng-container *ngIf="clientInfo$ | async as clientInfo"></ng-container>
<form [formGroup]="updateCoverageFormGroup" class="update-Coverage">
  <div class="form-wrapper">
    <div class="mat-row pad-b-1">
      <div *ngIf="
            dialogData.action === 'addCoverage' ||
            dialogData.action === 'updateCoverage'
          " class="mat-8 pad-r-2">
        <mat-label>Group<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <mat-select placeholder="Select Group" formControlName="groupName" matNativeControl
            [disabled]="dialogData.coverage.edit">
            <ng-container *ngIf="listOfGroups$ | async as groups">
              <mat-option class="grp-dropdown" (click)="onGroupSelection(group)" *ngFor="let group of groups"
                [value]="group.groupId">
                {{ group.groupId }} : {{group.groupName }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateCoverageFormGroup" controlName="groupName"></app-custom-mat-hint>
      </div>
      <div *ngIf="
            dialogData.action === 'addRx' || dialogData.action === 'updateRx'
          " class="mat-8 pad-r-2">
        <mat-label>Coverage Type<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <mat-select placeholder="Select Coverage Type" [disabled]="dialogData.action === 'updateRx'"
            (selectionChange)="onChangeFlag()" formControlName="groupName" matNativeControl>
            <mat-option *ngFor="let type of dialogData.flexTagTypes" [value]="type.Tag">
              {{ type.Name }}
            </mat-option>
            <!-- <mat-option value="FORCE65"> No Rx Coverage </mat-option>
              <mat-option value="WELLness">Wellness Program </mat-option>
              <mat-option value="PICA"> PICA Coverage </mat-option> -->
          </mat-select>
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateCoverageFormGroup" controlName="groupName"></app-custom-mat-hint>
      </div>
      <div class="mat-6 pad-r-2">
        <mat-label>Effective Date<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
          <input matInput [matDatepicker]="effectiveDatepicker" formControlName="startDate"
            placeholder="Enter Effective Date" [disabled]="dialogData.coverage.edit && dialogData.action !== 'addRx'" />
          <mat-datepicker-toggle matIconSuffix [for]="effectiveDatepicker"
            [disabled]="dialogData.coverage.edit"></mat-datepicker-toggle>
          <mat-datepicker #effectiveDatepicker></mat-datepicker>
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateCoverageFormGroup" controlName="startDate"></app-custom-mat-hint>
      </div>
      <div class="mat-6">
        <mat-label>End Date<span
            *ngIf="dialogData.action === 'addRx' || dialogData.action === 'updateRx' || dialogData.action === 'updateCoverage'"
            class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
          <input matInput [matDatepicker]="endDatePicker" [min]="minDate" formControlName="endDate"
            placeholder="Enter End Date" />
          <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateCoverageFormGroup" controlName="endDate"></app-custom-mat-hint>
      </div>
    </div>
  </div>
  <!-- Note -->
  <div class="note-wrapper"
    *ngIf="dialogData.memberDetails.relationshipCode === cardHolderRC && (dialogData.action === 'updateCoverage' || dialogData.action === 'addCoverage')">
    <ng-container *ngIf="dialogData.action !== 'addCoverage'">
      <h4>Note:</h4>
      <p>The coverage end date will also be updated for the active dependents.
      </p>
    </ng-container>
    <ng-container *ngIf="dialogData.action === 'addCoverage'">
      <h4>Note:</h4>
      <p>The coverage will also be added for the selected dependents.
      </p>
    </ng-container>
    <h3 class="total-dependents">Dependents ({{ totalDependents }})</h3>
    <div class="content-table dependents-table" *ngIf="dependents$ | async as dependents">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="select" *ngIf="dialogData.action === 'addCoverage'">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (change)="selectRow(row)"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            {{ row.firstName }} {{ row.lastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cardId">
          <th mat-header-cell *matHeaderCellDef>Card/Alt ID</th>
          <td mat-cell *matCellDef="let row">
            {{ row.cardId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="personCode">
          <th mat-header-cell *matHeaderCellDef>Person Code</th>
          <td mat-cell *matCellDef="let row">
            {{ row.personCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="relationshipCode">
          <th mat-header-cell *matHeaderCellDef>Relationship</th>
          <td mat-cell *matCellDef="let row">
            {{ row.relationshipCode }} - {{ row.relationshipValue }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gender">
          <th mat-header-cell *matHeaderCellDef>Gender</th>
          <td mat-cell *matCellDef="let row">
            {{ displayGender[row.gender] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="DOB">
          <th mat-header-cell *matHeaderCellDef>DOB</th>
          <td mat-cell *matCellDef="let row">
            {{ row.DOB | timeStampToDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Effective Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.coverage.startDate | timeStampToDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.coverage.endDate | timeStampToDate }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{
          'coverage-expired-active': checkIfCoverageExpired(row)
        }"></tr>

        <tr class="mat-row no-data-row" *matNoDataRow>
          <td class="mat-cell" colspan="8" role="status">
            No Data Found
          </td>
        </tr>

      </table>
    </div>
    <div class="coverage-expired accordion-table-footer">
      <div class="legends">
        <div class="legends-item">
          <span class="ce-legend"></span>
          Coverage Expired
        </div>
      </div>
    </div>
  </div>
  <div class="mat-row dialog-btn-row">
    <div class="mat-12" fxLayoutAlign="end end">
      <button *ngIf="
            dialogData.action !== 'addRx' && dialogData.action !== 'updateRx'
          " mat-button class="primary-btn" [disabled]="
            !checkIfAnythingUpdated() || updateCoverageFormGroup.invalid
          " (click)="save()">
        Save Changes
      </button>
      <button *ngIf="
            dialogData.action === 'addRx' || dialogData.action === 'updateRx'
          " mat-button class="primary-btn" [disabled]="
            !checkIfAnythingUpdated() || updateCoverageFormGroup.invalid 
          " (click)="save()">
        Save Changes
      </button>
      <button mat-button class="secondary-btn" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</form>