<main class="main-page full-height overflow-hidden" fxLayout="column">
    <div class="top-header">
        <app-header (sideBarToggle)="isSideBarClosed = $event;"></app-header>
    </div>
    <div class="dashboard-wrapper full-height" [ngClass]="{'closed': isSideBarClosed}" fxLayout="row">

        <div class="dashboard-sidenav overflow-auto">
            <app-sidebar (reportIdEmitter)="getValue($event)" (nextMenu)="getMenu($event)"></app-sidebar>
        </div>

        <div class="dashboard-content full-height overflow-hidden" fxLayout="column">

            <div class="full-height overflow-hidden">
                <div *ngIf="showIframe" class="full-height overflow-hidden">
                    <app-dashboard [embedId]="embedId"></app-dashboard>
                </div>

                <div *ngIf="showOtherMenu" class="full-height overflow-hidden">
                    <app-documents></app-documents>
                </div>
                <div *ngIf="showInvoices" class="full-height overflow-hidden">
                    <app-finanical-invoices></app-finanical-invoices>
                </div>
            </div>

            <div class="footer text-center">
               <app-footer></app-footer>
            </div>
        </div>
    </div>
</main>