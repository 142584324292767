/**
 * @file shared.service.ts
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay, map } from 'rxjs/operators';
import { AppConstants } from '../../app.constants';
import { AuthTokenService } from '../../core/services/authToken/auth-token.service';
import { HttpService } from '../../core/services/http/http.service';
import { ClientsResponse } from '../../interfaces/clients-response.interface';
import { DocumentsResponse } from '../../interfaces/documents-response.interface';
import { DomoRequest } from '../../interfaces/domo-api-request.interface';
import { EmbedResponse } from '../../interfaces/embed-response.interface';
import { MainMenu } from '../../interfaces/main-menu.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserAccess } from 'src/app/interfaces/user-access.interface';
import { Clients } from 'src/app/interfaces/clients.interface';
import { PreferredMedicationFileURL } from '../../interfaces/preferred-medication-list-response.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { GroupLevelAccessResponse } from '../../interfaces/group-level-access.interface';

@Injectable({
   providedIn: 'root'
})
export class SharedService {
   constants = AppConstants;
   private accessLevelSubject = new BehaviorSubject<string>('');

   public accessLevel$ = this.accessLevelSubject.asObservable();
   // email: string;
   clientResponse: ClientsResponse = null;


   constructor(private readonly authService: AuthService, private readonly httpService: HttpService) { }

   /**
   * @description This method decodes the jwt token and sets the email
   * @returns void
   */
   // public setEmail(): void {
   //    this.email = jwtDecode<{ emails: string[]; }>(this.tokenService.azureIdToken || '').emails[0];
   // }

   /**
   * @description This method is used to get the dashboards according to the embedId which is passed as a parameter.
   * @param  embedId contains embedId for the dashboard to be loaded
   * @returns the response for API.
   */
   public getDashboard(embedId: string): Observable<EmbedResponse> {
      const isParent = JSON.parse(sessionStorage.getItem(this.constants.LABELS.isParentId));
      const clientLevel = sessionStorage.getItem(this.constants.LABELS.clientLevel);
      const clientId = sessionStorage.getItem(this.constants.LABELS.selectedId);
      let subGroupId = sessionStorage.getItem(this.constants.LABELS.subGroupId);
      subGroupId = subGroupId === 'null' ? null : subGroupId;
      const body: DomoRequest = {
         embedId: embedId,
         clientId: clientId,
         clientLevelOnly: clientLevel,
         isParentClientId: isParent,
         subGroupId: subGroupId
      };
      return this.httpService.post<EmbedResponse>(`${this.constants.URLS.DASHBOARD}`, body);
   }

   /**
   * @description This method is used to make an API call and get the menus/reports based on the clientID and reportType
   * @returns the response for API.
   */
   public getMenusAndSubMenus(): Observable<MainMenu[]> {
      const clientId = sessionStorage.getItem(this.constants.LABELS.selectedId);
      return this.httpService.get<MainMenu[]>(`${this.constants.URLS.MENUS}${clientId}&emailId=${this.authService.userEmail}`);
   }

   /**
   * @description This method makes a api call to get the clients and takes email as a parameter.
   * @returns the response for API.
   */
   public getClients(): Observable<ClientsResponse> {
      return this.httpService.get<ClientsResponse>(`${this.constants.URLS.CLIENTS}${this.authService.userEmail}`)
   }

   /**
   * @description This method makes a api call to get the clients and takes email as a parameter.
   * @returns the response for API.
   */
   public getBapClients(): Observable<ClientsResponse> {
      return this.httpService.get<ClientsResponse>(`${this.constants.URLS.BAP_CLIENTS}${this.authService.userEmail}`)
   }

   /** 
   * @description This method makes a api call to get the group level access for logged in user.
   * @returns the response for API.
   */
   public getUserGroupsAccess(reqBody: { emailId: string, clientId: string }): Observable<GroupLevelAccessResponse[]> {
      const url = `${this.constants.URLS.BAP_GROUP_ACCESS}?emailId=${reqBody.emailId}&clientId=${reqBody.clientId}`;
      return this.httpService.get<GroupLevelAccessResponse[]>(url);
   }

   /**
   * @description This method makes a api call to get the clients documents and takes clientId as parameter.
   * @returns the response for API.
   */
   public getDocuments(): Observable<DocumentsResponse[]> {
      const clientId = sessionStorage.getItem(this.constants.LABELS.selectedId);
      return this.httpService.get<DocumentsResponse[]>(`${this.constants.URLS.DOCUMENTS}${clientId}`);
   }

   /**
   * @description This method makes a api call to get the clients documents and takes clientId as parameter.
   * @returns the response for API.
   */
   public getDocumentsById(body): Observable<Blob> {
      return this.httpService.getFile(`${this.constants.URLS.DOCUMENTSBYID}`, body);
   }

   /**
   * @description This method makes a api call to get the clients documents and takes clientId as parameter.
   * @returns the response for API.
   */
   public getInvoices(): Observable<DocumentsResponse[]> {
      const clientId = sessionStorage.getItem(this.constants.LABELS.selectedId);
      return this.httpService.get<DocumentsResponse[]>(`${this.constants.URLS.INVOICES}${clientId}`);
   }

   /**
   * @description This method makes a api call to get the clients documents and takes clientId as parameter.
   * @returns the response for API.
   */
   public getInvoicesById(body): Observable<Blob> {
      return this.httpService.getFile(`${this.constants.URLS.INVOICESBYID}`, body);
   }

   /**
    * Fetch the user access level for reporting and management module
    * @returns 
    */
   public getUserAccessRoles(): Observable<UserAccess> {
      return this.httpService.get<UserAccess>(`${AppConstants.URLS.USER_ACCESS}?emailId=${this.authService.userEmail}`);
   }

   public getPreferredMedicationURL(filePath: string): Observable<PreferredMedicationFileURL> {
      return this.httpService.get<PreferredMedicationFileURL>(`/Domo/GetPreferredMedDocumentUrl?pdfUrl=${filePath}`)
   }

   public updateAccessLevel(value: string) {
      this.accessLevelSubject.next(value);
   }

   public getClientsWithoutDigitalIDCard(): Observable<string[]> {
      return this.httpService.get<string[]>(`/MockAPIResponse/GetClientListforMock`);
   }

   public getClientWithAutoChargeAndShip(): Observable<string[]> {
      return this.httpService.get<string[]>(`/MockAPIResponse/GetAutofillClientList
 `);
   }

   public isLevelD() {
      let accessLevel: string;
      this.accessLevel$.subscribe((v) => accessLevel = v);
      return accessLevel === 'Level D';
   }
}


