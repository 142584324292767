import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaintenanceMode } from 'src/app/interfaces/maintenance-mode.interface';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class CMSService {
  constructor(private readonly httpService: HttpService) { }

  public getMaintenanceMode(): Observable<MaintenanceMode[]> {
    return this.httpService.get<MaintenanceMode[]>(`/MockAPIResponse/GetMaintenanceMode`);
  }
}
