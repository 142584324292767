<div class="page-wrapper">
  <!-- Page Header -->
  <div class="page-header">
    <h2>Co-pay Schedule</h2>
    <p>
      To view a Co-pay schedule, select a client and group from the dropdown
      boxes
    </p>
  </div>

  <!-- Form Section -->
  <app-benefit-form (onClientSelect)="onFieldsSelect($event)" (benefitList)="filterList($event)"
    (noDataForClient)="NoDataHandling($event)" (clearDataEvent)="clearData($event)"></app-benefit-form>

  <ng-container *ngIf="copaySchedule$ | async as copayData">
    <div *ngIf="!isNoData">
      <!-- Details Information Section -->
      <!-- <div class="detail-information info-margin">
        <div class="mat-row" fxLayout="row">
          <div class="mat-9" *ngIf="copayData.customMessages && copayData.customMessages.length > 0">
            <h2>Details</h2>
            <p class="pad-b-half" *ngFor="let item of copayData.customMessages">
              {{item.message}}
            </p>
          </div>
          <div *ngIf="preferredMedicationList$ | async as preferredMedicationList"
            class="detail-information info-margin">
            <ng-container *ngIf="preferredMedicationList.length > 0">
              <p class="pad-b-half see-ur-pro">See your programs</p>
              <div class="files-list">
                <ul>
                  <li *ngFor="let preferredMedication of preferredMedicationList">
                    <a (click)="openPdf(preferredMedication.url)" target="_blank">{{ preferredMedication.name }}</a>
                  </li>
                </ul>

              </div>
            </ng-container>
          </div>
        </div>
      </div> -->

      <!-- Tabs Information Section -->
      <div class="tabs-information info-margin pad-t-1">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Retail Co-pay Schedule">
            <div class="pad-1">
              <p>
                When you fill prescriptions at a participating retail pharmacy,
                your retail co-payment amount will be:
              </p>
              <ul class="item-list">
                <li *ngFor="let item of copayData.retailCopays">{{ item }}</li>
              </ul>
            </div>
          </mat-tab>
          <mat-tab label="Mail Co-pay Schedule">
            <div class="pad-1">
              <p>
                If you choose to fill maintenance medications through our mail
                service pharmacy, Prescription Mart (PMart), your mail order
                co-payment amount required at the time you place your order will
                be:
              </p>
              <ul class="item-list">
                <li *ngFor="let item of copayData.mailOrderCopays">
                  {{ item }}
                </li>
              </ul>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isNoData">
    No data is available for selected client.
  </ng-container>
  <div *ngIf="!(copaySchedule$ | async) && groupChosen">No Data Found.</div>
</div>