import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import {
  ClaimSearchCompareDetailsResponse,
  ClaimSearchDetailsResponse,
  ThirdPartyAssistanceCompareResponseInterface,
  ThirdPartyAssistanceResponseInterface,
} from 'src/app/interfaces/claim-search-detail-response.interface';
import { LoaderService } from 'src/app/management/services/loader/loader.service';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/core/components/error/error.component';
import { SharedService } from '../../../../services/shared/shared.service';
import { UserAccessLevel } from '../../../../interfaces/user-access.interface';
import { BenefitManagementService } from '../../../../core/services/benefit-management/benefit-management.service';

@Component({
  selector: 'app-claim-search-compare',
  templateUrl: './claim-search-compare.component.html',
  styleUrls: ['./claim-search-compare.component.scss'],
})
export class ClaimSearchCompareComponent implements OnInit, OnDestroy {
  public claimSearchCompareDetails$!: Observable<
    Partial<ClaimSearchCompareDetailsResponse>
  >;
  public claimCompareDetails: Partial<ClaimSearchCompareDetailsResponse> = {};
  claim1Id: string;
  claim2Id: string;
  routeParams$: Observable<any>;
  routeSubscription: Subscription;
  claimSearchCompareSub: Subscription = new Subscription();
  claim1$: Observable<ClaimSearchDetailsResponse>;
  claim2$: Observable<ClaimSearchDetailsResponse>;
  thirdParty1$: Observable<ThirdPartyAssistanceResponseInterface>;
  thirdParty2$: Observable<ThirdPartyAssistanceResponseInterface>;
  thirdPartyCompareDetails$: Observable<ThirdPartyAssistanceCompareResponseInterface>;
  thirdPartyCompareSub: Subscription = new Subscription();
  thirdParty1: string = '';
  thirdParty2: string = '';
  dataLoaded: boolean = false;
  public migrationFlag: string;
  public userAccessLevel: string;
  public userAccessLevels = UserAccessLevel;
  public isExpanded: boolean = false;
  constructor(
    public router: Router,
    private eligibilityService: EligibilityService,
    private readonly loader: LoaderService,
    private location: Location,
    private appInsightsService: AppInsightsService,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private sharedService: SharedService,
    private benefitService: BenefitManagementService
  ) { }

  ngOnInit(): void {
    this.sharedService.accessLevel$.subscribe((v) => {
      this.userAccessLevel = v;
    });
    this.eligibilityService.isMigratedClient$.subscribe((f) => this.migrationFlag = f ? 'Y' : 'N');
    // console.log('Current route:', this.router.url);
    this.appInsightsService.trackPageView(
      AppCenterAnalytics.CLAIM_SEARCH_COMPARE
    );
    this.loader.showLoader();
    let queryParams;
    this.routeParams$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        queryParams = res;
        return this.checkMigrationFlag(res);
      }),
      tap((res) => {
        // console.log('Res on route:', res);
        this.claim1$ = this.eligibilityService.getClaimSearchDetails({
          claimId: queryParams.claim1Id,
          patientId: queryParams.patientId,
          clientId: queryParams.clientId,
          authNum: queryParams.authNum1,
          migrationFlag: this.migrationFlag,
          accessLevel: this.userAccessLevel
        });
        this.claim2$ = this.eligibilityService.getClaimSearchDetails({
          claimId: queryParams.claim2Id,
          patientId: queryParams.patientId,
          clientId: queryParams.clientId,
          authNum: queryParams.authNum2,
          migrationFlag: this.migrationFlag,
          accessLevel: this.userAccessLevel

        });
        this.thirdParty1$ = this.eligibilityService.getThirdPartyAssistance({
          claimId: queryParams.claim1Id,
          patientId: queryParams.patientId,
          clientId: queryParams.clientId,
          authNum: queryParams.authNum1

        });
        this.thirdParty2$ = this.eligibilityService.getThirdPartyAssistance({
          claimId: queryParams.claim2Id,
          patientId: queryParams.patientId,
          clientId: queryParams.clientId,
          authNum: queryParams.authNum2
        });
      })
    );

    this.routeSubscription = this.routeParams$.subscribe();

    this.claimSearchCompareDetails$ = forkJoin({
      claim1: this.claim1$,
      claim2: this.claim2$,
    }).pipe(
      tap((res) => {
        this.claimCompareDetails = res;
        this.claim1Id = res.claim1.claimId;
        this.claim2Id = res.claim2.claimId;
        // console.log(
        //   'Response on get claim details compare:',
        //   this.claimCompareDetails
        // );
      })
      // catchError((err) => {

      //   return of({});
      // })
    );
    this.thirdPartyCompareDetails$ = forkJoin({
      thirdParty1: this.thirdParty1$,
      thirdParty2: this.thirdParty2$,
    }).pipe(
      tap((res) => {
        this.thirdParty1 = res.thirdParty1.thirdPartyAssistance;
        this.thirdParty2 = res.thirdParty2.thirdPartyAssistance;
        // console.log('Response on get third party compare:', res);
      })
    );
    this.claimSearchCompareSub = this.claimSearchCompareDetails$.subscribe({
      next: (res) => {
        this.loader.hideLoader();
      },
      error: (err) => {
        this.loader.hideLoader();
        this.dataLoaded = false;
        const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
          ErrorComponent,
          {
            width: '300px',
            data: {
              title: 'ERROR',
              message: err.error.errorMessage,
            },
          }
        );
        dialogRef.afterClosed().subscribe(() => {
          // this.router.navigateByUrl('/management/online-eligibility');
          this.location.back();
        });
      },
      complete: () => {
        this.thirdPartyAssistanceCompareDetails();
        this.dataLoaded = true;
      },
    });

    // this.claimSearchCompareDetails$ = this.eligibilityService
    //   .getClaimSearchCompareDetails()
    //   .pipe(
    //     tap((res) => {
    //       this.claimCompareDetails = res;
    //       console.log('Claim Compare Details:', this.claimCompareDetails);
    //       this.claim1Id = res.claim1.claimID;
    //       this.claim2Id = res.claim2.claimID;
    //       this.loader.hideLoader();
    //     })
    //   );
  }

  private checkMigrationFlag(res) {
    return this.benefitService.getClientsListByAccess().pipe(
      tap((clients) => {
        const selectedClient = clients.find(c => c.clientId === res.clientId);
        this.migrationFlag = selectedClient?.migrationFlag ? selectedClient?.migrationFlag : 'N';
        this.eligibilityService.updateIsMigratedValue(selectedClient.migrationFlag && selectedClient.migrationFlag === 'Y')
      })
    )
  }

  public navigateBackToClaimSearch(): void {
    this.location.back();
  }

  thirdPartyAssistanceCompareDetails() {
    this.loader.showLoader();
    this.thirdPartyCompareSub = this.thirdPartyCompareDetails$.subscribe({
      next: (res) => { },
      error: (err) => {
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  public getSpecialtyValue(claimDetails): string {
    if (claimDetails && claimDetails.specialty) {
      return claimDetails.specialty.toLowerCase() === 'yes' ? 'Yes' : 'No'
    }
    return 'No';
  }

  public toggle() {
    this.isExpanded = !this.isExpanded;
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.claimSearchCompareSub) this.claimSearchCompareSub.unsubscribe();
    if (this.thirdPartyCompareSub) this.thirdPartyCompareSub.unsubscribe();
  }
}
