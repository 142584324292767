import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppInsightsService } from '../../../../services/app-insights/app-insights.service';
import { ErrorComponent } from '../../../components/error/error.component';
import { AppCenterAnalytics } from '../../../../services/app-insights/app-insights';
import { AuthService } from '../auth.service';
import jwtDecode from 'jwt-decode';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  constructor(
    private matDialog: MatDialog,
    private appInsightService: AppInsightsService,
    private authService: AuthService
  ) {
  }

  public checkSessionTime(): void {
    const token = localStorage.getItem('id_token');
    if (!token) {
      return;
    }

    // Decode the token and get expiry time in milliseconds
    const tokenExpiry = jwtDecode(token)['exp'] * 1000;
    const expiryTime = new Date(tokenExpiry).valueOf();
    const currentTime = new Date().valueOf();

    // Calculate the remaining time until expiration
    const timeUntilExpiry = expiryTime - currentTime;

    if (timeUntilExpiry > 0) {
      // Set up a timer to trigger at the exact moment of session expiration
      timer(timeUntilExpiry).subscribe(() => {
        this.handleSessionExpiry();
      });
    } else {
      // If the token is already expired, handle the session expiry immediately
      this.handleSessionExpiry();
    }
  }

  private handleSessionExpiry(): void {
    // Open the dialog to notify the user
    const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
      ErrorComponent,
      {
        width: '500px',
        data: {
          title: 'Session Expired',
          message: 'Your session has expired. Kindly log in again.',
        },
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.logout(180);
    });
  }

  private logout(minutes: number): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.LENGTH_SESSION_USER_ACTIVITY, {
      UserActivitySessionLength: `${minutes} minutes`
    });
    this.appInsightService.trackPageView(AppCenterAnalytics.USER_SESSION_TIMED_OUT);
    this.authService.logout();
  }
}
