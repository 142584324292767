/**
 * @file terms-and-conditions.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppConstants } from '../../../app.constants';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  panelOpenState: boolean;
  constants = AppConstants.LABELS;
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle(this.constants.pageTitle  + this.constants.hyphen + this.constants.termsOfUse);
   }

  ngOnInit(): void {
    this.panelOpenState = false;
  }

}
