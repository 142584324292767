<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <p>{{ message }}</p>
</mat-dialog-content>
<div class="detail-card">
  <div class="mat-row">
    <div class="mat-2">
      <label class="card-label">Person Code</label>
      <p>{{ existingMemberDetails.personCode }}</p>
    </div>
    <div class="mat-2">
      <label class="card-label">Name</label>
      <p>
        {{ existingMemberDetails.firstName }}
        {{ existingMemberDetails.lastName }}
      </p>
    </div>
    <div class="mat-2">
      <label class="card-label">Card/Alt ID</label>
      <p>{{ existingMemberDetails.cardId }}</p>
    </div>
    <div class="mat-2">
      <label class="card-label">Relationship</label>
      <p>{{existingMemberDetails.relationshipCode}} - {{relationShip[existingMemberDetails.relationshipCode] }}</p>
    </div>
    <div class="mat-2">
      <label class="card-label">DOB</label>
      <p>{{ existingMemberDetails.DOB | timeStampToDate }}</p>
    </div>
 
    
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <p>Please review the details below.</p>
</mat-dialog-content>
<div class="content-table dependents-table">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 member-search-table update-coverage-dialog-table">
    <ng-container matColumnDef="personCode">
      <th mat-header-cell *matHeaderCellDef width="70">Person Code</th>
      <td mat-cell *matCellDef="let row">
        <div [ngClass]="{
        'person-code-coverage-expired': checkIfCoverageExpired(row),
        'person-code-coverage-active': checkIfCoverageIsStartingSoon(row)
      }">{{ row.personCode }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.firstName }} {{ row.lastName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cardId">
      <th mat-header-cell *matHeaderCellDef>Card/Alt ID</th>
      <td mat-cell *matCellDef="let row">
        {{ row.cardId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="relationshipCode">
      <th mat-header-cell *matHeaderCellDef>Relationship</th>
      <td mat-cell *matCellDef="let row">
        {{ row.relationshipCode }} - {{ relationShip[row.relationshipCode] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="DOB">
      <th mat-header-cell *matHeaderCellDef>DOB</th>
      <td mat-cell *matCellDef="let row">
        {{ row.DOB | timeStampToDate }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{
        'coverage-expired-active': checkIfCoverageExpired(row)
      }"></tr>

    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" colspan="8" role="status">
        No Data Found
      </td>
    </tr>

  </table>
</div>
<div class="coverage-expired accordion-table-footer">
  <div class="legends mt-2">

    <div class="legends-item">
      <span class="cs-legend"></span>
      Coverage Starting Soon
    </div>
    <div class="legends-item">
      <span class="ce-legend"></span>
      Coverage Expired
    </div>
  </div>
  <mat-dialog-actions align="end" class="action-buttons">
    <button mat-button class="primary-btn" (click)="navigateBackToMemberSearch()">
      Back to Member Search
    </button>
    <button class="secondary-btn" mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
