import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Prescription } from 'src/app/interfaces/prescription.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';

@Component({
  selector: 'app-prescription-details-dialog',
  templateUrl: './prescription-details-dialog.component.html',
  styleUrls: ['./prescription-details-dialog.component.scss']
})
export class PrescriptionDetailsDialogComponent implements OnInit {
  public prescription: Prescription;
  public drugClassValues = ['2', '3', '4', '5'];
  constructor(public dialogRef: MatDialogRef<PrescriptionDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private readonly appInsightService: AppInsightsService) { }

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.PRESCRIPTIONS_DETAIL_DIALOG_VIEWED)
    this.prescription = this.dialogData.prescriptions;
  }
  public cancel(): void {
    this.dialogRef.close();
  }

}
