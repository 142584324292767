import { ELIGIBILITY_MENUS } from "./eligibility-menus";


export const ACCESS_ROLES = {
    "Level A": [
        {
            "name": "Benefit Tools",
            "menus": [
                {
                    "key": "co-pay-schedule",
                    "name": "Co-pay Schedule",
                    "action": "view",
                    "route": "/management/copay-schedule"
                },
                {
                    "key": "annual-cap",
                    "name": "Annual Cap",
                    "action": "view",
                    "route": "/management/annual-cap"
                },
                {
                    "key": "deductible",
                    "name": "Deductible",
                    "action": "view",
                    "route": "/management/deductible"
                },
                {
                    "key": "exclusions",
                    "name": "Exclusions",
                    "action": "view",
                    "route": "/management/exclusions"
                },
                {
                    "key": "preferred-medication-list",
                    "name": "Preferred Medication List",
                    "action": "view",
                    "route": "/management/preferred-medication-list"
                },
                {
                    "key": "online-eligibility",
                    "name": "Online Eligibility",
                    "subFeatures": [
                        // ELIGIBILITY_MENUS.MEMBER_SEARCH, //"Member Search",
                        ELIGIBILITY_MENUS.DRUG_PRICING, //"Drug Pricing",
                        ELIGIBILITY_MENUS.PHARMACY_SEARCH, //"Pharmacy Search"
                    ],
                    "route": "/management/online-eligibility"
                }
            ],
            "class": "benefit-tools"
        },
        {
            "name": "Audit Logs",
            "action": "view",
            "route": "/management/audit-logs",
            "class": "audit-logs"
        }
    ],
    "Level B": [
        {
            "name": "Benefit Tools",
            "menus": [
                {
                    "key": "co-pay-schedule",
                    "name": "Co-pay Schedule",
                    "action": "view",
                    "route": "/management/copay-schedule"
                },
                {
                    "key": "annual-cap",
                    "name": "Annual Cap",
                    "action": "view",
                    "route": "/management/annual-cap"
                },
                {
                    "key": "deductible",
                    "name": "Deductible",
                    "action": "view",
                    "route": "/management/deductible"
                },
                {
                    "key": "exclusions",
                    "name": "Exclusions",
                    "action": "view",
                    "route": "/management/exclusions"
                },
                {
                    "key": "preferred-medication-list",
                    "name": "Preferred Medication List",
                    "action": "view",
                    "route": "/management/preferred-medication-list"
                },
                {
                    "key": "online-eligibility",
                    "name": "Online Eligibility",
                    "subFeatures": [
                        // ELIGIBILITY_MENUS.MEMBER_SEARCH, //"Member Search",
                        ELIGIBILITY_MENUS.ADD_NEW_DEPENDENT, //"Add a New Dependent",
                        ELIGIBILITY_MENUS.UPDATE_MEMBER, //"Update Member",
                        ELIGIBILITY_MENUS.UPDATE_COVERAGE, //"Update Coverage",
                        ELIGIBILITY_MENUS.UPDATE_CONTACT_INFO, //"Add/ Update Contact Info",
                        ELIGIBILITY_MENUS.REQUEST_ID_CARD, //"Request ID Card",
                        ELIGIBILITY_MENUS.PRINT_DIGITAL_ID_CARD, //"Print Digital ID Card",
                        ELIGIBILITY_MENUS.CAP_DEDUCTIBLE_MOOP, //"Cap, Deductible & Moop",
                        ELIGIBILITY_MENUS.DRUG_PRICING, //"Drug Pricing",
                        ELIGIBILITY_MENUS.PHARMACY_SEARCH, //"Pharmacy Search"
                    ],
                    "route": "/management/online-eligibility"
                }
            ],
            "class": "benefit-tools"
        },
        {
            "name": "Audit Logs",
            "action": "view",
            "route": "/management/audit-logs",
            "class": "audit-logs"
        }
    ],
    "Level C": [
        {
            "name": "Benefit Tools",
            "menus": [
                {
                    "key": "co-pay-schedule",
                    "name": "Co-pay Schedule",
                    "action": "view",
                    "route": "/management/copay-schedule"
                },
                {
                    "key": "annual-cap",
                    "name": "Annual Cap",
                    "action": "view",
                    "route": "/management/annual-cap"
                },
                {
                    "key": "deductible",
                    "name": "Deductible",
                    "action": "view",
                    "route": "/management/deductible"
                },
                {
                    "key": "exclusions",
                    "name": "Exclusions",
                    "action": "view",
                    "route": "/management/exclusions"
                },
                {
                    "key": "preferred-medication-list",
                    "name": "Preferred Medication List",
                    "action": "view",
                    "route": "/management/preferred-medication-list"
                },
                {
                    "key": "online-eligibility",
                    "name": "Online Eligibility",
                    "subFeatures": [
                        // ELIGIBILITY_MENUS.MEMBER_SEARCH, //"Member Search",
                        ELIGIBILITY_MENUS.ADD_NEW_MEMBER, //"Add New Member",
                        ELIGIBILITY_MENUS.ADD_NEW_DEPENDENT, //"Add a New Dependent",
                        ELIGIBILITY_MENUS.UPDATE_MEMBER, //"Update Member",
                        ELIGIBILITY_MENUS.UPDATE_COVERAGE, //"Update Coverage",
                        ELIGIBILITY_MENUS.UPDATE_CONTACT_INFO, //"Add/ Update Contact Info",
                        ELIGIBILITY_MENUS.REQUEST_ID_CARD, //"Request ID Card",
                        ELIGIBILITY_MENUS.PRINT_DIGITAL_ID_CARD, //"Print Digital ID Card",
                        ELIGIBILITY_MENUS.CAP_DEDUCTIBLE_MOOP, //"Cap, Deductible & Moop",
                        ELIGIBILITY_MENUS.CLAIM_SEARCH, //"Claim Search",
                        // ELIGIBILITY_MENUS.MEMBER_SEARCH, //"Claim Search Detail",
                        ELIGIBILITY_MENUS.DRUG_PRICING, //"Drug Pricing",
                        ELIGIBILITY_MENUS.PHARMACY_SEARCH, //"Pharmacy Search"
                        ELIGIBILITY_MENUS.MAIL_ORDERS, // Mail Orders
                        ELIGIBILITY_MENUS.PRESCRIPTIONS, // Prescriptions
                        ELIGIBILITY_MENUS.CLINICAL_REVIEWS, // Clinical Reviews
                        ELIGIBILITY_MENUS.EOB // Explanation of Benefits
                    ],
                    "route": "/management/online-eligibility"
                }
            ],
            "class": "benefit-tools"
        },
        {
            "name": "Audit Logs",
            "action": "view",
            "route": "/management/audit-logs",
            "class": "audit-logs"
        }
    ],
    "Level D": [
        {
            "name": "Benefit Tools",
            "menus": [
                {
                    "key": "co-pay-schedule",
                    "name": "Co-pay Schedule",
                    "action": "view",
                    "route": "/management/copay-schedule"
                },
                {
                    "key": "annual-cap",
                    "name": "Annual Cap",
                    "action": "view",
                    "route": "/management/annual-cap"
                },
                {
                    "key": "deductible",
                    "name": "Deductible",
                    "action": "view",
                    "route": "/management/deductible"
                },
                {
                    "key": "exclusions",
                    "name": "Exclusions",
                    "action": "view",
                    "route": "/management/exclusions"
                },
                {
                    "key": "preferred-medication-list",
                    "name": "Preferred Medication List",
                    "action": "view",
                    "route": "/management/preferred-medication-list"
                },
                {
                    "key": "online-eligibility",
                    "name": "Online Eligibility",
                    "subFeatures": [
                        // ELIGIBILITY_MENUS.MEMBER_SEARCH, //"Member Search",
                        ELIGIBILITY_MENUS.ADD_NEW_MEMBER, //"Add New Member",
                        ELIGIBILITY_MENUS.ADD_NEW_DEPENDENT, //"Add a New Dependent",
                        ELIGIBILITY_MENUS.UPDATE_MEMBER, //"Update Member",
                        ELIGIBILITY_MENUS.UPDATE_COVERAGE, //"Update Coverage",
                        ELIGIBILITY_MENUS.UPDATE_CONTACT_INFO, //"Add/ Update Contact Info",
                        ELIGIBILITY_MENUS.REQUEST_ID_CARD, //"Request ID Card",
                        ELIGIBILITY_MENUS.PRINT_DIGITAL_ID_CARD, //"Print Digital ID Card",
                        ELIGIBILITY_MENUS.CAP_DEDUCTIBLE_MOOP, //"Cap, Deductible & Moop",
                        ELIGIBILITY_MENUS.CLAIM_SEARCH, //"Claim Search",
                        // ELIGIBILITY_MENUS.MEMBER_SEARCH, //"Claim Search Detail",
                        // ELIGIBILITY_MENUS.CLAIM_SEARCH_ADDITIONAL_DETAILS, //"Claim Search Additional Details",
                        ELIGIBILITY_MENUS.DRUG_PRICING, //"Drug Pricing",
                        ELIGIBILITY_MENUS.PHARMACY_SEARCH, //"Pharmacy Search"
                        ELIGIBILITY_MENUS.MAIL_ORDERS, // Mail Orders
                        ELIGIBILITY_MENUS.PRESCRIPTIONS, // Prescriptions
                        ELIGIBILITY_MENUS.CLINICAL_REVIEWS, // Clinical Reviews
                        ELIGIBILITY_MENUS.EOB // Explanation of Benefits
                    ],
                    "route": "/management/online-eligibility"
                }
            ],
            "class": "benefit-tools"
        },
        {
            "name": "Audit Logs",
            "action": "view",
            "route": "/management/audit-logs",
            "class": "audit-logs"
        }
    ],
    "Level E": [
        {
            "name": "Benefit Tools",
            "menus": [
                {
                    "key": "co-pay-schedule",
                    "name": "Co-pay Schedule",
                    "action": "view",
                    "route": "/management/copay-schedule"
                },
                {
                    "key": "annual-cap",
                    "name": "Annual Cap",
                    "action": "view",
                    "route": "/management/annual-cap"
                },
                {
                    "key": "deductible",
                    "name": "Deductible",
                    "action": "view",
                    "route": "/management/deductible"
                },
                {
                    "key": "exclusions",
                    "name": "Exclusions",
                    "action": "view",
                    "route": "/management/exclusions"
                },
                {
                    "key": "preferred-medication-list",
                    "name": "Preferred Medication List",
                    "action": "view",
                    "route": "/management/preferred-medication-list"
                },
                {
                    "key": "online-eligibility",
                    "name": "Online Eligibility",
                    "subFeatures": [
                        // ELIGIBILITY_MENUS.MEMBER_SEARCH, //"Member Search",
                        ELIGIBILITY_MENUS.CAP_DEDUCTIBLE_MOOP, //"Cap, Deductible & Moop",
                        ELIGIBILITY_MENUS.CLAIM_SEARCH, //"Claim Search",
                        ELIGIBILITY_MENUS.DRUG_PRICING, //"Drug Pricing",
                        ELIGIBILITY_MENUS.PHARMACY_SEARCH, //"Pharmacy Search"
                        ELIGIBILITY_MENUS.MAIL_ORDERS, // Mail Orders
                        ELIGIBILITY_MENUS.PRESCRIPTIONS, // Prescriptions
                        ELIGIBILITY_MENUS.CLINICAL_REVIEWS, // Clinical Reviews
                        ELIGIBILITY_MENUS.EOB // Explanation of Benefits
                    ],
                    "route": "/management/online-eligibility"
                }
            ],
            "class": "benefit-tools"
        },
        {
            "name": "Audit Logs",
            "action": "view",
            "route": "/management/audit-logs",
            "class": "audit-logs"
        }
    ],
}



