<ng-container>
    <h4 class="modal-header">

        <span>Request Claims</span>
        <span class="close-icon-dialog"><mat-icon (click)="cancel()">close</mat-icon></span>
    </h4>
    <p>Please check back for the requested data. Your request may take up to 5 business days.</p>

    <form [formGroup]="requestClaimDataForm">
        <div class="form-wrapper">
            <div class="mat-row pad-b-1">
                <div class="mat-12">
                    <mat-label>Select Year <span class="asterisk-mark">*</span></mat-label>
                    <mat-form-field floatLabel="never" class="full-width">
                        <mat-select placeholder="Select" formControlName="year">
                            <mat-option *ngFor="let value of years" [value]="value">
                                {{ value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-custom-mat-hint [formGroup]="requestClaimDataForm" controlName="year"></app-custom-mat-hint>
                </div>
            </div>
            <div class="mat-row pad-b-1">
                <div class="mat-12">
                    <mat-label>Reason<span class="asterisk-mark">*</span></mat-label>
                    <mat-form-field floatLabel="never" class="full-width">
                        <textarea matInput placeholder="" formControlName="reason" class="claim-reason"></textarea>
                    </mat-form-field>
                    <app-custom-mat-hint [formGroup]="requestClaimDataForm" controlName="reason"></app-custom-mat-hint>
                </div>
            </div>
        </div>
        <div class="mat-row dialog-btn-row">
            <div class="mat-12" fxLayoutAlign="center center">
                <button mat-button class="primary-btn" [disabled]="requestClaimDataForm.invalid" (click)="save()">
                    Request
                </button>
                <button mat-button class="secondary-btn" (click)="cancel()">
                    Cancel
                </button>
            </div>
        </div>
    </form>

</ng-container>