<div class="page-wrapper">
  <div class="accordion-table-filter">
    <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToClaimSearch()"><mat-icon
        matListIcon>arrow_back</mat-icon>Member Claim Search</a>

    <button mat-button (click)="toggle()" class="expand-collapse-button">
      Expand/Collapse All
    </button>
  </div>

  <!-- Member Coverage | Dependant -->
  <div class="see-more-accordian claim-search-compare" *ngIf="dataLoaded">
    <mat-accordion>
      <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Member Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Card/Alt ID</label>
            <p>{{ claimCompareDetails.claim1.cardId }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Date of birth </label>
            <p>
              {{
              claimCompareDetails.claim1.dob
              ? (claimCompareDetails.claim1.dob | timeStampToDate)
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label"> Name</label>
            <p>{{ claimCompareDetails.claim1.name }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Person Code</label>
            <p>{{ claimCompareDetails.claim1.personCode }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label"> Gender</label>
            <p>
              {{
              claimCompareDetails.claim1.gender === "F" ? "Female" : "Male"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Relationship Code</label>
            <p>{{ claimCompareDetails.claim1.relationshipCode }}</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Client Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Client ID</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.clientId }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.clientId }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Group ID</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.groupId }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.groupId }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Client Name</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.clientName }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.clientName }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Group Name</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.groupName }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.groupName }}</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Claim Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Claim ID</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.claimId }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.claimId }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Auth Number</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.authNum? claimCompareDetails.claim1.authNum: '-' }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.authNum? claimCompareDetails.claim1.authNum: '-' }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Process Date</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.processDate
              ? (claimCompareDetails.claim1.processDate | date : "MM/dd/yyyy, hh:mm:ss a")
              : claimCompareDetails.claim1.processDate
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.processDate
              ? (claimCompareDetails.claim2.processDate | date : "MM/dd/yyyy, hh:mm:ss a")
              : claimCompareDetails.claim2.processDate
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Rx Number</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.rxNumber }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.rxNumber }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Fill Date</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.rxFillDate
              | timeStampToDate
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.rxFillDate
              | timeStampToDate
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Fill Number</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.fillNumber }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.fillNumber }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Written Date</label>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim1.writtenDate | timeStampToDate }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim2.writtenDate | timeStampToDate }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Quantity Prescribed</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.quantityPrescribed }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.quantityPrescribed }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Compound</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.compound
              ? claimCompareDetails.claim1.compound
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.compound
              ? claimCompareDetails.claim2.compound
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Quantity Dispensed</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.quantityDispensed
              ? claimCompareDetails.claim1.quantityDispensed
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.quantityDispensed
              ? claimCompareDetails.claim2.quantityDispensed
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">DAW</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.daw
              ? claimCompareDetails.claim1.daw
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.daw
              ? claimCompareDetails.claim2.daw
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Days Supply</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.daysSupply
              ? claimCompareDetails.claim1.daysSupply
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.daysSupply
              ? claimCompareDetails.claim2.daysSupply
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Prescriber</label>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim1.prescriber? claimCompareDetails.claim1.prescriber: '-' }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim2.prescriber? claimCompareDetails.claim2.prescriber: '-' }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Pharmacy</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.pharmacy? claimCompareDetails.claim1.pharmacy: '-' }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.pharmacy? claimCompareDetails.claim2.pharmacy: '-' }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Prescriber NPI</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.prescriberNPI? claimCompareDetails.claim1.prescriberNPI: '-' }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.prescriberNPI? claimCompareDetails.claim2.prescriberNPI: '-' }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Pharmacy NPI</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.pharmacyNPI }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.pharmacyNPI }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Pharmacy Address</label>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim1.pharmacyAddress }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim2.pharmacyAddress }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Pharmacy Phone Number</label>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim1.pharmacyPhoneNumber? claimCompareDetails.claim1.pharmacyPhoneNumber: '-' }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim2.pharmacyPhoneNumber? claimCompareDetails.claim2.pharmacyPhoneNumber: '-' }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Pharmacy Store Number</label>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim1.pharmacyStoreNumber? claimCompareDetails.claim1.pharmacyStoreNumber: '-' }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim2.pharmacyStoreNumber? claimCompareDetails.claim2.pharmacyStoreNumber: '-' }}
            </p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Drug Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Medication</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.medication }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.medication }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">NDC</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.ndc }}</p>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.ndc }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Brand/Generic</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.brandGeneric === "B"
              ? "Brand"
              : "Generic"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.brandGeneric === "B"
              ? "Brand"
              : "Generic"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Specialty</label>
          </div>
          <div class="mat-4">
            <p>
              {{ getSpecialtyValue(claimCompareDetails.claim1) }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ getSpecialtyValue(claimCompareDetails.claim2) }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Route of Admin</label>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim1.routeOfAdmin }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ claimCompareDetails.claim2.routeOfAdmin }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Formulary Tier</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.formularyTier
              ? claimCompareDetails.claim1.formularyTier
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.formularyTier
              ? claimCompareDetails.claim2.formularyTier
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Multisource Code</label>
          </div>
          <div class="mat-4">
            <ng-container [ngSwitch]="claimCompareDetails.claim1.multiSourceCode">
              <p *ngSwitchCase="'M'">
                Single-source, co-licensed product, Brand
              </p>
              <p *ngSwitchCase="'O'">Multi-source, originator product, Brand</p>
              <p *ngSwitchCase="'N'">Single-source product, Brand</p>
              <p *ngSwitchCase="'Y'">Multi-source product, Generic</p>
            </ng-container>
          </div>
          <div class="mat-4">
            <ng-container [ngSwitch]="claimCompareDetails.claim2.multiSourceCode">
              <p *ngSwitchCase="'M'">
                Single-source, co-licensed product, Brand
              </p>
              <p *ngSwitchCase="'O'">Multi-source, originator product, Brand</p>
              <p *ngSwitchCase="'N'">Single-source product, Brand</p>
              <p *ngSwitchCase="'Y'">Multi-source product, Generic</p>
            </ng-container>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Maintenance</label>
          </div>
          <div class="mat-4">
            <ng-container [ngSwitch]="claimCompareDetails.claim1.maintenance">
              <p *ngSwitchCase="'0'">No</p>
              <p *ngSwitchCase="'1'">No</p>
              <p *ngSwitchCase="'2'">Yes</p>
            </ng-container>
          </div>
          <div class="mat-4">
            <p>
              <ng-container [ngSwitch]="claimCompareDetails.claim2.maintenance">
                <p *ngSwitchCase="'0'">No</p>
                <p *ngSwitchCase="'1'">No</p>
                <p *ngSwitchCase="'2'">Yes</p>
              </ng-container>
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Prior Auth List</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.priorAuthList
              ? claimCompareDetails.claim1.priorAuthList
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.priorAuthList
              ? claimCompareDetails.claim2.priorAuthList
              : "-"
              }}
            </p>
          </div>
        </div>
        <!-- <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Indications</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.indication
                  ? claimCompareDetails.claim1.indication
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.indication
                  ? claimCompareDetails.claim2.indication
                  : "-"
              }}
            </p>
          </div>
        </div> -->
        <!-- <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Step Therapy Protocol</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.steptherapyProtocol
                  ? claimCompareDetails.claim1.steptherapyProtocol
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.steptherapyProtocol
                  ? claimCompareDetails.claim2.steptherapyProtocol
                  : "-"
              }}
            </p>
          </div>
        </div> -->
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Primary Drug Category</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.primaryDrugCategory
              ? claimCompareDetails.claim1.primaryDrugCategory
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.primaryDrugCategory
              ? claimCompareDetails.claim2.primaryDrugCategory
              : "-"
              }}
            </p>
          </div>
        </div>
        <!-- <div fxLayout="row">
            <div class="mat-4">
              <label class="card-label">Quantity Limit List</label>
            </div>
            <div class="mat-4">
              <p>
                {{
                  claimCompareDetails.claim1.quantityLimitList
                    ? claimCompareDetails.claim1.quantityLimitList
                    : "-"
                }}
              </p>
            </div>
            <div class="mat-4">
              <p>
                {{
                  claimCompareDetails.claim2.quantityLimitList
                    ? claimCompareDetails.claim2.quantityLimitList
                    : "-"
                }}
              </p>
            </div>
          </div> -->
        <!-- <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Equivalent Medications</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.equivalentMedication
                  ? claimCompareDetails.claim1.equivalentMedication
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.equivalentMedication
                  ? claimCompareDetails.claim2.equivalentMedication
                  : "-"
              }}
            </p>
          </div>
        </div> -->
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Status Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Status</label>
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim1.status }}</p>
            <!-- <ng-container [ngSwitch]="claimCompareDetails.claim1.status">
              <p *ngSwitchCase="'R'">Rejected</p>
              <p *ngSwitchCase="'AR'">Accepted Reversal</p>
              <p *ngSwitchCase="'AP'">Accepted Paid</p>
              <p *ngSwitchCase="'AA'">All Accepted Claims</p>
            </ng-container> -->
          </div>
          <div class="mat-4">
            <p>{{ claimCompareDetails.claim2.status }}</p>
            <!-- <ng-container [ngSwitch]="claimCompareDetails.claim2.status">
              <p *ngSwitchCase="'R'">Rejected</p>
              <p *ngSwitchCase="'AR'">Accepted Reversal</p>
              <p *ngSwitchCase="'AP'">Accepted Paid</p>
              <p *ngSwitchCase="'AA'">All Accepted Claims</p>
            </ng-container> -->
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Reject Code</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.rejectCode
              ? claimCompareDetails.claim1.rejectCode
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.rejectCode
              ? claimCompareDetails.claim2.rejectCode
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Processed as Non-primary</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.processedAsPrimary === "false"
              ? "Yes"
              : "No"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.processedAsPrimary === "false"
              ? "Yes"
              : "No"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Reject code description</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.rejectCodeDescription
              ? claimCompareDetails.claim1.rejectCodeDescription
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.rejectCodeDescription
              ? claimCompareDetails.claim2.rejectCodeDescription
              : "-"
              }}
            </p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Pricing Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Plan Amount</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.planAmount
              ? (claimCompareDetails.claim1.planAmount | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.planAmount
              ? (claimCompareDetails.claim2.planAmount | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row" *ngIf="userAccessLevel !== userAccessLevels.LEVEL_C">
          <div class="mat-4">
            <label class="card-label">Ingredient Cost </label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.ingredientCost
              ? (claimCompareDetails.claim1.ingredientCost
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.ingredientCost
              ? (claimCompareDetails.claim2.ingredientCost
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Total Member Responsibility</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.totalMemberResponsibility
              ? (claimCompareDetails.claim1.totalMemberResponsibility
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.totalMemberResponsibility
              ? (claimCompareDetails.claim2.totalMemberResponsibility
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row" *ngIf="userAccessLevel !== userAccessLevels.LEVEL_C">
          <div class="mat-4">
            <label class="card-label">Dispensing fee</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.dispensingFee
              ? (claimCompareDetails.claim1.dispensingFee
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.dispensingFee
              ? (claimCompareDetails.claim2.dispensingFee
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Third Party Assistance</label>
          </div>
          <div class="mat-4">
            <p>
              {{ thirdParty1 ? (thirdParty1 | currency : "USD") : "-" }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{ thirdParty2 ? (thirdParty2 | currency : "USD") : "-" }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Shipping charge</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.shippingCharge
              ? (claimCompareDetails.claim1.shippingCharge
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.shippingCharge
              ? (claimCompareDetails.claim2.shippingCharge
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Total Member Pay</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.totalMemberPay
              ? (claimCompareDetails.claim1.totalMemberPay
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.totalMemberPay
              ? (claimCompareDetails.claim2.totalMemberPay
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Sales tax</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.salesTax
              ? (claimCompareDetails.claim1.salesTax | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.salesTax
              ? (claimCompareDetails.claim2.salesTax | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Member Co-pay</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.memberCopay
              ? (claimCompareDetails.claim1.memberCopay | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.memberCopay
              ? (claimCompareDetails.claim2.memberCopay | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Incentive Amount</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.incentiveAmount
              ? (claimCompareDetails.claim1.incentiveAmount
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.incentiveAmount
              ? (claimCompareDetails.claim2.incentiveAmount
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Member Deductible</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.memberDeductible
              ? (claimCompareDetails.claim1.memberDeductible
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.memberDeductible
              ? (claimCompareDetails.claim2.memberDeductible
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Total Cost</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.totalCost
              ? (claimCompareDetails.claim1.totalCost | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.totalCost
              ? (claimCompareDetails.claim2.totalCost | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Member out of pocket</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.memberOutOfPocket
              ? (claimCompareDetails.claim1.memberOutOfPocket
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.memberOutOfPocket
              ? (claimCompareDetails.claim2.memberOutOfPocket
              | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Pricing source used</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.pricingSourceUsed
              ? claimCompareDetails.claim1.pricingSourceUsed
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.pricingSourceUsed
              ? claimCompareDetails.claim2.pricingSourceUsed
              : "-"
              }}
            </p>
          </div>
        </div>
        <!-- <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Ancillary Charge</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.ancillaryCharge
                  ? (claimCompareDetails.claim1.ancillaryCharge
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.ancillaryCharge
                  ? (claimCompareDetails.claim2.ancillaryCharge
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div> -->
        <div fxLayout="row" *ngIf="userAccessLevel !== userAccessLevels.LEVEL_C">
          <div class="mat-4">
            <label class="card-label">100% AWP</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.awp
              ? (claimCompareDetails.claim1.awp | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.awp
              ? (claimCompareDetails.claim2.awp | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Ancillary Charge</label>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim1.ancillaryCharge
              ? (claimCompareDetails.claim1.ancillaryCharge | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
              claimCompareDetails.claim2.ancillaryCharge
              ? (claimCompareDetails.claim2.ancillaryCharge | currency : "USD")
              : "-"
              }}
            </p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- <mat-accordion>
     <mat-expansion-panel [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Pharmacy Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Reimbursement Amount</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement
                  .pharmacyReimbursementAmount | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement
                  .pharmacyReimbursementAmount | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Ingredient Cost</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.ingredientCost
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.ingredientCost
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Total Member Responsibility</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement
                  .totalMemberresponsibility | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement
                  .totalMemberresponsibility | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Dispensing Fee</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.dispensingfee
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.dispensingfee
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Third Party Assistance</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement
                  .thirdPartyAssistance | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement
                  .thirdPartyAssistance | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Shipping Charge</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.shippingCharge
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.shippingCharge
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Total Member Pay</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.totalMemberPay
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.totalMemberPay
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Sales Tax</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.salesTax
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.salesTax
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Member Co-pay</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.copay
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.copay
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Incentive Amount</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.incentiveAmount
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.incentiveAmount
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Member Deductible</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.deductible
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.deductible
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Total Cost</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.totalCost
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.totalCost
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Member out of pocket </label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.outOfPocket
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.outOfPocket
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Pricing source used</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement
                  .pricingSourceUsed | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement
                  .pricingSourceUsed | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">Ancillary Charge</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement.ancillaryCharge
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement.ancillaryCharge
                  | currency : "USD"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-4">
            <label class="card-label">100% AWP</label>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim1.pharmacyReimbursement
                  .hundredPercentAwp | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-4">
            <p>
              {{
                claimCompareDetails.claim2.pharmacyReimbursement
                  .hundredPercentAwp | currency : "USD"
              }}
            </p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
</div>