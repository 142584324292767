<h4 class="modal-header order-header">
    Order Details
    <span class="close-icon-dialog"><mat-icon (click)="cancel()">close</mat-icon></span>
    <div class="order-id">Order {{order.orderNumber}} | {{order.prescriptionFills?.prescribedDrugName}} </div>
</h4>
<ng-container>
    <!-- Member Detail card -->
    <div class="order-details">
        <div class="detail-card">
            <h3>General</h3>
            <div>
                <h4>Order Number</h4>
                <p>{{order.orderNumber || '-'}} </p>
            </div>
            <div>
                <h4>Rx Number</h4>
                <p>{{order.prescriptionFills?.rxNumber || '-'}}</p>
            </div>
            <div>
                <h4>Drug Name</h4>
                <p>{{order.prescriptionFills?.prescribedDrugName || '-'}}</p>
            </div>
            <div>
                <h4>Days Supply</h4>
                <p>{{order.prescriptionFills?.daysSupply || '-'}}</p>
            </div>
            <div>
                <h4>Received Date</h4>
                <p>{{order.receivedDate? (order.receivedDate | timeStampToDate) : '-'}}</p>
            </div>
            <div>
                <h4>Pharmacy</h4>
                <p>{{order.pharmacy || '-'}}</p>
            </div>
            <div>
                <h4>Patient Name</h4>
                <p>{{order.prescriptionFills?.patientFirstName || '-'}}
                    {{order.prescriptionFills?.patientLastName || '-'}}
                </p>
            </div>
            <div>
                <h4>Patient DOB</h4>
                <p>{{order.prescriptionFills?.patientDateOfBirth? (order.prescriptionFills?.patientDateOfBirth |
                    timeStampToDate): '-'}}
                </p>
            </div>
        </div>
        <div class="detail-card">
            <h3>Shipping Address</h3>
            <div>
                <h4>Ship to Name</h4>
                <p>{{order.shipToAddress?.shipToName || '-'}}</p>
            </div>
            <div>
                <h4>Care of Name</h4>
                <p>{{order.shipToAddress?.careOfName || '-'}}</p>
            </div>
            <div>
                <h4>Address Line 1</h4>
                <p>{{order.shipToAddress?.addressLine1 || '-'}}</p>
            </div>
            <div>
                <h4>Address Line 2</h4>
                <p>{{order.shipToAddress?.addressLine2 || '-'}}</p>
            </div>
            <div>
                <h4>City</h4>
                <p>{{order.shipToAddress?.city || '-'}}</p>
            </div>
            <div>
                <h4>State</h4>
                <p>{{order.shipToAddress?.state || '-'}}</p>
            </div>
            <div>
                <h4>Zip Code</h4>
                <p>{{order.shipToAddress?.zipCode || '-'}}</p>
            </div>

        </div>
        <div class="detail-card">
            <h3>Order Status</h3>
            <div>
                <h4>{{order.orderStatus || '-'}}</h4>
                <p></p>
            </div>
        </div>
    </div>
</ng-container>