import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, defer, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  Coverage,
  CoverageResponse,
  MemberDetails,
} from '../../../interfaces/member.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpdateCoverageDetailsDialogComponent } from './update-coverage-details-dialog/update-coverage-details-dialog.component';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { AppConstants } from '../../../app.constants';
import { LoaderService } from '../../services/loader/loader.service';
import {
  IFlexTagTypes,
  MemberRxHistoryResponseInterface,
  RxTypeInterface,
} from 'src/app/interfaces/coverage.interface';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-update-coverage',
  templateUrl: './update-coverage.component.html',
  styleUrls: ['./update-coverage.component.scss'],
})
export class UpdateCoverageComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'group',
    'client',
    'effectiveDate',
    'endDate',
    'edit',
  ];
  public displayedColumnsRx: [string, string, string, string] = [
    'memberRxType',
    'effectiveDate',
    'endDate',
    'edit',
  ];
  public dataSource!: MatTableDataSource<Coverage>;
  public coverage: Coverage[] = [];
  public dataSourceRx!: MatTableDataSource<RxTypeInterface>;
  public coverageDetails$!: Observable<CoverageResponse>;
  public memberRxDetails$!: Observable<
    Partial<MemberRxHistoryResponseInterface>
  >;
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public displayGender = AppConstants.DISPLAY_GENDER;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public isActiveCoverage: boolean = false;
  public availableFlexTag: IFlexTagTypes[] = [];
  public coverageTypes = AppConstants.COVERAGE_TYPES;
  public cardHolderGroupNumber: string;
  // allEndDatesArray: string[] = [];
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  cardHolderRC: string = AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER;
  private subscription: Subscription[] = [];
  public isActive: boolean = true;
  constructor(
    private readonly router: Router,
    private matDialog: MatDialog,
    private readonly appInsightsService: AppInsightsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly eligibilityService: EligibilityService,
    private readonly loader: LoaderService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.loader.showLoader();
    this.selectedMemberDetails$ = this.eligibilityService.searchCoverage.pipe(
      switchMap(() => this.activatedRoute.queryParams.pipe(
        switchMap((res) => {
          this.cardHolderGroupNumber = res['cardHolderRCGrpNum'] ? res['cardHolderRCGrpNum'] : '';
          return this.eligibilityService.getMemberDetailsById(res).pipe(
            map((res) => {
              this.memberDetails = res[0];
              this.getAvailableFlexTag(this.memberDetails.clientId);
              this.coverageDetails$ = this.geCoverageDetails().pipe(
                tap((res) => {
                  this.coverage = (res || res.coverage) == null ? [] : res.coverage;
                  let displayData = this.isActive ? this.coverage.filter((cov) => cov.active === 'True') : this.coverage;
                  let filtered = this.coverage.filter(
                    (cov) =>
                      new Date(cov.endDate).valueOf() >= new Date(new Date().setUTCHours(0, 0, 0, 0)).valueOf() && cov.active === 'True'
                  );
                  if (!this.cardHolderGroupNumber) {
                    this.cardHolderGroupNumber = this.coverage.filter((c) => c.active)[0].cardholder_group_number;
                  }
                  this.isActiveCoverage = filtered.length !== 0;
                  this.coverage.sort(this.sortByActiveStatus);
                  this.dataSource = new MatTableDataSource(displayData);
                  this.getMemberRxCoverageList();
                })
              );
              return res[0];
            })
          );
        })
      )));
    this.appInsightsService.trackPageView(AppCenterAnalytics.UPDATE_COVERAGE);
  }

  public onTabClick(tabName: string): void {
    this.isActive = tabName == 'active' ? true : false;
    let displayData = this.isActive ? this.coverage.filter((cov) => cov.active === 'True') : this.coverage;
    this.dataSource = new MatTableDataSource(displayData);
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  getAvailableFlexTag(clientId: string): void {
    this.subscription.push(this.eligibilityService.getFlexTagTypes(clientId).pipe(
      tap((tags) => {
        this.availableFlexTag = tags
        if (this.memberDetails.relationshipCode !== AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER) {
          const index = this.availableFlexTag.findIndex((e) => e.Tag === 'FORCE65');
          if (index !== -1) {
            this.availableFlexTag.splice(index, 1);
          }
        }
      })
    ).subscribe());
  }

  getMemberRxCoverageList() {
    this.memberRxDetails$ = defer(() => {
      return this.eligibilityService
        .getRxCoverageDetails({
          patientId: this.memberDetails.patientId,
          clientId: this.memberDetails.clientId,
        })
        .pipe(
          tap((res) => {
            let sortedResponseArray = res.rxType.sort(this.sortByActiveStatus);
            sortedResponseArray = sortedResponseArray.filter((e) => e.flexTagName && e.flexTagName !== 'None')
            this.dataSourceRx = new MatTableDataSource(sortedResponseArray);
            // this.allEndDatesArray = res.rxType.map((rx) => rx.flexTagEndDate);
            // console.log('aLL eND dates array:', this.allEndDatesArray);
            // console.log(
            //   'All dates in the past?:',
            //   this.allDatesInThePast(this.allEndDatesArray)
            // );
            this.loader.hideLoader();
          }),
          catchError((err) => {
            this.loader.hideLoader();
            return of({});
          })
        )
    });
  }

  sortByActiveStatus = (a: any, b: any): number => {
    const isActiveA = a.active.toLowerCase() === 'true';
    const isActiveB = b.active.toLowerCase() === 'true';

    // Sort in descending order so that "True" comes first
    if (isActiveA && !isActiveB) {
      return -1;
    } else if (!isActiveA && isActiveB) {
      return 1;
    } else {
      return 0;
    }
  };

  allDatesInThePast(dateStringArray: string[]): boolean {
    const today = new Date();

    return dateStringArray.every((dateString) => {
      const date = new Date(dateString);
      return date < today;
    });
  }

  dateInThePast(dateString: string): boolean {
    const givenDate = new Date(dateString);

    const today = new Date();

    if (givenDate < today) {
      return true;
    } else if (givenDate > today) {
      return false;
    } else {
      return false;
    }
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public addNewCoverage(action: string): void {
    if (this.memberDetails.relationshipCode !== AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER) {
      const index = this.availableFlexTag.findIndex((e) => e.Tag === 'FORCE65');
      if (index !== -1) {
        this.availableFlexTag.splice(index, 1);
      }
    }
    const coverage = { edit: false };
    const dialogRef: MatDialogRef<UpdateCoverageDetailsDialogComponent> =
      this.matDialog.open(UpdateCoverageDetailsDialogComponent, {
        autoFocus: false,
        minWidth: window.innerWidth < 766 ? '600' : '980',
        panelClass: action === 'addCoverage' && this.memberDetails.relationshipCode === this.cardHolderRC ? 'update-coverage-details-modalbox' : 'add-coverage-types-modalbox',
        data: {
          coverage,
          action,
          memberDetails: this.memberDetails,
          flexTagTypes: this.availableFlexTag,
          cardHolderGroupNumber: this.cardHolderGroupNumber
        },
      });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  public geCoverageDetails(): Observable<CoverageResponse> {
    return defer(() => {
      this.loader.showLoader();
      const isCardHolderGroupNumberRequired = this.cardHolderGroupNumber ? '' : 'true';
      return this.eligibilityService
        .getMemberCoverageDetails(
          this.memberDetails.clientId,
          this.memberDetails.patientId,
          isCardHolderGroupNumberRequired
        )
        .pipe(
          tap((res) => {
            this.loader.hideLoader();
          }),
          catchError((err) => {
            this.loader.hideLoader();
            return of({ count: 0, coverage: [] });
          })
        )
    });
  }

  public updateCoverage(action: string, coverage: Coverage): void {
    coverage.edit = true;
    const dialogRef: MatDialogRef<UpdateCoverageDetailsDialogComponent> =
      this.matDialog.open(UpdateCoverageDetailsDialogComponent, {
        autoFocus: false,
        minWidth: window.innerWidth < 766 ? 'auto' : 980,
        maxHeight: 500,
        data: { coverage, action, memberDetails: this.memberDetails, flexTagTypes: this.availableFlexTag },
      });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.forEach((s) => s.unsubscribe());
  }
}
