/**
 * @file dashboard.component.ts
 */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConstants } from '../../app.constants';
import { ErrorManagerService, ErrorObject } from '../../core/services/error';
import { EmbedResponse } from '../../interfaces/embed-response.interface';
import { SharedService } from '../../services/shared/shared.service';

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnChanges {
   @Input() embedId: string;
   dashboard: unknown;
   embedUrl: string;
   embedToken: string;
   showLoader: boolean;
   appConstants = AppConstants;

   constructor(private readonly sharedService: SharedService, private readonly errorService: ErrorManagerService) { }

   /**
   * @description This method is used to check if the embedId is passed as a input and checks for its current and previous value and loads the dashboard.
   * @param  changes contains input properties for the component with interface as SimpleChanges
   * @returns void
   */
   ngOnChanges(changes: SimpleChanges): void {
      this.showLoader = true;
      if (changes[this.appConstants.LABELS.embedId] && (changes[this.appConstants.LABELS.embedId].currentValue !== changes[this.appConstants.LABELS.embedId].previousValue)) {
         this.embedId = changes[this.appConstants.LABELS.embedId].currentValue;
         this.getDashboard();
      }
   }

   /**
   * @description This method is used to load the dashboard according to the embedId which is passed as a parameter.
   * @param  embedId contains embedId for the dashboard to be loaded
   * @returns void
   */
   private getDashboard(): void {
      this.sharedService.getDashboard(this.embedId).subscribe((response: EmbedResponse) => {
         this.showLoader = false;
         if (response) {
            this.embedUrl = response.url;
            this.embedToken = response.embedToken;
            this.dashboard = '' +
               "<html>" +
               "   <body>" +
               "        <form id=\"form\" action=\"" + this.embedUrl + "\" method=\"post\">" +
               "        <input type=\"hidden\" name=\"embedToken\" value=\"" + this.embedToken + "\">" +
               "        </form>" +
               "        <script>" +
               "        document.getElementById(\"form\").submit();\n" +
               "        </script>" +
               "    </body>" +
               "</html>";
         } else {
            this.showError(this.appConstants.ERROR.MESSAGES.GENERIC);
         }
      });
   }

   /**
   * @description This method is used to throw an error if the API call is not successful.
   * @param  message contains the message to be shown to user
   * @param  title contains the title to be shown to user. Optional field. 
   * @returns void
   */
   public showError(message: string, title?: string): void {
      const data: ErrorObject = { message: message, title: title };
      this.errorService.throwError(data);
   }
}
