import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './core/modules/material.module';
import { SpinnerLoaderComponent } from './core/components/spinner-loader/spinner-loader.component';
import { ErrorComponent } from './core/components/error/error.component';
import { MainModule } from './main/main.module';
import { _HTTPInterceptorsProviders } from './interceptors';
import { SharedModule } from './shared/shared.module';
import { AppSelectorComponent } from './core/components/app-selector/app-selector.component';
import { ManagementModule } from './management/management.module';

@NgModule({
   declarations: [
      AppComponent,
      SpinnerLoaderComponent,
      ErrorComponent,
      AppSelectorComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      BrowserAnimationsModule,
      MaterialModule,
      MainModule,
      SharedModule,
      ManagementModule
   ],
   providers: [
      _HTTPInterceptorsProviders,
      { provide: LOCALE_ID, useValue: 'en-US' }
   ],
   bootstrap: [AppComponent]
})
export class AppModule { }
