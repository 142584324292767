export interface UserAccess {
    isOLAUser: boolean;
    olaUserType: UserAccessLevel;
    isReportsUser: boolean;
    reportUserType: string;
    userName: string;
}


export enum UserAccessLevel {
    LEVEL_A = 'Level A',
    LEVEL_B = 'Level B',
    LEVEL_C = 'Level C',
    LEVEL_D = 'Level D',
    LEVEL_E = 'Level E'
}