<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button *ngIf="showSideBarMenu" mat-icon-button class="menu-button" (click)="toggleSidebar()">
            <mat-icon>menu</mat-icon>
        </button>
        <a href="https://www.empirxhealth.com" target="_blank" rel="noopener noreferrer">
            <img class="brand" src="../../../../assets/images/brand-logo/logo.svg" width="95" alt="EmpiRx"></a>
        <span class="spacer"></span>
        <span class="header-title client-name" *ngIf="showClientName">{{clientName}}</span>
        <span class="header-title" *ngIf="showClientName">|</span>
        <span class="header-title">{{userName}}</span>

        <button mat-icon-button class="logout-button" (click)="openDialog()">
            <mat-icon>power_settings_new</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>