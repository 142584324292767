/**
 * @file error.interceptor.ts
 * @description this file implements interceptor to handler http errors
 */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ErrorManagerService, ErrorType } from '../../core/services/error';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { AppConstants } from '../../app.constants';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  private static readonly className = 'ErrorInterceptor';
  constructor(
    private readonly errorManager: ErrorManagerService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly authService: AuthService
  ) { }

  /**
   * @description Interceptor to handle http errors
   * @param  HttpRequest request
   * @param  HttpHandler next
   * @returns Observable
   */
  public intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    const retryCount = 3;
    const errorStatus = 401;

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.log('Error caught in the interceptor:', error);

        if (error.status === errorStatus) {
          this.authService.logout();
          return throwError(error);
        }

        if (error.url.includes('/member/modification')) {
          return throwError(error);
        }

        if (error.url.includes('/members/addressupdate')) {
          return throwError(error);
        }

         if (error.url.includes('/AuditLogs/SaveAuditLog')) {
          return throwError(error);
        }

        if (error.url.includes('client/flags')) {
          return throwError(error);
        }

        if (error.status === 405 && error.message.includes('temp-id-card')) {
          return throwError(error);
        }

        if (error.status === 422 && error.message.includes('claims-service/rest/getclientclaim')) {
          return throwError(error);
        }

        if (error.status === 500 && error.message.includes('third/party/assistance')) {
          return throwError(error);
        }
        if (error.url.includes('/api/bap/mail-order/search-patient-enrollment')) {
          return throwError(error);
        }
        if (error.url.includes('/api/bap/member/coverage')) {
          return throwError(error);
        }

        this.errorManager.throwError({
          type: ErrorType.ERROR_GENERIC,
          message: error?.error?.Message ? error.error.Message : AppConstants.ERROR.GENERIC_ERROR_MESSAGE,
          component: ErrorInterceptor.className,
          title: '',
        });

        return throwError(error);
      })
    );
  }

}
