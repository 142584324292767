/**
 * @file main.component.spec.ts
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CoPayScheduleComponent } from './co-pay-schedule/co-pay-schedule.component';
import { AnnualCapComponent } from './annual-cap/annual-cap.component';
import { DeductibleComponent } from './deductible/deductible.component';
import { ExclusionsComponent } from './exclusions/exclusions.component';
import { PreferredMedicationListComponent } from './preferred-medication-list/preferred-medication-list.component';
import { AddMemberComponent } from './online-eligibility/add-member/add-member.component';
import { MemberSearchComponent } from './online-eligibility/member-search.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { AddDependentComponent } from './online-eligibility/add-dependent/add-dependent.component';
import { UpdateMemberComponent } from './online-eligibility/update-member/update-member.component';
import { UpdateCoverageComponent } from './online-eligibility/update-coverage/update-coverage.component';
import { UpdateContactInfoComponent } from './online-eligibility/update-contact-info/update-contact-info.component';
import { RequestIdCardComponent } from './online-eligibility/request-id-card/request-id-card.component';
import { ClaimSearchComponent } from './online-eligibility/claim-search/claim-search.component';
import { CapDeductibleMoopComponent } from './online-eligibility/cap-deductible-moop/cap-deductible-moop.component';
import { PharmacySearchComponent } from './online-eligibility/pharmacy-search/pharmacy-search.component';
import { ClaimSearchDetailComponent } from './online-eligibility/claim-search/claim-search-detail/claim-search-detail.component';
import { ClaimSearchCompareComponent } from './online-eligibility/claim-search/claim-search-compare/claim-search-compare.component';
import { DrugPricingComponent } from './online-eligibility/drug-pricing/drug-pricing.component';
import { PrintIdCardComponent } from './online-eligibility/print-id-card/print-id-card.component';
import { UserAccessLevel } from '../interfaces/user-access.interface';
import { AccessLevelGuard } from '../core/guards/access-level/access-level.guard';
import { MailOrdersComponent } from './online-eligibility/mail-orders/mail-orders.component';
import { PrescriptionsComponent } from './online-eligibility/prescriptions/prescriptions.component';
import { ClinicalReviewsComponent } from './online-eligibility/clinical-reviews/clinical-reviews.component';
import { ExplanationOfBenefitsComponent } from './online-eligibility/explanation-of-benefits/explanation-of-benefits.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: 'copay-schedule', component: CoPayScheduleComponent },
      { path: 'annual-cap', component: AnnualCapComponent },
      { path: 'deductible', component: DeductibleComponent },
      { path: 'exclusions', component: ExclusionsComponent },
      {
        path: 'preferred-medication-list',
        component: PreferredMedicationListComponent,
      },
      { path: 'online-eligibility', component: MemberSearchComponent },
      {
        path: 'online-eligibility/add-new-member',
        component: AddMemberComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D] },
        canActivate: [AccessLevelGuard]
      },
      { path: 'audit-logs', component: AuditLogsComponent },
      {
        path: 'online-eligibility/add-new-dependent',
        component: AddDependentComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/update-member',
        component: UpdateMemberComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/update-coverage',
        component: UpdateCoverageComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/update-contact-info',
        component: UpdateContactInfoComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/request-id-card',
        component: RequestIdCardComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/claim-search',
        component: ClaimSearchComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/claim-search-detail',
        component: ClaimSearchDetailComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/claim-search-compare',
        component: ClaimSearchCompareComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E, UserAccessLevel.LEVEL_C] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/cap-deductible-moop',
        component: CapDeductibleMoopComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/pharmacy-search',
        component: PharmacySearchComponent,
      },
      {
        path: 'online-eligibility/drug-pricing',
        component: DrugPricingComponent
      },
      {
        path: 'online-eligibility/print-id-card',
        component: PrintIdCardComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_B, UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/mail-orders',
        component: MailOrdersComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/prescriptions',
        component: PrescriptionsComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/clinical-reviews',
        component: ClinicalReviewsComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E] },
        canActivate: [AccessLevelGuard]
      },
      {
        path: 'online-eligibility/eob',
        component: ExplanationOfBenefitsComponent,
        data: { accessLevels: [UserAccessLevel.LEVEL_C, UserAccessLevel.LEVEL_D, UserAccessLevel.LEVEL_E] },
        canActivate: [AccessLevelGuard]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagementRoutingModule { }
