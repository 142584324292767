import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ErrorManagerService, ErrorType } from '../../services/error';
import { AppConstants } from '../../../app.constants';
import { SharedService } from '../../../services/shared/shared.service';
import { BenefitManagementService } from '../../services/benefit-management/benefit-management.service';
import { UserAccessLevel } from '../../../interfaces/user-access.interface';
import { Clients } from '../../../interfaces/clients.interface';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { GroupLevelAccessResponse } from '../../../interfaces/group-level-access.interface';

@Injectable({
    providedIn: 'root'
})
export class AccessLevelGuard implements CanActivate {
    private static readonly className = 'AccessLevelGuard';
    private clients: Clients[] = [];

    constructor(private readonly errorManager: ErrorManagerService,
        private benefitService: BenefitManagementService,
        private sharedService: SharedService,
        private authService: AuthService,
    ) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        await this.fetchClientData();
        // Access the parameters from ActivatedRouteSnapshot
        const accessLevels = next.data.accessLevels;
        let accessLevelToCheck;
        this.sharedService.accessLevel$.subscribe((v) => accessLevelToCheck = v);

        if (next.queryParams.clientId) {
            const client = this.clients.filter((e) => e.clientId === next.queryParams.clientId)[0];
            this.sharedService.updateAccessLevel(client?.olA_Access_Type);
            if (client.groupLevelFlag) {
                await this.sharedService.getUserGroupsAccess({ emailId: this.authService.userEmail, clientId: client.clientId }).pipe(tap((res) => {
                    const grpAccess = res.find((g) => g.groupId === next.queryParams.groupNumber);
                    this.sharedService.updateAccessLevel(grpAccess?.benefitEligibilityAccessType ? grpAccess?.benefitEligibilityAccessType : client.olA_Access_Type);
                })).toPromise();
            }
        }

        if (next.routeConfig.path.includes('add-new-member')) {
            this.checkIfHasAccessToAddMember();
        } else {
            if (!accessLevels.includes(accessLevelToCheck)) {
                this.showNoAccessMessage();
                return false;
            }
        }

        // Your authentication logic here based on the parameters
        return true; // Return true to allow access, false to deny access
    }


    private async fetchClientData(): Promise<void> {
        try {
            // Subscribe to the observable and await the result
            this.clients = await this.benefitService.getClientsListByAccess().toPromise();
        } catch (error) {
            console.error('Error fetching clients data:', error);
        }
    }

    private checkIfHasAccessToAddMember(): void {
        const hasAccessToAddNewMember = this.clients.filter((e) =>
            e.olA_Access_Type !== UserAccessLevel.LEVEL_A && e.olA_Access_Type !== UserAccessLevel.LEVEL_E
        ).length !== 0;

        if (!hasAccessToAddNewMember) {
            this.showNoAccessMessage();
        }
    }

    private showNoAccessMessage(): void {
        this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: AppConstants.ERROR.MESSAGES.NO_ACCESS_TO_MODULE,
            component: AccessLevelGuard.className,
            title: '',
        });
    }
}
