import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { environment } from 'src/environments/environment';
import { PreferredMedicationListResponse } from 'src/app/interfaces/preferred-medication-list-response.interface';
import { PreferredMedicationRequestInterface } from 'src/app/interfaces/preferred-medication.interface';
import { convertJSONToQueryParams } from 'src/app/util';
import { HttpClient } from '@angular/common/http';
import { PreferredMedicationFileApiResponse } from '../../../interfaces/preferred-medication-file.interface';

@Injectable({
  providedIn: 'root',
})
export class PreferredMedicationService {
  constructor(private httpService: HttpService, private http: HttpClient) { }

  getPreferredMedicationList(reqBody: PreferredMedicationRequestInterface) {
    const query = convertJSONToQueryParams(reqBody);
    const url = `${environment.apiGatewayBaseURL}/preferred/medications?subscription-key=${environment.ocpApimSubscriptionKey}&${query}`;
    // console.log('Preferred medications URL:', url);
    return this.http.get<PreferredMedicationFileApiResponse>(url);
  }
}
