/**
 * @file auth.interceptor.ts
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthTokenService } from '../../core/services/authToken/auth-token.service';
import { AutoLogoutService } from '../../core/services/auth/logout/auto-logout.service';
import { environment } from 'src/environments/environment';
import { VersionOverrideType } from 'src/api-versions/api-version-config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthTokenService,
    private autoLogoutService: AutoLogoutService
  ) {
    this.autoLogoutService.checkSessionTime();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // set API version to default version
    let version = environment.apiVersionConfig.defaultVersion;
    try {
      let baseUrl = request.url.split('?')[0];
      baseUrl = baseUrl.replace(environment.apiGatewayBaseURL, "");
      baseUrl = baseUrl.replace(environment.baseUrl, "");
      const apiOverrides: VersionOverrideType = environment.apiVersionConfig.versionOverrides;
      if (apiOverrides.hasOwnProperty(baseUrl)) {
        const apiDetails = apiOverrides[baseUrl];
        if (apiDetails.methods && apiDetails.methods.length > 0) {
          if (apiDetails.methods.includes(request.method)) {
            version = apiDetails.apiVersion;
          }
        } else {
          // if no method is specified, then set the overridden API version for all the APIs with baseURL
          version = apiDetails.apiVersion;
        }
      }
      // TODO: Remove after testing
      //  console.log('calculated version', version);
    } catch (error) {
      const errorObj: Error = {
        name: "ADD API VERSION",
        message: `Exception while calculation API version of API`,
        stack: JSON.stringify(error)
      }
      // this.insightsService.trackException(errorObj, )
    }

    request = request.clone({
      setHeaders: {
        Authorization: this.authService.azureIdToken || '',
        'x-api-version': version
      }
    });
    return next.handle(request);
  }
}
