import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from 'src/app/interfaces/benefit-tool.interface';
import { CopaySchedule } from 'src/app/interfaces/co-pay-schedule.interface';
import { PreferredMedicationFile } from 'src/app/interfaces/preferred-medication-file.interface';
import { PreferredMedicationListResponse } from 'src/app/interfaces/preferred-medication-list-response.interface';

import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { SharedService } from '../../services/shared/shared.service';
import { PreferredMedicationService } from '../../core/services/preferred-medication/preferred-medication.service';
import { PreferredMedicationRequestInterface } from '../../interfaces/preferred-medication.interface';
import { LoaderService } from '../services/loader/loader.service';
import { EligibilityService } from '../../core/services/eligibility/eligibility.service';
import { ErrorManagerService, ErrorType } from '../../core/services/error';

@Component({
  selector: 'app-co-pay-schedule',
  templateUrl: './co-pay-schedule.component.html',
  styleUrls: ['./co-pay-schedule.component.scss'],
})
export class CoPayScheduleComponent implements OnInit {
  public copaySchedule$: Observable<CopaySchedule>;
  public preferredMedicationList$: Observable<PreferredMedicationFile[]>;
  public benefitToolData: Group[] = [];
  public isNoData: boolean = false;
  groupChosen: boolean = false;
  constructor(
    private readonly appInsightService: AppInsightsService,
    private loader: LoaderService,
    private eligibilityService: EligibilityService,
    private pmService: PreferredMedicationService,
    private errorManager: ErrorManagerService
  ) {}

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.CO_PAY_SCHEDULE);
  }

  public filterList(event) {
    this.benefitToolData = event;
  }

  public onFieldsSelect(event) {
    // console.log('form selection', event);
    const reqBody: PreferredMedicationRequestInterface = {
      clientId: event.clientId,
    };
    this.groupChosen = true;
    this.copaySchedule$ = this.getCopayScheduleData();
    this.preferredMedicationList$ = this.pmService
      .getPreferredMedicationList(reqBody)
      .pipe(map((res) => res.files));
  }

  public NoDataHandling(event) {
    this.isNoData = event;
    this.groupChosen = false;
    if (this.isNoData) {
      this.copaySchedule$ = null;
    }
  }

  clearData(clear: boolean) {
    this.groupChosen = false;
    if (clear) this.copaySchedule$ = null;
  }

  public getCopayScheduleData(): Observable<CopaySchedule> {
    let copaySchedule: CopaySchedule =
      this.benefitToolData.length == 0 || this.benefitToolData == null
        ? null
        : this.benefitToolData[0].copaySchedule;

    return of(copaySchedule);
  }

  openPdf(url: string) {
    const docURL = new URL(url);
    const pdfUrl = docURL.pathname + docURL.search;
    this.loader.showLoader();
    this.eligibilityService.preferredMedicationListById(pdfUrl).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
        this.loader.hideLoader();
      },
      (error) => {
        this.errorManager.throwError({
          type: ErrorType.ERROR_GENERIC,
          message: error,
        });
        this.loader.hideLoader();
      }
    );
  }
}
