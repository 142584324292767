import { Injectable } from '@angular/core';
import { ErrorMessagingConstants } from './error-messaging-constants';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagingServiceService {

  constructor() { }

  public getFormControlErrorMessage(fnName: string, fcErrorObj: ValidationErrors): string {
    const errorKey = Object.keys(fcErrorObj)[0];
    if (ErrorMessagingConstants.FORMCONTROL_ERROR_MAP[fnName]
      && ErrorMessagingConstants.FORMCONTROL_ERROR_MAP[fnName][errorKey] instanceof Function) {
      return ErrorMessagingConstants.FORMCONTROL_ERROR_MAP[fnName][errorKey](fcErrorObj[errorKey]);
    }
  }
}
