import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConstants } from '../../../app.constants';

import { Loader } from '../../../interfaces/loader.interface';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent implements OnInit {
  private static readonly className = 'LoaderComponent';

  private subscription: Subscription = new Subscription();
  public show = false;
  public message = AppConstants.LOADER.DEFAULT_MESSAGE;

  /**
   * @param  LoaderService loaderService
   */
  constructor(private readonly loaderService: LoaderService) { }

  public ngOnInit(): void {
    this.subscription = this.loaderService.loader.subscribe((state: Loader) => {
      this.message = state.message;
      this.show = state.show;
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
