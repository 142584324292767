<div class="page-wrapper flex-column">
  <!-- Page Header -->
  <div class="page-header">
    <h2>Formulary</h2>
    <p>To view a Formulary, select a client from the dropdown boxes</p>
  </div>
  <!-- See more accordian -->
  <div class="see-more-accordian">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title> Definition </mat-panel-title>
          <div class="exp-label">
            See {{ panelOpenState ? "Less" : "More" }}
          </div>
        </mat-expansion-panel-header>
        <p>
          The preferred medication list, also called a formulary, devised by
          EmpiRx Health focuses on the evidence-based approach offering guidance
          to medications that are therapeutically effective and appropriately
          priced. Through our transparent and pass-through business model our
          goal is to provide clinically appropriate utilization in conjunction
          with the lowest net cost.
        </p>
        <p>
          The preferred medication list is not intended to supersede the
          judgement of your healthcare professional. However, sharing your plan
          specific preferred medication list with your healthcare professional
          will allow them to, where appropriate, prescribe medications which
          allow you to take advantage of cost savings.
        </p>
        <p>
          Before making decisions as a client or member using this EmpiRx Health
          Preferred Drug list, please be sure to log in and review the formulary
          associated with your specific prescription benefit program.
          Additionally, be sure to review benefits and coverage details as
          therapeutic categories or specific medications listed on the formulary
          may not be covered under your program.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <!-- Form Section -->
  <div class="form-wrapper">
    <div class="mat-row pad-b-1">
      <div class="mat-12">
        <mat-label>Client<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <mat-select [formControl]="clientFormControl" placeholder="Select Client" panelClass="custom-select-panel" (selectionChange)="onSelectionChange($event)" [disableOptionCentering]="true">
            <mat-option>
              <ngx-mat-select-search [formControl]="clientFilterControl"  [clearSearchInput]="true" [showToggleAllCheckbox]="false"
                placeholderLabel="Search Client(s) by ID or Name..." noItemsLabel="No clients found">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="(filteredClients | async)?.length === 0" disabled>
              No clients found
            </mat-option>
              <mat-option *ngFor="let client of filteredClients | async" [value]="client.clientId">
                {{ client.clientId }} - {{ client.clientName }}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>

  <!-- Details Information Section -->
  <div *ngIf="preferredMedicationList$ | async as preferredMedicationList" class="detail-information info-margin">
    <ng-container *ngIf="preferredMedicationList.length > 0">
      <h2>Details</h2>
      <div class="files-list">
        <ul>
          <li *ngFor="let preferredMedication of preferredMedicationList">
            <a (click)="openPdf(preferredMedication.url)" target="_blank">{{
              preferredMedication.name
              }}</a>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>

  <div class="detail-information info-margin" *ngIf="noData">
    <p>No data found.</p>
  </div>

  <!-- Note -->
  <div class="note-wrapper">
    <h4>Note:</h4>
    <p>
      ** EmpiRx Health does not provide medical advice, diagnosis or treatment
      recommendations. It is not a substitute for your prescriber or pharmacist.
      The material on this site is for informational purposes only. Before you
      switch your medication you may want to speak with your prescriber.
    </p>

    <p>
      *** Some medications listed on the formulary may have strengths that are
      available over the counter and therefore may not be covered by your plan.
      Please review your plan coverage.
    </p>
  </div>
</div>