/**
 * @file app-insights.ts
 */
export class AppCenterAnalytics {
  public static readonly HOME_VIEWED = 'HOME PAGE VIEWED';
  public static readonly DASHBOARD_VIEWED = 'DASHBOARD PAGE VIEWED';
  public static readonly REPORT_SELECTION_VIEWED =
    'REPORT SELECTION PAGE VIEWED';
  public static readonly REPORT_CLICKED = 'REPORT CLICKED';
  public static readonly REPORT_VIEWED = ' REPORT VIEWED';
  public static readonly CLIENT_CLICKED = 'CLIENT CLICKED';
  public static readonly CLIENT_SELECTED = 'CLIENT SELECTED';
  public static readonly INVOICE_CLICKED = 'INVOICE CLICKED';
  public static readonly INVOICE_VIEWED = 'INVOICE VIEWED';
  public static readonly MY_DOCUMENTS_VIEWED = 'MY DOCUMENTS PAGE VIEWED';
  public static readonly FINANCIAL_DOCUMENTS_VIEWED =
    'FINANCIAL DOCUMENTS PAGE VIEWED';
  public static readonly DOCUMENTS_DOWNLOAD = 'DOCUMENT DOWNLOAD';
  public static readonly INVOICE_DOWNLOAD = 'FINANCIAL INVOICE DOWNLOAD';
  public static readonly LENGTH_SESSION_USER_ACTIVITY =
    'LENGTH OF SESSION OF USER ACTIVITY';
  public static readonly USER_SESSION_TIMED_OUT =
    'USER SESSION TIMED OUT';
  public static readonly VIEWED = 'VIEWED';
  public static readonly FIND_PHARMACY_VIEWED = 'FIND PHARMACY PAGE VIEWED';
  public static readonly CO_PAY_SCHEDULE = 'CO-PAY SCHEDULE PAGE VIEWED';
  public static readonly ANNUAL_CAP = 'ANNUAL CAP PAGE VIEWED';
  public static readonly DEDUCTABLE = 'DEDUCTABLE PAGE VIEWED';
  public static readonly EXCLUSIONS = 'EXCLUSIONS PAGE VIEWED';
  public static readonly PREFERRED_MEDICATION_LIST =
    'PREFERRED MEDICATION LIST PAGE VIEWED';
  public static readonly ONLINE_ELIGIBILITY = 'ONLINE ELIGIBILITY PAGE VIEWED';
  public static readonly ADD_MEMBER = 'ADD MEMBER PAGE VIEWED';
  public static readonly ADD_DEPENDENT = 'ADD DEPENDENT PAGE VIEWED';
  public static readonly UPDATE_MEMBER = 'UPDATE MEMBER PAGE VIEWED';
  public static readonly UPDATE_COVERAGE = 'UPDATE COVERAGE PAGE VIEWED';
  public static readonly UPDATE_CONTACT_INFO =
    'UPDATE CONTACT INFO PAGE VIEWED';
  public static readonly REQUEST_ID_CARD = 'REQUEST ID CARD PAGE VIEWED';
  public static readonly CAP_DEDUCTIBLE_MOOP =
    'CAP DEDUCTIBLE MOOP PAGE VIEWED';
  public static readonly CLAIM_SEARCH = 'CLAIM SEARCH PAGE VIEWED';
  public static readonly CLAIM_SEARCH_DETAIL =
    'CLAIM SEARCH DETAIL PAGE VIEWED';
  public static readonly CLAIM_SEARCH_COMPARE =
    'CLAIM SEARCH COMPARE PAGE VIEWED';
  public static readonly PRINT_ID_CARD = 'PRINT ID CARD PAGE VIEWED';
  public static readonly DRUG_PRICING = 'DRUG PRICING PAGE VIEWED';
  public static readonly GROUP_SELECTION = 'GROUP SELECTED';
  public static readonly PLAN_SELECTION = 'PLAN SELECTED';
  public static readonly ID_TYPE_SELECTION = 'ID TYPE SELECTED';
  public static readonly AUDIT_LOGS = 'AUDIT LOGS PAGE VIEWED';

  public static readonly REQUEST_HISTORIC_DATA_BUTTON_CLICKED = "CLAIM-REQUEST HISTORIC DATA BUTTON CLICKED";
  public static readonly REQUEST_HISTORIC_DATA_FORM_VIEWED = "CLAIM-REQUEST HISTORIC DATA FORM VIEWED";
  public static readonly REQUEST_HISTORIC_DATA_FORM_SUBMIT_BUTTON_CLICKED = "CLAIM-REQUEST HISTORIC DATA FORM SUBMIT BUTTON CLICKED";
  public static readonly REQUEST_HISTORIC_DATA_FORM_CANCEL_BUTTON_CLICKED = "CLAIM-REQUEST HISTORIC DATA FORM CANCEL BUTTON CLICKED";
  // Access Level API 
  public static readonly GET_USER_ACCESS_TYPE_API_SUCCESS = "GET USER ACCESS_TYPE API SUCCESS";
  public static readonly GET_USER_ACCESS_TYPE_API_ERROR = "GET USER ACCESS TYPE API ERROR";
  // Client, Plan, Group selected event
  public static readonly GET_SELECTED_CLIENT_ID = "GET SELECTED CLIENT ID";
  public static readonly GET_SELECTED_GROUP_ID = "GET SELECTED GROUP ID";
  public static readonly GET_SELECTED_PLAN_ID = "GET SELECTED PLAN ID";
  // Preferred medication list
  public static readonly GET_SELECTED_CLIENT_ID_FOR_PREFERRED_MEDICATION = "GET SELECTED CLIENT ID FOR PREFERRED MEDICATION";
  public static readonly GET_PREFERRED_MEDICATION_LIST_API_SUCCESS = "GET PREFERRED MEDICATION LIST API SUCCESS";
  public static readonly GET_PREFERRED_MEDICATION_LIST_API_ERROR = "GET PREFERRED MEDICATION LIST API ERROR";
  // Online eligibility 
  public static readonly GET_ONLINE_ELIGIBILITY_SEARCH_REQUEST = "GET ONLINE ELIGIBILITY SEARCH REQUEST";
  // Claim Search 
  public static readonly GET_CLAIM_SEARCH_REQUEST = "GET CLAIM SEARCH REQUEST";
  // Drug Pricing 
  public static readonly GET_DRUG_PRICING_REQUEST = "GET DRUG PRICING REQUEST";
  // Pharmacy Search
  public static readonly GET_PHARMACY_SEARCH_REQUEST = "GET PHARMACY SEARCH REQUEST";
  // Get audit log
  public static readonly GET_AUDIT_LOG_REQUEST = "GET AUDIT LOG REQUEST";
  // Save audit log
  public static readonly DRUG_PRICING_VIEW_OTHER_PHARMACY_CLICKED = "DRUG PRICING VIEW OTHER PHARMACY CLICKED";
  public static readonly PHARMACY_VIEW_OTHER_PHARMACY_CLICKED = "PHARMACY VIEW OTHER PHARMACY CLICKED";
  public static readonly PHARMACY_SEARCH_SET_DEFAULT_RADIUS = "PHARMACY SEARCH SET DEFAULT RADIUS";
  public static readonly DRUG_PRICING_SET_DEFAULT_RADIUS = "PHARMACY SEARCH SET DEFAULT RADIUS";
  public static readonly SAVE_AUDIT_LOG_API_SUCCESS = "SAVE AUDIT LOG API SUCCESS";
  public static readonly SAVE_AUDIT_LOG_API_ERROR = "SAVE AUDIT LOG API ERROR";
  public static readonly CAP_DEDUCTIBLE_MOOP_API_SUCCESS = 'CAP DEDUCTIBLE MOOP API SUCESS';
  public static readonly CAP_DEDUCTIBLE_MOOP_API_ERROR = 'CAP DEDUCTIBLE MOOP API ERROR';
  public static readonly PRINT_ID_CARD_API_SUCCESS = 'PRINT ID CARD API SUCESS';
  public static readonly PRINT_ID_CARD_API_ERROR = 'PRINT ID CARD API ERROR';

  public static readonly MAIL_ORDERS = 'MAIL ORDERS PAGE VIEWED';
  public static readonly MAIL_ORDER_API_SUCCESS = 'MAIL ORDER API SUCCESS';
  public static readonly MAIL_ORDER_API_ERROR = 'MAIL ORDER API ERROR';
  public static readonly MAIL_ORDER_API_REQUEST_PAYLOAD = 'MAIL ORDER API REQUEST PAYLOAD';
  public static readonly MAIL_ORDER_DETAIL_DIALOG_VIEWED = 'MAIL ORDER DETAIL DIALOG VIEWED';

  public static readonly PRESCRIPTIONS = 'PRESCRIPTIONS PAGE VIEWED';
  public static readonly PRESCRIPTIONS_API_SUCCESS = 'PRESCRIPTIONS API SUCCESS';
  public static readonly PRESCRIPTIONS_API_ERROR = 'PRESCRIPTIONS API ERROR';
  public static readonly PRESCRIPTIONS_API_REQUEST_PAYLOAD = 'PRESCRIPTIONS API REQUEST PAYLOAD';
  public static readonly PRESCRIPTIONS_DETAIL_DIALOG_VIEWED = 'PRESCRIPTIONS DETAIL DIALOG VIEWED';

  public static readonly CLINICAL_REVIEWS = 'CLINICAL REVIEWS PAGE VIEWED';
  public static readonly CLINICAL_REVIEWS_API_SUCCESS = 'CLINICAL REVIEWS API SUCCESS';
  public static readonly CLINICAL_REVIEWS_API_ERROR = 'CLINICAL REVIEWS API ERROR';
  public static readonly CLINICAL_REVIEWS_PDF_VIEWED = 'CLINICAL REVIEWS PDF VIEWED';
  public static readonly CLINICAL_REVIEWS_PDF_DOWNLOAD = 'CLINICAL REVIEWS PDF DOWNLOAD';
  public static readonly CLINICAL_REVIEWS_DOC_API_SUCCESS = 'CLINICAL REVIEWS DOC API SUCCESS';
  public static readonly CLINICAL_REVIEWS_DOC_API_ERROR = 'CLINICAL REVIEWS DOC API ERROR';

  public static readonly EOB = 'EXPLANATION OF BENEFITS (EOB) PAGE VIEWED';
  public static readonly EOB_API_SUCCESS = 'EXPLANATION OF BENEFITS (EOB) API SUCCESS';
  public static readonly EOB_API_ERROR = 'EXPLANATION OF BENEFITS (EOB) API ERROR';
  public static readonly EOB_PDF_VIEWED = 'EXPLANATION OF BENEFITS (EOB) PDF VIEWED';

  public static readonly PLANNED_OUTAGE_MSG_DISPLAYED = 'PLANNED OUTAGE MSG VIEWED';
  public static readonly PLANNED_OUTAGE_MSG_CLOSED = 'PLANNED OUTAGE POPUP CLOSED';
}
export class AppCenterAnalyticsAPIMsgs {
  public static readonly COMMON_INITIAL_MSG = 'Response text not updated';
  public static readonly SUCCESS_ADD_SHIPPING_ADDRESS =
    'Shipping address added successfully';
  public static readonly ERROR_ADD_SHIPPING_ADDRESS = 'Something went wrong';
  public static readonly SUCCESS_ADD_PAYMENT_METHOD =
    'Payment method added successfully';
  public static readonly ERROR_ADD_PAYMENT_METHOD = 'Something went wrong';
  public static readonly SUCCESS_UPDATE_PROFILE =
    'Profile updated successfully';
  public static readonly ERROR_UPDATE_PROFILE = 'Something went wrong';
  public static readonly SUCCESS_ORDER_PLACEMENT = 'Order placed successfully';
  public static readonly ERROR_ORDER_PLACEMENT = 'Something went wrong';
  public static readonly SUCCESS_NOTIFICATIONS_UPDATE =
    'Notifications updated successfully';
  public static readonly ERROR_NOTIFICATIONS_UPDATE = 'Something went wrong';
  public static readonly COMMON_ERROR_MSG = 'Something went wrong';
}
