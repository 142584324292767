import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  EligibilityMenu,
  IMainMenu,
  MenuItem,
} from '../../interfaces/menu.interface';
import { ACCESS_ROLES } from 'src/app/shared/constants/management-portal-access-roles';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private jsonUrl = 'assets/access_roles.json';
  public sidebarClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth < 766 ? true : false);

  constructor(private http: HttpClient) { }

  public getAccessRoles(accessLevel: string): IMainMenu[] {
    return ACCESS_ROLES[accessLevel];
  }

  public getEligibilityMenus(accessLevel: string) {
    const menus = ACCESS_ROLES[accessLevel];
    let benefitMenus = (menus || []).find(
      (x) => x.name === 'Benefit Tools'
    )?.menus;
    let eligibilityMenus = benefitMenus.find(
      (x) => x.key === 'online-eligibility'
    )?.subFeatures;
    return eligibilityMenus;
  }


}
