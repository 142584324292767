import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManagementRoutingModule } from './management-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../core/modules/material.module';
import { CoPayScheduleComponent } from './co-pay-schedule/co-pay-schedule.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AnnualCapComponent } from './annual-cap/annual-cap.component';
import { DeductibleComponent } from './deductible/deductible.component';
import { ExclusionsComponent } from './exclusions/exclusions.component';
import { PreferredMedicationListComponent } from './preferred-medication-list/preferred-medication-list.component';
import { OnlineEligibilityModule } from './online-eligibility/online-eligibility.module';
import { BenefitFormComponent } from './benefit-form/benefit-form.component';
import { MatInputModule } from '@angular/material/input';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { RequestIdCardComponent } from './online-eligibility/request-id-card/request-id-card.component';
import { ClaimSearchComponent } from './online-eligibility/claim-search/claim-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    DashboardComponent,
    CoPayScheduleComponent,
    SidebarComponent,
    AnnualCapComponent,
    DeductibleComponent,
    ExclusionsComponent,
    PreferredMedicationListComponent,
    BenefitFormComponent,
    AuditLogsComponent,
    RequestIdCardComponent,
    ClaimSearchComponent,
  ],
  imports: [
    CommonModule,
    ManagementRoutingModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    OnlineEligibilityModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatSortModule
  ],
})
export class ManagementModule {}
