import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateAddressInfoDialogComponent } from './update-address-info-dialog/update-address-info-dialog.component';
import { UpdateContactInfoDialogComponent } from './update-contact-info-dialog/update-contact-info-dialog.component';
import { FormGroup, Validators } from '@angular/forms';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { ErrorComponent } from 'src/app/core/components/error/error.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MemberDetails } from '../../../interfaces/member.interface';
import { map, switchMap } from 'rxjs/operators';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { AppConstants } from '../../../app.constants';
import { formatPhone } from 'src/app/util';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-update-contact-info',
  templateUrl: './update-contact-info.component.html',
  styleUrls: ['./update-contact-info.component.scss'],
})
export class UpdateContactInfoComponent implements OnInit {
  public selectedIndex: number = 0;
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public displayGender = AppConstants.DISPLAY_GENDER;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public phoneTypes = AppConstants.PHONE_LABELS;
  dataSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public queryParams;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  constructor(
    public router: Router,
    private matDialog: MatDialog,
    private readonly appInsightsService: AppInsightsService,
    private readonly eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.selectedMemberDetails$ = this.dataSaved.pipe(
      switchMap((res) => {
        return this.activatedRoute.queryParams.pipe(
          switchMap((res) => {
            this.queryParams = res;
            return this.eligibilityService.getMemberDetailsById(res).pipe(
              map((res) => {
                return (this.memberDetails = res[0]);
              })
            );
          })
        );
      })
    );
    this.appInsightsService.trackPageView(
      AppCenterAnalytics.UPDATE_CONTACT_INFO
    );
  }
  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public editAddress(edit: boolean = true): void {
    const dialogRef: MatDialogRef<UpdateAddressInfoDialogComponent> =
      this.matDialog.open(UpdateAddressInfoDialogComponent, {
        autoFocus: false,
        minWidth: window.innerWidth < 766 ? 'auto' : 600,
        data: {
          isEdit: edit,
          memberDetails: this.memberDetails,
        },
      });
    dialogRef.afterClosed().subscribe(() => {
      // console.log('closed');
    });
    dialogRef.componentInstance.dataSaved.subscribe((data: string) => {
      // console.log('Received data from dialog:', data);
      this.openStatusDialog();
      this.dataSaved.next(true);
    });
  }

  public checkIfContactCanBeAdded() {
    return (
      !this.getPhoneNumber('cellPhone') ||
      this.getPhoneNumber('cellPhone') === 'null' ||
      !this.getPhoneNumber('homePhone') ||
      this.getPhoneNumber('homePhone') === 'null' ||
      !this.getPhoneNumber('workPhone') ||
      this.getPhoneNumber('workPhone') === 'null'
    );
  }

  public getPhoneNumber(phoneType: string): string {
    return this.memberDetails.phones.filter((p) => p.type === phoneType)[0]
      .phoneNumber;
  }

  formatPhoneNumber(phone: string): string {
    return formatPhone(phone);
  }

  public openStatusDialog(): void {
    const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
      ErrorComponent,
      {
        width: '300px',
        data: {
          title: 'Success',
          message: 'Data saved successfully',
        },
      }
    );
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  public editContact(typeName: string, phone: any, edit: boolean = true): void {
    const dialogRef: MatDialogRef<UpdateContactInfoDialogComponent> =
      this.matDialog.open(UpdateContactInfoDialogComponent, {
        autoFocus: false,
        minWidth: window.innerWidth < 766 ? 'auto' : 600,
        data: {
          type: typeName,
          edit,
          memberDetails: this.memberDetails,
          phoneDetails: phone,
        },
      });
    dialogRef.afterClosed().subscribe(() => {
      // console.log('closed');
    });
    dialogRef.componentInstance.dataSaved.subscribe((data: string) => {
      // console.log('Received data from dialog:', data);
      this.openStatusDialog();
      this.dataSaved.next(true);
    });
  }
}
