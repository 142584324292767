import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailOrder } from 'src/app/interfaces/mail-order.interface';
import { PrescriptionFill } from 'src/app/interfaces/prescription.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';

@Component({
  selector: 'app-mail-order-details-dialog',
  templateUrl: './mail-order-details-dialog.component.html',
  styleUrls: ['./mail-order-details-dialog.component.scss']
})
export class MailOrderDetailsDialogComponent implements OnInit {
  public order:MailOrder<PrescriptionFill>;
  constructor(public dialogRef: MatDialogRef<MailOrderDetailsDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public orders: MailOrder<PrescriptionFill>,
    private readonly appInsightService: AppInsightsService) { }

  ngOnInit(): void {
    this.appInsightService.trackPageView(AppCenterAnalytics.MAIL_ORDER_DETAIL_DIALOG_VIEWED)
    this.order = this.orders;
  }
  public cancel(): void {
    this.dialogRef.close();
  }

}
