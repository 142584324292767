import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DemoClientService {
  constructor(private httpService: HttpService) {}

  public checkDemoClient(clientId: string): Observable<boolean> {
    let url = `/MockAPIResponse/GetIsDemoUser?ClientId=${clientId}`;
    return this.httpService.get<boolean>(url);
  }

  public set updateDemoClientFlag(demoClient: boolean) {
    localStorage.setItem('IsDemoClient', JSON.stringify(demoClient));
  }

  public get getDemoClientFlag(): boolean {
    return JSON.parse(localStorage.getItem('IsDemoClient'));
  }
}
