/**
 * @file sidebar.component.ts
 */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../../../app.constants';
import { ErrorManagerService } from '../../../core/services/error/error-manager/error-manager.service';
import { ErrorObject } from '../../../core/services/error/error.interface';
import { MainMenu } from '../../../interfaces/main-menu.interface';
import { SubMenu } from '../../../interfaces/sub-menu.interface';
import { SharedService } from '../../../services/shared/shared.service';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Output() reportIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() nextMenu: EventEmitter<string> = new EventEmitter<string>();
  isSideBarClosed = false;
  mainMenu: MainMenu[];
  subMenu: SubMenu[];
  subReportId: number;
  showSubMenus: boolean;
  reportId: number;
  showError: boolean;
  errorMsg = AppConstants.ERROR.MESSAGES;
  menuName: string;
  isSingleClient: boolean;
  showDocuments: boolean;
  showInvoices: boolean;
  isInvoice: boolean = false;
  constants = AppConstants;
  constructor(private readonly sharedService: SharedService, private readonly errorService: ErrorManagerService,
    private readonly router: Router, private readonly appInsightService: AppInsightsService) { }

  ngOnInit(): void {
    this.showSubMenus = false;
    this.showError = false;
    this.subMenu = [];
    this.mainMenu = [];
    this.isSingleClient = JSON.parse(sessionStorage.getItem(this.constants.LABELS.isSingleClient));
    const invoiceValue = JSON.parse(sessionStorage.getItem(this.constants.LABELS.isInvoice));
    const subGroupIdValue = sessionStorage.getItem(this.constants.LABELS.subGroupId);
    this.isInvoice = invoiceValue && subGroupIdValue === "null" ? true : false;
    this.getMenus();
  }

  /**
  * @description This method is used to make a api call to get the reports/menus and handle the response.
  * @returns void
  */
  private getMenus(): void {
    this.sharedService.getMenusAndSubMenus().subscribe((response: MainMenu[]) => {
      if (response.length > 0) {
        this.showError = false;
        this.mainMenu = response;
        this.menuName = this.mainMenu[0].reportName;
        this.clickMainMenu(this.mainMenu[0]);
      } else if (this.isInvoice) {
        this.clickedInvoices();
      } else {
        this.showErrorResponse();
      }
    });
  }

  /**
  * @description This function is used to return the string value for each report according to the reportId.
  * @returns string value for icons
  */
  public menuIcons(reportId: number): string {
    switch (reportId) {
      case this.constants.LABELS.one: return 'monthlyreport';
      case this.constants.LABELS.six: return 'eligibilitysummary';
      case this.constants.LABELS.seven: return 'eligibilitydetails';
      case this.constants.LABELS.eight: return 'covidanalytics';
      case this.constants.LABELS.thirteen: return 'claimsdata';
      case this.constants.LABELS.fourteen: return 'financialsummary';
      case this.constants.LABELS.fifteen: return 'keystatistics';
      case this.constants.LABELS.sixteen: return 'top20drugsnames';
      case this.constants.LABELS.twenty: return 'toputilizers';
      default: return ''
    }
  }

  /**
  * @description This method is called when a user clicks a particular report and open the sub-reports if available
  * @returns void
  */
  public clickMainMenu(menu: MainMenu): void {
    this.appInsightService.trackPageView(`${menu.reportName.toUpperCase()} ${AppCenterAnalytics.REPORT_VIEWED}`, { ReportName: menu.reportName, IsParentMenu: true });
    this.showDocuments = false;
    this.showInvoices = false;
    sessionStorage.setItem(this.constants.LABELS.clientLevel, menu.clientLevelOnly);
    if (this.menuName === menu.reportName && this.showSubMenus) {
      this.showSubMenus = false;
    } else if (menu.embedId === null && menu.subReports.length === 0) {
      this.reportId = menu.reportId;
      this.menuName = menu.reportName;
      this.showSubMenus = false;
    } else {
      this.activateReport(menu);
    }
  }

  /**
  * @description This method is called when user wants to view a sub-report and emit the value.
  * @returns void
  */
  public activateReport(menu: MainMenu): void {
    this.reportId = menu.reportId;
    if (menu.subReports.length > 0) {
      this.subMenu = menu.subReports;
      this.subReportId = menu.subReports[0].reportId;
    }
    this.menuName = menu.reportName;
    const id: string = menu.embedId === null ? menu.subReports[0].embedId : menu.embedId;
    this.showSubMenus = menu.embedId === null ? true : false;
    this.reportIdEmitter.emit(id);
  }
  /**
  * @description This method is called when a user clicks a particular sub-reports and emit the embedId
  * @returns void
  */
  public clickSubMenu(menu: SubMenu): void {
    if (menu.embedId !== null) {
      const clientId = sessionStorage.getItem(this.constants.LABELS.selectedId)
      this.appInsightService.trackPageView(`${menu.reportName.toUpperCase()} ${AppCenterAnalytics.REPORT_VIEWED}`, { ReportName: menu.reportName, IsParentMenu: false });
      sessionStorage.setItem(this.constants.LABELS.clientLevel, menu.clientLevelOnly);
      this.subReportId = menu.reportId;
      this.reportIdEmitter.emit(menu.embedId);
      event.stopPropagation();
    }
  }

  /**
  * @description This method is used to throw an error and show default message if the API call is not successful.
  * @returns void
  */
  public showErrorResponse(): void {
    const data: ErrorObject = { message: AppConstants.ERROR.MESSAGES.NO_REPORTS_DATA };
    this.errorService.throwError(data);
  }

  /**
  * This method is used to navigate back to client selection page.
  * @returns void
  */
  public navigateToHome() {
    sessionStorage.setItem(this.constants.LABELS.reportType, '');
    sessionStorage.setItem(this.constants.LABELS.selectedId, '');
    sessionStorage.setItem(this.constants.LABELS.clientName, '');
    this.router.navigate(['/main/home']);
  }
  /**
  * This method is used to navigate to my documents tab.
  * @returns void
  */
  public showOtherMenus(): void {
    this.menuName = this.constants.LABELS.documents;
    this.showSubMenus = false;
    this.showDocuments = true;
    this.showInvoices = false;
    this.nextMenu.emit(this.constants.LABELS.documents);
  }

  public showWhoWeArePage(): void {
    window.open('/whoWeAre', '_blank');
  }

  public clickedInvoices(): void {
    this.menuName = this.constants.LABELS.financialDocuments;
    this.showSubMenus = false;
    this.showInvoices = true;
    this.showDocuments = false;
    this.nextMenu.emit(this.constants.LABELS.financialDocuments);
  }
}

