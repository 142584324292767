import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../../app.constants';
import { Router } from '@angular/router';
import {
  clearPhoneFormat,
  clearSsnFormat,
  numericOnly as externalNumericOnly,
  onlyCharSingleSpace as externalOnlyCharSingleSpace,
  fromDateToUTCString,
  getErrorMessage,
} from 'src/app/util';
import { formatDate } from '../../../util';
import { AddMemberResponseDialogComponent } from './add-member-response-dialog/add-member-response-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, defer, of } from 'rxjs';
import { Clients } from '../../../interfaces/clients.interface';
import { SharedService } from '../../../services/shared/shared.service';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { GroupDetails, ILocationCodes } from '../../../interfaces/UserGroup.interface';
import { catchError, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { LoaderService } from '../../services/loader/loader.service';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import {
  ICclientFlags,
  ICreateMemberReq,
  MemberDetails,
} from '../../../interfaces/member.interface';
import { SaveAuditLogsRequestInterface } from 'src/app/interfaces/audit-logs.interface';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { RequestIDCardRequestInterface } from 'src/app/interfaces/request-card.interface';
import { DemoClientService } from 'src/app/core/services/demo-client/demo-client.service';
import { UserAccessLevel } from '../../../interfaces/user-access.interface';
import { ErrorComponent } from '../../../core/components/error/error.component';
import { ErrorManagerService, ErrorType } from '../../../core/services/error';
import { HttpErrorResponse } from '@angular/common/http';
import { GroupLevelAccessResponse } from '../../../interfaces/group-level-access.interface';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
})
export class AddMemberComponent implements OnInit, OnDestroy {
  public listOfClients$: Observable<Array<Clients>>;
  public listOfGroups$: Observable<Array<GroupDetails>>;
  public filteredGroups!: Observable<Array<GroupDetails>>
  public selectedClientSubject: BehaviorSubject<Clients> = new BehaviorSubject(
    null
  );
  public isLinear = true;
  public personalIfoFormGroup: FormGroup;
  public benefitFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public contactInfoFormGroup: FormGroup;
  public clientList: Clients[] = [];
  public groupList: GroupDetails[];
  public genderList: { key: string; name: string }[] = AppConstants.GENDER_LIST;
  public phoneTypes: { value: string; name: string }[] = [
    { value: '', name: 'Select Phone Type' },
    { value: 'homePhone', name: 'Home Phone' },
    { value: 'cellPhone', name: 'Cell Phone' },
    { value: 'workPhone', name: 'Work Phone' },
  ];
  public checkTouchForPersonalInfo = false;
  public checkTouchForBenefitInfo = false;
  public checkTouchForAddressInfo = false;
  public checkTouchForContactInfo = false;
  public maxDate = new Date();
  public states: { name: string; value: string }[] = AppConstants.STATES;
  public filteredStates!: Observable<Array<{ name: string; value: string }>>;
  public isAutoGenIdCard$: Observable<ICclientFlags>;
  public isAutoGenIdCard: boolean;
  public isLocationCodeRequired: boolean;
  public groupAccess: GroupLevelAccessResponse[];
  numericOnly = externalNumericOnly;
  charOnlySingleSpace = externalOnlyCharSingleSpace;
  public filteredClients!: Observable<Array<Clients>>;
  public clientFilterControl = new FormControl();
  public groupFilterControl = new FormControl();
  public stateFilterControl = new FormControl();
  public effectiveDateMaxDate = new Date('12/31/9999');

  newMember: Partial<MemberDetails> = {};
  selectedGroupName: string = '';
  selectedProductIndicator: string = '';
  requestIdCardSubscription: Subscription;
  clientLocationCode: ILocationCodes[];
  private subscription: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private readonly router: Router,
    private matDialog: MatDialog,
    private sharedService: SharedService,
    private benefitService: BenefitManagementService,
    private readonly appInsightService: AppInsightsService,
    private readonly loader: LoaderService,
    private readonly eligibilityService: EligibilityService,
    private auditLogsService: AuditLogsService,
    private authService: AuthService,
    private demoClientService: DemoClientService,
    private readonly errorManager: ErrorManagerService,
  ) { }

  ngOnInit() {
    this.appInsightService.trackPageView(AppCenterAnalytics.ADD_MEMBER);
    this.listOfClients$ = this.benefitService.getClientsListByAccess().pipe(map(res => {
      this.clientList = res;
      return res.filter((e) => e.olA_Access_Type !== UserAccessLevel.LEVEL_A && e.olA_Access_Type !== UserAccessLevel.LEVEL_E)
    }));

    // Filter clients based on search input
    this.filteredClients = this.clientFilterControl.valueChanges.pipe(
      startWith(''), // Initially show all clients
      switchMap(searchTerm => this.filterClients(searchTerm)) // Filter clients when search term changes
    );

    const requiredValidator = Validators.required;
    const emailValidator = Validators.pattern(
      AppConstants.EMAIL_VALIDATION_PATTERN
    );

    this.isAutoGenIdCard$ = this.selectedClientSubject.pipe(
      filter((client) => !!client), // Only proceed if client is truthy
      switchMap((client) => {
        this.loader.showLoader();
        // console.log('Client:', client);
        return this.eligibilityService.checkIdGeneration(client.clientId);
      }),
      tap((res) => {
        const cardIdControl = this.benefitFormGroup.get('cardId');
        // console.log('Response checkcard:', res);
        if (!res.autoGenerateCardId) {
          if (!cardIdControl) {
            this.benefitFormGroup.addControl(
              'cardId',
              this.formBuilder.control('', [
                requiredValidator,
                Validators.pattern(/^[a-zA-Z0-9]{1,20}$/),
              ])
            );
            this.isAutoGenIdCard = true;
          }
        } else if (cardIdControl) {
          this.benefitFormGroup.removeControl('cardId');
          this.isAutoGenIdCard = false;
        }
        if (res.locationCodeRequired) {
          this.benefitFormGroup.get('clientInternalMemberId').setValidators([Validators.required, Validators.maxLength(9)]);
          this.isLocationCodeRequired = true;
        } else {
          this.benefitFormGroup.get('clientInternalMemberId').clearValidators();
          this.isLocationCodeRequired = false;
        }
      }),
      catchError((err) => {
        console.log(err);
        const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
          ErrorComponent,
          {
            maxWidth: window.innerWidth < 766 ? 350 : 800,
            minWidth: window.innerWidth < 766 ? 350 : 700,
            data: {
              title: 'ERROR',
              message: 'Sorry, the client data could not be fetched. Please contact technical support.'
            },
          }
        );
        dialogRef.afterClosed().subscribe(() => this.navigateBackToMemberSearch());
        this.loader.hideLoader();
        return EMPTY;
      }))


    this.personalIfoFormGroup = this.formBuilder.group({
      firstName: ['', [requiredValidator, Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN), Validators.maxLength(35)]],
      middleName: ['', [Validators.maxLength(35), Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN)]],
      lastName: ['', [requiredValidator, Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN), Validators.maxLength(35)]],
      gender: ['', requiredValidator],
      dob: ['', requiredValidator],
      ssn: ['', [Validators.maxLength(9), Validators.minLength(9)]],
    });

    this.benefitFormGroup = this.formBuilder.group({
      client: ['', requiredValidator],
      group: ['', requiredValidator],
      // empiRxMemberId: ['', [requiredValidator, Validators.maxLength(20)]],
      coverageEffect: ['', requiredValidator],
      clientInternalMemberId: [''],
      coverageType: ['', requiredValidator],
      orderIdCard: [''],
    });

    this.addressFormGroup = this.formBuilder.group({
      addressLine1: ['', [requiredValidator, Validators.maxLength(35)]],
      addressLine2: ['', [Validators.maxLength(55)]],
      zipCode: [
        '',
        [
          requiredValidator,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
      city: [
        '',
        [
          requiredValidator,
          Validators.maxLength(35),
          // Validators.pattern(/^[a-zA-Z]+$/),
        ],
      ],
      state: ['', requiredValidator],
    });

    this.contactInfoFormGroup = this.formBuilder.group({
      phoneType: [''],
      phoneNumber: [
        { value: '', disabled: true },
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
      email: ['', [emailValidator]],
    });
    this.contactInfoFormGroup.controls.phoneType.valueChanges.subscribe(
      (value) => {
        if (value == null || value == '') {
          this.contactInfoFormGroup.get('phoneNumber')?.disable();
          this.contactInfoFormGroup.get('phoneNumber').setValidators(null);
          this.contactInfoFormGroup.controls.phoneNumber.setValue('');
          this.contactInfoFormGroup.get('phoneNumber').updateValueAndValidity();
        } else {
          this.contactInfoFormGroup.get('phoneNumber')?.enable();
          this.contactInfoFormGroup
            .get('phoneNumber')
            .setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10)]);
          this.contactInfoFormGroup.get('phoneNumber').updateValueAndValidity();
        }
      }
    );
    this.fetchStatesDropdownValue();
  }

  fetchStatesDropdownValue(): void {
    this.filteredStates = this.stateFilterControl.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => this.filterState(searchTerm)) // Filter group)
    )
  }

  fetchGroupDropdownValue(): void {
    this.filteredGroups = this.groupFilterControl.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => this.filterGroup(searchTerm))
    )
  }

  // Function to filter clients based on the search term
  private filterClients(searchTerm: string): Observable<Array<Clients>> {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return this.listOfClients$.pipe(
      map(clients =>
        clients.filter(client =>
          client.clientName.toLowerCase().includes(lowerCaseTerm) || client.clientId.includes(lowerCaseTerm)
        )
      )
    );
  }

  setOrderIdCardValidator() {
    this.benefitFormGroup
      .get('orderIdCard')
      .setValidators([Validators.required]);

    this.benefitFormGroup.get('orderIdCard').updateValueAndValidity();
  }

  removeOrderIdCardValidator() {
    this.benefitFormGroup.get('orderIdCard').clearValidators();
    this.benefitFormGroup.get('orderIdCard').updateValueAndValidity();
  }

  private getGroupsByClient(clientId: string): Observable<GroupDetails[]> {
    this.loader.showLoader$.next(true);
    return this.eligibilityService.getClientDetails(clientId).pipe(
      map((res) => {
        this.selectedProductIndicator = res[0].plans[0].productIndicator;
        if (this.selectedProductIndicator === 'Member Guide')
          this.setOrderIdCardValidator();
        else this.removeOrderIdCardValidator();
        let groupsArray = [];
        if (res[0].plans) {
          for (const plan of res[0].plans) {
            if (plan.groups) {
              groupsArray.push(...plan.groups);
            }
          }
        }
        this.loader.showLoader$.next(false);
        return groupsArray.filter((group) => !!group.groupId);
      }),
      catchError(() => {
        this.loader.showLoader$.next(false);
        return of([]);
      })
    );
  }

  public createMember() {
    this.checkTouchForContactInfo = true;
    const {
      personalIfoFormGroup,
      benefitFormGroup,
      addressFormGroup,
      contactInfoFormGroup,
    } = this;

    if (contactInfoFormGroup.valid) {
      // console.log(
      //   personalIfoFormGroup.value,
      //   benefitFormGroup.value,
      //   addressFormGroup.value,
      //   contactInfoFormGroup.value
      // );
      this.loader.showLoader();
      this.loader.showWaitingMessage$.next(AppConstants.ERROR.WAIT_MESSAGE_FOR_UPDATE_MEMBER);
      const reqObj: MemberDetails = {
        clientId: this.benefitFormGroup.value.client,
        firstName: this.personalIfoFormGroup.value.firstName.trim(),
        lastName: this.personalIfoFormGroup.value.lastName.trim(),
        middleName: this.personalIfoFormGroup.value.middleName.trim(),
        DOB: formatDate(new Date(this.personalIfoFormGroup.value.dob)),
        gender: this.personalIfoFormGroup.value.gender,
        clientInternalMemberId:
          this.benefitFormGroup.value.clientInternalMemberId,
        empirxMemberId: this.benefitFormGroup.value.empiRxMemberId,
        SSN: clearSsnFormat(this.personalIfoFormGroup.value.ssn),
        email: this.contactInfoFormGroup.value.email,
        addressLine1: this.addressFormGroup.value.addressLine1.trim(),
        addressLine2: this.addressFormGroup.value.addressLine2.trim(),
        zipCode: this.addressFormGroup.value.zipCode,
        city: this.addressFormGroup.value.city.trim(),
        state: this.addressFormGroup.value.state,
        effectiveDate: formatDate(
          new Date(this.benefitFormGroup.value.coverageEffect)
        ),
        isUpdated: false,
        groupNumber: this.benefitFormGroup.value.group,
        orderIdCard: this.benefitFormGroup.value.orderIdCard,
        coverageType: this.benefitFormGroup.value.coverageType,
        relationshipCode: '01',
      };
      if (this.isAutoGenIdCard) {
        reqObj.cardId = this.benefitFormGroup.value.cardId;
      }
      if (this.contactInfoFormGroup.value.phoneType !== '') {
        reqObj[this.contactInfoFormGroup.value.phoneType] = clearPhoneFormat(
          this.contactInfoFormGroup.value.phoneNumber
        )
      }
      //console.log('Request object:', reqObj);
      this.eligibilityService.addMember(reqObj).subscribe({
        next: (res) => {
          if (res) {
            this.loader.hideLoader();
            this.loader.showWaitingMessage$.next(null);
            res.PhoneType = this.contactInfoFormGroup.value.phoneType;
            res.firstName = this.personalIfoFormGroup.value.firstName;
            res.dateOfBirth = formatDate(
              new Date(this.personalIfoFormGroup.value.dob)
            );
            res.lastName = this.personalIfoFormGroup.value.lastName;
            res.coverageType = this.benefitFormGroup.value.coverageType;
            res[res.PhoneType] = this.contactInfoFormGroup.value.phoneNumber;
            res.gender = this.personalIfoFormGroup.value.gender;
            res.startDate = formatDate(
              new Date(this.benefitFormGroup.value.coverageEffect)
            );
            res.patientNum = res.patientNum
              ? res.patientNum
              : this.benefitFormGroup.value.cardId
                ? this.benefitFormGroup.value.cardId
                : 'TEST_CARD_ID';
            res.email = this.contactInfoFormGroup.value.email;
            res.addressLine1 = this.addressFormGroup.value.addressLine1;
            res.addressLine2 = this.addressFormGroup.value.addressLine2;
            res.zipCode = this.addressFormGroup.value.zipCode;
            res.city = this.addressFormGroup.value.city;
            res.state = this.addressFormGroup.value.state;
            res.personCode = res.personCode ? res.personCode : '01';
            res.relationshipCode = '1';
            const dialogRef: MatDialogRef<AddMemberResponseDialogComponent> =
              this.matDialog.open(AddMemberResponseDialogComponent, {
                autoFocus: false,
                maxWidth: window.innerWidth < 766 ? 350 : 800,
                minWidth: window.innerWidth < 766 ? 350 : 700,
                data: res,
              });

            dialogRef.afterClosed().subscribe(() => {
              // console.log('closed');
              this.navigateBackToMemberSearch();
            });
            this.newMember = res;
          }
        },
        error: (err) => {
          this.createAuditLog(false, err, reqObj);
          const message = getErrorMessage(err);
          this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: message,
            component: 'Add Member',
            title: '',
          });
          this.loader.hideLoader();
          this.loader.showWaitingMessage$.next(null);
        },
        complete: () => {
          // console.log('New Member:', this.newMember);
          this.createAuditLog(true);
          if (this.benefitFormGroup.get('orderIdCard').value === '1') {
            const reqBodyCreateCard: RequestIDCardRequestInterface = {
              patientIds: [+this.newMember.patientId],
              cardRequestDate: formatDate(new Date()),
              cardsRequested: 1,
            };
            this.requestIDCard(reqBodyCreateCard);
          }
        },
      });
    }
  }

  createAuditLog(success: boolean, err?: HttpErrorResponse, memberDetails?: MemberDetails) {
    if (memberDetails) {
      this.newMember = memberDetails;
      this.newMember.dateOfBirth = memberDetails.DOB.toString();
    }
    // console.log('Request Body create audit log:', reqBody);
    const reqBody: SaveAuditLogsRequestInterface = {
      clientId: this.benefitFormGroup.value.client,
      cardId: this.newMember.patientNum ? this.newMember.patientNum : memberDetails.cardId,
      personCd: this.newMember.personCode,
      firstName: this.newMember.firstName,
      lastName: this.newMember.lastName,
      emailId:
        this.newMember.email &&
          this.newMember.email.toLowerCase() !== 'null'
          ? this.newMember.email
          : this.authService.userEmail,
      birthDt: this.newMember.dateOfBirth,
      groupNo: this.newMember.groupNum || this.newMember.groupNumber,
      groupName: this.selectedGroupName,
      changes:
        AppConstants.AUDIT_LOGS.addMember.action.addNewMember.change,
      comments:
        AppConstants.AUDIT_LOGS.addMember.action.addNewMember.comments,
      addedBy: this.authService.userEmail,
      isSuccess: success,
    };
    if (!success) {
      const message = err?.error?.Message ? err.error.Message : JSON.stringify(err);
      reqBody.httpStatusCode = err.status,
        reqBody.errorMsg = message
    }
    this.auditLogsService.saveAuditLog(reqBody).subscribe({
      next: (res) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_SUCCESS, { Response: res });
      },
      error: (err) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_ERROR, { Response: err });
      },
      complete: () => { },
    });
  }

  requestIDCard(reqBody: RequestIDCardRequestInterface) {
    this.requestIdCardSubscription = this.eligibilityService
      .requestCard(reqBody)
      .subscribe({
        next: (res) => {
          // console.log('Response on request card:', res);
        },
        error: (err) => {
          // console.log('Error on request card:', err);
        },
        complete: () => { },
      });
  }

  isDemoClient(clientId: string) {
    this.loader.showLoader();
    this.demoClientService.checkDemoClient(clientId).subscribe({
      next: (res) => {
        // console.log('Response on isDemoClient:', res);
        this.demoClientService.updateDemoClientFlag = res;
      },
      error: (err) => {
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  public onClientSelection(event) {
    this.clientLocationCode = null;
    this.benefitFormGroup.controls.clientInternalMemberId.reset();
    const client = this.clientList.filter((e) => e.clientId === event.value)[0];
    this.appInsightService.trackPageView(AppCenterAnalytics.CLIENT_SELECTED);
    this.isDemoClient(client.clientId);
    this.benefitFormGroup.controls.group.reset();
    this.selectedClientSubject.next(client);
    if (client.groupLevelFlag) {
      this.sharedService.getUserGroupsAccess({ emailId: this.authService.userEmail, clientId: client.clientId }).subscribe((res) => {
        this.groupAccess = res;
        this.selectedClientSubject.pipe(
          switchMap((client) => {
            return this.getGroupsByClient(client.clientId);
          })
        ).pipe(switchMap(res => {
          this.groupList = [];
          res.forEach((g => {
            const grpAccess = this.groupAccess.find((grp) => grp.groupId === g.groupId);
            if (grpAccess) {
              if (grpAccess.benefitEligibilityAccessType !== UserAccessLevel.LEVEL_A && grpAccess.benefitEligibilityAccessType !== UserAccessLevel.LEVEL_E)
                this.groupList.push(g);
            } else {
              this.groupList.push(g);
            }
          }))
          return of(this.groupList);
        })).subscribe((res) => {
          this.listOfGroups$ = of(res);
          this.fetchGroupDropdownValue()
        });
      })
    } else {
      this.selectedClientSubject.pipe(
        switchMap((client) => {
          return this.getGroupsByClient(client.clientId);
        })
      ).pipe(switchMap(res => {
        this.groupList = res;
        return of(this.groupList);
      })).subscribe((res) => {
        this.listOfGroups$ = of(res);
        this.fetchGroupDropdownValue()
      });
    }
  }


  public onGroupSelection(event) {
    this.clientLocationCode = null;
    this.benefitFormGroup.controls.clientInternalMemberId.reset();
    const group = this.groupList.filter((g) => g.groupId === event.value)[0];
    this.selectedGroupName = group.groupName;
    this.clientLocationCode = group.locationCodes;
  }
  // Function to filter groups based on search string
  private filterGroup(searchTerm: string): Observable<Array<GroupDetails>> {
    const lowerCaseTerm = searchTerm.trim().toLowerCase();
  
    return this.listOfGroups$.pipe(
      map(groups =>
        groups.filter(group =>
          group.groupName.toLowerCase().includes(lowerCaseTerm) ||
          group.groupId.trim().toLowerCase().includes(lowerCaseTerm) // Case-insensitive and trimmed
        )
      )
    );
  }

  private filterState(searchTerm: string): Observable<Array<{ name: string; value: string }>> {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return of(this.states.filter(s =>
      s.name.toLowerCase().includes(lowerCaseTerm)
    ))
  }

  public cancel(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.forEach((s) => s.unsubscribe());
  }
}
