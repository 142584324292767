/**
 * @file report-selection.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Clients } from '../../interfaces/clients.interface';
import { CheckboxEvent } from '../../interfaces/checkbox-event.interface';
import { SharedService } from '../../services/shared/shared.service';
import { ClientsResponse } from '../../interfaces/clients-response.interface';
import { AppConstants } from '../../app.constants';
import { ErrorObject } from '../../core/services/error/error.interface';
import { ErrorManagerService } from '../../core/services/error';
import { LogoutModalComponent } from '../../shared/components/logout-modal/logout-modal.component';
import { DiversifiedClients } from 'src/app/interfaces/diversified-clients.interface';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';

@Component({
   selector: 'app-report-selection',
   templateUrl: './report-selection.component.html',
   styleUrls: ['./report-selection.component.scss']
})
export class ReportSelectionComponent implements OnInit {
   isCombinedReport: boolean;
   isReportSelected: boolean;
   selectedClients: string;
   listOfClients: Clients[];
   listOfParentClients: Clients[];
   showError: boolean;
   isLoading: boolean;
   constants = AppConstants;
   userName: string;
   isSingleClient: boolean;
   reportType: string;
   clientName: string;
   showDiversifiedClients: boolean;
   diversifiedClientList: DiversifiedClients[] = [];
   subGroupId: string = null;
   isInvoices: boolean = false;
   public showBackIcon: boolean = false;

   constructor(private readonly sharedService: SharedService, private readonly router: Router,
      private readonly errorService: ErrorManagerService, public readonly dialog: MatDialog,
      private readonly appInsightService: AppInsightsService) { }

   ngOnInit(): void {
      this.isCombinedReport = this.isReportSelected = false;
      this.showError = false;
      this.isSingleClient = false;
      this.showDiversifiedClients = false;
      this.listOfClients = [];
      this.listOfParentClients = [];
      this.selectedClients = null;
      // this.sharedService.setEmail();
      const hasReportsAccess = localStorage.getItem(AppConstants.LABELS.hasReportsAccess) === 'YES';
      const hasManagementAccess = localStorage.getItem(AppConstants.LABELS.hasManagementAccess) === 'YES';
      this.checkNavigation();
      if (hasReportsAccess && hasManagementAccess) {
         this.showBackIcon = true;
      }
   }

   /**
   * @description This method is used to make api call to get the clients and handle the response. 
   * @returns void
   */

   private checkNavigation(): void {
      this.isLoading = true;
      if (this.sharedService.clientResponse === null) {
         this.sharedService.getClients().subscribe((response: ClientsResponse) => {
            if (response) {
               this.conditions(response);
               this.sharedService.clientResponse = response;
               this.appInsightService.trackPageView(AppCenterAnalytics.REPORT_SELECTION_VIEWED);
            } else {
               this.showErrorResponse();
            }
         });
      } else {
         this.conditions(this.sharedService.clientResponse);
         this.appInsightService.trackPageView(AppCenterAnalytics.REPORT_SELECTION_VIEWED);
      }

   }

   /**
   * @description This method is used to check different conditions as per the response returned as per the user
   * @returns void
   */
   public conditions(response: ClientsResponse): void {
      this.isLoading = false;
      this.userName = response.userName;
      sessionStorage.setItem(this.constants.LABELS.userName, response.userName);
      // if (!response.showClientSelection) {
      //    this.defaultOneClientId(response.listOfClients, false);
      // } else if (response.listOfClients.length === 0 && response.listOfParentClients.length === 1) {
      //    this.defaultOneClientId(response.listOfParentClients, true);
      // } else
      if (response.listOfClients.length === 0 && response.listOfParentClients.length === 0) {
         this.showError = true;
      } else {
         this.listOfClients = response.listOfClients;
         this.listOfParentClients = response.listOfParentClients;
         this.diversifiedClientList = response.listOfDiversifiedClients;
         this.resetLists();
      }
   }

   /**
   * @description This method is called when there is one parent or individual client id and navigate to dashboard.
   * @returns void
   */
   public defaultOneClientId(list: Clients[], isCombinedReport: boolean) {
      this.listOfClients = isCombinedReport ? [] : list;
      this.listOfParentClients = isCombinedReport ? list : [];
      this.reportType = list[0].reportType;
      this.isSingleClient = true;
      this.clientName = list[0].clientName;
      this.isInvoices = list[0].accessFinancialInvoice;
      this.accessStorage(isCombinedReport, list[0].clientId);
      this.router.navigate(['/main/dashboards']);
   }

   /**
   * @description This method is called if combined report is selected and call selectReport method
   * @returns void
   */
   public clickedCombinedReport(event: CheckboxEvent, parent: Clients): void {
      this.showDiversifiedClients = false;
      this.selectReport(event, parent);
      this.isCombinedReport = true;
   }

   /**
   * @description This method is called if individual report is selected and call selectReport method.
   * @returns void
   */
   public checkedIndividualClientReport(event: CheckboxEvent, client: Clients): void {
      this.showDiversifiedClients = client.clientName === 'Diversified' && event.checked ? true : false;
      this.selectReport(event, client);
      this.isCombinedReport = false;
   }

   /**
   * @description This method is called if individual report or combined report is selected.
   * @returns void
   */
   public selectReport(event: CheckboxEvent, client: Clients): void {
      if (event.checked) {
         this.appInsightService.trackPageView(AppCenterAnalytics.CLIENT_SELECTED);
         this.resetLists();
         client.checked = true;
         this.selectedClients = client.clientId;
      } else {
         client.checked = false;
         this.selectedClients = null;
      }
      this.isReportSelected = this.selectedClients !== null ? true : false;
      this.reportType = client.reportType;
      this.isInvoices = client.accessFinancialInvoice;
      this.clientName = client.clientName;
   }

   /**
   * @description This method is used to save the selected reports and navigate to dashboard
   * @returns void
   */
   public saveReportDetails(): void {
      this.isSingleClient = false;
      this.accessStorage(this.isCombinedReport, this.selectedClients);
      if (this.isReportSelected) {
         this.appInsightService.trackPageView(AppCenterAnalytics.CLIENT_SELECTED);
         this.router.navigate(['/main/dashboards']);
      }
   }

   /**
   * @description This method is used to setItem in the session storage.
   * @returns void
   */
   private accessStorage(isCombinedReport, clientId): void {
      sessionStorage.setItem(this.constants.LABELS.reportType, this.reportType);
      sessionStorage.setItem(this.constants.LABELS.isSingleClient, JSON.stringify(this.isSingleClient));
      sessionStorage.setItem(this.constants.LABELS.isParentId, JSON.stringify(isCombinedReport));
      sessionStorage.setItem(this.constants.LABELS.selectedId, clientId);
      sessionStorage.setItem(this.constants.LABELS.clientName, this.clientName);
      sessionStorage.setItem(this.constants.LABELS.subGroupId, this.subGroupId);
      sessionStorage.setItem(this.constants.LABELS.isInvoice, JSON.stringify(this.isInvoices));
   }

   /**
   * @description This method is used to open the logout modal.
   * @returns void
   */
   public openDialog(): void {
      this.dialog.open(LogoutModalComponent, {
         autoFocus: false, minWidth: 400, disableClose: true
      });
   }

   /**
   * @description This function is used to disable the continue button if no clients are selected
   * @returns boolean
   */
   public isDisabledButton(): boolean {
      return this.selectedClients === null ? true : false;
   }

   /**
   * @description This method is used to reset the clients and parentClient lists.
   * @returns void
   */
   public resetLists(): void {
      this.listOfClients.forEach((item: Clients) => item.checked = false);
      this.listOfParentClients.forEach((item: Clients) => item.checked = false);
      this.diversifiedClientList.forEach((item: DiversifiedClients) => item.checked = false);
   }

   /**
   * @description This method is used to throw an error and show default message if the API call is not successful.
   * @returns void
   */
   public showErrorResponse(): void {
      const data: ErrorObject = { message: this.constants.ERROR.MESSAGES.GENERIC };
      this.errorService.throwError(data);
   }

   checkedDiversifiedClient(event: CheckboxEvent, client: DiversifiedClients): void {
      if (event.checked) {
         this.resetLists();
         client.checked = true;
         this.selectedClients = client.clientId;
         this.subGroupId = client.subGroupId;
         this.clientName = client.subGroupName;
      } else {
         client.checked = false;
         this.selectedClients = null;
         this.subGroupId = null;
         this.clientName = null;
         this.showDiversifiedClients = false;
      }
      this.isCombinedReport = false;
      this.isReportSelected = this.selectedClients !== null ? true : false;
      //this.reportType = null;
   }

   public backToAppSelection(): void {
      this.router.navigateByUrl('/app');
   }
}
