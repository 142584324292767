export enum EligibilityMenus {
  MemberSearch = 'MemberSearch',
  AddNewDependent = 'AddNewDependent',
  AddNewMember = 'AddNewMember',
  UpdateMember = 'UpdateMember',
  UpdateCoverage = 'UpdateCoverage',
  UpdateContactInfo = 'UpdateContactInfo',
  RequestIdCard = 'RequestIdCard',
  PrintDigitalIdCard = 'PrintDigitalIdCard',
  CapDeductibleMOOP = 'CapDeductibleMOOP',
  ClaimSearch = 'ClaimSearch',
  ClaimDetails = 'ClaimDetails',
  DrugPricing = 'DrugPricing',
  PharmacySearch = 'PharmacySearch',
  MailOrders = 'MailOrders',
  Prescriptions = 'Prescriptions',
  ClinicalReviews = 'ClinicalReviews',
  Eob = 'Eob'
}

export const ELIGIBILITY_MENUS = {
  MEMBER_SEARCH: {
    key: EligibilityMenus.MemberSearch,
    displayName: 'Member Search',
    route: '/management/online-eligibility/add-new-member',
    show: true,
  },
  ADD_NEW_MEMBER: {
    key: EligibilityMenus.AddNewMember,
    displayName: 'Add New Member',
    route: '/management/online-eligibility/add-new-member',
    show: true,
  },
  ADD_NEW_DEPENDENT: {
    key: EligibilityMenus.AddNewDependent,
    displayName: 'Add New Dependent',
    route: '/management/online-eligibility/add-new-dependent',
    show: true,
  },
  UPDATE_MEMBER: {
    key: EligibilityMenus.UpdateMember,
    displayName: 'Update Member',
    route: '/management/online-eligibility/update-member',
    show: true,
  },
  UPDATE_COVERAGE: {
    key: EligibilityMenus.UpdateCoverage,
    displayName: 'Update Coverage',
    route: '/management/online-eligibility/update-coverage',
    show: true,
  },
  UPDATE_CONTACT_INFO: {
    key: EligibilityMenus.UpdateContactInfo,
    displayName: 'Add/Update Contact Info',
    route: '/management/online-eligibility/update-contact-info',
    show: true,
  },
  REQUEST_ID_CARD: {
    key: EligibilityMenus.RequestIdCard,
    displayName: 'Request ID Card',
    route: '/management/online-eligibility/request-id-card',
    show: true,
  },
  PRINT_DIGITAL_ID_CARD: {
    key: EligibilityMenus.PrintDigitalIdCard,
    displayName: 'Print Digital ID Card',
    route: '/management/online-eligibility/print-id-card',
    show: true,
  },
  CAP_DEDUCTIBLE_MOOP: {
    key: EligibilityMenus.CapDeductibleMOOP,
    displayName: 'Cap, Deductible and MOOP',
    route: '/management/online-eligibility/cap-deductible-moop',
    show: true,
  },
  CLAIM_SEARCH: {
    key: EligibilityMenus.ClaimSearch,
    displayName: 'Claim Search',
    route: '/management/online-eligibility/claim-search',
    show: true,
  },
  CLAIM_SEARCH_ADDITIONAL_DETAILS: {
    key: EligibilityMenus.ClaimSearch,
    displayName: 'Claim Additional Details',
    route: '',
    show: true,
  },
  DRUG_PRICING: {
    key: EligibilityMenus.DrugPricing,
    displayName: 'Drug Pricing',
    route: '/management/online-eligibility/drug-pricing',
    show: true,
  },
  PHARMACY_SEARCH: {
    key: EligibilityMenus.PharmacySearch,
    displayName: 'Pharmacy Search',
    route: '/management/online-eligibility/pharmacy-search',
    show: true,
  },
  MAIL_ORDERS: {
    key: EligibilityMenus.MailOrders,
    displayName: 'Mail Orders',
    route: '/management/online-eligibility/mail-orders',
    show: true,
  },
  PRESCRIPTIONS: {
    key: EligibilityMenus.Prescriptions,
    displayName: 'Prescriptions',
    route: '/management/online-eligibility/prescriptions',
    show: true,
  },
  CLINICAL_REVIEWS: {
    key: EligibilityMenus.ClinicalReviews,
    displayName: 'Clinical Reviews',
    route: '/management/online-eligibility/clinical-reviews',
    show: true,
  },
  EOB: {
    key: EligibilityMenus.Eob,
    displayName: 'Explanation of Benefits (EOB)',
    route: '/management/online-eligibility/eob',
    show: true,
  },
};
