import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent implements OnInit {

  constructor(private readonly authService: AuthService, private readonly appInsightService: AppInsightsService) { }

  ngOnInit(): void {
  }

  logout(): void {
    const currentTime = new Date();
    const login_time = new Date(localStorage.getItem('login_date_time'));
    var dif = Math.abs((currentTime.getTime() - login_time.getTime()) / 1000);
    const sec = parseInt(dif.toString());
    const hours = Math.floor(sec / 3600); // get hours
    const minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    const seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds

    (minutes === 0) ?
      this.appInsightService.trackPageView(AppCenterAnalytics.LENGTH_SESSION_USER_ACTIVITY, { UserActivitySessionLength: `${minutes} minutes` })
      :
      this.appInsightService.trackPageView(AppCenterAnalytics.LENGTH_SESSION_USER_ACTIVITY, { UserActivitySessionLength: `${seconds} seconds` });
    this.authService.logout();
  }
}
