/**
 * @file auth.guard.ts
 */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserAccess, UserAccessLevel } from 'src/app/interfaces/user-access.interface';
import { AppConstants } from 'src/app/app.constants';
import { ErrorManagerService, ErrorObject } from '../../services/error';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeDialogComponent } from 'src/app/shared/components/downtime-dialog/downtime-dialog.component';
import { MaintenanceService } from '../../services/maintenance/maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessGuard implements CanActivate {
  private static readonly className = 'UserAccessGuard';
  constructor(
    private readonly router: Router,
    private readonly sharedService: SharedService,
    private readonly errorManager: ErrorManagerService,
    private readonly appInsightsService: AppInsightsService,
    public readonly dialog: MatDialog,
    public maintenance: MaintenanceService
  ) { }

  /**
   * Checks if the user has access to reporting portal or Management Portal or both.
   * @param route 
   * @param state 
   * @returns 
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getAccess().pipe(map((res) => {
      this.appInsightsService.trackPageView(AppCenterAnalytics.GET_USER_ACCESS_TYPE_API_SUCCESS, { Response: res });
      if (!res.isOLAUser && !res.isReportsUser) {
        // if user doesn't have access to any of the module, show error message
        const error: ErrorObject = { message: AppConstants.ERROR.MESSAGES.NO_ACCESS, component: UserAccessGuard.className, };
        this.errorManager.throwError(error);
        return false;
      }

      // If maintenance dialog is not already displayed, check if maintenance is enabled from wordpress and display dialog
      !this.maintenance.isOutageDialogDisplayed ? this.showMaintenanceMessage() : null;

      sessionStorage.setItem(AppConstants.LABELS.userName, res.userName);

      localStorage.setItem(AppConstants.LABELS.hasReportsAccess, res.isReportsUser ? 'YES' : 'NO');
      localStorage.setItem(AppConstants.LABELS.hasManagementAccess, res.isOLAUser ? 'YES' : 'NO');

      if (res.isOLAUser) {
        this.sharedService.updateAccessLevel(res.olaUserType);
      }

      if (res.isOLAUser && res.isReportsUser) {
        // if user has both access, redirect to module selection screen. either reporting or management.
        return true;
      } else {
        // if user has access only to reporting module, redirect to client selection page
        if (res.isReportsUser) {
          this.router.navigateByUrl('/main');
        }

        // if user has access only to management module, redirect to management dashboard
        if (res.isOLAUser) {
          return true;
        }
        return false;
      }
    }, (error) => {
      this.appInsightsService.trackPageView(AppCenterAnalytics.GET_USER_ACCESS_TYPE_API_ERROR, { Response: error });
    }));
  }

  public getAccess(): Observable<UserAccess> {
    return this.sharedService.getUserAccessRoles();
  }

  /**
   * Displays the scheduled maintenance dialog based on the configurations returned from the WordPress API
   */
  private showMaintenanceMessage(): void {
    this.maintenance.showOutagePopup().pipe(take(1), switchMap((res: { show: boolean, title: string ,message: string, is_complete_shutdown: boolean }) => {
      if (res.show) {
        this.appInsightsService.trackPageView(AppCenterAnalytics.PLANNED_OUTAGE_MSG_DISPLAYED, {
          data: {
            title: res.title,
            displayed_message: res.message,
            isCompleteShutdown: res.is_complete_shutdown
          }
        });
        const dialogRef = this.dialog.open(DowntimeDialogComponent, {
          disableClose: true,
          data: { message: res.message, title: res.title, outageFlag: res.is_complete_shutdown },
          panelClass: 'downtime-dialog-panel'
        });
        this.maintenance.isOutageDialogDisplayed = true;
        return dialogRef.afterClosed().pipe(take(1), tap(() => {
          this.appInsightsService.trackPageView(AppCenterAnalytics.PLANNED_OUTAGE_MSG_CLOSED);
        }));
      }
      return of(false);
    }), catchError(() => {
      return of(false);
    })).subscribe();
  }
}

