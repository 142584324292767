<div class="page-wrapper">
  <!-- Page Header -->
  <div class="page-header">
    <h2>Deductible</h2>
    <p>
      To view a Deductible, select a client and group from the dropdown boxes
    </p>
  </div>

  <!-- Form Section -->
  <app-benefit-form
    (onClientSelect)="onFieldsSelect($event)"
    (benefitList)="filterList($event)"
    (noDataForClient)="NoDataHandling($event)"
    (clearDataEvent)="clearData($event)"
  ></app-benefit-form>

  <!-- Details Information Section -->
  <div *ngIf="!isNoData">
    <div
      class="detail-information info-margin"
      *ngIf="deductibles$ | async as annualCapList"
    >
      <h2>Details</h2>
      <div fxLayout="row">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef>Frequency</th>
            <td mat-cell *matCellDef="let element">
              {{ element.frequency | titlecase }}
            </td>
          </ng-container>

          <ng-container matColumnDef="familyTotal">
            <th mat-header-cell *matHeaderCellDef>Family Total</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.familyTotal !== null
                  ? (element.familyTotal | currency : "USD")
                  : "n/a"
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="familyMember">
            <th mat-header-cell *matHeaderCellDef>Family Member</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.familyMember !== null
                  ? (element.familyMember | currency : "USD")
                  : "n/a"
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="single">
            <th mat-header-cell *matHeaderCellDef>Single</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.single !== null
                  ? (element.single | currency : "USD")
                  : "n/a"
              }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr tabindex="0" class="mat-row no-data-row" *matNoDataRow>
            <td class="mat-cell" colspan="9" role="status">No Data Found</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="isNoData">No data is available for selected client.</div>
  <div *ngIf="!(deductibles$ | async) && groupChosen">No Data Found.</div>
</div>
