import { MapsAPILoader } from '@agm/core';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConstants } from 'src/app/app.constants';
import { PharmacyHours } from 'src/app/interfaces/pharmacy-hours.interface';
@Component({
  selector: 'app-drug-details',
  templateUrl: './drug-details.component.html',
  styleUrls: ['./drug-details.component.scss'],
})
export class DrugDetailsComponent implements OnInit {
  isLoading = false;
  lat = 0;
  lng = 0;
  placeId!: string;
  days = AppConstants.DAYS;
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly dialogRef: MatDialogRef<DrugDetailsComponent>,
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getCoordinates();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getDay(day: string): string {
    const val: string[] = this.data.drugPricing.pharmacy.hours[
      day as keyof PharmacyHours
    ] as string[];
    if (!val[0] || !val[1]) {
      return '-';
    }

    const str1 = (+val[0].split(':')[0] % 12).toString() || '12';
    const str2 = (+val[1].split(':')[0] % 12).toString() || '12';
    return str1 + ':00AM' + ' - ' + str2 + ':00PM';
  }
  getCoordinates(): void {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      this.isLoading = true;
      geocoder.geocode(
        {
          address: `${this.data.drugPricing.pharmacy.name}+${this.data.drugPricing.pharmacy.address.zipCode}+${this.data.drugPricing.pharmacy.address.city}+${this.data.drugPricing.pharmacy.address.state},+${this.data.drugPricing.pharmacy.address.addressLine1}+${this.data.drugPricing.pharmacy.address.addressLine2}`,
        },
        (
          results: google.maps.GeocoderResult[],
          status: google.maps.GeocoderStatus
        ) => {
          if (results && results.length > 0) {
            this.placeId = results[0].place_id;
            this.lat = results[0].geometry.location.lat();
            this.lng = results[0].geometry.location.lng();
            // console.log('Results:', results);
            // console.log('Lat:', this.lat, 'Lng:', this.lng);
            this.cdRef.detectChanges();
          }
          this.isLoading = false;
        }
      );
    });
  }
  public openMap(): void {
    window.open(
      `https://www.google.com/maps/place/?q=place_id:${this.placeId}`
    );
  }
  public cancel(): void {
    this.dialogRef.close();
  }
}
