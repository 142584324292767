import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { MemberDetails } from '../../../interfaces/member.interface';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { BenefitManagementService } from '../../../core/services/benefit-management/benefit-management.service';
import { AppConstants, OrderStatus } from '../../../app.constants';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AppInsightsService } from '../../../services/app-insights/app-insights.service';
import { AppCenterAnalytics } from '../../../services/app-insights/app-insights';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MailOrderDetailsDialogComponent } from './mail-order-details-dialog/mail-order-details-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MailOrderStatus } from 'src/app/interfaces/enum';
import { formatDate } from 'src/app/util';
import { SharedService } from 'src/app/services/shared/shared.service';
import { LoaderService } from '../../services/loader/loader.service';
import { MailOrder } from 'src/app/interfaces/mail-order.interface';
import { PrescriptionFill } from 'src/app/interfaces/prescription.interface';

@Component({
  selector: 'app-mail-orders',
  templateUrl: './mail-orders.component.html',
  styleUrls: ['./mail-orders.component.scss']
})
export class MailOrdersComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public maxDate = new Date();
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public migratedFlag: string;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public mailOrdersSearchFormGroup: FormGroup;
  public isLoading: boolean;
  public mailOrders: MailOrder<PrescriptionFill>[];
  public userAccessLevel: string;
  public totalLength!: number;
  public searchList = '';
  public displayedColumns: string[] = ['dispensedDrugName', 'orderNumber', 'rxNumber', 'quantity', 'daysSupply', 'receivedDate', 'orderStatus'];
  public status = OrderStatus.All;
  public dataSource: MatTableDataSource<MailOrder<PrescriptionFill>> = new MatTableDataSource([] as MailOrder<PrescriptionFill>[]);
  public statusList = [
    MailOrderStatus.IN_PROGRESS.toString(),
    MailOrderStatus.SHIPPED.toString(),
    MailOrderStatus.WAITING_FOR_PAYMENT.toString(),
    MailOrderStatus.READY_FOR_PICK_UP.toString(),
    MailOrderStatus.PICKED_UP.toString(),
    MailOrderStatus.CONTACTING_PRESCRIBER.toString(),
    MailOrderStatus.HOLD_TILL_REQUESTED.toString(),
    MailOrderStatus.CANCELLED.toString()
  ]
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  public showTable: boolean = false;
  constructor(private readonly router: Router, public eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute, private benefitService: BenefitManagementService, private readonly appInsightService: AppInsightsService, private formBuilder: FormBuilder,
    private readonly dialog: MatDialog, private sharedService: SharedService, private loader: LoaderService,
    private readonly appInsightsService: AppInsightsService) { }

  ngOnInit(): void {
    this.subscription.add(this.sharedService.accessLevel$.subscribe((v) => {
      this.userAccessLevel = v;
    }));
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        return this.eligibilityService.getMemberDetailsById(res).pipe(
          map((res) => {
            this.memberDetails = res[0];
            return res[0];
          }),
          tap(() => this.checkGroupLevelAccess()),
          tap(() => this.loadMailOrders()),
        );
      })
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  private loadMailOrders() {
    this.appInsightService.trackPageView(AppCenterAnalytics.MAIL_ORDERS);
    const requiredValidator = Validators.required;
    this.mailOrdersSearchFormGroup = this.formBuilder.group(
      {
        formDateControl: [new Date(new Date().getFullYear(), 0, 1), // set default start date to beginning of the current year
          requiredValidator,
        ],
        toDateControl: [new Date(), // set default end date to current date
          requiredValidator,
        ],
      },
      { validators: this.dateRangeValidator }
    );
  }

  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const fromDate = control.get('formDateControl').value;
    const toDate = control.get('toDateControl').value;

    if (fromDate && toDate && fromDate > toDate) {
      control.get('formDateControl').setErrors({ dateInvalid: true });
      control.get('toDateControl').setErrors({ dateInvalid: true });
      return { dateInvalid: true };
    }
  }

  private checkGroupLevelAccess() {
    this.subscription.add(this.benefitService.getClientsListByAccess().pipe(
      tap((clients) => {
        const selectedClient = clients.find(c => c.clientId === this.memberDetails.clientId);
        this.migratedFlag = selectedClient?.migrationFlag ? selectedClient?.migrationFlag : 'N';
        this.eligibilityService.updateIsMigratedValue(selectedClient.migrationFlag && selectedClient.migrationFlag === 'Y')
        this.eligibilityService.updateIsGroupLevelAccessValue(selectedClient?.groupLevelFlag);
      })
    ).subscribe())
  }

  public searchMailOrder(): void {
    this.showTable = false;
    this.isLoading = true;
    this.getMailOrderList();
  }
  public dateDifferenceValidator(): null | { dateDifference: true } {
    const startDate = this.mailOrdersSearchFormGroup.value.formDateControl;
    const endDate = this.mailOrdersSearchFormGroup.value.toDateControl;

    if (startDate && endDate) {
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const differenceInYears =
        differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);

      if (differenceInYears > 2) {
        return { dateDifference: true };
      }
    }
    return null;
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  getMailOrderList(): void {
    this.isLoading = true;
    this.loader.showLoader();
    let reqObj = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
      personCode: this.memberDetails.personCode,
      startDate: formatDate(
        new Date(this.mailOrdersSearchFormGroup.value.formDateControl)
      ),
      endDate: formatDate(
        new Date(this.mailOrdersSearchFormGroup.value.toDateControl)
      ),
      migrationFlag: this.migratedFlag,
      accessLevel: this.userAccessLevel
    }
    this.appInsightsService.trackPageView(AppCenterAnalytics.MAIL_ORDER_API_REQUEST_PAYLOAD, { data: reqObj });
    this.subscription.add(this.eligibilityService.getMailOrders(reqObj).subscribe((res) => {
      if (res) {
        this.appInsightsService.trackPageView(AppCenterAnalytics.MAIL_ORDER_API_SUCCESS);
        this.loader.hideLoader();
        this.showTable = true;
        this.isLoading = false;
        this.mailOrders = this.mapOrderAndPrescription(res.mailOrders);
        this.totalLength = this.mailOrders.length;
        this.mailOrders = this.mailOrders.sort((a, b) => new Date(b.receivedDate) as any - (new Date(a.receivedDate) as any));
        this.dataSource = new MatTableDataSource(this.mailOrders);
        this.setPaginator();
      }
    }, (err) => {
      this.appInsightsService.trackPageView(AppCenterAnalytics.MAIL_ORDER_API_ERROR, { Response: err });
      this.loader.hideLoader();
    }))
  }
  setPaginator(): void {
    this.dataSource.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (
        data: any,
        sortHeaderId: string
      ): string => {
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
      };
    }, 0);
  }
  public search(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.totalLength = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public mapOrderAndPrescription(orders: MailOrder<Array<PrescriptionFill>>[]): MailOrder<PrescriptionFill>[] {
    const newFlatOrders: any = [];
    orders.forEach(order => {
      if (order.renewalRequest) {
        order.prescriptionFills = [order.renewalRequest];
      }
      order.prescriptionFills = order.prescriptionFills ? order.prescriptionFills : [];
      order.prescriptionFills.forEach((prescriptionFill: any) => {
        const newObj = JSON.parse(JSON.stringify(order));
        newObj.prescriptionFills = JSON.parse(JSON.stringify(prescriptionFill));
        newFlatOrders.push(newObj);
      });
    });
    return newFlatOrders;
  }


  public openDialog(mailOrder: MailOrder<PrescriptionFill>): void {
    const dialogRef: MatDialogRef<MailOrderDetailsDialogComponent> =
      this.dialog.open(MailOrderDetailsDialogComponent, {
        autoFocus: false,
        minWidth: window.innerWidth < 766 ? 'auto' : 760,
        maxHeight: 550,
        panelClass: 'mail-order-modalbox',
        data: mailOrder
      });
  }
  public statusChange(): void {
    this.searchList = '';
    if (this.status == OrderStatus.All) {
      let displayData2 = this.mailOrders;
      this.dataSource = new MatTableDataSource(displayData2);
      this.totalLength = displayData2.length;
    } else {
      let displayData2 = this.mailOrders.filter((o) => o.orderStatus === this.status);
      this.dataSource = new MatTableDataSource(displayData2);
      this.totalLength = displayData2.length;
    }
    this.setPaginator();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
