<main class="main-page full-height overflow-hidden" fxLayout="column">
  <div class="top-header">
    <app-header (sideBarToggle)="isSideBarClosed = $event" [showClientName]="false"></app-header>
  </div>
  <!-- <button mat-button mat-dialog-close (click)="navigateToCoPaySchedule()">Co-pay Schedule</button> -->
  <div class="dashboard-wrapper full-height" [ngClass]="{ closed: isSideBarClosed  , mobileClosed: isSideBarClosed && innerWidth < 766}" fxLayout="row">
    <div class="dashboard-sidenav overflow-auto">
      <sidebar (sideBarToggleSubMenu)="onMenuToggle($event)"></sidebar>
    </div>
    <div class="dashboard-content full-height" fxLayout="column">
      <div class="full-height">
        <div class="full-height">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="footer text-center closed">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</main>

<div class="global-loader" fxLayoutAlign="center center" *ngIf="isShowLoader">
    <img src="assets/images/loader.gif" class="loader-img" alt="loader" />
    <p *ngIf="message">{{message}}</p>
</div>