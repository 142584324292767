<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <!-- Page Header -->
  <div class="page-header">
    <h2>Add a New Dependent</h2>
  </div>
  <ng-container *ngIf="clientInfo$ | async as clientInfo"></ng-container>
  <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <!-- Member Detail card -->
    <div class="detail-card">
      <div class="client-id-name">
        <h4>Member</h4>
        <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
      </div>
      <div class="mat-row">
        <div class="mat-2">
          <label class="card-label">Name</label>
          <p>
            {{ selectedMemberDetails.firstName }}
            {{ selectedMemberDetails.lastName }}
          </p>
        </div>
        <div class="mat-2">
          <label class="card-label">Person Code</label>
          <p>{{ selectedMemberDetails.personCode }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">DOB</label>
          <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Relationship</label>
          <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Card/Alt ID</label>
          <p>{{ selectedMemberDetails.cardId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Coverage Type</label>
          <p>
            {{
            displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
            }}
          </p>
        </div>
      </div>
    </div>

    <!-- Member Coverage | Dependant -->
    <div class="see-more-accordian mar-b-1" *ngIf="groupInfo$ | async as clientInfo">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Member Coverage </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" class="member-coverage-details">
            <div class="mat-row">
              <div class="mat-6">
                <label class="card-label">Group</label>
                <p>{{ memberDetails.coverage?.groupNumber }}</p>
              </div>
              <div class="mat-2">
                <label class="card-label">Effective Date</label>
                <p>{{ memberDetails.coverage?.startDate | timeStampToDate }}</p>
              </div>
              <div class="mat-3">
                <label class="card-label">End Date</label>
                <p>-</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="dependents-accordion">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dependents ({{ totalDependents }})
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" *ngIf="dependents$ | async as dependents" class="dependent-table">
            <table class="mar-t-1" mat-table [dataSource]="dataSource">
              <!--['name',  'cardId', 'personCode', 'relationshipCode', 'gender', 'DOB', 'startDate', 'endDate'];-->
              <ng-container matColumnDef="name">
                <th scope="col" mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.firstName }} {{ row.lastName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="cardId">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Card/Alt ID
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ memberDetails.cardId }}
                </td>
              </ng-container>

              <ng-container matColumnDef="personCode">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Person Code
                </th>
                <td mat-cell *matCellDef="let row">{{ row.personCode }}</td>
              </ng-container>

              <ng-container matColumnDef="relationshipCode">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Relationship
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.relationshipCode }} - {{ row.relationshipValue }}
                </td>
              </ng-container>

              <ng-container matColumnDef="gender">
                <th scope="col" mat-header-cell *matHeaderCellDef>Gender</th>
                <td mat-cell *matCellDef="let row">
                  {{ displayGender[row.gender] }}
                </td>
              </ng-container>

              <ng-container matColumnDef="DOB">
                <th scope="col" mat-header-cell *matHeaderCellDef>DOB</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.DOB | timeStampToDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="startDate">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Cov. Effective Date
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.coverage.startDate | timeStampToDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Cov. End Date
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.coverage.endDate | timeStampToDate }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr class="mat-row no-data-row" *matNoDataRow>
                <td class="mat-cell" colspan="9" role="status">
                  No Data Found
                </td>
              </tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <mat-divider></mat-divider>
    <h3 class="form-heading">Add Dependent Details</h3>
    <mat-horizontal-stepper class="add-new-member-stepper add-dependent-details-stepper" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="personalIfoFormGroup">
        <form [formGroup]="personalIfoFormGroup">
          <ng-template matStepLabel>Personal</ng-template>
          <div class="form-wrapper">
            <div class="mat-row pad-b-1">
              <div class="mat-6 pad-r-2">
                <mat-label>First Name<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input matInput placeholder=" Enter First Name" formControlName="firstName" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="firstName"></app-custom-mat-hint>
              </div>
              <div class="mat-6">
                <mat-label>Gender<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <mat-select placeholder="Select Gender" formControlName="gender">
                    <mat-option *ngFor="let gender of genderList" [value]="gender.key">{{ gender.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="gender"></app-custom-mat-hint>
              </div>
            </div>
            <div class="mat-row pad-b-1">
              <div class="mat-6 pad-r-2">
                <mat-label>Middle Name</mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input matInput placeholder="Enter Middle Name" formControlName="middleName" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="middleName"></app-custom-mat-hint>
              </div>
              <div class="mat-6">
                <mat-label>DOB<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
                  <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="dob"
                    placeholder="Enter DOB" />
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="dob"></app-custom-mat-hint>
              </div>
            </div>
            <div class="mat-row pad-b-1">
              <div class="mat-6 pad-r-2">
                <mat-label>Last Name<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input matInput placeholder="Enter Last Name" formControlName="lastName" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="lastName"></app-custom-mat-hint>
              </div>
              <!-- <div class="mat-6">
                        <mat-label>Person Code</mat-label>
                        <mat-form-field floatLabel="never" class="full-width">
                            <mat-select placeholder="Please Select Person Code" formControlName="personCode"
                                required>
                                <mat-option *ngFor="let code of personCodes" [value]="code">{{ code
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-custom-mat-hint [formGroup]="personalIfoFormGroup"
                            [isCheckTouch]="checkTouchForPersonalInfo"
                            controlName="personCode"></app-custom-mat-hint>
                    </div> -->
              <div class="mat-6">
                <mat-label>Relationship<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <mat-select placeholder="Select Relationship" formControlName="relationShip">
                    <mat-option *ngFor="let relation of relationshipData" [value]="relation.value">{{ relation.name
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="relationShip"></app-custom-mat-hint>
              </div>
            </div>
            <div class="mat-row pad-b-1">
              <div class="mat-6 pad-r-2">
                <mat-label>SSN</mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <input matInput appSsnFormat type="text" placeholder="Enter SSN (999-99-9999)"
                    formControlName="ssn" />
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="ssn"></app-custom-mat-hint>
              </div>
              <div class="mat-6">
                <mat-label>Effective Date<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width mat-datepicker-alignment">
                  <input matInput [matDatepicker]="picker1" formControlName="effectiveDate"
                    placeholder="Enter Effective Date" />
                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="effectiveDate"></app-custom-mat-hint>
              </div>
            </div>
            <div class="mat-row pad-b-1">
              <div class="mat-6 pad-r-2">
                <mat-label>Coverage Type<span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <mat-select matNativeControl placeholder="Select Coverage Type" formControlName="coverageType"
                    [disabled]="true">
                    <mat-option value="FAM"> Family </mat-option>
                    <mat-option value="IND">Individual</mat-option>
                  </mat-select>
                </mat-form-field>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="coverageType"></app-custom-mat-hint>
              </div>
              <div class="mat-6" *ngIf="memberDetails.clientInternalMemberId">
                <mat-label>Client Internal Member ID <span class="asterisk-mark">*</span></mat-label>
                <mat-form-field floatLabel="never" class="full-width">
                  <mat-select matNativeControl placeholder="Select Client Internal Member ID"
                    formControlName="clientInternalMemberId">
                    <ng-container>
                      <mat-option *ngFor="let locCode of locationCode" [value]="locCode.value">
                        {{ locCode.displayName }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
                <mat-hint class="text-danger title-11" *ngIf="locationCode?.length === 0">
                  No Client Internal Member IDs found.
                </mat-hint>
                <app-custom-mat-hint [formGroup]="personalIfoFormGroup" [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="clientInternalMemberId"></app-custom-mat-hint>
              </div>
            </div>
            <!-- <div class="mat-row pad-b-1">
              <div class="mat-6">
                <mat-label class="radio-label"
                  >Order ID Card<span class="asterisk-mark">*</span></mat-label
                >
                <mat-radio-group
                  floatLabel="never"
                  class="full-width"
                  formControlName="orderIdCard"
                >
                  <mat-radio-button value="true">Yes</mat-radio-button>
                  <mat-radio-button value="false">No</mat-radio-button>
                </mat-radio-group>
                <app-custom-mat-hint
                  [formGroup]="personalIfoFormGroup"
                  [isCheckTouch]="checkTouchForPersonalInfo"
                  controlName="orderIdCard"
                ></app-custom-mat-hint>
              </div>
            </div> -->
            <div fxLayout="row" class="button-row">
              <div class="mat-12" fxLayoutAlign="end end">
                <button class="primary-btn" mat-button matStepperNext (click)="checkTouchForPersonalInfo = true">
                  Next
                </button>
                <button mat-button (click)="navigateBackToMemberSearch()" class="secondary-btn">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="contactInfoFormGroup">
        <form [formGroup]="contactInfoFormGroup">
          <ng-template matStepLabel>Contact</ng-template>
          <h4>Phone Number</h4>
          <div fxLayout="row" class="pad-b-1">
            <div class="mat-6 pad-r-2">
              <mat-label>Type<span class="asterisk-mark"></span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <mat-select matNativeControl placeholder="Select Phone Type" formControlName="phoneType">
                  <mat-option *ngFor="let type of phoneTypes" [value]="type.value">{{ type.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="contactInfoFormGroup" [isCheckTouch]="checkTouchForContactInfo"
                controlName="phoneType"></app-custom-mat-hint>
            </div>
            <div class="mat-6">
              <mat-label>Phone Number<span class="asterisk-mark"></span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input appPhoneNumberFormat matInput type="text" placeholder="Enter Number (555-555-5555)"
                  formControlName="phoneNumber" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="contactInfoFormGroup" [isCheckTouch]="checkTouchForContactInfo"
                controlName="phoneNumber"></app-custom-mat-hint>
            </div>
          </div>

          <h4>Email</h4>
          <div fxLayout="row" class="pad-b-1">
            <div class="mat-6 pad-r-2">
              <mat-label>Email Id<span class="asterisk-mark"></span></mat-label>
              <mat-form-field floatLabel="never" class="full-width">
                <input matInput placeholder="Enter Email" formControlName="email" />
              </mat-form-field>
              <app-custom-mat-hint [formGroup]="contactInfoFormGroup" [isCheckTouch]="checkTouchForContactInfo"
                controlName="email"></app-custom-mat-hint>
            </div>
          </div>
          <div fxLayout="row" class="button-row">
            <div class="mat-6">
              <button mat-button matStepperPrevious class="secondary-btn">
                Back
              </button>
            </div>
            <div class="mat-6" fxLayoutAlign="end end">
              <button mat-button [disabled]="contactInfoFormGroup.invalid" (click)="addIndependent()"
                class="primary-btn">
                Save
              </button>
              <button mat-button (click)="cancel()" class="secondary-btn">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>
</div>