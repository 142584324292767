<main class="main-page full-height overflow-hidden" fxLayout="column">
  <div class="top-header">
    <app-header [showSideBarMenu]="false" [showClientName]="false"></app-header>
  </div>

  <div class="dashboard-content full-height overflow-hidden" style="padding: 4rem" fxLayout="column">
    <div class="dashboard-wrapper full-height" fxLayout="row" style="background: none">
      <div fxLayout="row" class="full-width" fxLayout.xs="column">
        <div class="welcome-contnet" fxLayoutAlign="left center">
          <div class="hero-text hero-text-full">
            <h1>
              <span>{{ constants.LABELS.welcome }} </span>
            </h1>
            <p>{{ constants.ERROR.MESSAGES.DESCRIPTION_NEW }}</p>
            <p>{{ constants.ERROR.MESSAGES.DESCRIPTION_NEW_2 }}</p>
          </div>
        </div>
        <div class="welcome-buttons" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
          <button mat-raised-button *ngIf="hasReportAccess" (click)="navigateToReports()">
            <img src="../../../../assets/images/reports.svg" alt="" /> Reports
          </button>
          <button mat-raised-button (click)="navigateToManagement()">
            <img src="../../../../assets/images/manage.svg" alt="" />
            Eligibility and Benefits
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center welcome-footer">
    <div class="flex justify-content-between">
      <span class="content-left">&copy; {{currentYear}} EmpiRx Health, LLC. All Rights Reserved. | 
        <a href="/termsAndConditions"  target="_blank" rel="noopener noreferrer">Terms of Use </a> </span>  
      <span class="content-right">Technical Support: 844-358-3961</span>
    </div>
  </div>
</main>