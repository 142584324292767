<h4 class="modal-header">
  Edit Address
  <span class="close-icon-dialog"><mat-icon (click)="cancel()">close</mat-icon></span>
</h4>
<ng-container *ngIf="groupInfo$ | async as groupInfo"></ng-container>
<form [formGroup]="updateAddressFormGroup" class="update-address">
  <div class="form-wrapper">
    <div class="mat-row pad-b-1">
      <div class="mat-6 pad-r-2">
        <mat-label>Address Line 1<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <input matInput placeholder="Enter Address Line 1" formControlName="addressLine1" />
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateAddressFormGroup" controlName="addressLine1"></app-custom-mat-hint>
      </div>
      <div class="mat-6">
        <mat-label>Zip Code<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <input (keydown)="numericOnly($event)" type="text" matInput placeholder="Enter Zip Code"
            formControlName="zipCode" />
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateAddressFormGroup" controlName="zipCode"></app-custom-mat-hint>
      </div>
    </div>
    <div class="mat-row pad-b-1">
      <div class="mat-8 pad-r-2">
        <mat-label>Address Line 2<span class="asterisk-mark"></span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <input matInput placeholder="Enter Address Line 2" formControlName="addressLine2" />
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateAddressFormGroup" controlName="addressLine2"></app-custom-mat-hint>
      </div>
      <div class="mat-6 pad-r-2">
        <mat-label>State<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <mat-select placeholder="Select State" formControlName="state" matNativeControl>
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{ state.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateAddressFormGroup" controlName="state"></app-custom-mat-hint>
      </div>
      <div class="mat-6">
        <mat-label>City<span class="asterisk-mark">*</span></mat-label>
        <mat-form-field floatLabel="never" class="full-width">
          <input matInput placeholder="Enter City" formControlName="city" />
        </mat-form-field>
        <app-custom-mat-hint [formGroup]="updateAddressFormGroup" controlName="city"></app-custom-mat-hint>
      </div>
    </div>
    <ng-container
      *ngIf="dialogData?.memberDetails?.relationshipCode === cardHolderRC && dependents$ | async as dependentsData">
      <div class="mat-row pad-b-1 dependents-drop" *ngIf="dependentsData.length">
        <div class="mat-12">
          <mat-label>Select dependents</mat-label>
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Select" formControlName="dependents" multiple>
              <mat-option *ngFor="let value of dependentsData" [value]="value.patientId">
                {{ value.firstName }} {{ value.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-custom-mat-hint [formGroup]="updateAddressFormGroup" controlName="dependents"></app-custom-mat-hint>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="mat-row dialog-btn-row">
    <div class="mat-12" fxLayoutAlign="end end">
      <button mat-button class="primary-btn" [disabled]="!checkIfAnythingUpdated() || updateAddressFormGroup.invalid"
        (click)="save()">
        Save Changes
      </button>
      <button mat-button class="secondary-btn" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</form>