import { AppConstants } from './app.constants';

export function numericOnly(event: KeyboardEvent): void {
  const allowedKeys: Array<string> = [
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ];
  const pattern = /[0-9]/;

  if (allowedKeys.includes(event.key) || pattern.test(event.key)) {
    return;
  }

  event.preventDefault();
}

export function onlyCharSingleSpace(event: KeyboardEvent): void {
  const allowedKeys: Array<string> = [
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ];
  const pattern = /^[^\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

  const value = (event.target as HTMLInputElement).value;
  const lastCharacterIsSpace = value.slice(-1) === ' ';
  const hasSpace = /\s/.test(value);

  if (
    allowedKeys.includes(event.key) ||
    (event.key === ' ' && !hasSpace) ||
    (event.key !== ' ' && pattern.test(event.key)) ||
    (event.key === ' ' && lastCharacterIsSpace)
  ) {
    return;
  }

  event.preventDefault();
}

export function formatDateToYYYYMMDD(dateString: string) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}/${month}/${day}`;
}

export function formatDate(date) {
  if (date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 since months are 0-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  } else {
    return date;
  }
}

export function getErrorMessage(err) {
  return err?.error?.Message && checkMessageExists(err) ? err.error.Message : AppConstants.ERROR.GENERIC_ERROR_MESSAGE
}

function checkMessageExists(err) {
  return AppConstants.messageArray.includes(err?.error?.Message.trim()) || err?.error.Message.includes('This member already has an active eligibile coverage with card ID');
}

export function convertJSONToQueryParams(obj: object): string {
  return Object.entries(obj)
    .filter(
      ([key, value]) => value !== undefined && value !== null && value !== ''
    )
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
}

export function fromDateToUTCString(date: Date): string {
  const fromUTCDate = date ? new Date(date) : null;
  let fromUTCString = '';
  if (fromUTCDate) {
    fromUTCDate.setUTCHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to UTC
    fromUTCDate.setMinutes(fromUTCDate.getTimezoneOffset()); // Adjust for local timezone offset
    fromUTCDate.setDate(fromUTCDate.getDate() + 1); // Add one day to match the day
    fromUTCString = fromUTCDate.toISOString();
    // console.log('Converted fromUTCDate:', fromUTCDate);
    // console.log('Resulting fromUTCString:', fromUTCString);
  }
  return fromUTCString;
}

export function toDateToUTCString(date: Date): string {
  const toUTCDate = date ? new Date(date) : null;
  let toUTCString = '';
  if (toUTCDate) {
    toUTCDate.setUTCHours(23, 59, 59, 999); // Set hours, minutes, seconds, and milliseconds to UTC
    toUTCDate.setMinutes(toUTCDate.getTimezoneOffset()); // Adjust for local timezone offset
    // toUTCDate.setDate(toUTCDate.getDate() + 1); // Add one day to match the day
    toUTCString = toUTCDate.toISOString();
    // console.log('Converted toUTCDate:', toUTCDate);
    // console.log('Resulting toUTCString:', toUTCString);
  }
  return toUTCString;
}

export function formatDateToMMDDYYYY(inputDateString: string): string {
  const dateObject = new Date(inputDateString);

  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');
  const year = dateObject.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export function formatSSN(ssn: string): string {
  if (ssn && ssn.toLowerCase() !== 'null') {
    const regex = /^(\d{3})(\d{2})(\d{4})$/;
    const match = ssn.match(regex);

    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    } else {
      // Handle invalid input (not exactly 9 digits)
      return ssn;
    }
  } else {
    return ssn;
  }
}

export function clearSsnFormat(formattedSsn: string): string {
  if (formattedSsn)
    return formattedSsn.replace(/\D/g, ''); // Remove non-numeric characters
  else return '';
}


export function formatPhone(phone: string): string {
  const regex = /^(\d{3})(\d{3})(\d{4})$/;
  const match = phone.match(regex);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  } else {
    // Handle invalid input (not exactly 10 digits)
    return phone;
  }
}

export function clearPhoneFormat(formattedPhone: string): string {
  if (formattedPhone)
    return formattedPhone.replace(/\D/g, ''); // Remove non-numeric characters
  else return '';
}

export function trimIfUNE(inputString: string) {
  // Check if the first three letters are "UNE"
  if (inputString.substring(0, 3).toLowerCase() === 'une') {
    // Trim the first three letters and remove extra spaces
    const trimmedString = inputString.substring(3).replace(/\s+/g, '');
    return trimmedString.trim();
  }

  // Return the original string if it doesn't start with "UNE"
  return inputString;
}
